import SectionHeader from '../../../../Componenets/atomic-atoms/SectionHeader';
import ComingSoon from '../../../../Componenets/atomic-atoms/ComingSoon';

function TemplateCategories() {
  return (
    <>
      <SectionHeader title="Template Categories" />
      <ComingSoon title="Template Categories" />
    </>
  );
}

export default TemplateCategories;
