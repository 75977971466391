import React, { useState, useEffect, useRef, useMemo } from 'react';
import './stream.css';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme } from '@mui/material/styles';
import {
  AvatarGroup,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Grid,
  Box,
  Rating,
  TextField,
} from '@mui/material';
import { EditorState, ContentState, Entity } from 'draft-js';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import { motion, AnimatePresence } from 'framer-motion';
import {
  estimate_channels,
  generalChat,
  getAllMsg,
  getMessages,
  getTeam,
  projectChat,
} from './streamRedux';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import ChatBody from './ChatBody';
import 'draft-js/dist/Draft.css';
import { AddFav } from './streamRedux';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg';
import Bounce from 'react-reveal/Bounce';
import { useLocation } from 'react-router-dom';
import ChatHelpVideos from './ChatHelpVideos';
import RichTextEditor from '../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import Skeleton from '@mui/material/Skeleton';
import SimpleDialog from '../../Componenets/atomic-organisms/RichTextEditor/SimpleDialog';
import { appRoutes } from '../../appRoutes';
import TitleBar from '../../Componenets/atomic-molecules/Headers/TitleBar';

const Stream = ({
  setCurrentTargetOfEstiimate,
  setEstimateProLoading,
  currentTargetOfEstimate,
  estimateProLoading,
  proLoading,
  setProLoading,
  currentProDils,
  setCurrentDil,
  setCurrentTarget,
  currentTarget,
  setPage,
  page,
  setEstimateMessage,
  estimateMsg,
  screenHeight,
  currentProject,
  setCurrentProject,
  setLocalProjectState,
  localProjectState,
}) => {
  const location = useLocation();
  const notificationData = location.state?.notificationData || null;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const access_token = useSelector((state) => state.auth.user.access);
  const chatData = useSelector((state) => state.Stream.data);
  const allMsg = useSelector((state) => state.Stream.allMsg);
  const userTeam = useSelector((state) => state.userTeam.team);
  const Projects = useSelector((state) => state.projects.projects);
  const [channelMsg, setChannelMsg] = useState();
  const [projectId, setProjectId] = useState();
  const [estiId, setEstiId] = useState();
  const [proChat, setProChat] = useState([]);
  const [projectNew, setProjectNew] = useState();

  const getChannelMessage = async (chatId) => {
    try {
      const response = await fetch(
        `${ENDPOINT}/api/chat/channel/detail/${chatId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const data = await response.json();
      if (!response.ok) {
        // Handle error
      } else {
        setChannelMsg(data);
      }
    } catch (error) {
      // Handle error
    }
  };

  const apiInitialProChat = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/api/chat/channel/list/new/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (!response.ok) {
        // Handle error
      } else {
        setProChat(data);
        // Assuming you want to work with the first channel in the list
        if (data.length > 0) {
          getChannelMessage(data[0].id); // Fetch details for the first channel
        }
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    apiInitialProChat();
  }, []);

  useEffect(() => {
    if (proChat.length > 0) {
      const estimateChat = proChat
        .filter((item) => item.estimate && !item.deliverable)
        ?.sort((a, b) => a.name.localeCompare(b.name));
      setEstiId(estimateChat);
      const projectChat = proChat
        .filter((item) => item.project && !item.deliverable)
        ?.sort((a, b) => a.name.localeCompare(b.name));
      if (!userData?.is_owner || !userData?.is_admin) {
        const filtered = projectChat.filter((item) => {
          if (item?.subteam && Array.isArray(item?.subteam?.members)) {
            return item?.subteam?.members?.some(
              (member) => member?.id === userData.id,
            );
          }
          return false;
        });
        setProjectId(filtered);
      } else {
        setProjectId(projectChat);
      }
    }
  }, [proChat]);

  const teamMentions =
    userTeam && userTeam.member
      ? userTeam.member.map((item) => ({
          name: item.first_name,
          id: item.id,
        }))
      : { name: 'No team mates' };
  const [dotDropDown, setDotDropDown] = useState(null);
  const Dotopen = Boolean(dotDropDown);
  const handleDotDropDown = (event) => {
    setDotDropDown(event.currentTarget);
  };
  const handleDotDropDownClose = () => {
    setDotDropDown(null);
  };

  const [searchField, setSearchField] = useState('button');

  useEffect(() => {
    let isMounted = true;

    if (notificationData) {
      if (notificationData.notification_type === 'message') {
        const member = userTeam.member?.find(
          (item) => item.id === notificationData.notification_sender,
        );
        setPage('people');
        setCurrentTarget(member);
      } else if (notificationData.notification_type === 'user_mention') {
        setEstimateMessage(null);

        const find = estiId?.find(
          (item) => item?.id === notificationData?.notification_channel,
        );
        const project = projectId?.find(
          (item) => item?.project?.id === notificationData.notification_project,
        );
        if (find) {
          async function fetchChatChannelDetails(projectId, find) {
            setPage('estimate');
            setActive(false);
            const res = await fetch(
              `${ENDPOINT}/api/chat/channel/detail/${projectId}/`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT${access_token}`,
                },
              },
            );
            const data = await res.json();
            if (!res.ok) {
            }
            if (data) {
              const res2 = await fetch(
                `${ENDPOINT}/api/opportunity/estimate/${find?.estimate?.unique_id}/`,
                {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT${access_token}`,
                  },
                },
              );
              const data2 = await res2.json();

              if (res2.ok) {
                if (data2) {
                  setEstimateMessage(data?.message_in_channel);
                  setPage('estimate');
                  setCurrentTargetOfEstiimate(data2);
                  setCurrentTarget({
                    ...find,
                    title: find?.title,
                    estimate: true,
                  });
                  setEstimateProLoading(false);
                }
              } else {
                console.error(data2, 'Errro');
              }
            }
          }
          fetchChatChannelDetails(find.id, find);
        } else if (project) {
          async function fetchChatChannelDetails(project) {
            try {
              const response = await fetch(
                `${ENDPOINT}/api/chat/channel/detail/${project?.id}/`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `JWT ${access_token}`,
                    'Content-Type': 'application/json',
                  },
                },
              );

              if (!response.ok) {
                throw new Error('Network response was not ok');
              }

              const data = await response.json();
              if (data && data?.message_in_channel) {
                setCurrentProject(project);
                setLocalProjectState(data?.message_in_channel);
                setCurrentTarget(data);
                setPage('chat');
              } else {
              }
              setProLoading(false);
            } catch (error) {
              setProLoading(false);
            }
          }
          fetchChatChannelDetails(project);
        } else {
          setPage('general');
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [notificationData, projectId, estiId]);

  const [messageScroll, setMesssageScroll] = useState();

  const myfunc = (chatBodyData) => {
    setMesssageScroll(chatBodyData);
    if (chatBodyData.estimate !== null) {
      setPage('estimate');
      setCurrentTarget({
        ...chatBodyData,
        title: chatBodyData.name,
        estimate: true,
      });
      setEstimateMessage(chatBodyData?.message_in_channel);
    } else if (chatBodyData.project) {
      setPage('chat');
      setCurrentTarget({
        ...chatBodyData,
        title: chatBodyData.name,
      });
      setLocalProjectState(chatBodyData.message_in_channel);
    } else if (
      chatBodyData.estimate === null &&
      chatBodyData.project === null
    ) {
      setPage('general');
    }
  };

  const [userMsg, setUserMsg] = useState([]);

  //  DROPDOWN
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    const element = document.getElementsByClassName('project-people-estimate');
    setAnchorEl(element[0]);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // DROPDOWN 2
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    const element = document.getElementsByClassName('project-people-estimate');
    setAnchorEl2(element[0]);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const meta =
    userTeam && userTeam.member
      ? userTeam.member.map((item) => ({
          name: item.first_name,
          id: item.id,
          avatar: item.avatar,
        }))
      : { name: 'No Team Members Yet' };

  const length =
    userTeam && userTeam?.member
      ? userTeam?.member
          .map((item) => {
            if (item.first_name === 'First Name') {
              return null; // Return null if the first_name is not 'First Name'
            }
            return {
              name: item.first_name,
              id: item.id,
              avatar: item.avatar,
            };
          })
          .filter(Boolean) // Filter out null values from the array
      : [{ name: 'No Team Members Yet' }];
  // ------------------------PEOPLE CHAT MESSAAGE CODE ----------------------
  //
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  useEffect(() => {
    setUserMsg(allMsg);
  }, [allMsg]);

  // ---------------------PROJECT CHAT MESSAGE CODE HERE------------------

  const [projects, setProjects] = useState();

  useEffect(() => {
    if (page === 'chat') {
      if (currentTarget.project_subteam) {
        const preciceTarget = proChat.filter(
          (item) => item.id === currentTarget.id,
        )[0];

        setProjects(preciceTarget);

        setLocalProjectState(
          preciceTarget?.project_subteam[0]?.subteam_channel[0]
            ?.message_in_channel ?? [],
        );
      }

      console.error(projects, 'Proje');
    }
  }, [currentTarget]);

  function decodeHtmlEntities(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
  }

  const unreadProjectMessages = localProjectState
    ? localProjectState.filter((item) => item.seen === null || false)
    : [];

  const projectTeam =
    currentProject && currentProject.project_subteam
      ? currentProject.project_subteam[0].members.map((item) => {
          return { name: item.first_name, id: item.id };
        })
      : { name: 'No Subteam Yet' };

  const project_deliverable =
    currentProject && currentProject.deliverable_project
      ? currentProject.deliverable_project.map((item) => {
          return item.deliverable_name;
        })
      : { name: 'No Subteam Yet' };
  // ---------------------General CHAT MESSAGE CODE HERE------------------
  const [localGeneral, setLocalGeneral] = useState([]);
  const generalId = useSelector((state) => state.Stream.general);

  useEffect(() => {
    if (generalId) {
      if (generalId[0]) {
        setLocalGeneral(generalId[0].message_in_channel);
      }
    }
  }, [generalId]);

  const userName = useSelector(
    (state) => state?.userData?.userData?.first_name,
  );

  const [mentionState, setMentionState] = useState([]);
  const all_channels_data = useSelector((state) => state.Stream.data);

  useEffect(() => {
    const regexPattern = new RegExp(
      `<span data-type="mention" class="deliverable-mention" data-id="@${userName}">@${userName}</span>`,
      'i',
    );

    if (Array.isArray(localGeneral) && localGeneral.length) {
      const shallowCopy = localGeneral.map((item) => ({ ...item }));
      const filteration = shallowCopy.filter((msg) =>
        regexPattern.test(msg.message),
      );

      const newState =
        Array.isArray(all_channels_data) && all_channels_data.length
          ? all_channels_data.flatMap((it) =>
              it.message_in_channel.filter((messgae) =>
                regexPattern.test(messgae.message),
              ),
            )
          : [];
      const final = [...filteration].concat(newState);

      setMentionState(final);
    }
  }, [page]);

  const memoizedMentionState = useMemo(() => mentionState, [mentionState]);
  const fav = useSelector((state) => state?.Stream?.fav ?? []);

  const [active, setActive] = useState(5);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const [search, setSearch] = useState('');

  const generalUnread = localGeneral
    ? localGeneral.filter((item) => item.seen === null || false)
    : [];

  const userMsgUnread = userMsg
    ? userMsg.filter((item) => item.seen === null || false)
    : userMsg;

  const mentionedUnread = memoizedMentionState
    ? memoizedMentionState?.filter(
        (message) =>
          !message?.read_message.some((item) => item?.read_by === userData.id),
      )?.length
    : [];

  const unreadAllChat =
    Array.isArray(chatData) && chatData.length
      ? chatData?.filter((item) => item.seen === null || false)
      : [];

  const [show, setShow] = useState(false);
  const [peopleMsg, setPeopleMsg] = useState();
  const [projectMessage, setProjectMessage] = useState();
  const editorRef = useRef(null);
  const [btn, setbtn] = useState();
  const check = (e) => {
    setbtn(e);
  };

  const [generalMsg, setGeneralMsg] = useState();
  const [estimateNewMsg, setEstimateNewMessage] = useState();
  const [notRun, setNotRun] = useState(false);

  const new_channals = useSelector((state) => state.Stream.new_channels);
  const [openLink, setOpenLink] = useState(false);

  useEffect(() => {
    dispatch(getAllMsg({ access_token }));
    dispatch(generalChat({ access_token }));
    dispatch(projectChat({ access_token }));
  }, []);
  const [linkUrl, setLinkUrl] = useState('');

  const setLink = () => {
    const url = linkUrl.trim(); // Trim whitespace from the input URL
    // Update link only if the URL is not empty
    if (url !== '') {
      btn.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }
    setOpenLink(false);
    setLinkUrl('');
  };

  return (
    <Bounce left>
      <section className="oppor-section">
        <SimpleDialog
          open={openLink}
          setOpen={setOpenLink}
          linkUrl={linkUrl}
          setLinkUrl={setLinkUrl}
          setLink={setLink}
        />

        <TitleBar
          helpIconFunction={() => setShow(true)}
          sectionTitle={
            page === 'allconversation'
              ? 'All Conversations'
              : currentTarget?.first_name
                ? currentTarget?.first_name
                : page === 'general'
                  ? '#General'
                  : page === 'myMentions'
                    ? 'My Mentions'
                    : currentTarget?.name
          }
          Dotopen={Dotopen}
          handleDotDropDown={handleDotDropDown}
          menuDotRequired={true}
          buttonRequired={false}
        />

        <Menu
          id="dor-menu"
          anchorEl={dotDropDown}
          open={Dotopen}
          onClose={handleDotDropDownClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleDotDropDownClose}>
            <Link
              to={appRoutes.archivedChats}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                color: '#03071e',
              }}
            >
              <InventoryIcon /> View Achieved Porjects
            </Link>
          </MenuItem>
          <MenuItem onClick={handleDotDropDownClose}>
            {' '}
            <SettingsIcon /> Settings
          </MenuItem>
        </Menu>
        <section className="netnet-chat-section">
          <section
            className="oppor-row g10-p5-bg-v-1"
            style={{
              border: '1px solid #D9D9D9',
              padding: '1.2rem 2rem',
              borderRadius: '8px',
              marginBottom: '1rem',
              overflow: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alingItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <button
                className={
                  page == 'allconversation' ? 'netnetc-btn-act' : 'netnetc-inc1'
                }
                onClick={() => setPage('allconversation')}
                style={{
                  borderTopRightRadius: page === 'allconversation' ? 0 : 0,
                  borderBottomRightRadius: page === 'allconversation' ? 0 : 0,
                }}
              >
                <span className={page == 'allconversation' ? 'me-lg-2' : ''}>
                  All Chats
                </span>
                {unreadAllChat.length > 0 && (
                  <span
                    className="py-1 px-lg-2 rounded-circle"
                    style={{
                      backgroundColor: '#00E6B8',
                      border: '2px solid #E9ECEF',
                      fontSize: '12px',
                      marginLeft: '5px',
                      color: '#fff',
                    }}
                  >
                    {unreadAllChat.length}
                  </span>
                )}
              </button>
              <button
                className={
                  page == 'general' ? 'netnetc-btn-act' : ' netnetc-inc2'
                }
                onClick={() => setPage('general')}
                style={{
                  borderTopLeftRadius: page === 'general' ? 0 : 0,
                  borderBottomLeftRadius: page === 'general' ? 0 : 0,
                }}
              >
                #General
                {generalUnread.length > 0 && (
                  <span
                    style={{
                      backgroundColor: '#00E6B8',
                      border: '2px solid #E9ECEF',
                      fontSize: '12px',
                      marginLeft: '5px',
                      color: '#fff',
                      padding:
                        page == 'general'
                          ? generalUnread.length > 9
                            ? '4px 6px'
                            : '4px 8px'
                          : generalUnread.length > 9
                            ? '5px 7px'
                            : '5px 10px',
                      borderRadius: '50%',
                    }}
                  >
                    {generalUnread.length}
                  </span>
                )}
              </button>

              <button
                className={
                  page == 'myMentions' ? 'netnetc-btn-act' : 'netnetc-inc3'
                }
                onClick={() => setPage('myMentions')}
                style={{
                  margin: ' 0px 15px',
                }}
              >
                @ My
                {mentionedUnread > 0 && (
                  <span
                    style={{
                      backgroundColor: '#00E6B8',
                      border: '2px solid #E9ECEF',
                      fontSize: '12px',
                      marginLeft: '5px',
                      color: '#fff',
                      padding:
                        page == 'myMentions'
                          ? mentionedUnread > 9
                            ? '4px 6px'
                            : '4px 8px'
                          : mentionedUnread > 9
                            ? '5px 7px'
                            : '5px 10px',
                      borderRadius: '50%',
                    }}
                  >
                    {mentionedUnread}
                  </span>
                )}
              </button>
              <Box
                ref={menuRef}
                className="project-people-estimate"
                sx={{
                  width: {
                    xs: '200%',
                    sm: '150%',
                    md: '100%',
                    lg: '80%',
                    xl: '80%',
                  },
                }}
              >
                <Grid sx={{ width: '100%' }} container direction="row">
                  <button
                    className="netnetc-slect project-tab-btn"
                    style={{
                      border:
                        currentTarget?.name ||
                        (currentTarget?.first_name && 'none'),
                      borderRight: '1px solid #D9D9D9',
                      width: `100%`,
                      minWidth: '180px',
                      borderRadius: currentTarget?.name
                        ? '4px'
                        : '8px 0 0px 8px',
                      backgroundColor:
                        currentTarget?.name && !currentTarget.estimate
                          ? '#711fff'
                          : active === 2 || active === 3
                            ? '#ebe9e9'
                            : '#fff',
                      fontWeight: active === 2 || active === 3 ? '400' : '600',
                      color:
                        currentTarget?.name && !currentTarget.estimate
                          ? '#fff'
                          : active === 2 || active === 3
                            ? 'gray'
                            : '#000',
                      padding: currentTarget?.name && '0px 2rem',
                    }}
                    onClick={() => {
                      handleClick();
                      if (active === 1) {
                        setActive(0);
                      } else {
                        setActive(1);
                      }
                    }}
                  >
                    JOBS{' '}
                    <span
                      style={{
                        color: currentTarget.title && '#fff',
                        marginLeft: currentTarget.title && '1rem',
                      }}
                    >
                      {currentTarget && !currentTarget?.estimate
                        ? currentTarget.name?.length > 15
                          ? currentTarget.name?.slice(0, 11) + '...'
                          : currentTarget.name
                        : ''}
                    </span>
                    {unreadProjectMessages.length > 0 && (
                      <span
                        style={{
                          backgroundColor: '#00E6B8',
                          border: '2px solid #E9ECEF',
                          fontSize: '12px',
                          marginLeft: '5px',
                          color: '#fff',
                          padding: currentTarget.title ? '4px 8px' : '5px 10px',
                          borderRadius: '50%',
                        }}
                      >
                        {unreadProjectMessages.length}
                      </span>
                    )}
                  </button>
                  <button
                    className="netnetc-slect people-tab-btn"
                    style={{
                      borderRight: '0.5px solid #D9D9D9',
                      width: `100%`,
                      minWidth: '180px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderLeft:
                        currentTarget.title ||
                        (currentTarget.first_name && 'none'),
                      borderTop:
                        currentTarget.title ||
                        (currentTarget.first_name && 'none'),
                      borderBottom:
                        currentTarget.title ||
                        (currentTarget.first_name && 'none'),
                      borderRadius: currentTarget.first_name
                        ? '4px'
                        : '0 0 0 0',
                      backgroundColor: currentTarget.first_name
                        ? '#711fff'
                        : active === 1 || active === 3
                          ? '#ebe9e9'
                          : '#fff',
                      fontWeight: active === 3 || active === 1 ? '400' : '600',
                      color: currentTarget.first_name
                        ? '#fff'
                        : active === 1 || active === 3
                          ? 'gray'
                          : '#000',
                      '&:hover': {
                        background: 'red',
                      },
                    }}
                    onClick={() => {
                      handleClick2();
                      if (active === 2) {
                        setActive(0);
                      } else {
                        setActive(2);
                      }
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span>PEOPLE</span>
                      {currentTarget.first_name && (
                        <span className="px-3 text-white">
                          {currentTarget.first_name.toUpperCase()}
                        </span>
                      )}
                      <div className="d-flex text-white align-items-center">
                        <AvatarGroup
                          className="response-avatar"
                          max={3}
                          sx={{
                            justifyContent: 'flex-end',
                            paddingLeft: '15px',
                          }}
                        >
                          {userTeam && Array.isArray(length) && length
                            ? length?.map((item) => {
                                return (
                                  <Avatar
                                    alt={item?.name}
                                    src={
                                      item?.avatar
                                        ? item?.avatar
                                        : '/static/images/avatar/1.jpg'
                                    }
                                  />
                                );
                              })
                            : null}
                        </AvatarGroup>
                      </div>
                    </div>
                  </button>
                  <button
                    className="netnetc-slect estimates-tab-btn"
                    style={{
                      borderRadius: '0 8px 8px 0',
                      width: `100%`,
                      minWidth: '180px',
                      backgroundColor:
                        currentTarget.name && currentTarget.estimate
                          ? '#711fff'
                          : active === 2 || active === 1
                            ? '#ebe9e9'
                            : '#fff',
                      fontWeight: active === 2 || active === 1 ? '400' : '600',
                      color:
                        currentTarget.name && currentTarget.estimate
                          ? '#fff'
                          : active === 2 || active === 1
                            ? 'gray'
                            : '#000',
                      border:
                        currentTarget.name ||
                        (currentTarget.first_name && 'none'),
                    }}
                    onClick={() => {
                      setActive(3);
                    }}
                  >
                    ESTIMATES{' '}
                    <span
                      style={{
                        color: currentTarget.title && '#fff',
                        marginLeft: currentTarget.title && '1rem',
                      }}
                    >
                      {currentTarget && currentTarget?.estimate
                        ? currentTarget.name?.length > 15
                          ? currentTarget.name?.slice(0, 11) + '...'
                          : currentTarget.name
                        : ''}
                    </span>
                  </button>
                </Grid>

                {active === 1 && (
                  <Grid item>
                    <Box
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      style={{
                        maxHeight: ITEM_HEIGHT * 4.5,
                        backgroundColor: '#fff',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        position: 'absolute',
                        top: '1',
                        zIndex: 9999,
                        borderRadius: '10px',
                        border: '1px solid #dedede',
                        marginTop: '3px',
                        width: `${
                          document.getElementsByClassName('people-tab-btn')[0]
                            ?.offsetWidth +
                          document.getElementsByClassName('project-tab-btn')[0]
                            ?.offsetWidth +
                          document.getElementsByClassName(
                            'estimates-tab-btn',
                          )[0]?.offsetWidth
                        }px`,
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      {projectId && projectId.length > 0 ? (
                        projectId.map((project) => {
                          const currentPro = projectId.find(
                            (item) => item.id === project.id,
                          );

                          return (
                            <Box
                              key={project.id}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                '&:hover': {
                                  fontWeight: '600',
                                  backgroundColor: '#b3ffef',
                                },
                              }}
                            >
                              <li
                                onClick={async () => {
                                  setProLoading(true);
                                  setProjectNew(currentPro);
                                  setAnchorEl(null);
                                  setPage('chat');
                                  setActive(false);

                                  const res = await fetch(
                                    `${ENDPOINT}/api/chat/channel/detail/${currentPro?.id}/`,
                                    {
                                      method: 'GET',
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `JWT${access_token}`,
                                      },
                                    },
                                  );
                                  const data = await res.json();
                                  if (!res.ok) {
                                  }
                                  if (data) {
                                    const currentp = Projects.find(
                                      (it) => it.id === data?.project,
                                    );
                                    const res = await fetch(
                                      `${ENDPOINT}/api/project/detail/${currentp?.unique_id}/`,
                                      {
                                        method: 'GET',
                                        headers: {
                                          'Content-Type': 'application/json',
                                          Authorization: `JWT ${access_token}`,
                                        },
                                      },
                                    );
                                    const data2 = await res.json();
                                    if (res.ok) {
                                      const cpDil =
                                        data2 &&
                                        data2.deliverable_project &&
                                        data2.deliverable_project.map(
                                          (item) => {
                                            return item.deliverable_name;
                                          },
                                        );
                                      setCurrentDil(cpDil);
                                      setCurrentProject(data2);
                                      setLocalProjectState(
                                        data?.message_in_channel,
                                      );
                                      setCurrentTarget(data);
                                      setProLoading(false);
                                    }
                                  }
                                }}
                                style={{
                                  padding: '12px 9px',
                                  fontSize: '16px',
                                  cursor: 'pointer',
                                  width: '98%',
                                  color: '#000',
                                }}
                              >
                                {project?.project?.title}
                                <div className="menu-detail">
                                  <span className="member-detail">
                                    {project.subteam.members.length > 0 ? (
                                      <span
                                        style={{
                                          display: 'flex',
                                          fontSize: '15px',
                                        }}
                                      >
                                        <p style={{ marginRight: '5px' }}>
                                          {project.subteam.members.length}{' '}
                                        </p>
                                        members
                                      </span>
                                    ) : (
                                      <span>No Collaborators Yet</span>
                                    )}
                                  </span>
                                  {project.subteam.members.length > 0 && (
                                    <Grid
                                      container
                                      sx={{
                                        marginLeft: '1rem',
                                        gap: '0.7rem',
                                      }}
                                      alignItems="center"
                                    >
                                      <AvatarGroup
                                        max={2}
                                        sx={{
                                          borderBottom: '1px dashed #D9D9D9',
                                        }}
                                      >
                                        {project.subteam.members.map(
                                          (member) => (
                                            <Avatar
                                              alt={member.first_name}
                                              src={member.avatar}
                                            />
                                          ),
                                        )}
                                      </AvatarGroup>
                                    </Grid>
                                  )}
                                </div>
                              </li>
                              <Rating
                                sx={{ marginRight: '8px' }}
                                value={fav.some(
                                  (ele) => ele.title === project.name,
                                )}
                                max={1}
                                onChange={(e) => {
                                  if (
                                    fav.some(
                                      (ele) => ele.title === project.name,
                                    )
                                  ) {
                                    const newArr = fav.filter(
                                      (item) => item.title !== project.name,
                                    );
                                    dispatch(AddFav(newArr));
                                  } else {
                                    dispatch(
                                      AddFav([
                                        ...fav,
                                        {
                                          title: project.name,
                                          path: '/Chat',
                                          currentTargetChat: project,
                                          whatFav: 'Pro',
                                          projectChannelID: project?.id,
                                        },
                                      ]),
                                    );
                                  }
                                }}
                                icon={<StarIcon />} // Filled icon
                                emptyIcon={<StarIcon />} // Empty icon
                              />
                            </Box>
                          );
                        })
                      ) : (
                        <li
                          style={{
                            textAlign: 'center',
                            padding: '10px 10px',
                          }}
                        >
                          No Projects Yet
                        </li>
                      )}
                    </Box>
                  </Grid>
                )}

                {active === 2 && (
                  <Grid item>
                    <Box
                      id="long-menu2"
                      MenuListProps={{
                        'aria-labelledby': 'long-button2',
                      }}
                      anchorEl={anchorEl2}
                      open={open2}
                      onClose={handleClose2}
                      disableAutoFocus
                      style={{
                        maxHeight: ITEM_HEIGHT * 4.5,
                        backgroundColor: '#fff',
                        overflowY: 'auto',
                        position: 'absolute',
                        top: '1',
                        zIndex: 9999,
                        borderRadius: '10px',
                        border: '1px solid #dedede',
                        marginTop: '3px',
                        cursor: 'pointer',
                        width: `${
                          document.getElementsByClassName('people-tab-btn')[0]
                            ?.offsetWidth +
                          document.getElementsByClassName('project-tab-btn')[0]
                            ?.offsetWidth +
                          document.getElementsByClassName(
                            'estimates-tab-btn',
                          )[0]?.offsetWidth
                        }px`,
                      }}
                    >
                      {userTeam &&
                      Array.isArray(userTeam.member) &&
                      userTeam.member ? (
                        userTeam.member
                          ?.sort((a, b) =>
                            a.first_name?.localeCompare(b.first_name),
                          )
                          .map((item) => {
                            if (item.first_name === 'First Name') {
                              return;
                            }
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  '&:hover': {
                                    fontWeight: '600',
                                    backgroundColor: '#fabde5',
                                  },
                                }}
                              >
                                <li
                                  key={item.id}
                                  onClick={() => {
                                    if (
                                      item.first_name !== 'No Team Members Yet'
                                    ) {
                                      setCurrentTarget(item);
                                      setAnchorEl2(null);
                                      setPage('people');
                                      setActive(false);
                                    } else {
                                      return 0;
                                    }
                                  }}
                                  style={{
                                    padding: '15px 10px',
                                    display: 'flex',
                                    width: '98%',
                                    justifyContent: 'space-between',
                                    '&:hover': {
                                      fontWeight: 'bold',
                                      backgroundColor: '#FABDE5',
                                    },
                                  }}
                                >
                                  <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <Avatar
                                      alt={
                                        item.first_name
                                          ? item.first_name
                                          : 'Avatar'
                                      }
                                      src={
                                        item.avatar
                                          ? item.avatar
                                          : '/static/images/avatar/1.jpg'
                                      }
                                      sx={{
                                        height: '23px',
                                        width: '23px',
                                        marginRight: '8px',
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontSize: '16px',
                                      }}
                                    >
                                      {item.first_name}
                                    </span>
                                  </Box>
                                </li>
                                <Rating
                                  value={fav.some(
                                    (ele) => ele.title === item.first_name,
                                  )} // Set the value based on whether the project is in the favorites
                                  max={1}
                                  onChange={(e) => {
                                    if (
                                      fav.some(
                                        (ele) => ele.title === item.first_name,
                                      )
                                    ) {
                                      const newArr = fav.filter(
                                        (num) => num.title !== item.first_name,
                                      );
                                      dispatch(AddFav(newArr));
                                    } else {
                                      dispatch(
                                        AddFav([
                                          ...fav,
                                          {
                                            title: item.first_name,
                                            path: '/Chat',
                                            currentTargetChat: item,
                                            whatFav: 'chat',
                                          },
                                        ]),
                                      );
                                    }
                                  }}
                                  icon={<StarIcon />} // Filled icon
                                  emptyIcon={<StarIcon />} // Empty icon
                                  sx={{
                                    marginLeft: 'auto',
                                    marginRight: '8px',
                                  }}
                                />
                              </Box>
                            );
                          })
                      ) : (
                        <li
                          style={{
                            textAlign: 'center',
                            padding: '10px 10px',
                          }}
                        >
                          You don't have any Team
                        </li>
                      )}
                    </Box>
                  </Grid>
                )}

                {active === 3 && (
                  <Grid item>
                    <Box
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      style={{
                        maxHeight: ITEM_HEIGHT * 4.5,
                        backgroundColor: '#fff',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        position: 'absolute',
                        top: '1',
                        zIndex: 9999,
                        borderRadius: '10px',
                        border: '1px solid #dedede',
                        marginTop: '3px',
                        width: `${
                          document.getElementsByClassName('people-tab-btn')[0]
                            ?.offsetWidth +
                          document.getElementsByClassName('project-tab-btn')[0]
                            ?.offsetWidth +
                          document.getElementsByClassName(
                            'estimates-tab-btn',
                          )[0]?.offsetWidth
                        }px`,
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      {estiId && estiId.length > 0 ? (
                        estiId.map((est) => {
                          const currentEstimate = estiId.find(
                            (item) => item.id === est.id,
                          );

                          return (
                            <Box
                              key={est.id}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                '&:hover': {
                                  fontWeight: '600',
                                  backgroundColor: '#b3ffef',
                                },
                              }}
                            >
                              <li
                                onClick={async () => {
                                  setEstimateProLoading(true);

                                  setAnchorEl(null);
                                  setPage('estimate');
                                  setActive(false);
                                  const res = await fetch(
                                    `${ENDPOINT}/api/chat/channel/detail/${currentEstimate?.id}/`,
                                    {
                                      method: 'GET',
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `JWT${access_token}`,
                                      },
                                    },
                                  );
                                  const data = await res.json();
                                  if (!res.ok) {
                                  }
                                  if (data) {
                                    const res2 = await fetch(
                                      `${ENDPOINT}/api/opportunity/estimate/${currentEstimate?.estimate?.unique_id}/`,
                                      {
                                        method: 'GET',
                                        headers: {
                                          'Content-Type': 'application/json',
                                          Authorization: `JWT${access_token}`,
                                        },
                                      },
                                    );
                                    const data2 = await res2.json();
                                    if (res2.ok) {
                                      if (data2) {
                                        setEstimateMessage(
                                          data?.message_in_channel,
                                        );

                                        setCurrentTargetOfEstiimate(data2);
                                        setCurrentTarget({
                                          ...est,
                                          title: currentEstimate?.title,
                                          estimate: true,
                                        });
                                        setEstimateProLoading(false);
                                      }
                                    } else {
                                    }
                                  }
                                }}
                                style={{
                                  padding: '12px 9px',
                                  fontSize: '16px',
                                  cursor: 'pointer',
                                  width: '98%',
                                  color: '#000',
                                }}
                              >
                                {est?.estimate?.title}
                                <div className="menu-detail">
                                  <span className="member-detail">
                                    US ${' '}
                                    {currentEstimate?.estimate.budget
                                      ? currentEstimate.estimate.budget
                                      : '0'}
                                  </span>
                                </div>
                              </li>
                              <Rating
                                sx={{ marginRight: '8px' }}
                                value={
                                  fav.some((ele) => ele.title === est.name)
                                  // Assuming fav is an array and est.title is a valid property
                                } // Set the value based on whether the project is in the favorites
                                max={1}
                                onChange={(e) => {
                                  if (
                                    fav.some((ele) => ele.title === est.name)
                                  ) {
                                    const newArr = fav.filter(
                                      (item) => item.title !== est.name,
                                    );
                                    dispatch(AddFav(newArr));
                                  } else {
                                    dispatch(
                                      AddFav([
                                        ...fav,
                                        {
                                          title: est.name,
                                          path: '/Chat',
                                          currentTargetChat: {
                                            ...est,
                                            title: est.name,
                                          },
                                          whatFav: 'Est',
                                        },
                                      ]),
                                    );
                                  }
                                }}
                                icon={<StarIcon />} // Filled icon
                                emptyIcon={<StarIcon />} // Empty icon
                              />
                            </Box>
                          );
                        })
                      ) : (
                        <li
                          style={{
                            textAlign: 'center',
                            padding: '10px 10px',
                          }}
                        >
                          No Estimates Yet
                        </li>
                      )}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>

            {searchField === 'button' ? (
              <Button
                className="search-btn"
                onClick={() => {
                  setSearchField('input');
                }}
              >
                <ManageSearchIcon className="search-icon" />
              </Button>
            ) : (
              <AnimatePresence>
                <motion.div
                  key="field"
                  style={{
                    background: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '6px 8px',
                    borderRadius: '6px',
                    color: '#676b6b',
                    cursor: 'pointer',
                  }}
                  initial={{ x: 10, opacity: 0, transition: { duration: 0.5 } }}
                  animate={{ x: 0, opacity: 1, transition: { duration: 0.5 } }}
                  exit={{ x: -10, opacity: 0, transition: { duration: 0.5 } }}
                >
                  <SearchIcon
                    style={{ curser: 'pointer' }}
                    onClick={() => {
                      setSearchField('button');
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    sx={{ padding: '0px', width: '100%' }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </motion.div>
              </AnimatePresence>
            )}
          </section>
        </section>

        <Grid
          contianer={true}
          direction="column"
          sx={{
            border: '1px solid #D9D9D9',
            borderRadius: '8px',
            backgroundColor: '#fff',
            height: screenHeight - 240,
            padding: '20px 20px',
            position: 'relative',
          }}
          alignItems="flex-start"
        >
          <ChatBody
            Projects={Projects}
            page={page}
            setEstimateMessage={setEstimateMessage}
            project_deliverable={project_deliverable}
            currentTargetOfEstimate={currentTargetOfEstimate}
            userData={userData}
            projectTeam={projectTeam}
            chatData={chatData}
            setCurrentTarget={setCurrentTarget}
            currentTarget={currentTarget}
            setUserMsg={setUserMsg}
            userMsg={userMsg}
            localGeneral={localGeneral}
            setLocalGeneral={setLocalGeneral}
            localProjectState={localProjectState}
            setLocalProjectState={setLocalProjectState}
            parentCall={myfunc}
            messageScroll={messageScroll}
            mentionState={memoizedMentionState}
            search={search}
            estimateMsg={estimateMsg}
            screenHeight={screenHeight}
            proLoading={proLoading}
            estimateProLoading={estimateProLoading}
          />
          {page === 'chat' &&
            (proLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  width: '100%',
                }}
              >
                <Skeleton variant="rectangular" width={'100%'} height={110} />
              </Box>
            ) : (
              <Grid
                item
                justifySelf="flex-end"
                sx={{
                  padding: '0 1rem',
                }}
              >
                <section
                  className="editor px-2 pt-1 mb-2 rounded"
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      // Check if the pressed key is Enter
                      e.preventDefault();

                      if (!notRun) {
                        const replaceWordsWithSpan = (inputText) => {
                          // Define the primary pattern to match the span elements with the desired format
                          const pattern =
                            /<span data-type="mention" class="deliverable-mention" data-id="([^"]+)">~([^~]+)~\/([^~]+)<\/span>/g;

                          // Define the fallback pattern to match ~text1/text2 format
                          const fallBackPattern = /~([^~!]+)!/g;

                          // Define the replacement function for the primary pattern
                          const replacement = (
                            match,
                            id,
                            firstPart,
                            taskPart,
                          ) => {
                            // Wrap the task part in a <span> tag with the fukrah class
                            return `<span data-type="mention" class="deliverable-mention" data-id="${id}">~${firstPart}~/<span class="fukrah">${taskPart}</span></span>`;
                          };

                          // Replace the text using the primary pattern and the replacement function
                          let replacedText = inputText.replace(
                            pattern,
                            replacement,
                          );

                          // If no replacement was made by the primary pattern, try the fallback pattern
                          if (replacedText === inputText) {
                            // Define the replacement function for the fallback pattern
                            const fallbackReplacement = (match, word) => {
                              // Split the word using the delimiter '/'
                              const parts = word.split('/');
                              // Check if there are two parts
                              if (parts.length === 2) {
                                // Wrap the first part in a <span> tag with the specified class name
                                // and include the second part inside with a new class name
                                return `<span class="deliverable-mention">~${parts[0]}~/<span class="fukrah">${parts[1]}</span></span>`;
                              } else {
                                // If there's only one part, wrap it in the main <span> tag
                                return `<span class="deliverable-mention">${word}</span>`;
                              }
                            };

                            // Replace the text using the fallback pattern and the fallback replacement function
                            replacedText = inputText.replace(
                              fallBackPattern,
                              fallbackReplacement,
                            );
                          }

                          // Clean up any malformed HTML or orphaned tags
                          // Remove tags with no content
                          const cleanedText = replacedText.replace(
                            /<span[^>]*><\/span>/g,
                            '',
                          );
                          // Optionally clean up text left after the replacement
                          // Clean any leftover ~ or / characters
                          const finalText = cleanedText.replace(/~\/~|~~/g, '');

                          return finalText;
                        };

                        const tempMessage = {
                          created: new Date().toISOString(),
                          channel:
                            Array.isArray(currentTarget) && currentTarget.length
                              ? currentTarget
                              : [],
                          is_response: false,
                          message: replaceWordsWithSpan(projectMessage),
                          received: null,
                          receiver_read: false,
                          seen: null,
                          sender_read: true, // Assuming the sender has already read their own message
                          sender: userData,
                          tempId: Math.random().toString(36).substring(7), // Unique temporary ID
                        };
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(
                          projectMessage,
                          'text/html',
                        );
                        const doc2 = parser.parseFromString(
                          replaceWordsWithSpan(projectMessage),
                          'text/html',
                        );
                        // Extract mentioned persons
                        const mentions = doc.querySelectorAll(
                          'span[data-type="mention"]',
                        );
                        const mentionedPersons = Array.from(mentions).map(
                          (mention) => mention.textContent.replace('@', ''),
                        );

                        // Display the message immediately
                        setLocalProjectState([
                          ...localProjectState,
                          tempMessage,
                        ]);
                        btn?.editor?.commands.clearContent();
                        setProjectMessage();
                        const text = decodeHtmlEntities(projectMessage);

                        if (
                          Array.isArray(mentionedPersons) &&
                          mentionedPersons.length
                        ) {
                          for (let i = 0; i < mentionedPersons.length; i++) {
                            const res = await fetch(
                              `${ENDPOINT}/api/superadmin/notification/create/`,
                              {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json',
                                  Authorization: `JWT ${access_token}`,
                                },
                                body: JSON.stringify({
                                  notification_channel: currentTarget?.id,
                                  notification_project: currentTarget?.project,
                                  notification_sender: userData.id,
                                  notification_receiver_read: false,
                                  notification_seen: null,
                                  notification_type: 'user_mention',
                                  notification_receiver: projectTeam.find(
                                    (item) => item.name === mentionedPersons[i],
                                  )?.id,
                                }),
                              },
                            );
                            if (!res.ok) {
                              const data = await res.json();
                              return;
                            } else {
                            }
                          }
                        }
                        const dilMentions = doc.querySelectorAll(
                          'span[class="deliverable-mention"]',
                        );
                        const catchDil = Array.from(dilMentions).map(
                          (mention) => mention.textContent.replace('~', ''),
                        );

                        if (Array.isArray(catchDil) && catchDil.length) {
                          for (var i = 0; i < catchDil.length; i++) {
                            const id =
                              currentProject?.deliverable_project?.find(
                                (it) => it.deliverable_name === catchDil[i],
                              )?.id;
                            const crtMessages = new_channals?.find(
                              (item) => item?.deliverable === id,
                            );

                            if (crtMessages) {
                              const response = await fetch(
                                `${ENDPOINT}/api/chat/channel/message/create/`,
                                {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `JWT ${access_token}`,
                                  },
                                  body: JSON.stringify({
                                    channel: crtMessages?.id,
                                    message:
                                      replaceWordsWithSpan(projectMessage),
                                    sender: userData,
                                  }),
                                },
                              );

                              if (!response.ok) {
                                const data = await response.json();

                                return;
                              }

                              const data = await response.json();
                              dispatch(getMessages({ access_token }));
                            }
                          }
                        }

                        const taskMentions = doc2.querySelectorAll(
                          'span[class="fukrah"]',
                        );
                        const catchTask = Array.from(taskMentions).map(
                          (mention) => mention.textContent.replace('~', ''),
                        );

                        if (Array.isArray(catchTask) && catchTask.length) {
                          for (var i = 0; i < catchTask.length; i++) {
                            const id =
                              currentProject?.deliverable_project?.flatMap(
                                (it) =>
                                  it.task_deliverable.filter(
                                    (wt) => wt.task_name === catchTask[i],
                                  ),
                              );
                            const crtMessages = new_channals?.find(
                              (item) => item?.task === id[0]?.id,
                            );

                            if (crtMessages) {
                              const taskResponse = await fetch(
                                `${ENDPOINT}/api/chat/channel/message/create/`,
                                {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `JWT ${access_token}`,
                                  },
                                  body: JSON.stringify({
                                    channel: crtMessages?.id,
                                    message:
                                      replaceWordsWithSpan(projectMessage),
                                  }),
                                },
                              );

                              if (!taskResponse.ok) {
                                const data = await taskResponse.json();
                                return;
                              }

                              const data = await taskResponse.json();
                              dispatch(getMessages({ access_token }));

                              // Send to deliverable channel as well
                              const deliverableId =
                                currentProject?.deliverable_project?.find(
                                  (it) =>
                                    it.task_deliverable.some(
                                      (wt) => wt.task_name === catchTask[i],
                                    ),
                                )?.id;
                              const deliverableMessages = new_channals?.find(
                                (item) => item?.deliverable === deliverableId,
                              );

                              if (deliverableMessages) {
                                const deliverableResponse = await fetch(
                                  `${ENDPOINT}/api/chat/channel/message/create/`,
                                  {
                                    method: 'POST',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      Authorization: `JWT ${access_token}`,
                                    },
                                    body: JSON.stringify({
                                      channel: deliverableMessages?.id,
                                      message:
                                        replaceWordsWithSpan(projectMessage),
                                      sender: userData,
                                    }),
                                  },
                                );

                                if (!deliverableResponse.ok) {
                                  const data = await deliverableResponse.json();
                                  return;
                                }

                                const data = await deliverableResponse.json();
                                dispatch(getMessages({ access_token }));
                              }
                            }
                          }
                        }
                        const response = await fetch(
                          `${ENDPOINT}/api/chat/channel/message/create/`,
                          {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT ${access_token}`,
                            },
                            body: JSON.stringify({
                              channel: currentTarget?.id,
                              message: replaceWordsWithSpan(projectMessage),
                            }),
                          },
                        );

                        if (!response.ok) {
                          const data = await response.json();

                          return;
                        }

                        const data = await response.json();

                        setLocalProjectState([
                          ...localProjectState,
                          { ...data, sender: userData },
                        ]);
                        setProjectMessage();
                      } else {
                      }
                    } else if (e.key === 'Enter' && e.shiftKey) {
                      let htmlEditor = btn.editor.getHTML();
                      if (
                        btn.editor.isActive('orderedList') ||
                        btn.editor.isActive('bulletList')
                      ) {
                        // Replace only <br> tags without a class attribute with <span>
                        htmlEditor = htmlEditor.replace(
                          /<br(?![^>]*class\s*=\s*['"][^'"]*['"])[^>]*>/gi,
                          '<span></span>',
                        );
                        btn.editor.commands.setContent(htmlEditor);
                      } else {
                        // Add a class to <br> tags that do not already have one
                        htmlEditor = htmlEditor.replace(/<br\s*\/?>/gi, '<p/>');
                        btn.editor.commands.setContent(htmlEditor);
                      }
                      btn.editor
                        .chain()
                        .focus()
                        .splitListItem('listItem')
                        .run();
                    }
                  }}
                >
                  <RichTextEditor
                    open={openLink}
                    setOpen={setOpenLink}
                    setNotRun={setNotRun}
                    projectNew={projectNew}
                    editorRef={editorRef}
                    setHtml={setProjectMessage}
                    check={check}
                    html={projectMessage}
                    mentions={
                      Array.isArray(projectTeam) && projectTeam?.length
                        ? projectTeam.map((item) => item.name)
                        : ['No Mentions']
                    }
                    currentAllDil={currentProject?.deliverable_project}
                    deliverbales={currentProDils}
                    currentTarget={currentTarget}
                  />
                </section>
              </Grid>
            ))}

          {page === 'people' && (
            <Grid
              item
              justifySelf="flex-end"
              sx={{
                padding: '0 1rem',
              }}
            >
              <section
                className="editor px-2 pt-1 mb-2 rounded"
                onKeyDown={async (e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    // Check if the pressed key is Enter
                    e.preventDefault();
                    if (!notRun) {
                      btn.editor.commands.clearContent();
                      const tempMessage = {
                        is_response: false,
                        created: new Date().toISOString(),
                        received: null,
                        seen: null,
                        sender_read: true,
                        sender: userData,
                        receiver: currentTarget,
                        receiver_read: false,
                        tempId: new Date(),
                        id: 10,
                        message: peopleMsg,
                      };
                      setUserMsg([...userMsg, tempMessage]);
                      const response = await fetch(
                        `${ENDPOINT}/api/chat/dm/message/create/`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                          body: JSON.stringify({
                            receiver: currentTarget.id,
                            message: peopleMsg,
                          }),
                        },
                      );
                      const data = await response.json();

                      if (!response.ok) {
                      } else {
                        setPeopleMsg('');
                        dispatch(getAllMsg({ access_token }));
                        btn.editor.commands.clearContent();

                        setUserMsg((prevMessages) =>
                          prevMessages.map((message) =>
                            message.tempId === tempMessage.tempId
                              ? { ...data, sender: userData }
                              : message,
                          ),
                        );
                      }
                    }
                  } else if (e.key === 'Enter' && e.shiftKey) {
                    let htmlEditor = btn.editor.getHTML();
                    if (
                      btn.editor.isActive('orderedList') ||
                      btn.editor.isActive('bulletList')
                    ) {
                      // Replace only <br> tags without a class attribute with <span>
                      htmlEditor = htmlEditor.replace(
                        /<br(?![^>]*class\s*=\s*['"][^'"]*['"])[^>]*>/gi,
                        '<span></span>',
                      );
                      btn.editor.commands.setContent(htmlEditor);
                    } else {
                      // Add a class to <br> tags that do not already have one
                      htmlEditor = htmlEditor.replace(/<br\s*\/?>/gi, '<p/>');
                      btn.editor.commands.setContent(htmlEditor);
                    }
                    btn.editor.chain().focus().splitListItem('listItem').run();
                  }
                }}
              >
                <RichTextEditor
                  open={openLink}
                  setOpen={setOpenLink}
                  setNotRun={setNotRun}
                  setHtml={setPeopleMsg}
                  html={peopleMsg}
                  check={check}
                  mentions={teamMentions
                    .filter((items) => items.name !== 'First Name')
                    .map((item) => item.name)}
                />
              </section>
            </Grid>
          )}
          {page === 'general' && (
            <Grid
              item
              justifySelf="flex-end"
              sx={{
                padding: '0 1rem',
              }}
            >
              {userTeam &&
              Array.isArray(userTeam?.member) &&
              userTeam.member.length ? (
                <section
                  className="editor px-2 pt-1 mb-2 rounded"
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      // Check if the pressed key is Enter
                      e.preventDefault();

                      if (!notRun) {
                        const tempMessage = {
                          created: new Date().toISOString(),
                          channel: generalId && generalId[0]?.id,
                          is_response: false,
                          message: generalMsg,
                          received: null,
                          receiver_read: false,
                          seen: null,
                          sender_read: true, // Assuming the sender has already read their own message
                          sender: userData,
                          tempId: Math.random().toString(36).substring(7), // Unique temporary ID
                        };
                        setLocalGeneral([...localGeneral, { ...tempMessage }]);
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(
                          generalMsg,
                          'text/html',
                        );

                        // Extract mentioned persons

                        const mentions = doc.querySelectorAll(
                          'span[data-type="mention"]',
                        );
                        const mentionedPersons = Array.from(mentions).map(
                          (mention) => mention.textContent.replace('@', ''),
                        );

                        btn.editor.commands.clearContent();
                        setGeneralMsg('');
                        if (
                          Array.isArray(mentionedPersons) &&
                          mentionedPersons.length
                        ) {
                          for (var i = 0; i < mentionedPersons.length; i++) {
                            const res = await fetch(
                              `${ENDPOINT}/api/superadmin/notification/create/`,
                              {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json',
                                  Authorization: `JWT ${access_token}`,
                                },
                                body: JSON.stringify({
                                  notification_channel:
                                    generalId && generalId[0].id,
                                  notification_receiver: teamMentions.length
                                    ? teamMentions
                                        .filter(
                                          (items) =>
                                            items.name !== 'First Name',
                                        )
                                        .find(
                                          (item) =>
                                            item.name === mentionedPersons[i],
                                        )?.id
                                    : null,
                                  notification_sender: userData.id,
                                  notification_receiver_read: false,
                                  notification_seen: null,
                                  notification_type: 'user_mention',
                                }),
                              },
                            );
                            if (!res.ok) {
                              const data = await res.json();
                              return;
                            } else {
                            }
                          }
                        }

                        const response = await fetch(
                          `${ENDPOINT}/api/chat/channel/message/create/`,
                          {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT ${access_token}`,
                            },
                            body: JSON.stringify({
                              channel: generalId && generalId[0]?.id,
                              message: generalMsg,
                            }),
                          },
                        );
                        const data = await response.json();
                        if (!response.ok) {
                        } else {
                          setLocalGeneral([
                            ...localGeneral,
                            { ...data, sender: userData },
                          ]);

                          btn.editor.commands.clearContent();
                          setGeneralMsg('');
                        }
                      }
                    } else if (e.key === 'Enter' && e.shiftKey) {
                      let htmlEditor = btn.editor.getHTML();
                      if (
                        btn.editor.isActive('orderedList') ||
                        btn.editor.isActive('bulletList')
                      ) {
                        htmlEditor = htmlEditor.replace(
                          /<br(?![^>]*class\s*=\s*['"][^'"]*['"])[^>]*>/gi,
                          '<span></span>',
                        );
                        btn.editor.commands.setContent(htmlEditor);
                      } else {
                        htmlEditor = htmlEditor.replace(/<br\s*\/?>/gi, '<p/>');
                        btn.editor.commands.setContent(htmlEditor);
                      }
                      btn.editor
                        .chain()
                        .focus()
                        .splitListItem('listItem')
                        .run();
                    }
                  }}
                >
                  <RichTextEditor
                    open={openLink}
                    setOpen={setOpenLink}
                    setNotRun={setNotRun}
                    setHtml={setGeneralMsg}
                    check={check}
                    html={generalMsg}
                    mentions={teamMentions
                      .filter((items) => items.name !== 'First Name')
                      .map((item) => item.name)}
                  />
                </section>
              ) : null}
            </Grid>
          )}
          {page === 'estimate' && currentTarget && !estimateProLoading ? (
            <Grid
              item
              justifySelf="flex-end"
              sx={{
                padding: '0 1rem',
              }}
            >
              <section
                className="editor px-2 pt-1 mb-2 rounded"
                onKeyDown={async (e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    // Check if the pressed key is Enter
                    e.preventDefault();

                    if (!notRun) {
                      const replaceWordsWithSpan = (inputText) => {
                        // Define the primary pattern to match the span elements with the desired format
                        const pattern =
                          /<span data-type="mention" class="deliverable-mention" data-id="([^"]+)">~([^~]+)~\/([^~]+)<\/span>/g;

                        // Define the fallback pattern to match ~text1/text2 format
                        const fallBackPattern = /~([^~!]+)!/g;

                        // Define the replacement function for the primary pattern
                        const replacement = (
                          match,
                          id,
                          firstPart,
                          taskPart,
                        ) => {
                          // Wrap the task part in a <span> tag with the fukrah class
                          return `<span data-type="mention" class="deliverable-mention" data-id="${id}">~${firstPart}~/<span class="fukrah">${taskPart}</span></span>`;
                        };

                        // Replace the text using the primary pattern and the replacement function
                        let replacedText = inputText.replace(
                          pattern,
                          replacement,
                        );

                        // If no replacement was made by the primary pattern, try the fallback pattern
                        if (replacedText === inputText) {
                          // Define the replacement function for the fallback pattern
                          const fallbackReplacement = (match, word) => {
                            // Split the word using the delimiter '/'
                            const parts = word.split('/');
                            // Check if there are two parts
                            if (parts.length === 2) {
                              // Wrap the first part in a <span> tag with the specified class name
                              // and include the second part inside with a new class name
                              return `<span class="deliverable-mention">~${parts[0]}~/<span class="fukrah">${parts[1]}</span></span>`;
                            } else {
                              // If there's only one part, wrap it in the main <span> tag
                              return `<span class="deliverable-mention">${word}</span>`;
                            }
                          };

                          // Replace the text using the fallback pattern and the fallback replacement function
                          replacedText = inputText.replace(
                            fallBackPattern,
                            fallbackReplacement,
                          );
                        }

                        // Remove tags with no content
                        const cleanedText = replacedText.replace(
                          /<span[^>]*><\/span>/g,
                          '',
                        );
                        // Clean any leftover ~ or / characters
                        const finalText = cleanedText.replace(/~\/~|~~/g, '');

                        return finalText;
                      };

                      const tempMessage = {
                        created: new Date().toISOString(),
                        is_response: false,
                        message: replaceWordsWithSpan(estimateNewMsg),
                        received: null,
                        receiver_read: false,
                        seen: null,
                        sender_read: true, // Assuming the sender has already read their own message
                        sender: userData,
                        tempId: Math.random().toString(36).substring(7), // Unique temporary ID
                      };
                      const parser = new DOMParser();
                      const doc = parser.parseFromString(
                        estimateNewMsg,
                        'text/html',
                      );

                      // Extract mentioned persons
                      const mentions = doc.querySelectorAll(
                        'span[data-type="mention"]',
                      );

                      const mentionedPersons = Array.from(mentions).map(
                        (mention) => mention.textContent.replace('@', ''),
                      );

                      // Display the message immediately
                      if (Array.isArray(estimateMsg) && estimateMsg.length) {
                        setEstimateMessage([...estimateMsg, tempMessage]);
                      } else {
                        setEstimateMessage([tempMessage]);
                      }
                      btn.editor.commands.clearContent();

                      const text = decodeHtmlEntities(estimateNewMsg);

                      if (
                        Array.isArray(mentionedPersons) &&
                        mentionedPersons.length
                      ) {
                        for (var i = 0; i < mentionedPersons.length; i++) {
                          const res = await fetch(
                            `${ENDPOINT}/api/superadmin/notification/create/`,
                            {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT ${access_token}`,
                              },
                              body: JSON.stringify({
                                notification_channel: currentTarget?.id,

                                notification_sender: userData.id,
                                notification_receiver_read: false,
                                notification_seen: null,
                                notification_type: 'user_mention',
                                notification_receiver:
                                  currentTargetOfEstimate &&
                                  Array.isArray(
                                    currentTargetOfEstimate?.subteam_estimate,
                                  ) &&
                                  currentTargetOfEstimate.subteam_estimate
                                    .length &&
                                  Array.isArray(
                                    currentTargetOfEstimate.subteam_estimate[0]
                                      .members,
                                  ) &&
                                  currentTargetOfEstimate.subteam_estimate[0]
                                    .members.length &&
                                  currentTargetOfEstimate.subteam_estimate[0].members.find(
                                    (item) =>
                                      item.first_name === mentionedPersons[i],
                                  )?.id,
                              }),
                            },
                          );
                          if (!res.ok) {
                            const data = await res.json();

                            return;
                          } else {
                          }
                        }
                      }

                      const response = await fetch(
                        `${ENDPOINT}/api/chat/channel/message/create/`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                          body: JSON.stringify({
                            channel: currentTarget?.id,
                            message: replaceWordsWithSpan(estimateNewMsg),
                          }),
                        },
                      );

                      if (!response.ok) {
                        const data = await response.json();

                        return;
                      }

                      const data = await response.json();
                      setEstimateMessage((prevMessages) =>
                        prevMessages.map((message) =>
                          message.tempId === tempMessage.tempId
                            ? { ...data, sender: userData }
                            : message,
                        ),
                      );
                      setEstimateNewMessage('');

                      // Dispatch your action to update channels

                      dispatch(estimate_channels({ access_token }));
                    }
                  } else if (e.key === 'Enter' && e.shiftKey) {
                    let htmlEditor = btn.editor.getHTML();
                    if (
                      btn.editor.isActive('orderedList') ||
                      btn.editor.isActive('bulletList')
                    ) {
                      htmlEditor = htmlEditor.replace(
                        /<br(?![^>]*class\s*=\s*['"][^'"]*['"])[^>]*>/gi,
                        '<span></span>',
                      );
                      btn.editor.commands.setContent(htmlEditor);
                    } else {
                      // Add a class to <br> tags that do not already have one
                      htmlEditor = htmlEditor.replace(/<br\s*\/?>/gi, '<p/>');
                      btn.editor.commands.setContent(htmlEditor);
                    }
                    btn.editor.chain().focus().splitListItem('listItem').run();
                  }
                }}
              >
                <RichTextEditor
                  open={openLink}
                  setOpen={setOpenLink}
                  setNotRun={setNotRun}
                  currentAllDil={currentTargetOfEstimate?.deliverable}
                  setHtml={setEstimateNewMessage}
                  html={estimateNewMsg}
                  check={check}
                  deliverbales={
                    currentTargetOfEstimate &&
                    Array.isArray(currentTargetOfEstimate.deliverable) &&
                    currentTargetOfEstimate.deliverable.length
                      ? currentTargetOfEstimate.deliverable.map(
                          (item) => item.deliverable_name,
                        )
                      : []
                  }
                  mentions={
                    currentTargetOfEstimate &&
                    Array.isArray(currentTargetOfEstimate?.subteam_estimate) &&
                    currentTargetOfEstimate.subteam_estimate.length &&
                    Array.isArray(
                      currentTargetOfEstimate.subteam_estimate[0].members,
                    ) &&
                    currentTargetOfEstimate.subteam_estimate[0].members.length
                      ? currentTargetOfEstimate.subteam_estimate[0].members.map(
                          (item) => item.first_name,
                        )
                      : ['No Mentions']
                  }
                />
              </section>
            </Grid>
          ) : (
            page === 'estimate' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  width: '100%',
                }}
              >
                <Skeleton variant="rectangular" width={'100%'} height={110} />
              </Box>
            )
          )}
        </Grid>
        <ChatHelpVideos show={show} setShow={setShow} page={page} />
      </section>
    </Bounce>
  );
};
export default Stream;
