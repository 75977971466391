import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';

import { Box, Backdrop, Grid, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import ProgressBar from 'react-bootstrap/ProgressBar';
import { LuServer } from 'react-icons/lu';
import { MdControlPointDuplicate } from 'react-icons/md';
import Popup from 'reactjs-popup';

import { getVariant } from './ProjectUtility';
import 'primereact/resources/primereact.min.css';
import { getProjects } from './proRedux';
import './project.css';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import moreIco from '../Opportunity/assets/moreIco.png';
import DeleteIcon from '../Contacts/assets/DeleteIcon.png';
import icon1 from '../../Assets/icon/icon1.jpg';
import icon2 from '../../Assets/icon/icon2.jpg';
import icon3 from '../../Assets/icon/icon3.jpg';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { PreLoader1 } from '../../Componenets/Misc';
import { ActiveProjectAlert } from './NewProject/PFifth';
import { getWorkTypes } from '../User/redux';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';
import TitleBar from '../../Componenets/atomic-molecules/Headers/TitleBar';
import { FilterBar } from '../../Componenets/atomic-molecules/Headers/FilterBar';
import NetNetTable from '../../Componenets/atomic-organisms/Tables/NetNetTable';
import { BrowserStorageManager } from '../../cache/BrowserStorageManager';
import {
  loadColumnsFromLocalStorage,
  saveColumnsToLocalStorage,
} from '../../cache/columnsPersistence';

dayjs.extend(utc);

const fontFamily = 'Open Sans';

function Duplicate({ setSureDuplicate, currentTarget, myArr2 }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const resGet = await fetch(
      `${ENDPOINT}/api/project/detail/${currentTarget.unique_id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );
    const getData = await resGet.json();

    const formData = { ...getData };
    let duplicateNumber = 1;
    let newTitle = `${formData.title} - Duplicate ${duplicateNumber}`;

    // eslint-disable-next-line
    while (myArr2.some((item) => item.title === newTitle)) {
      // eslint-disable-next-line
      duplicateNumber++;
      newTitle = `${formData.title} - Duplicate ${duplicateNumber}`;
    }

    delete formData.work_type;
    delete formData.id;
    delete formData.collaborator;
    delete formData.created_at;

    if (resGet.ok) {
      const res = await fetch(`${ENDPOINT}/api/project/create/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          title: newTitle,
          budget: Number(
            parseFloat(formData?.budget).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            }) || 0,
          ),
          est_start_date:
            formData.target_start ||
            new Date(
              new Date().setDate(new Date().getDate() + 14),
            ).toISOString(),
          est_finish_date: formData.finish_date || null,
          members:
            Array.isArray(formData.project_subteam) &&
            formData.project_subteam.length
              ? formData.project_subteam[0].members.map((item) => item.email)
              : [],
          project_deliverable:
            Array.isArray(formData.deliverable_project) &&
            formData.deliverable_project.length
              ? formData.deliverable_project.map((item) => ({
                  deliverable_name: item.deliverable_name,
                  loe_price: item.loe_price ?? 0,
                  description: item.description || '',
                  task_deliverable:
                    Array.isArray(item.task_deliverable) &&
                    item.task_deliverable.length
                      ? item.task_deliverable.map((tsk) => ({
                          ...tsk,
                          task_name: tsk.task_name,
                          task_worktypes: Array.isArray(tsk.task_worktype)
                            ? tsk.task_worktype.map((tskWtt) => ({
                                id: tskWtt.id,
                                name: tskWtt.name,
                                hours: tskWtt.hours || 0,
                              }))
                            : [],
                        }))
                      : [],
                  deliverable_multiplier: Array.isArray(
                    item.deliverable_multiplier,
                  )
                    ? item.deliverable_multiplier.map((multi) => ({
                        label: multi.label,
                        rate: multi.rate,
                        operation: multi.operation,
                      }))
                    : [],
                  user_price: item.user_price || 0,
                  deliverable_startdate: item.deliverable_startdate,
                  deliverable_enddate: item.deliverable_enddate,
                  deliverable_worktype: Array.isArray(item.deliverable_worktype)
                    ? item.deliverable_worktype.map((wtt) => ({
                        name: wtt.name,
                        hours: wtt.hours || 0,
                        id: wtt.id,
                      }))
                    : [],
                }))
              : [],
          estimate_hardcost: Array.isArray(formData.estimate_hardcost)
            ? formData.estimate_hardcost.map((est) => ({
                hardcost_label: est.hardcost_label,
                our_price: est.our_price,
                price_to_customer: est.price_to_customer,
                hardcost_margin: est.hardcost_margin,
              }))
            : [],
          worktype:
            Array.isArray(formData.project_deliverable) &&
            formData.project_deliverable.length
              ? formData.project_deliverable[0].deliverable_worktype.map(
                  (item) => ({
                    id: Number(item.id),
                    hours: item.hours || 0,
                  }),
                )
              : Array.isArray(getData?.work_type) &&
                getData?.work_type.length &&
                getData?.work_type.map((item) => ({
                  id: Number(item.id),
                  hours: 0,
                })),
        }),
      });
      if (res.ok) {
        dispatch(getProjects({ access_token }));
        setSureDuplicate(false);
        setLoading(false);
      }
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '500px',
            height: '150px',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '1.48rem 1rem',
          }}
        >
          <p className="fstxt">
            Are You Sure You want to Mark this Job as Duplicate?
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureDuplicate(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{ background: loading && '#FF6827', height: '46px' }}
              className="netWarn-btn"
              onClick={() => handleDelete()}
            >
              {loading ? (
                <Box
                  sx={{ width: '30px', height: '30px', margin: '0rem 0.6rem' }}
                >
                  <PreLoader1 />
                </Box>
              ) : (
                'Duplicate'
              )}{' '}
            </button>
          </Box>
        </Box>
      </Slide>
    </section>
  );
}

function InfoCreate({ menuRef, setShowModal, updateInnerNetNetTabs }) {
  const routeTo = useRouteTo();
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '2.44rem 2.88rem',
        gap: '2rem',
        width: 'fit-content',
      }}
      alignItems="center"
      justifyContent="center"
      ref={menuRef}
    >
      <Box
        sx={{
          background: '#310085',
          padding: '1.44rem 1.88rem',
          color: '#fff',
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontStyle: '1rem',
          borderRadius: '8px',
          width: '27rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          updateInnerNetNetTabs('New Job', appRoutes.newJob);
          routeTo(appRoutes.newJob);
          setShowModal(false);
        }}
      >
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: '600',
          }}
        >
          Set up a
        </Typography>
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.6rem',
            fontWeight: '600',
            marginBottom: '2rem',
          }}
        >
          JOB
        </Typography>
        <Box marginLeft="4px" fontSize="0.8rem">
          <li>Does not include Pricing information</li>
          <li>Ideal for internal Jobs or client Jobs already in progress</li>
          <li>Reporting & KPI&apos;s will only be in hours</li>
        </Box>
      </Box>
      <Box
        sx={{ width: '1.5px', backgroundColor: '#d9d9d9', height: '150px' }}
      />
      <Box
        sx={{
          background: '#5fcea8',
          padding: '1.44rem 1.88rem',
          color: '#fff',
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontStyle: '1rem',
          borderRadius: '8px',
          width: '27rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          updateInnerNetNetTabs('New Job Estimate', appRoutes.newJobEstimate);
          routeTo(appRoutes.newJobEstimate);
          setShowModal(false);
        }}
      >
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: '600',
          }}
        >
          Create an
        </Typography>
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.6rem',
            fontWeight: '600',
            marginBottom: '1.4rem',
          }}
        >
          ESTIMATE
        </Typography>
        <Box marginLeft="4px" fontSize="0.8rem">
          <li>Great if you want Pricing information</li>
          <li>Made for client work</li>
          <li>Will display in your sales reporting</li>
        </Box>
      </Box>
    </Grid>
  );
}

const projectFilterOptions = [
  {
    value: 'all',
    type: 'all',
    label: 'All',
  },
  {
    value: 'inactive',
    type: 'project',
    label: 'Inactive Projects',
  },
  {
    value: 'inactive',
    type: 'retainer',
    label: 'Inactive Retainers',
  },
  {
    value: 'pending',
    type: 'project',
    label: 'Pending Projects',
  },
  {
    value: 'pending',
    type: 'retainer',
    label: 'Pending Retainers',
  },
  {
    value: 'active',
    type: 'project',
    label: 'Active Projects',
  },
  {
    value: 'active',
    type: 'retainer',
    label: 'Active Retainers',
  },

  {
    value: 'complete',
    type: 'project',
    label: 'Completed Projects',
  },
  {
    value: 'complete',
    type: 'retainer',
    label: 'Completed Retainers',
  },
  {
    value: 'archived',
    type: 'project',
    label: 'Archived Projects',
  },
  {
    value: 'archived',
    type: 'retainer',
    label: 'Archived Retainers',
  },
];

const localStorageManager = new BrowserStorageManager(localStorage);

function Project({
  showModal,
  setShowModal,
  setSettings,
  setProjectDetail,
  screenHeight,
  updateInnerNetNetTabs,
}) {
  const routeTo = useRouteTo();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.user.access);
  const meta = {
    access_token: token,
  };
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  useEffect(() => {
    dispatch(getProjects(meta));
    setSettings(false);
    setProjectDetail(false);
    dispatch(getWorkTypes(meta));
  }, []);

  const currentUser = useSelector((state) => state.userData.userData);
  const myArr = useSelector((state) => state.projects.projects);
  const allTasks = useSelector((state) => state.projects.tasks);
  const [args, setArgs] = useState([
    {
      type: 'project',
      value: 'active',
    },
    {
      type: 'retainer',
      value: 'active',
    },
    {
      type: 'project',
      value: 'inactive',
    },
    {
      type: 'retainer',
      value: 'inactive',
    },
  ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [projects, setProjects] = useState([]);
  const filteredMyArr = myArr.filter((item) =>
    args.some(
      (ele) => ele.type === item.project_type && ele.value === item.status,
    ),
  );

  const myArr2 = args && !args.includes('all') ? filteredMyArr : myArr;
  const filteredSearch = myArr2
    ? myArr2?.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : [];
  const myArr3 = searchQuery ? filteredSearch : myArr2;

  const sortingJobsKey = `JobsTableSortModelForUserID${currentUser.id}`;
  const manageColumnsJobsKey = `JobsTableColumnVisibilityForUserID${currentUser.id}`;

  const getStoredVisibility = () => {
    const storedSettings = localStorageManager.get(manageColumnsJobsKey);
    return storedSettings ?? {};
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    getStoredVisibility(),
  );

  useEffect(() => {
    localStorageManager.set(manageColumnsJobsKey, columnVisibilityModel);
  }, [columnVisibilityModel]);

  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };

  const [jobsSortModel, setJobsSortModel] = useState(
    localStorageManager.get(sortingJobsKey)
      ? localStorageManager.get(sortingJobsKey)
      : [
          {
            field: 'name',
            sort: 'asc',
          },
        ],
  );

  useEffect(() => {
    localStorageManager.set(sortingJobsKey, jobsSortModel);
  }, [jobsSortModel]);

  useEffect(() => {
    setProjects(myArr3);
  }, [args, searchQuery]);

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const [AlertMessage, setAlertMessage] = useState('');
  const [AlertShow, SetAlertShow] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  const [sureDelete, setSureDelete] = useState(false);
  const [currentDlt, setCurrentDlt] = useState();
  const deleteEndPoint = `api/project/delete/${currentDlt?.id}/`;
  const [sureDuplicate, setSureDuplicate] = useState(false);
  const [columns, setColumns] = useState([
    {
      field: 'name',
      headerName: 'Job Name',

      flex: 2,
      renderHeader: () => (
        <div className="tableHeader" style={{ padding: '0 0 0 1rem' }}>
          Job Name
        </div>
      ),
      renderCell: (params) => (
        <div
          id="Cypress-row-test"
          className="td"
          onClick={() => {
            if (params.row.creation_wizard_completed) {
              updateInnerNetNetTabs(
                'Job Detail',
                `${appRoutes.jobDetail}/${params.row.unique_id}`,
              );
              routeTo(`${appRoutes.jobDetail}/${params.row.unique_id}`);
            } else {
              updateInnerNetNetTabs('New Job', appRoutes.newJob);
              setProjectDetail(params.row);
              routeTo(appRoutes.newJob);
            }
          }}
          style={{ padding: '0 0 0 1rem' }}
        >
          {params.value}
        </div>
      ),
      align: 'left',
    },

    {
      field: 'company',
      headerName: 'Company Name',
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => {
        const currentTarget = currentCompanies.find(
          (ele) => Number(ele.id) === Number(params.value),
        );
        return (
          <div className="td">
            {currentTarget ? currentTarget.name : '-----'}
          </div>
        );
      },
    },
    {
      field: 'project_type',
      headerName: 'Project Type',
      flex: 1.2,
      renderHeader: () => <div className="tableHeader">Type</div>,

      sortable: false,
      renderCell: (params) => (
        <div className="td" style={{ textTransform: 'capitalize' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.2,
      renderHeader: () => <div className="tableHeader">Status</div>,

      sortable: false,
      renderCell: (params) => (
        <div className="td" style={{ textTransform: 'capitalize' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'target_start',
      headerName: 'Timeline',
      sortable: false,
      flex: 2,
      renderHeader: () => <div className="tableHeader">Timeline</div>,
      renderCell: (params) => {
        const start = new Date(
          params?.row?.target_start?.split('T')[0],
        ).getTime();
        const end = new Date(params?.row?.target_end?.split('T')[0]).getTime();
        const today = getToday().getTime();
        const progressDate = (today - start) / (1000 * 3600 * 24);
        const totalDate = (end - start) / (1000 * 3600 * 24);

        return (
          <div className="timeline">
            <ProgressBar
              now={progressDate}
              className={getVariant(progressDate, totalDate, 'date')}
              max={totalDate}
              min={0}
            />

            <div className="date_range p-1">
              <span className="start">
                {dayjs(params?.row?.target_start).format('MMM DD, YYYY')}
              </span>
              &nbsp; | &nbsp;
              <span className="end">
                {params?.row?.target_end
                  ? dayjs(params?.row?.target_end).format('MMM DD, YYYY')
                  : '------------------'}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'Level_of_effort',
      headerName: 'Level of Effort',
      sortable: false,
      flex: 2,
      renderHeader: () => <div className="tableHeader">Level of Effort</div>,
      renderCell: (params) => {
        const totalLoe = params?.row?.total_loe || 0;
        let progressHour = 0;
        const currentTask =
          Array.isArray(allTasks) && allTasks.length
            ? allTasks?.filter((it) => it?.task_project === params?.row?.id)
            : [];

        if (Array.isArray(currentTask) && currentTask.length) {
          progressHour = currentTask.reduce(
            (subAcc, task) =>
              subAcc +
              task.time_tracking.reduce((tTime, hour) => {
                if (hour.tracked_time_hours) {
                  const [hrs, mins] = hour.tracked_time_hours
                    .split(':')
                    .map(Number);
                  return tTime + hrs + mins / 60;
                }
                return tTime;
              }, 0),
            0,
          );
        }
        const remainingTime = Math.max(0, totalLoe - progressHour);
        const remainingHours = Math.floor(remainingTime); // Whole hours
        const remainingMinutes = Math.round(
          (remainingTime - remainingHours) * 60,
        );
        return (
          <div className="timeline">
            <ProgressBar
              sx
              now={progressHour}
              className={getVariant(progressHour, totalLoe, 'hour')}
              min={0}
              max={totalLoe}
            />
            <div className="date_range p-1">
              <span className="end">
                {`${remainingHours}h : ${remainingMinutes}m Remaining`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'menu',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 0.5,
      renderCell: (params) => (
        <div className="td" style={{ padding: '0 1rem 0 0' }}>
          <Popup
            open={isOpen === params.row.id}
            trigger={
              <button type="button" className="noBorder">
                <img
                  onClick={() => setIsOpen(params.row.id)}
                  src={moreIco}
                  alt="more"
                />
              </button>
            }
            onClose={() => setIsOpen(false)}
            position="right center"
          >
            <ul className="popup" id={params.id}>
              <li
                className="popupChildren"
                onClick={() => {
                  routeTo(`${appRoutes.jobDetail}/${params.row.unique_id}`);
                  updateInnerNetNetTabs(
                    'Job Detail',
                    `${appRoutes.jobDetail}/${params.row.unique_id}`,
                  );
                  setSettings(true);
                  setIsOpen('');
                }}
              >
                <SettingsIcon style={{ marginRight: '5px' }} />
                Settings
              </li>
              {params.row.status === 'inactive' ||
              params.row.status === 'archived' ? (
                <li
                  className="popupChildren"
                  onClick={async () => {
                    const res = await fetch(
                      `${ENDPOINT}/api/project/update/${params.row.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          status: 'active',
                          creation_wizard_completed: true,
                        }),
                      },
                    );
                    const data = await res.json();
                    /* eslint-disable */
                    if (!res.ok) {
                      if (data?.title?.['Title Error']) {
                        for (let i in data.title) {
                          setErrorState({
                            error: true,
                            message:
                              data && data?.title
                                ? data?.title?.[i]
                                : 'Could not Activate Your Job.',
                          });
                        }
                      } else if (data?.['create project']) {
                        for (let i in data) {
                          const msg = data[i];
                          setAlertMessage(msg);
                        }
                        SetAlertShow(true);
                      } else {
                        for (let i in data) {
                          let msg = data[i];
                          setErrorState({
                            error: true,
                            message:
                              data && msg
                                ? msg
                                : 'Could not Activate Your Job.',
                          });
                        }
                      }
                      return;
                    } else {
                      dispatch(getProjects({ access_token }));
                      setIsOpen('');
                      setArgs('all');
                    }
                    /* eslint-enable */
                  }}
                >
                  <LuServer style={{ marginRight: '5px' }} />

                  {params.row.project_type === 'project'
                    ? 'Activate Project'
                    : 'Activate Retainer'}
                </li>
              ) : null}
              <li
                className="popupChildren"
                onClick={() => {
                  setCurrentDlt(params.row);
                  setSureDuplicate(true);
                  setIsOpen('');
                }}
              >
                <MdControlPointDuplicate
                  style={{ marginRight: '5px', fontSize: '20px' }}
                />{' '}
                Duplicate
              </li>

              <li
                className="popupChildren LastChild"
                onClick={async () => {
                  setCurrentDlt(params.row);
                  setSureDelete(true);
                  setIsOpen('');
                }}
                style={{ color: 'red' }}
              >
                <img
                  alt="delete-icon"
                  src={DeleteIcon}
                  className="icon-sm"
                  style={{ marginLeft: '-5px' }}
                />
                Delete Selected
              </li>
            </ul>
          </Popup>
        </div>
      ),
    },
  ]);

  const jobsColumnOrderStorageKey = `JobsTableColumnOrderForUserID${currentUser?.id}`;

  const handleColumnOrderChange = (newColumnOrder) => {
    const updatedColumns = [...columns.map((item) => ({ ...item }))];
    const columnToMove = updatedColumns[newColumnOrder.targetIndex];
    const find = columns.find(
      (item) => item.field === newColumnOrder.column.field,
    );

    updatedColumns[newColumnOrder.targetIndex] = find;
    updatedColumns[newColumnOrder.oldIndex] = columnToMove;
    saveColumnsToLocalStorage(jobsColumnOrderStorageKey, updatedColumns);
    setColumns(updatedColumns);
  };

  useEffect(() => {
    setColumns(loadColumnsFromLocalStorage(jobsColumnOrderStorageKey, columns));
  }, []);

  useEffect(() => {
    dispatch(getProjects(meta));
  }, [showModal, sureDelete]);

  const rows =
    Array.isArray(projects) && projects.length
      ? projects.map((project) => ({
          id: project.id,
          total_loe: project.total_loe,
          name: project.title,
          project_type: project.project_type,
          company: project.company,
          target_start: project.target_start,
          target_end: project.finish_date,
          current_hour: project.current_hour,
          total_hour: project.total_hour,
          teams: [icon1, icon2, icon3],
          deliverable_project: project.deliverable_project,
          status: project.status ? project.status : 'Active',
          unique_id: project.unique_id,
          creation_wizard_completed: project?.creation_wizard_completed,
        }))
      : [];

  const courses = useSelector((state) => state.NetNetU.courses)?.filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters?.filter((chapter) => chapter.title === 'Project');
  });
  const [show, setShow] = useState(false);

  const handleFilter = (e) => {
    let selectedValue = e.target.value; // Array of objects with { value, type }
    const lastValue = selectedValue[selectedValue.length - 1]; // Last selected object
    const isAlreadyExist = args.some(
      (item) => item.value === lastValue.value && item.type === lastValue.type,
    );

    if (args.length === 1 && isAlreadyExist) {
      return;
    }

    if (
      lastValue.value === 'all' &&
      !args.some((item) => item.value === 'all')
    ) {
      // If "all" is selected and not already in args
      selectedValue = projectFilterOptions.map((item) => ({ ...item }));
      setArgs(selectedValue);
    } else if (
      lastValue.value === 'all' &&
      args.some((item) => item.value === 'all')
    ) {
      // If "all" is unselected and was present in args
      selectedValue = [{ value: 'inactive', type: 'project' }]; // Default inactive with type
      setArgs(selectedValue);
    } else if (
      projectFilterOptions
        .filter((item) => item.value !== 'all') // Exclude "all"
        .every((opt) =>
          selectedValue.some(
            (val) => val.value === opt.value && val.type === opt.type,
          ),
        )
    ) {
      // If all other options are selected
      selectedValue = projectFilterOptions.map((item) => ({ ...item }));
      setArgs(selectedValue);
    } else if (isAlreadyExist) {
      // if already exists and selected second time so just remove
      const updatedArgs = args.filter(
        (item) =>
          !(item.value === lastValue.value && item.type === lastValue.type),
      );
      setArgs(updatedArgs);
    } else {
      // Remove "all" if it's selected alongside other options
      selectedValue = selectedValue.filter((item) => item.value !== 'all');
      setArgs(selectedValue);
    }
  };

  return Array.isArray(myArr) && myArr.length ? (
    <>
      {AlertShow && (
        <ActiveProjectAlert
          SetAlertShow={SetAlertShow}
          AlertMessage={AlertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
      <Bounce left>
        <section className="oppor-section">
          <TitleBar
            helpIconFunction={() => setShow(true)}
            buttonFunction={() => setShowModal(true)}
            buttonRequired={true}
            buttonTitle="New"
            sectionTitle="Jobs"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchBarRequired={true}
          />
          <FilterBar
            filterDropdownArray={[
              {
                options: projectFilterOptions,
                labelId: 'jobs-filter',
                id: 'jobs-filter',
                selectedFilter: args,
                value: args,
                onChange: (e) => handleFilter(e),
                defaultValue: 'Projects & Retainers',
              },
            ]}
            hideListIcon={true}
            hideKanbanIcon={true}
          />
          <Box
            sx={{
              width: '100%',
              minHeight: screenHeight - 0,
              maxHeight: screenHeight - 0,
              overflow: 'auto',
            }}
          >
            <NetNetTable
              rows={rows}
              columns={columns}
              components={{
                ColumnSortedAscendingIcon: IoMdArrowDropdown,
                ColumnSortedDescendingIcon: IoMdArrowDropup,
              }}
              rowHeight={60}
              sx={{
                '& .MuiDataGrid-root': {
                  borderRadius: '8px',
                },
                minHeight: screenHeight - 220,
                maxHeight: screenHeight - 220,
                overflow: 'auto',
                borderRadius: '8px',
                background: '#fff',
              }}
              handleColumnOrderChange={handleColumnOrderChange}
              sortModel={jobsSortModel}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              setSortModel={setJobsSortModel}
              initialState={{
                columns: {
                  columnVisibilityModel,
                },
              }}
              componentsProps={{
                panel: {
                  sx: {
                    [`& .MuiDataGrid-columnsPanel > div:last-child`]: {
                      display: 'none',
                    },
                  },
                },
              }}
              setSureDelete={setSureDelete}
              sureDelete={sureDelete}
              currentTargetData={currentDlt}
              itemName="Job"
              paragraph="Are you sure you want to Delete this Job? Your Job will become
            inactive and the billing will also stop."
              deleteEndPoint={deleteEndPoint}
            />
          </Box>
        </section>
      </Bounce>
      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
      {sureDuplicate && (
        <Duplicate
          setSureDuplicate={setSureDuplicate}
          currentTarget={currentDlt}
          myArr2={myArr}
        />
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={() => setShowModal(false)}
        open={showModal}
      >
        <InfoCreate
          setShowModal={setShowModal}
          menuRef={menuRef}
          updateInnerNetNetTabs={updateInnerNetNetTabs}
        />
      </Backdrop>
    </>
  ) : (
    <>
      <section className="oppor-section">
        <TitleBar
          helpIconFunction={() => setShow(true)}
          buttonFunction={() => setShowModal(true)}
          buttonRequired={true}
          buttonTitle="New"
          sectionTitle="Jobs"
        />
        <section className="myCenter" style={{ height: screenHeight - 155 }}>
          <article className="box-txt">
            <h3 className="txtCenter">No Job yet</h3>
            <p className="txtCenter">All Your Job Would Appear Here!</p>
            <button
              type="button"
              className="btn"
              onClick={() => setShowModal(true)}
            >
              Create JOB
            </button>
          </article>
        </section>
      </section>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={() => setShowModal(false)}
        open={showModal}
      >
        <InfoCreate
          setShowModal={setShowModal}
          menuRef={menuRef}
          updateInnerNetNetTabs={updateInnerNetNetTabs}
        />
      </Backdrop>
      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
    </>
  );
}
export default Project;
