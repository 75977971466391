import { Avatar } from '@mui/material';
import { React, useState, useEffect, useRef } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Box, Typography, Paper, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { useDispatch } from 'react-redux';
import { getUserTeam } from '../Team/team';
import Autocomplete from '@mui/material/Autocomplete';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import Shake from 'react-reveal/Shake';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import { PreLoader1 } from '../../Componenets/Misc';
import TitleBar from '../../Componenets/atomic-molecules/Headers/TitleBar';
const IconComp = ({
  setCurrentTarget,
  setIsEditing,
  isEditing,
  item,
  setEditData,
  access_token,
  setSucc,
  setDltsucc,
  setSureDelete,
  setLoading,
  setErr,
  setErrMsg,
}) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async (event, id) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (id) {
      setCurrentTarget(item);
      setSureDelete(true);
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userData.userData);
  return (
    <>
      <MoreHorizIcon
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ color: 'GrayText', fontSize: '30px', cursor: 'pointer' }}
      />

      <div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            borderRadius: '10px',
            '& .css-1ps6pg7-MuiPaper-root': {
              backgroundColor: '#fff',
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: 'none',
            },
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{
                      width: {
                        xl: '180px',
                        lg: '110px',
                      },
                      boxShadow: 'none',
                      border: '0.5px solid #9f9c9cde',
                      borderRadius: '10px',
                    }}
                  >
                    <MenuItem
                      onClick={async (e) => {
                        handleClose(e);
                        if (item.f_name === 'First Name') {
                          const res = await fetch(
                            `${ENDPOINT}/api/user/userpersonalization/`,
                            {
                              method: 'PUT',
                              headers: {
                                Authorization: `JWT ${access_token}`,
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({
                                invitation: [
                                  {
                                    email: item.email,
                                    role:
                                      item.type.props.children === 'Owner'
                                        ? 'is_owner'
                                        : item.type.props.children === 'Admin'
                                          ? 'is_admin'
                                          : item.type.props.children === 'Lead'
                                            ? 'is_lead'
                                            : 'is_user',
                                  },
                                ],
                              }),
                            },
                          );

                          const data = await res.json();

                          if (!res.ok) {
                            setLoading(false);
                            setErr(true);
                            for (const key in data) {
                              setErrMsg(data[key]);
                            }
                          } else {
                            dispatch(getUserTeam({ access_token }));
                            setSucc(true);
                          }
                        } else {
                          setIsEditing(true);
                          setEditData(item);
                        }
                      }}
                      sx={{
                        color: 'gray',
                        display: 'flex',
                        gap: { xl: '1rem', lg: '.5rem' },
                        marginBottom: '1rem',
                      }}
                    >
                      {item.f_name === 'First Name' ? (
                        <>
                          {' '}
                          <Send /> Resend{' '}
                        </>
                      ) : (
                        <>
                          {' '}
                          <BorderColorIcon /> Edit
                        </>
                      )}
                    </MenuItem>

                    {/* // ! The 'MenuItem' has been commented out to satisfy #121 issue (turn off features). ---------Issue #121-------- */}
                    {currentUser.is_admin && (
                      <></>
                      // <MenuItem
                      //   onClick={(e) => handleClose(e, item.id)}
                      //   sx={{
                      //     color: 'red',
                      //     display: 'flex',
                      //     gap: { xl: '1rem', lg: '.5rem' },
                      //   }}
                      // >
                      //   <DeleteOutlineIcon /> Delete
                      // </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

const SureDeleteOpp = ({
  setSureDelete,
  currentTarget,
  setDltsucc,
  setSucc,
}) => {
  const team = useSelector((item) => item.userTeam.team.member);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [error, setError] = useState(false);
  const handleDelete = async () => {
    if (type === 'delete') {
      setLoading(true);
      const res = await fetch(`${ENDPOINT}/api/user/team/update/`, {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          member: team
            .filter((item) => item.id !== currentTarget.id)
            .map((item) => item.id),
        }),
      });

      const data = await res.json();

      if (!res.ok) {
        setLoading(false);
        setError(true);
        for (const key in data) {
          setErrMsg(data[key]);
        }
      } else {
        dispatch(getUserTeam({ access_token }));
        setDltsucc(true);
        setTimeout(() => {
          setDltsucc(false);
        }, 3000);
        setSureDelete(false);
      }
    } else {
      setError('Incorrect Spelling');
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '850px',
            height: '320px',
            background: 'rgba(241, 243, 245, 0.50)',
            padding: '2rem',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '95%',
              height: '95%',
              backgroundColor: 'rgba(255,255,255)',
              border: '1px solid #d9d9d9',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px',
              padding: '0.68rem 1rem',
              justifyContent: 'space-around',
              boxShadow: '2px 2px 6px 0px #D9D9D9',
              gap: '10px',
            }}
          >
            <p
              style={{
                fontSize: '0.9rem',
                fontWeight: '600',
                fontFamily: 'Open Sans, sans-serif',
                color: 'red',
              }}
            >
              DELETE TEAM MEMBER
            </p>
            <p
              className="fstxt"
              style={{ width: '80%', textAlign: 'center', fontWeight: '400' }}
            >
              Are you sure you want to Delete this Team Member
            </p>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className="fstxt" style={{ fontWeight: '400' }}>
                Please type the word "DELETE" and click the Delete button
              </p>
              <Box
                sx={{
                  width: '40%',
                  borderRadius: '8px',
                  padding: '1rem 1.1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '65px',
                }}
              >
                <TextField
                  value={type}
                  variant="standard"
                  onChange={(e) => setType(e.target.value.toLocaleLowerCase())}
                  placeholder={'Delete'}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: '1rem',
                      fontFamily: 'Open Sans',
                      fontWeight: '500',
                      color: '#000000',
                      textAlign: 'center',
                    },
                    '& .MuiInput-underline:before': {
                      borderBottom: '1px dashed #000000',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottom: '1px dashed #000000',
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: '1px dashed #000000',
                    },
                  }}
                />
              </Box>
              {error && (
                <Shake>
                  <p style={{ color: 'red' }}>{error}</p>
                </Shake>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
                margin: '0 auto',
              }}
            >
              <button
                className="cancel-btn"
                onClick={() => setSureDelete(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  fontFamily:
                    "'Neue Haas Display Pro', 'Open Sans', sans-serif",
                  fontWeight: '500',
                  padding: '1rem 0.5rem',
                  backgroundColor: type ? '#cd0b4c' : '#f99f9f',
                  borderRadius: '5px',
                  color: 'var(--white-100)',
                  border: 'none',
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontSize: '1rem',
                  padding: '0rem 2rem',
                  height: '38px',
                }}
                onClick={handleDelete}
                disabled={type ? false : true}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.1rem',
                    }}
                  >
                    <PreLoader1 />
                  </Box>
                ) : (
                  'Delete'
                )}{' '}
              </button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </section>
  );
};

const TeamMembers = ({ screenHeight }) => {
  const [tableData, setTableData] = useState([]);
  const realTeam = useSelector((state) => state.userTeam.team?.member ?? []);
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});
  const [sureDelete, setSureDelete] = useState(false);

  useEffect(() => {
    if (Array.isArray(realTeam) && realTeam.length) {
      setTableData(
        realTeam.map((item) => {
          return {
            id: item.id,
            f_name: item.first_name,
            l_name: item.last_name ? item.last_name : '',
            avatar: item.avatar,

            email: item.email,
            type: (
              <p
                style={{
                  marginBottom: '0px',
                }}
              >
                {item.is_owner
                  ? 'Owner'
                  : item.is_lead
                    ? 'Team Lead'
                    : item.is_admin
                      ? 'Admin'
                      : 'Member'}
              </p>
            ),
            action: '',
          };
        }),
      );
    }
  }, [realTeam]);

  const filteredTable =
    tableData &&
    tableData.filter((item) => {
      let check =
        item.f_name === 'First Name'
          ? item.email.split('@')[0].toLocaleLowerCase()
          : item.f_name + ' ' + item.l_name.toLocaleLowerCase();
      return check.startsWith(searchQuery.toLocaleLowerCase());
    });
  const tableData2 = searchQuery ? filteredTable : tableData;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [text, setText] = useState('');
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const access_token = useSelector((state) => state.auth.user.access);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [succ, setSucc] = useState(false);
  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr(false);
        setErrMsg('');
      }, 3000);
    }
  }, [err]);

  useEffect(() => {
    if (succ) {
      setTimeout(() => {
        setSucc(false);
        setLoading(false);
      }, 2000);
    }
  }, [succ]);

  const dispatch = useDispatch();
  const levels = [
    'Owner',
    'Lead',
    'Admin',
    'Member',
    // 'Employee',
  ];
  const [access1, setAccess] = useState('');
  const [dltsucc, setDltsucc] = useState(false);
  const [currentTarget, setCurrentTarget] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <Bounce left>
      <>
        <div
          style={{
            display: 'block',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f7f7fa',
            padding: '1rem',
            height: '100vh',
          }}
        >
          <TitleBar
            helpIconFunction={() => setShow(true)}
            sectionTitle="Team Members"
            buttonRequired={true}
            buttonFunction={() => setIsDrawerOpen(true)}
            buttonTitle="Invite Members"
          />
          <Box
            sx={{
              width: '100%',
              height: screenHeight - 145,
              backgroundColor: 'white',
              borderRadius: '8px',
              marginTop: '2rem',
              border: '1px solid lightGray',
              overflow: 'auto',
              padding: '1rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
              borderBottom: 0,
            }}
          >
            <div>
              <Box
                sx={{
                  height: '5rem',
                  width: '100%',
                  borderRadius: '8px',
                  backgroundColor: '#f7f7f7',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0rem 1.5rem',
                }}
              >
                <Box></Box>
                <Paper
                  component="form"
                  sx={{
                    p: ' 0px 2px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '30%',
                    borderRadius: '8px',
                    boxShadow: 'none',
                  }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={'Search Team Members'}
                    inputProps={{ 'aria-label': 'Search here ' }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </Paper>
              </Box>
            </div>
            <Box
              sx={{
                marginTop: '2rem',
                height: '100%',
                width: '100%',
                borderRadius: '8px',
              }}
            >
              <TableContainer
                sx={{
                  borderRadius: '8px',
                  maxHeight: screenHeight - 250,
                  overflow: 'auto',
                  scrollbarWidth: 'none',
                  border: '1.5px solid lightGray',
                }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead
                    sx={{
                      border: '1.5px solid lightGray',
                      backgroundColor: '#f2f2f2',
                    }}
                  >
                    <TableRow
                      sx={{
                        height: '70px',
                        backgroundColor: '#f2f2f2',
                      }}
                    >
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        Person
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        Email
                      </TableCell>

                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        Account Type
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(tableData2) && tableData2.length ? (
                      tableData2.map((row) => (
                        <TableRow sx={{ height: '70px' }} key={row.id} hover>
                          <TableCell>
                            {' '}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.8rem',
                                fontSize: '16px',
                              }}
                            >
                              <Avatar
                                sx={{ height: '20px', width: '20px' }}
                                src={row.avatar ? row.avatar : row.f_name}
                                alt={row.f_name}
                              />
                              {row.f_name === 'First Name'
                                ? row.email.split('@')[0]
                                : row.f_name + ' ' + row.l_name}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              alignItems: 'center',
                              fontSize: '14px',
                              color: '#585151',
                            }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: '13px', fontWeight: '500' }}
                          >
                            <p
                              style={{
                                backgroundColor:
                                  row.type.props.children === 'Admin'
                                    ? '#e4d7fa'
                                    : row.type.props.children === 'Lead'
                                      ? '#D2B8FF'
                                      : row.type.props.children === 'Owner'
                                        ? '#e1fdf8'
                                        : row.f_name === 'First Name'
                                          ? 'none'
                                          : '#f7f7f7',
                                display: 'inline-block',
                                padding: '.8rem .7rem',
                                borderRadius: '9px',
                                fontSize: '14px',
                                fontWeight: '500',
                                color:
                                  row.f_name === 'First Name'
                                    ? '#711fff'
                                    : 'gray',
                              }}
                            >
                              {row.f_name === 'First Name'
                                ? '(INVITED) WAITING FOR THE APPROVAL'
                                : row.type.props.children}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <IconComp
                              setSureDelete={setSureDelete}
                              setCurrentTarget={setCurrentTarget}
                              setEditData={setEditData}
                              item={row}
                              isEditing={isEditing}
                              setIsEditing={setIsEditing}
                              access_token={access_token}
                              setSucc={setSucc}
                              setDltsucc={setDltsucc}
                              setLoading={setLoading}
                              setErr={setErr}
                              setErrMsg={setErrMsg}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Alert severity="info">
                            You dont have any Teammates s yet. You can still
                            Invite other peoples to be your Teammates !
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {sureDelete && (
                  <SureDeleteOpp
                    setSucc={setSucc}
                    setDltsucc={setDltsucc}
                    setSureDelete={setSureDelete}
                    sureDelete={sureDelete}
                    setCurrentTarget={setCurrentTarget}
                    currentTarget={currentTarget}
                  />
                )}
                {succ && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '20%',
                      left: '5%',
                      width: '90%',
                    }}
                  >
                    {' '}
                    <Alert sx={{ width: '100%' }} severity="success">
                      Invitation Resent Succesfully.
                    </Alert>
                  </Box>
                )}
                {dltsucc && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '20%',
                      left: '5%',
                      width: '90%',
                    }}
                  >
                    {' '}
                    <Alert sx={{ width: '100%' }} severity="success">
                      Team Member has been deleted Succesfully.
                    </Alert>
                  </Box>
                )}
              </TableContainer>
            </Box>
          </Box>
          <HelpVideo show={show} setShow={setShow} />
        </div>
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box
            p={2}
            height="90%"
            width="100%"
            textAlign="center"
            role="presentartion"
          >
            <Typography variant="h6" component="div">
              <div
                style={{
                  display: 'Flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    border: '1px solid lightGray',
                    borderRadius: '6px',
                    width: '800px',
                    padding: '2rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '1.15rem',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Invite people to NET NET
                    </span>
                    <CloseIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsDrawerOpen(false)}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      To:
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    <div>
                      <textarea
                        style={{
                          width: '100%',
                          border: '1px solid #e7e7e7',
                          fontSize: '1rem',
                          padding: '0.22rem 0.44rem',
                          borderRadius: '8px',
                          outlineColor: '#711FFF',
                        }}
                        rows={2} // Specify the number of visible rows
                        cols={40} // Specify the number of visible columns
                        value={text} // Set the value from state
                        onChange={handleTextChange} // Handle input value changes
                      />
                    </div>
                  </div>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      width: '48%',

                      marginTop: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#711FFF',
                        fontWeight: '500',
                        fontSize: { xl: '15px', lg: '13px' },
                      }}
                    >
                      Select Access Level
                    </Typography>
                    <Autocomplete
                      id="country"
                      includeInputInList
                      onChange={(e, value) => {
                        setAccess(value);
                      }}
                      options={levels}
                      getOptionLabel={(option) => option}
                      sx={{
                        width: { xl: 200, lg: 170 },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#fff',
                          },
                          '&.Mui-disabled fieldset': {
                            borderColor: '#fff',
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          value={access1}
                          color="secondary"
                          sx={{
                            borderBottom: '.5px solid #711FFF',
                            fontWeight: 'bold',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },

                              '&.Mui-disabled fieldset': {
                                borderColor: 'grey',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { width: '100%' },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {succ && (
                    <Alert severity="success">
                      Invitation Sent Succesfully.
                    </Alert>
                  )}
                  {err && <Alert severity="info">{errMsg}</Alert>}
                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'end',
                    }}
                  >
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: !text ? '#d2b8ff' : '#711fff',
                        padding: loading ? '0.2rem 1rem' : '0.5rem 1rem',
                        borderRadius: '8px',
                        gap: '0.7rem',
                        fontSize: '15px',
                        color: 'white',
                        width: '110px',
                        height: '35px',
                      }}
                      onClick={async () => {
                        setLoading(true);
                        const res = await fetch(
                          `${ENDPOINT}/api/user/userpersonalization/`,
                          {
                            method: 'PUT',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              invitation: [
                                {
                                  email: text,
                                  role:
                                    access1 === 'Owner'
                                      ? 'is_owner'
                                      : access1 === 'Lead'
                                        ? 'is_lead'
                                        : access1 === 'Admin'
                                          ? 'is_admin'
                                          : 'is_user',
                                },
                              ],
                            }),
                          },
                        );

                        const data = await res.json();

                        if (!res.ok) {
                          setLoading(false);
                          setErr(true);
                          for (const key in data) {
                            setErrMsg(data[key]);
                          }
                        } else {
                          dispatch(getUserTeam({ access_token }));
                          setSucc(true);
                          setIsDrawerOpen(false);
                          setText('');
                          setAccess('');
                        }
                      }}
                    >
                      {loading ? (
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            margin: '0rem 0.1rem',
                          }}
                        >
                          <PreLoader1 />
                        </Box>
                      ) : (
                        <>
                          <SendIcon
                            style={{
                              transform: 'rotate(-45deg)',
                              height: '20px',
                              width: '20px',
                            }}
                          />
                          Send
                        </>
                      )}{' '}
                    </button>
                  </div>
                </Box>
              </div>
            </Typography>
          </Box>
        </Drawer>

        <Drawer
          anchor="bottom"
          open={isEditing}
          onClose={() => setIsEditing(false)}
        >
          <Box
            p={2}
            height="90%"
            width="100%"
            textAlign="center"
            role="presentartion"
          >
            <Typography variant="h6" component="div">
              <div
                style={{
                  display: 'Flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    border: '1px solid lightGray',
                    borderRadius: '6px',
                    width: '800px',
                    padding: '2rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '1.15rem',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Update Team Member's Information
                    </span>
                    <CloseIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsDrawerOpen(false)}
                    />
                  </div>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box sx={{ width: '45%' }}>
                      <div
                        style={{
                          textAlign: 'start',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            color: '#711FFF',
                          }}
                        >
                          First Name
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: 'start',
                        }}
                      >
                        <div>
                          <TextField
                            sx={{
                              width: '100%',
                              border: '1px solid #e7e7e7',
                              fontSize: '1rem',
                              padding: '0.22rem 0.44rem',
                              borderRadius: '8px',
                              outlineColor: '#711FFF',
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },
                            }}
                            // Specify the number of visible columns
                            value={editData?.f_name} // Set the value from state
                            onChange={(e) => {
                              setEditData({
                                ...editData,
                                f_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Box>

                    <Box sx={{ width: '45%' }}>
                      <div
                        style={{
                          textAlign: 'start',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            color: '#711FFF',
                          }}
                        >
                          Last Name
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: 'start',
                        }}
                      >
                        <div>
                          <TextField
                            sx={{
                              width: '100%',
                              border: '1px solid #e7e7e7',
                              fontSize: '1rem',
                              padding: '0.22rem 0.44rem',
                              borderRadius: '8px',
                              outlineColor: '#711FFF',
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },
                            }}
                            // Specify the number of visible columns
                            value={editData?.l_name} // Set the value from state
                            onChange={(e) => {
                              setEditData({
                                ...editData,
                                l_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      width: '48%',

                      marginTop: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#711FFF',
                        fontWeight: '500',
                        fontSize: { xl: '15px', lg: '13px' },
                      }}
                    >
                      Select Access Level
                    </Typography>
                    <Autocomplete
                      id="country"
                      includeInputInList
                      defaultValue={
                        editData?.type?.props?.children === 'Account Owner'
                          ? 'Owner'
                          : editData?.type?.props?.children === 'Team Lead'
                            ? 'Lead'
                            : editData?.type?.props?.children
                      }
                      onChange={(e, value) => {
                        setAccess(value);
                      }}
                      options={levels}
                      getOptionLabel={(option) => option}
                      sx={{
                        width: { xl: 200, lg: 170 },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#fff',
                          },
                          '&.Mui-disabled fieldset': {
                            borderColor: '#fff',
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          color="secondary"
                          sx={{
                            borderBottom: '.5px solid #711FFF',
                            fontWeight: 'bold',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },

                              '&.Mui-disabled fieldset': {
                                borderColor: 'grey',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { width: '100%' },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {err && <Alert severity="info">{errMsg}</Alert>}
                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'end',
                    }}
                  >
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#711fff',
                        padding: loading ? '0.25rem 1rem' : '0.5rem 1rem',
                        borderRadius: '8px',
                        gap: '0.7rem',
                        fontSize: '15px',
                        color: 'white',
                        width: '110px',
                        height: '35px',
                      }}
                      onClick={async () => {
                        setLoading(true);

                        const res = await fetch(
                          `${ENDPOINT}/api/user/team/update/member-permissions/`,
                          {
                            method: 'PUT',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              member: [
                                {
                                  id: editData.id,
                                  role:
                                    access1 === 'Owner'
                                      ? 'is_owner'
                                      : access1 === 'Lead'
                                        ? 'is_lead'
                                        : access1 === 'Admin'
                                          ? 'is_admin'
                                          : 'is_user',
                                },
                              ],
                            }),
                          },
                        );

                        const data = await res.json();

                        if (!res.ok) {
                          setLoading(false);
                          setErr(true);
                          for (const key in data) {
                            setErrMsg(data[key]);
                          }
                        } else {
                          dispatch(getUserTeam({ access_token }));
                          setSucc(true);
                          setIsEditing(false);
                          setLoading(false);
                        }
                      }}
                    >
                      {loading ? (
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            margin: '0rem 0.1rem',
                          }}
                        >
                          <PreLoader1 />
                        </Box>
                      ) : (
                        <>Save</>
                      )}{' '}
                    </button>
                  </div>
                </Box>
              </div>
            </Typography>
          </Box>
        </Drawer>
      </>
    </Bounce>
  );
};
export default TeamMembers;
