import SectionHeader from '../../../../Componenets/atomic-atoms/SectionHeader';
import ComingSoon from '../../../../Componenets/atomic-atoms/ComingSoon';

function Lessons() {
  return (
    <>
      <SectionHeader title="Lessons" />
      <ComingSoon title="Lessons" />
    </>
  );
}

export default Lessons;
