import { React, useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';

import 'gantt-task-react/dist/index.css';
import {
  GanttComponent,
  Inject,
  Edit,
  Selection,
  EventMarkerDirective,
  EventMarkersDirective,
  DayMarkers,
  ColumnDirective,
  ColumnsDirective,
} from '@syncfusion/ej2-react-gantt';

import { PreLoading } from '../../Contacts/People/EditContact';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';

function PFourth({
  formData,
  setFormData,
  setTemp,
  view,
  dileverable,
  setDeliverable,
  access_token,
}) {
  const [tasks, setTasks] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const gridRef = useRef(null);

  useEffect(() => {
    const data = dileverable.map((item) => {
      const start = new Date(item.deliverable_startdate);
      const end = new Date(item.deliverable_enddate);
      const dependency = dileverable.find(
        (ob) => ob.id === item.deliverable_dependency,
      );

      // Determine the Predecessor field based on the dependency
      const predecessor = dependency ? dependency.id : '';
      const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
      return {
        TaskID: item.id,
        TaskName: item.deliverable_name,
        StartDate: start,
        EndDate: end,
        Duration: duration,
        Predecessor: predecessor,
        Hours: item.deliverable_worktype.reduce((total, acc) => {
          return total + Number(acc.hours ? acc.hours : 0);
        }, 0),
      };
    });

    setTasks(data);
  }, [dileverable]);

  const editOptions = {
    allowEditing: true,
    allowTaskbarEditing: true,
    columns: [
      { field: 'StartDate', allowEditing: false },
      { field: 'EndDate', allowEditing: false },
    ],
  };
  const toolbarOptions = ['ZoomToFit', 'ExpandAll', 'CollapseAll'];
  const taskFields = {
    id: 'TaskID',
    name: 'TaskName',
    startDate: 'StartDate',
    endDate: 'EndDate',
    duration: 'Duration',
    dependency: 'Predecessor',
    hours: 'Hours',
  };

  const timelineSettings = {
    timelineUnitSize: 80,
    timelineViewMode: view,
    workWeek: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Satudrday',
    ],
    durationUnit: 'Day',
  };

  const finishdate = formData?.finish_date?.split('T')[0];
  const estimate_finshDate = new Date(finishdate);

  useEffect(() => {
    const requiredFields = ['id', 'name', 'start', 'progress', 'displayOrder'];
    const hasRequiredFields = tasks?.every((t) =>
      requiredFields.every((field) => typeof t[field] !== 'undefined'),
    );
    if (hasRequiredFields) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      setTemp(tasks);
    }
  }, [tasks]);

  const handleTaskbar = async (args) => {
    const shallow_copy = dileverable.map((item) => ({ ...item }));
    shallow_copy[args.data.index].deliverable_startdate =
      args.data.StartDate.toISOString();
    shallow_copy[args.data.index].deliverable_enddate =
      args.data.EndDate.toISOString();
    const durationEst =
      new Date(formData.finish_date) - new Date(formData.target_start);
    const shall = { ...formData };
    const totalDays = durationEst / (24 * 60 * 60 * 1000);
    const startDateOfDil = new Date('11/11/2080').toISOString();
    const leastDate = shallow_copy.reduce((minDate, dil) => {
      if (dil.deliverable_startdate < minDate) {
        return dil.deliverable_startdate;
        // eslint-disable-next-line
      } else {
        return minDate;
      }
    }, startDateOfDil);

    delete shall.work_type;

    const res = await fetch(`${ENDPOINT}/api/project/update/${formData.id}/`, {
      method: 'PUT',
      headers: {
        Authorization: `JWT ${access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...shall,
        target_start: leastDate,
        finish_date: formData.finish_date
          ? new Date(
              new Date(leastDate).setDate(
                new Date(leastDate).getDate() + totalDays,
              ),
            )
          : null,
        project_deliverable: shallow_copy?.map((item, index) => {
          const newStartData = new Date(tasks[index].StartDate);
          newStartData.setDate(newStartData.getDate() + 1);
          const newEndDate = new Date(tasks[index].EndDate);
          newEndDate.setDate(newEndDate.getDate() + 1);
          // eslint-disable-next-line
          delete item.task_deliverable;
          return {
            ...item,
            deliver_id: item.id,
            deliverable_worktype: item.deliverable_worktype.map((wtt) => ({
              ...wtt,
              id: wtt.id ? wtt.id : '',
              hours: wtt.hours ? wtt.hours : null,
            })),
          };
        }),
      }),
    });
    if (res.ok) {
      const resGet = await fetch(
        `${ENDPOINT}/api/project/detail/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (resGet.ok) {
        if (
          dataGet &&
          Array.isArray(dataGet.deliverable_project) &&
          dataGet.deliverable_project.length
        ) {
          setDeliverable(dataGet.deliverable_project);

          setFormData(dataGet);
        }

        const data2 = shallow_copy.map((item) => {
          const start = new Date(item.deliverable_startdate);
          const end = new Date(item.deliverable_enddate);
          const dependency = dataGet.deliverable_project?.find(
            (ob) => ob?.id === item?.deliverable_dependency,
          );

          // Determine the Predecessor field based on the dependency
          const predecessor = dependency ? dependency?.id : '';

          return {
            TaskID: item.id,
            TaskName: item.deliverable_name,
            StartDate: start,
            EndDate: end,
            Predecessor: predecessor,
            Hours: item.deliverable_worktype.reduce((total, acc) => {
              return total + Number(acc.hours ? acc.hours : 0);
            }, 0),
          };
        });

        setTasks(data2);
      }
    }
  };

  return Array.isArray(tasks) && tasks.length ? (
    <Grid
      className="gantt-target"
      ref={gridRef}
      item
      container
      direction="column"
      xl={12}
    >
      <GanttComponent
        taskbarEdited={handleTaskbar}
        UpdateOffsetOnTaskbarEdit={false}
        toolbar={toolbarOptions}
        workWeek={[
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ]}
        queryTaskbarInfo={(args) => {
          if (args.data && args.data.Duration) {
            // eslint-disable-next-line
            args.data.Duration = Math.round(args.data.Duration);
          }
        }}
        actionBegin={(args) => {
          if (args.requestType === 'beforeOpenEditDialog') {
            // eslint-disable-next-line
            args.cancel = true; // Cancel the default dialog
          }
        }}
        rowHeight={60}
        timelineSettings={timelineSettings}
        allowParentDependency={true}
        editSettings={editOptions}
        dataSource={tasks}
        taskFields={taskFields}
        height="640px"
      >
        {formData.est_finish_date ? (
          <EventMarkersDirective>
            {/* eslint-disable-next-line */}
            <EventMarkerDirective
              day={estimate_finshDate}
              label="Target End Date"
            ></EventMarkerDirective>
          </EventMarkersDirective>
        ) : null}
        <ColumnsDirective>
          {/* eslint-disable-next-line */}
          <ColumnDirective
            field="TaskID"
            headerText=""
            visible={false}
          ></ColumnDirective>
          {/* eslint-disable-next-line */}
          <ColumnDirective
            field="TaskName"
            headerText="Deliverables"
            width="auto"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Edit, Selection, DayMarkers]} />
      </GanttComponent>
    </Grid>
  ) : (
    <PreLoading />
  );
}
export default PFourth;
