import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/system';
import {
  Alert,
  Button,
  Grid,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import RefreshIcon from '@mui/icons-material/Refresh';

import 'primereact/resources/primereact.min.css';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import SearchableListField from '../../Componenets/Reports/SearchableListField';
import { useGetJobDetail } from '../../hooks/api-hooks/useGetJobDetail';
import JobDetailsKPISummaries from '../Projects/ProjectDet/KPIs/JobDetailsKPI';
import { BrowserStorageManager } from '../../cache/BrowserStorageManager';
import { globalFormats } from '../../utils/globalFormats';

dayjs.extend(relativeTime);

const documentTimeStampFormat = globalFormats.timeStampFormatForExportFiles;
const sessionStorageManager = new BrowserStorageManager(sessionStorage);

// eslint-disable-next-line
export function JobReport() {
  const maxTimeToKeepIndividualJobDetailInStorage = 86400000 * 1; // * 1 day in milliseconds.

  const access_token = useSelector((state) => state.auth.user.access);
  const currentUserID = useSelector((state) => state.userData.userData.id);

  const [renderKPICharts, setRenderKPICharts] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);

  const jobStatusOptions = ['Active & Archived', 'Active', 'Archived'];
  const [jobStatus, setJobStatus] = useState({
    text: 'Active & Archived',
    index: 0,
  });

  const exportAsOptions = ['PDF'];
  const [exportAs, setExportAs] = useState({
    text: 'PDF',
    index: 0,
    clicked: false,
  });
  const dropdownOptions = ['Job Status:', 'Export As:'];

  const [jobDetailObj, setJobDetailObj] = useState({});
  const [jobDetailsArray, setJobDetailsArray] = useState([]);

  const projectsFromGlobalState =
    useSelector((state) => state.projects.projects) ?? [];
  const baseProjectsFromGlobalState = useMemo(
    () =>
      projectsFromGlobalState.map((project) => ({
        id: project.id,
        uniqueID: project.unique_id,
        title: project.title,
        company: project.company,
        status: project.status,
      })),
    [projectsFromGlobalState],
  );

  const companiesFromGlobalState =
    useSelector((state) => state.Contacts.companies) ?? [];
  const companiesDataForSearchableList = useMemo(
    () =>
      companiesFromGlobalState.map((company) => ({
        id: company.id,
        name: company.name,
      })),
    [companiesFromGlobalState],
  );

  const [projectsDataForSearchableList, setProjectsDataForSearchableList] =
    useState([...baseProjectsFromGlobalState]);

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [errorWhenExporting, setErrorWhenExporting] = useState(false);
  const [showForExportPurposes, setShowForExportPurposes] = useState(false);

  // * This ref is crucial for the Accordions at 'KPIBarChart' to be expanded when exporting to PDF.
  const showForExportPurposesRef = useRef(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  const [lastSelectedJobUniqueID, setLastSelectedJobUniqueID] = useState(null);
  const [lastSelectedCompanyID, setLastSelectedCompanyID] = useState(null);
  const [infoMessage, setInfoMessage] = useState('');

  const { jobDetail, isJobDetailRequestLoading, errorWhenFetchingJobDetail } =
    useGetJobDetail(access_token, lastSelectedJobUniqueID, shouldFetch);

  const checkForJobDetailsInBrowserStorage = () => {
    const parsedJobDetails = sessionStorageManager.get(
      `jobDetailsForJobReportForUserID${currentUserID}`,
    );

    if (parsedJobDetails?.userID !== currentUserID || !parsedJobDetails) {
      return [];
    }

    const jobDetailsArray = parsedJobDetails.updatedJobDetailsArray;

    return jobDetailsArray ?? [];
  };

  const getValidJobDetailFromStorage = (jobID) => {
    const jobDetailsOnBrowserStorage = checkForJobDetailsInBrowserStorage();

    if (jobDetailsOnBrowserStorage.length === 0) return null;

    return jobDetailsOnBrowserStorage.find((job) => job.id === jobID);
  };

  const isJobDetailExpired = (jobDetailsOnBrowserStorage) => {
    if (!jobDetailsOnBrowserStorage) return false;

    const lastTimeFetched = dayjs(jobDetailsOnBrowserStorage.lastTimeFetched);
    const currentTime = dayjs();

    return (
      currentTime.diff(lastTimeFetched, 'milliseconds') >
      maxTimeToKeepIndividualJobDetailInStorage
    );
  };

  const updateJobDetailsInBrowserStorage = (jobDetail) => {
    const jobDetailsOnBrowserStorage = checkForJobDetailsInBrowserStorage();
    let updatedJobDetailsArray = [];

    // * Check if the job detail is valid and not already stored in the browser storage.
    if (!isJobDetailExpired(jobDetailsOnBrowserStorage)) {
      if (getValidJobDetailFromStorage(jobDetail.id)) {
        updatedJobDetailsArray = jobDetailsOnBrowserStorage.filter(
          (job) => job.id !== jobDetail.id,
        );

        updatedJobDetailsArray = [
          ...updatedJobDetailsArray,
          {
            id: jobDetail.id,
            jobTitle: jobDetail.title,
            deliverables: jobDetail.deliverable_project,
            lastTimeFetched: dayjs().format(),
          },
        ];
      } else {
        updatedJobDetailsArray = [
          ...jobDetailsOnBrowserStorage,
          {
            id: jobDetail.id,
            jobTitle: jobDetail.title,
            deliverables: jobDetail.deliverable_project,
            lastTimeFetched: dayjs().format(),
          },
        ];
      }

      const updatedJobDetailsObj = {
        userID: currentUserID,
        updatedJobDetailsArray,
      };

      sessionStorageManager.set(
        `jobDetailsForJobReportForUserID${currentUserID}`,
        updatedJobDetailsObj,
      );
    }
  };

  const handleJobDetailFromStorage = (jobUniqueIDFromList) => {
    const jobID = projectsDataForSearchableList.find(
      (project) => project.uniqueID === jobUniqueIDFromList,
    )?.id;

    const individualJobDetailFromStorage = getValidJobDetailFromStorage(jobID);

    if (individualJobDetailFromStorage) {
      if (!isJobDetailExpired(individualJobDetailFromStorage)) {
        setJobDetailObj({
          jobTitle: individualJobDetailFromStorage.jobTitle,
          lastTimeFetched: individualJobDetailFromStorage.lastTimeFetched,
        });
        setJobDetailsArray(individualJobDetailFromStorage.deliverables);
        setRenderKPICharts(true);
        setShouldFetch(false);
      } else {
        // * If expired, trigger fetch.
        setShouldFetch(true);
      }
    } else {
      // * If no data in storage, trigger fetch.
      setShouldFetch(true);
    }
  };

  const handleFilterByCompany = (e) => {
    if (!e || e.length === 0) {
      setProjectsDataForSearchableList([...baseProjectsFromGlobalState]);
      setLastSelectedCompanyID(null);
      return;
    }

    const lastSelectedCompanyID = e.id;
    const projectsFromGlobalStateFiltered = baseProjectsFromGlobalState.filter(
      (project) => project.company === lastSelectedCompanyID,
    );

    setProjectsDataForSearchableList([...projectsFromGlobalStateFiltered]);
    setLastSelectedCompanyID(lastSelectedCompanyID);
  };

  const handleFilterByJob = (e) => {
    if (!e || e.length === 0) {
      setLastSelectedJobUniqueID(null);
      setRenderKPICharts(false);
      setJobDetailObj({});
      setJobDetailsArray([]);
      return;
    }

    const lastSelectedJobUniqueID = e.uniqueID;
    setLastSelectedJobUniqueID(lastSelectedJobUniqueID);
  };

  const handleFilterByJobStatus = (v, i) => {
    setJobStatus({
      text: v,
      index: i,
    });

    const lowerCaseV = v.toLowerCase();

    const projectsDataForSearchableListFiltered =
      projectsDataForSearchableList.filter(
        (project) =>
          lowerCaseV === 'active & archived' || project.status === lowerCaseV,
      );

    if (
      (lowerCaseV === 'archived' || lowerCaseV === 'active') &&
      projectsDataForSearchableListFiltered.length === 0
    ) {
      const dynamicStatus = lowerCaseV === 'archived' ? 'Archived' : 'Active';
      setInfoMessage(
        `There is no ${dynamicStatus} jobs to display. The list will show all Active & Archive jobs once this message disappears.`,
      );

      setProjectsDataForSearchableList([
        ...projectsDataForSearchableListFiltered,
      ]);

      setTimeout(() => {
        setInfoMessage('');
        setJobStatus({
          text: 'Active & Archived',
          index: 0,
        });

        if (lastSelectedCompanyID) {
          handleFilterByCompany({ id: lastSelectedCompanyID });
        }
      }, 5000);
    }
  };

  const handleClickExportAs = (v, i) => {
    setExportAs({
      text: v,
      index: i,
      clicked: true,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const getStylesBasedOnIndex = (index1, index2) => {
    const baseStyles = {
      borderRadius: 1,
      textTransform: 'inherit',
      fontSize: '14px',
      m: 1,
      padding: '0.2rem 0.5rem',
      '&:hover': {
        backgroundColor: '#E0E0DF',
        cursor: 'pointer',
      },
    };

    return index1 === index2
      ? {
          ...baseStyles,
          color: 'white',
          backgroundColor: '#711FFF',
        }
      : {
          ...baseStyles,
          color: 'black',
        };
  };

  const generateAndDownloadPDF = () => {
    setIsExportLoading(true);

    const input = document.getElementById('job-report-to-export');
    html2canvas(input)
      .then((canvas) => {
        // eslint-disable-next-line
        const pdf = new jsPDF('p', 'mm', 'a4');

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // Margin settings
        const topMargin = 15; // Top margin
        const bottomMargin = 15; // Bottom margin

        // Total height to subtract the top and bottom margins
        const contentHeight = pdfHeight - topMargin - bottomMargin;

        // Scaling ratio to fit content width-wise
        const ratio = pdfWidth / imgWidth;
        const scaledHeight = imgHeight * ratio;

        // Adjusted calculation for pageCount to account for margins
        const pageCount = Math.ceil(scaledHeight / contentHeight);

        for (let page = 0; page < pageCount; page += 1) {
          if (page > 0) {
            pdf.addPage();
          }

          const heightLeft = scaledHeight - page * contentHeight;
          const currentHeight = Math.min(contentHeight, heightLeft);

          const sourceY = (page * contentHeight) / ratio;
          const sourceHeight = currentHeight / ratio;

          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = canvas.width;
          tempCanvas.height = sourceHeight;
          const ctx = tempCanvas.getContext('2d');

          ctx.drawImage(
            canvas,
            0,
            sourceY,
            imgWidth,
            sourceHeight,
            0,
            0,
            imgWidth,
            sourceHeight,
          );

          const pageImgData = tempCanvas.toDataURL('image/png');

          // Add the image to the PDF with top and bottom margins
          const yPosition = topMargin; // Starting at the top margin

          pdf.addImage(
            pageImgData,
            'PNG',
            0,
            yPosition,
            pdfWidth,
            currentHeight,
          );
        }

        const formattedTimeStamp = dayjs(jobDetailObj.lastTimeFetched).format(
          documentTimeStampFormat,
        );

        pdf.save(
          `Job-Report-${jobDetailObj?.jobTitle}-${formattedTimeStamp}.pdf`,
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error generating PDF:', error);
        setErrorWhenExporting(true);
      })
      .finally(() => {
        setIsExportLoading(false);
        setErrorWhenExporting(false);
      });
  };

  useEffect(() => {
    if (lastSelectedJobUniqueID) {
      handleJobDetailFromStorage(lastSelectedJobUniqueID);
    }
  }, [lastSelectedJobUniqueID]);

  useEffect(() => {
    if (jobDetail) {
      updateJobDetailsInBrowserStorage(jobDetail);

      setJobDetailObj({
        jobTitle: jobDetail.title,
        lastTimeFetched: jobDetail.lastTimeFetched,
      });
      setJobDetailsArray(jobDetail.deliverable_project);
      setRenderKPICharts(true);
      setShouldFetch(false);
    }
  }, [jobDetail]);

  useEffect(() => {
    if (exportAs.clicked && exportAs.text === 'PDF') {
      setShowForExportPurposes(true);
      setExportAs({
        text: 'PDF',
        index: 0,
        clicked: false,
      });
    }
  }, [exportAs]);

  useEffect(() => {
    if (showForExportPurposes) {
      showForExportPurposesRef.current = true;

      // * 0 to allow the DOM to update before generating the PDF.
      setTimeout(() => {
        generateAndDownloadPDF();

        showForExportPurposesRef.current = false;
        setShowForExportPurposes(false);
      }, 0);
    }
  }, [showForExportPurposes]);

  const formattedLastTimeFetched = useMemo(
    () => dayjs(jobDetailObj.lastTimeFetched).format(documentTimeStampFormat),
    [jobDetailObj.lastTimeFetched],
  );

  const timeSinceLastFetch = useMemo(
    () => dayjs(jobDetailObj.lastTimeFetched).fromNow(),
    [jobDetailObj.lastTimeFetched],
  );

  return (
    <div>
      <Grid
        sx={{
          backgroundColor: 'inherit',
          padding: '1rem 0rem',
          borderBottom: '1px solid #e2e2e2',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: '2.9rem',
            width: '95%',
            backgroundColor: 'var(--light-primary-color)',
            padding: '1rem 1rem',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >
          <div>
            {dropdownOptions.map((name, index) => (
              <>
                <Button
                  variant="text"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  endIcon={
                    <ExpandMoreSharpIcon
                      style={{
                        width: '18px',
                        height: '18px',
                        fontWeight: 200,
                        color: '#03071E',
                      }}
                    />
                  }
                  style={{
                    marginRight: 10,
                    backgroundColor: '#fff',
                    color: 'black',
                    textTransform: 'inherit',
                    boxShadow: 'inherit',
                    fontWeight: 400,
                    fontSize: '14px',
                    border: '1px solid #E0E0DF',
                    borderRadius: '6px',
                  }}
                >
                  {index === 0
                    ? `${name} ${jobStatus.text}`
                    : index === 1
                      ? `${name} ${exportAs.text}`
                      : ''}
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'inherit',
                      border: '1px solid #E0E0DF',
                      borderRadius: 2,
                    },
                  }}
                >
                  {index === 0 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {jobStatusOptions.map((v, i) => {
                        return (
                          <Typography
                            key={v}
                            fullWidth
                            onClick={() => handleFilterByJobStatus(v, i)}
                            sx={{
                              ...getStylesBasedOnIndex(jobStatus.index, i),
                            }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {index === 1 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {exportAsOptions.map((v, i) => {
                        return (
                          <Typography
                            key={v}
                            fullWidth
                            onClick={() => handleClickExportAs(v, i)}
                            sx={{
                              ...getStylesBasedOnIndex(exportAs.index, i),
                            }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                </Popover>
              </>
            ))}

            {isExportLoading && (
              <RefreshIcon
                className={`loading-icon ${isExportLoading ? 'loading' : ''}`}
              />
            )}
          </div>
        </Box>

        <Box sx={{ mx: 3, mb: 1, display: 'flex', flexWrap: 'wrap' }}>
          <SearchableListField
            searchableListFieldID="jobReport-FilterByCompany"
            label="Choose a Company"
            listOfOptions={companiesDataForSearchableList}
            propertiesToDisplay={['name']}
            onChangeFunction={handleFilterByCompany}
            multiple={false}
          />

          <SearchableListField
            searchableListFieldID="jobReport-FilterByJob"
            label="Choose a Job"
            listOfOptions={projectsDataForSearchableList}
            propertiesToDisplay={['title']}
            onChangeFunction={handleFilterByJob}
            multiple={false}
          />
        </Box>
      </Grid>

      {infoMessage && (
        <Alert
          className="alert"
          severity="info"
          sx={{ width: 'max-content', margin: '0 auto', marginTop: '1rem' }}
        >
          {infoMessage}
        </Alert>
      )}

      {errorWhenFetchingJobDetail && (
        <Alert
          className="alert"
          severity="error"
          sx={{ width: 'max-content', margin: '0 auto', marginTop: '1rem' }}
        >
          {errorWhenFetchingJobDetail}.
        </Alert>
      )}

      {isJobDetailRequestLoading && (
        <Alert
          className="alert"
          severity="info"
          sx={{ width: 'max-content', margin: '0 auto', marginTop: '1rem' }}
        >
          We&apos;re crunching the KPIs for you. Please wait...
        </Alert>
      )}

      {renderKPICharts ? (
        <div id="job-report-to-export">
          <div className="report-header">
            <h2>{jobDetailObj?.jobTitle}</h2>

            <div className="divider">
              <hr />
            </div>

            {showForExportPurposes ? (
              <span className="text-for-export">
                Job Report | Data from {formattedLastTimeFetched}
              </span>
            ) : (
              <>
                <span>Data from {timeSinceLastFetch}</span>
                <Tooltip
                  title={`Refresh to get the ${jobDetailObj?.jobTitle} latest data`}
                  arrow
                >
                  <button
                    className="refresh-btn"
                    type="button"
                    aria-label="refresh"
                    onClick={() => setShouldFetch(true)}
                  >
                    <RefreshIcon
                      className={`${isJobDetailRequestLoading ? 'loading' : ''}`}
                    />
                  </button>
                </Tooltip>
              </>
            )}
          </div>

          {errorWhenExporting && (
            <Alert
              className="alert"
              severity="error"
              sx={{ width: 'max-content', margin: '0 auto' }}
            >
              Whoops... Something went wrong while exporting the {exportAs.text}
              , please try again.
            </Alert>
          )}

          <JobDetailsKPISummaries
            dileverable={jobDetailsArray}
            jobDetailsKPISummariesComponentHeight={550}
            showForExportPurposes={showForExportPurposes}
          />
        </div>
      ) : (
        <div className="job-report-placeholder">
          <h3>Select a Job first to generate the report.</h3>
          <p>
            All the information on Estimate vs Actual Job Report will be
            displayed here.
          </p>
        </div>
      )}
    </div>
  );
}
