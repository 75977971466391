import React from 'react';

function ComingSoon({ title }) {
  return (
    <section className="oppor-section">
      <sections className="myCenter">
        <article className="box-txt">
          <h3 className="txtCenter" style={{ marginBottom: '6px' }}>
            {title}{' '}
          </h3>
          <p className="txtCenter">Coming Soon!</p>
        </article>
      </sections>
    </section>
  );
}

export default ComingSoon;
