// eslint-disable-next-line import/prefer-default-export
export const globalFormats = {
  timeStampFormatForExportFiles: 'MMMM DD, YYYY, h:mm:ss A',
  currency: {
    locale: 'en-US',
    options: {
      style: 'currency',
      currency: 'USD',
    },
  },
};
