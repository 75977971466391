/* eslint-disable  */
export class CacheManagerBase {
  get(key) {
    throw new Error('"CacheManagerBase" Method "get" not implemented');
  }

  set(key, value) {
    throw new Error('"CacheManagerBase" Method "set" not implemented');
  }

  delete(key) {
    throw new Error('"CacheManagerBase" Method "delete" not implemented');
  }

  clear() {
    throw new Error('"CacheManagerBase" Method "clear" not implemented');
  }
}
/* eslint-enable */
