// * Please read the following before adding new routes:

// ! If you need routes with params, you can add them like this:
// * Route with query parameters
// * Usage: Pass an object with query parameters
// ? routeWithParams: (params) => `/some-route?${buildQueryString(params)}`,
// * Example usage in a component: routeTo(appRoutes.routeWithParams, { userId: 123, sort: 'name' })

// * Example usage of 'appRoutes' in components:
//  ? import appRoutes from 'routing/appRoutes';
//
//  ? <Route path={appRoutes.dashboard} component={Dashboard} />
//  ? <Link to={appRoutes.login} />
//
// * Note: Refer to `routeTo` function in `src/routing/routesHelper.js` for centralized navigation logic.

// eslint-disable-next-line
function buildQueryString(params) {
  return new URLSearchParams(params).toString();
}

// eslint-disable-next-line
export const appRoutes = Object.freeze({
  // * Authentication related URLs
  login: '/login',
  register: '/register',
  complete: '/complete',
  check: '/check',
  success: '/success',
  reset: '/reset',
  teamInvitation: '/team-invitation',
  resetPassword: '/reset-password',
  resendActivation: '/resend-activation',

  // * Chats section URL

  // ! **chat** has been commented out as part of the #121 issue (turn off features).
  // ! It is replace by an empty route to avoid breaking the app.
  chat: '',
  // chat: '/chat',
  archivedChats: '/archived-chat',

  // * Tasks section URL
  tasks: '/tasks',

  // * Me Section URLs
  myTasks: '/my-tasks',
  myTime: '/my-time',
  welcome: '/welcome',
  rootRoute: '/',
  dashboard: '/dashboard',

  // * NetNet U Section URLs

  // ! **netnetU** has been commented out as part of the #121 issue (turn off features).
  // ! It is replace by an empty route to avoid breaking the app.
  netnetU: '',
  // netnetU: '/net-net-u',
  allLessons: '/all-lessons',
  allCourses: '/all-courses',
  notFound: '*',

  // * User section URLs
  mySchedule: '/my-schedule',
  templates: '/templates',
  myWorkTypes: '/my-work-types',
  team: '/team',
  accountBilling: '/account-billing',
  myProfile: '/my-profile',

  // * Jobs Section URLs
  jobs: '/jobs',
  newJob: '/new-job',

  // * Sales Section URLs
  newJobEstimate: '/new-job-estimate',
  collaborators: '/collaborators',
  sales: '/sales',

  //* Reports Section URLs
  reports: {
    root: '/reports',
    timeReport: '/time-report',
    jobReport: '/job-report',
    salesReport: '/sales-report',
    teamReport: '/team-report',
    quickTasksReport: '/quick-tasks-report',
  },

  // * Contacts Section URLs
  newPerson: '/new-person',
  newCompany: '/new-company',
  importContacts: '/import-contacts',
  importComplete: '/import-complete',
  people: '/people',
  companies: '/companies',

  //* Super Admin Paths
  superAdmin: {
    root: '/super-admin',
    netNetU: {
      superAdminNetNetUCourseNew: '/netnet-u/courses/new',
      superAdminNetNetCourses: '/netnet-u/courses',
      netnetUCatagories: '/netnet-u/categories',
      netnetUCourses: '/netnet-u/courses',
      newNetNetUCourses: '/netnet-u/courses/new',
      newNetNetUCoursesChapter: '/netnet-u/courses/chapter/new',
      netNetUAuthors: '/netnet-u/authors',
      newNetNetUAuthors: '/netnet-u/authors/new',
      netNetUlessons: '/netnet-u/lessons',
      netNetUSectionVideos: '/netnet-u/section-videos',
      createNetNetUHelpVideos: '/netnet-u/create-help-videos',
      netNetUselectionHelpVideos: '/netnet-u/help-videos-selection',

      // * SuperAdmin NetNetU Authors Details
      authorsDetail: '/netnet-u/author',
      subHelpVideosDetail: '/netnet-u/create-subHelp-videos',
      netNetUCreateHelpVideosDetail: '/netnet-u/create-help-videos',
    },
    template: {
      templatesCatagories: '/templates/template-categories',
      templatesSuperAdmin: '/templates/templates',
    },
    tools: {
      toolsCustomers: '/tools/customers',
      customerDetail: '/tools/customer',
      toolsDiscountEngine: '/tools/discount-engine',
      toolsNotificationsandAlerts: '/tools/notifications-and-alerts',
      toolsDiscountEngineNew: '/tools/discount-engine/new',
      toolsNotificationNew: '/tools/notifications/new',
      toolHelpTickets: '/tools/help-tickets',
    },
    systemAdmin: {
      systemAdminUsers: '/system-admin/users',
      systemAdminLogFiles: '/system-admin/log-files',
    },
    reports: {
      accountReports: '/reports/account-reports',
    },
  },

  //* URL with IDs in the end
  courseDetail: '/course-detail',
  lessonDetail: '/lesson-detail',
  personDetail: '/person-detail',
  companyDetail: '/company-detail',
  editContact: '/edit-contact',
  editCompany: '/edit-company',
  estimateDetail: '/estimate-detail',
  collaborationDetail: '/collaboration',
  jobDetail: '/job-detail',

  //* Route with params
  accountBillingRouteWithParams: (params) =>
    `/account-billing?${buildQueryString(params)}`,
  superAdminNetNetUCoursesChapterNew: (params) =>
    `/SuperAdmin/NetNetU/Courses/Chapter/New?${buildQueryString(params)}`,
  chatWithParams: (params) => `/chat?${buildQueryString(params)}`,

  //* routes for the screen size less than 600
  timer: '/timer',
  tasksDetails: '/tasks/task-details',
});
