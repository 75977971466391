import { useEffect } from 'react';
import { Box } from '@mui/material';

import 'gantt-task-react/dist/index.css';
import '@fontsource/open-sans';
import {
  GanttComponent,
  Inject,
  Edit,
  Selection,
  EventMarkerDirective,
  EventMarkersDirective,
  DayMarkers,
  ColumnDirective,
  ColumnsDirective,
} from '@syncfusion/ej2-react-gantt';
import { useSelector } from 'react-redux';
import { ENDPOINT } from '../Redux/oppor';
import { PreLoading } from '../../Contacts/People/EditContact';

function FourthStep({
  formData,
  setFormData,
  setTemp,
  dileverable,
  setDeliverable,
  view,
  tasks,
  setTasks,
}) {
  const access_token = useSelector((state) => state.auth.user.access);
  useEffect(() => {
    const data = dileverable.map((item) => {
      const start = new Date(item.deliverable_startdate);
      const end = new Date(item.deliverable_enddate);
      const dependency = dileverable.find(
        (ob) => ob.id === item.deliverable_dependency,
      );
      const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

      // Determine the Predecessor field based on the dependency
      const predecessor = dependency ? dependency.id : '';
      return {
        TaskID: item.id,
        TaskName: item.deliverable_name,
        StartDate: start,
        EndDate: end,
        Predecessor: predecessor,
        Duration: duration,
        Hours: item.deliverable_worktype.reduce((total, acc) => {
          return total + Number(acc.hours ? acc.hours : 0);
        }, 0),
      };
    });

    setTasks(data);
  }, [dileverable]);

  useEffect(() => {
    setTemp(tasks);
  }, [tasks]);

  const editOptions = {
    allowEditing: true,
    allowTaskbarEditing: true,
    columns: [
      { field: 'StartDate', allowEditing: false },
      { field: 'EndDate', allowEditing: false },
    ],
  };

  const toolbarOptions = ['ZoomToFit', 'ExpandAll', 'CollapseAll'];
  const taskFields = {
    id: 'TaskID',
    name: 'TaskName',
    startDate: 'StartDate',
    endDate: 'EndDate',
    duration: 'Duration',
    dependency: 'Predecessor',
    hours: 'Hours',
  };

  const timelineSettings = {
    timelineUnitSize: 80,
    timelineViewMode: view,
    workWeek: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Satudrday',
    ],
    durationUnit: 'Day',
  };

  const finishdate = formData?.est_enddate?.split('T')[0];
  const estimate_finshDate = new Date(finishdate);

  const handleTaskbar = async (args) => {
    const shallow_copy = dileverable.map((item) => ({ ...item }));
    shallow_copy[args.data.index].deliverable_startdate =
      args.data.StartDate.toISOString();
    shallow_copy[args.data.index].deliverable_enddate =
      args.data.EndDate.toISOString();
    setDeliverable(shallow_copy);
    const shallowCopy = {
      ...formData,
    };
    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const durationEst =
      new Date(formData.est_enddate) - new Date(formData.est_startdate);
    const totalDays = durationEst / (24 * 60 * 60 * 1000);
    const startDateOfDil = new Date('11/11/2080').toISOString();
    const leastDate = shallow_copy.reduce((minDate, dil) => {
      if (dil.deliverable_startdate < minDate) {
        return dil.deliverable_startdate;
        // eslint-disable-next-line
      } else {
        return minDate;
      }
    }, startDateOfDil);

    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
                ...shallowCopy,
                est_startdate: leastDate,
                est_enddate: formData.est_enddate
                  ? new Date(
                      new Date(leastDate).setDate(
                        new Date(leastDate).getDate() + totalDays,
                      ),
                    )
                  : null,
                collaborator: formData?.collaborator?.map((item) => item?.id),
                estimate_deliverable: shallow_copy?.map((item, index) => {
                  const newStartData = new Date(tasks[index].StartDate);
                  newStartData.setDate(newStartData.getDate() + 1);
                  const newEndDate = new Date(tasks[index].EndDate);
                  newEndDate.setDate(newEndDate.getDate() + 1);
                  // eslint-disable-next-line
                  delete item.deliverable_multiplier;
                  // eslint-disable-next-line
                  delete item.task_deliverable;
                  return {
                    ...item,
                    deliver_id: item.id,
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_startdate: item.deliverable_startdate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
              })
            : JSON.stringify({
                ...shallowCopy,
                est_startdate: leastDate,
                est_enddate: formData.est_enddate
                  ? new Date(
                      new Date(leastDate).setDate(
                        new Date(leastDate).getDate() + totalDays,
                      ),
                    )
                  : null,
                estimate_deliverable: shallow_copy?.map((item, index) => {
                  const newStartData = new Date(tasks[index].StartDate);
                  newStartData.setDate(newStartData.getDate() + 1);
                  const newEndDate = new Date(tasks[index].EndDate);
                  newEndDate.setDate(newEndDate.getDate() + 1);
                  // eslint-disable-next-line
                  delete item.deliverable_multiplier;
                  // eslint-disable-next-line
                  delete item.task_deliverable;
                  return {
                    ...item,
                    deliver_id: item.id,
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_startdate: item.deliverable_startdate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
              }),
      },
    );
    if (res.ok) {
      const resGet = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (!resGet.ok) {
        return;
      }
      setFormData(dataGet);
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable) &&
        dataGet.deliverable.length
      ) {
        const sortedDeliverable = dataGet.deliverable
          .map((deliverable) => ({
            ...deliverable,
            deliverable_worktype: deliverable.deliverable_worktype.sort(
              (a, b) => a.name.localeCompare(b.name),
            ),
          }))
          .sort((a, b) => a.sort_order - b.sort_order);
        setDeliverable(sortedDeliverable);
        const data2 = shallow_copy.map((item) => {
          const start = new Date(item.deliverable_startdate);
          const end = new Date(item.deliverable_enddate);
          const dependency = dataGet.deliverable.find(
            (ob) => ob.id === item.deliverable_dependency,
          );

          // Determine the Predecessor field based on the dependency
          const predecessor = dependency ? dependency.id : '';

          return {
            TaskID: item.id,
            TaskName: item.deliverable_name,
            StartDate: start,
            EndDate: end,
            Predecessor: predecessor,
            Hours: item.deliverable_worktype.reduce((total, acc) => {
              return total + Number(acc.hours ? acc.hours : 0);
            }, 0),
          };
        });
        setTasks(data2);
      }
    }
  };

  return Array.isArray(tasks) && tasks.length ? (
    <Box className="gantt-target">
      <GanttComponent
        taskbarEdited={handleTaskbar}
        rowUniqueId="TaskID"
        UpdateOffsetOnTaskbarEdit={false}
        queryTaskbarInfo={(args) => {
          if (args.data && args.data.Duration) {
            // eslint-disable-next-line
            args.data.Duration = Math.round(args.data.Duration);
          }
        }}
        actionBegin={(args) => {
          if (args.requestType === 'beforeOpenEditDialog') {
            // eslint-disable-next-line
            args.cancel = true; // Cancel the default dialog
          }
        }}
        toolbar={toolbarOptions}
        workWeek={[
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ]}
        rowHeight={60}
        timelineSettings={timelineSettings}
        allowParentDependency={true}
        editSettings={editOptions}
        dataSource={tasks}
        taskFields={taskFields}
        height="640px"
      >
        {formData.est_enddate ? (
          <EventMarkersDirective>
            {/* eslint-disable-next-line */}
            <EventMarkerDirective
              day={estimate_finshDate}
              label="Target End Date"
            ></EventMarkerDirective>
          </EventMarkersDirective>
        ) : null}
        <ColumnsDirective>
          {/* eslint-disable-next-line */}
          <ColumnDirective
            field="TaskID"
            headerText=""
            visible={false}
          ></ColumnDirective>
          {/* eslint-disable-next-line */}
          <ColumnDirective
            field="TaskName"
            headerText="Deliverables"
            width="auto"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Edit, Selection, DayMarkers]} />
      </GanttComponent>
    </Box>
  ) : (
    <PreLoading />
  );
}

export default FourthStep;
