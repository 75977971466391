import React, { useEffect, useState } from "react";
import "./screenTwo.css";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { getVideos } from "../videoReducer";
import ReactPlayer from "react-player";
import arrow1 from "../assets/arrow-1.svg";
import logo from "../assets/Net Net Symbol.svg";
import playIcon from "../assets/playIcon.svg";
import playIcon2 from "../assets/playIcon2.svg";
import Gear from "../assets/Gear.png";
import { Link } from "react-router-dom";
import { ENDPOINT } from "../../../Opportunity/Redux/oppor";
import MenuItem from "@mui/material/MenuItem";
import { useRouteTo } from "../../../../hooks/useRouteTo";
import { appRoutes } from "../../../../appRoutes";

const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 10px 10px",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
        backgroundColor: "white"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "0px 22px",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
          Help Videos Selection
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "10px",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to={`${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.createNetNetUHelpVideos}`}>
          <Button
            sx={{
              width: 108,
              height: 35,
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};

const DragDrop = () => {
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const access_token = useSelector((state) => state.auth.user.access);
  const [helpVideos, sethelpVideos] = useState()
  const [formData, setFormData] = useState({
    title: "",
    screenSelect: "",
    help_video: []
  });

  const extractVideoId = (url) => {
    const match = url?.match(/(?:\?v=|\/embed\/|\/\d\d\/|\/vi\/|\/v\/|https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)([^#\&\?]*).*/);
    return (match && match[1].length === 11) ? match[1] : false;
  };

  // Function to construct the thumbnail URL
  const constructThumbnailUrl = (videoId, quality = 'maxresdefault') => {
    return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
  };

  // Handle rendering the thumbnail
  const handleRenderThumbnail = (youtubeURL) => {
    const videoId = extractVideoId(youtubeURL);

    if (videoId) {
      const thumbnailUrl = constructThumbnailUrl(videoId, 'maxresdefault');
      return thumbnailUrl
    } else {
    }
  };

  const [helpVideoScreen, setHelpVideoScreen] = useState()

  const getVideosScreen = async () => {
    const response = await fetch(`${ENDPOINT}/api/netnetu/help-video-screen-list/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${access_token}`
      },

    })

    const data = await response.json();
    if (!data) {
    } else {
      setHelpVideoScreen(data)
    }

    return data
  }

  useEffect(() => {
    getVideosScreen()
  }, [])

  const getHelpVideo = async () => {

    try {
      const getHelpVideos = await fetch(`${ENDPOINT}/api/netnetu/help-video-list/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${access_token}`
        }

      })

      const response = await getHelpVideos.json()
      if (!response) {
      } else {
        sethelpVideos(response)
      }
      return response
    } catch (error) {
    }
  }

  useEffect(() => {
    getHelpVideo()
  }, [])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVideos());
  }, [dispatch]);


  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("videoId", id);
  };

  const handleDragOver = (e, screenNumber) => {
    e.preventDefault();
  };

  const handleDrop = (e, screenNumber) => {
    e.preventDefault();
    let transferredVideoId = e.dataTransfer.getData("videoId");

    const selectedVideoObject = helpVideos.find(
      (video) => video.id === parseInt(transferredVideoId)
    );

    // Extract the existing selected video objects
    const existingSelectedVideos = { ...formData };

    // Update the selected video for the current screen
    existingSelectedVideos[`screen${screenNumber}SelectedVideo`] = selectedVideoObject;

    // Extract the video IDs in order of screens
    const helpVideoIds = Array.from({ length: 4 }, (_, i) => existingSelectedVideos[`screen${i + 1}SelectedVideo`]?.id);

    // Filter out undefined values
    const filteredHelpVideoIds = helpVideoIds.filter((id) => id !== undefined);

    // Update the help_video array
    const updatedFormData = {
      ...existingSelectedVideos,
      help_video: filteredHelpVideoIds,
    };

    setFormData(updatedFormData);
  };

  const handleMouseEnter = (videoId) => {
    setHoveredVideo(videoId);
  };
  const handleMouseLeave = () => {
    setHoveredVideo(null);
  };

  const routeTo = useRouteTo()

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { title, help_video } = formData

    const updatedFormData = {
      title,
      help_video
    }

    let requestBody;
    if (updatedFormData.title === "Me" || updatedFormData.title === "Chat" || updatedFormData.title === "All Chat" || updatedFormData.title === "Me" || updatedFormData.title === "General" || updatedFormData.title === "Global" || 
        updatedFormData.title === "Testing Again" || updatedFormData.title === "Test" || updatedFormData.title === "Projects Chat" || updatedFormData.title === "Estimates" || updatedFormData.title === "Opportunities" || 
        updatedFormData.title === "NEw" || updatedFormData.title === "Projects Chat" || updatedFormData.title === "My_Tasks" || updatedFormData.title === "My_Time" || updatedFormData.title === "Projects" || updatedFormData.title === "Tasks"
        || updatedFormData.title === "People" || updatedFormData.title === "Company" || updatedFormData.title === "Opportunities" || updatedFormData.title === "Reports", updatedFormData.title === "NetNet_U" || updatedFormData.title === "Templates"
        
        || updatedFormData.title === "My_Worktypes" || updatedFormData.title === "Team" || updatedFormData.title === "Collaborators" || updatedFormData.title === "Dashboard" || updatedFormData.title === "Welcome"
        ) {
          // Use help_video for the first API call
      requestBody = {
        title: updatedFormData.title,
        help_video: updatedFormData.help_video
      };
    } else {
      // Use subscreen_help_video for the second API call
      requestBody = {
        title: updatedFormData.title,
        subscreen_help_video: updatedFormData.help_video
      };
    }

    if(updatedFormData.title === "Me" || updatedFormData.title === "Chat" || updatedFormData.title === "Dashboard" || updatedFormData.title === "Me" || updatedFormData.title === "General" || updatedFormData.title === "Global" || 
      updatedFormData.title === "Testing Again" || updatedFormData.title === "Test" || updatedFormData.title === "Projects Chat" || updatedFormData.title === "Estimates" || updatedFormData.title === "Opportunities" || 
      updatedFormData.title === "NEw" || updatedFormData.title === "Projects Chat"  || updatedFormData.title === "My_Tasks" || updatedFormData.title === "Projects" || updatedFormData.title === "Tasks" || updatedFormData.title === "People"
      || updatedFormData.title === "Company" || updatedFormData.title === "Opportunities" || updatedFormData.title === "Reports" || updatedFormData.title === "NetNet_U" || updatedFormData.title === "Templates" 
      || updatedFormData.title === "My_Worktypes" || updatedFormData.title === "Team" || updatedFormData.title === "Collaborators" || updatedFormData.title === "My_Time" || updatedFormData.title === "Welcome"
    
      ){

      const response = await fetch(`${ENDPOINT}/api/netnetu/update-help-video-screen/${formData?.screenId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${access_token}`
        },
  
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
      } else {
        // Reset form data and navigate to a success page or perform any other action
        setFormData({
          title: "",
          help_video: [],
          screen1SelectedVideo: null,
          screen2SelectedVideo: null,
          screen3SelectedVideo: null,
          screen4SelectedVideo: null,
        });
        // Change the route address as needed
        routeTo(`${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.createNetNetUHelpVideos}`);
      }
      // navigate("/SuperAdmin/NetNetU/create-help-videos");
      setFormData({
        ...formData,
        help_video: [],
        screen1SelectedVideo: null,
        screen2SelectedVideo: null,
        screen3SelectedVideo: null,
        screen4SelectedVideo: null,
      });
    }else{
      const response2 = await fetch(`${ENDPOINT}/api/netnetu/update-help-video-sub-screen/${formData?.screenId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${access_token}`
        },
  
        body: JSON.stringify(requestBody),
      });
  
      if (!response2.ok) {
        const errorData = await response2.json();
      } else {
        // Reset form data and navigate to a success page or perform any other action
        setFormData({
          title: "",
          help_video: [],
          screen1SelectedVideo: null,
          screen2SelectedVideo: null,
          screen3SelectedVideo: null,
          screen4SelectedVideo: null,
        });
        // Change the route address as needed
        routeTo(`${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.createNetNetUHelpVideos}`);
      }
  
      // navigate("/SuperAdmin/NetNetU/create-help-videos");
      setFormData({
        ...formData,
        help_video: [],
        screen1SelectedVideo: null,
        screen2SelectedVideo: null,
        screen3SelectedVideo: null,
        screen4SelectedVideo: null,
      });
    }
    }

  return (
    <>
      <div className="videoSelectionWrapper">


        <Header />
        <main className="screenTwoWrapper" id="screen_container">
          <div className="internal-wrapper2">
            <div className="screenTwoContainer">
              <form onSubmit={handleSubmit} style={{ alignItems: "center", flex: 1.6 }}>
                <div className="formSide2">
                  <div className="selectScreen">
                    <h4 className="vTitle2"> Select Screen</h4>
                    <FormControl sx={{ width: "100%", margin: 0, padding: 0, }}>
                      <Select
                        sx={{
                          '& fieldset': {
                            legend: {
                              width: 0,
                            },
                          },

                          height: "42px",

                        }}
                        id="grouped-select"

                        value={formData?.title?.target?.value?.title}

                        onChange={(e) =>

                          setFormData({
                            ...formData,
                            title: e.target.value.title,
                            screenId: e.target.value.id
                          })
                        }
                      >
                        {helpVideoScreen?.length > 0 && helpVideoScreen?.map((item, index) => {
                          const menuItem = (
                            <MenuItem
                              key={item.id}
                              value={item}
                              style={{
                                paddingLeft: "28px",
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          );
                          const subMenuItems = item.help_video_subscreen && item.help_video_subscreen.length > 0
                            ? item.help_video_subscreen.map((subItem, subIndex) => (
                              <MenuItem
                                key={subItem.id}
                                value={subItem}
                                style={{
                                  paddingLeft: "48px",
                                }}
                              >
                                {subItem.title}
                              </MenuItem>
                            ))
                            : null;
                          return [menuItem, subMenuItems];
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="screenBorders">
                    <div
                      className="leftScreen screenOne"
                      onDragOver={(e) => handleDragOver(e, 1)}
                      onDrop={(e) => handleDrop(e, 1)}
                    >
                      <div className={`brokenBorder ${formData.screen1SelectedVideo ? 'hasVideo' : ''}`} style={{ borderRadius: "8px" }}>
                        {formData.screen1SelectedVideo && (
                          <ReactPlayer
                            url={formData.screen1SelectedVideo.youtube_link}
                            playing={false}
                            controls={true}
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "8px" }}
                          />
                        )}
                      </div>

                    </div>
                    <div className="rightScreen">
                      <div
                        className="rScreens screenTwo"
                        onDragOver={(e) => handleDragOver(e, 2)}
                        onDrop={(e) => handleDrop(e, 2)}
                      >
                        <div className={`brokenBorder ${formData.screen2SelectedVideo ? 'hasVideo' : ''}`}>
                          {" "}
                          {formData.screen2SelectedVideo && (
                            <ReactPlayer
                              url={formData.screen2SelectedVideo.youtube_link}
                              playing={true}
                              controls={true}
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="rScreens screenThree"
                        onDragOver={(e) => handleDragOver(e, 3)}
                        onDrop={(e) => handleDrop(e, 3)}
                      >
                        <div className={`brokenBorder ${formData.screen3SelectedVideo ? 'hasVideo' : ''}`}>
                          {" "}
                          {formData.screen3SelectedVideo && (
                            <ReactPlayer
                              url={formData.screen3SelectedVideo.youtube_link}
                              playing={true}
                              controls={true}
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="rScreens screenFour"
                        onDragOver={(e) => handleDragOver(e, 4)}
                        onDrop={(e) => handleDrop(e, 4)}
                      >
                        <div className={`brokenBorder ${formData.screen4SelectedVideo ? 'hasVideo' : ''}`}>
                          {" "}
                          {formData.screen4SelectedVideo && (
                            <ReactPlayer
                              url={formData.screen4SelectedVideo.youtube_link}
                              playing={true}
                              controls={true}
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="saveButton">
                    <Button
                      style={{
                        backgroundColor: "#711FFF",
                        fontSize: "18px",
                        fontFamily: "Open Sans",
                        textTransform: "capitalize",
                        width: 115,
                        height: 42,
                      }}
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>

              <div className="screenTwoVideoList" style={{ flex: 1, }}>
                {helpVideos?.map((video, index) => (
                  <div
                    key={index}
                    className="box2"
                    onMouseEnter={() => handleMouseEnter(video.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="wrapper">
                      <div className="imageWrapper2">
                        <div
                          className="insideContainer"
                          draggable
                          onDragStart={(e) => handleDragStart(e, video.id)}
                        >
                          <img
                            src={handleRenderThumbnail(video?.youtube_link)}
                            style={{ zIndex: "99" }}
                            alt=""
                          />
                          {/* )}  */}
                          <button className="">
                            <div
                              className='centerPlayIcon'
                            >
                              <img src={playIcon} alt="playIcon" />
                            </div>
                          </button>
                          <div className="smallPlayIcon">
                            <img
                              className="vector-icon"
                              alt="play"
                              src={playIcon2}
                            />
                            <div className="time">{`${video?.duration}:00`}</div>
                          </div>
                        </div>
                      </div>
                      <div className="vInfoWrapper2">
                        <div className="lessonTitle2">
                          <b>{video.title}</b>
                        </div>
                        <div className="lessonDescripton2">
                          <div>{video.description}</div>
                        </div>
                        <div className="more2">
                          <div className="learn_more2">LEARN MORE</div>
                          <img src={arrow1} alt="learn_more" />
                        </div>
                        <div className="logoAndAuthor">
                          <img src={logo} alt="logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DragDrop;
