import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Box } from '@mui/material';

import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { clearRecord } from '../ContactRedux';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

function Head() {
  return (
    <TableHead
      sx={{
        height: '100%',
        borderRadius: '8px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="fstxt">Name</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">New Record</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">Updated Records</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">Error Count</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">Source</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">User</div>
        </TableCell>
        <TableCell align="right">
          <div className="fstxt">Date</div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function Body({ exceptionCount, realTarget }) {
  const currentUser = useSelector((state) => state.userData.userData);
  const currentDate = dayjs().format('MMM DD, YYYY');
  const importContactsFileName =
    sessionStorage.getItem('importContactsFileName') || 'User CSV File';

  return realTarget && 'done' in realTarget ? (
    <TableBody>
      <TableRow
        sx={{
          height: '110px',
          backgroundColor: '#fff',
        }}
      >
        <TableCell align="left">
          <div className="fstxt">{importContactsFileName}</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">{realTarget.done ?? '0'}</div>
        </TableCell>

        <TableCell align="left">
          <div className="fstxt">{realTarget.updated ?? '0'}</div>
        </TableCell>

        <TableCell align="left">
          <div className="fstxt">
            {realTarget.failed ? realTarget.failed : exceptionCount || '0'}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">CSV</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">
            {currentUser.first_name} {'  '} {currentUser.last_name}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">{currentDate}</div>
        </TableCell>
      </TableRow>
    </TableBody>
  ) : (
    <Alert severity="error">
      {`Failed To Import ${realTarget?.failed_creations?.companies?.length} Companies and ${realTarget?.failed_creations?.contacts?.length} Contacts`}
    </Alert>
  );
}

function FifthStep({ step, prevData, exceptionCount, constructor, beData }) {
  const routeTo = useRouteTo();
  const target = useSelector((state) => state.Contacts.target);
  const dispatch = useDispatch();
  const [realTarget, setRealTarget] = useState({});

  useEffect(() => {
    if (target && 'import_view_response' in target) {
      setRealTarget(target.import_view_response);
    }
  }, [target]);

  const downloadCSV = () => {
    if (!realTarget?.failed_creations?.contacts) return;
    const { contacts } = realTarget.failed_creations;
    const headers = ['Error', ...Object.keys(contacts[0].contact)];

    const csvRows = contacts.map((contact) => {
      const errorMessages = Object.entries(contact.error)
        .map(
          ([key, value]) =>
            `${key}: ${Array.isArray(value) ? value.join(', ') : value.Error}`,
        )
        .join('  ');

      const contactValues = Object.values(contact.contact);

      return [errorMessages, ...contactValues].join(',');
    });

    const csvContent = [headers.join(','), ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    saveAs(blob, 'failed_contacts.csv');
  };

  const hasImportFailed = realTarget?.failed > 0;

  return (
    <section className="oppor-section">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          height: '100%',
        }}
      >
        <div className="steps-container">
          <h3 style={{ color: '#000' }} className="txtCenter">
            Congratulations!
          </h3>
          <h4 className="txtCenter">Awesome Job. You&apos;re all done.</h4>
          <p className="txtCenter initialParagraph txtESM">
            Here you can see the results of your import. If there were any
            errors, you can download a CSV file with the details.
          </p>
        </div>

        <Box sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: 'none',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
            }}
          >
            <Table aria-label="Data table">
              <Head />
              <Body
                prevData={prevData}
                exceptionCount={exceptionCount}
                constructor={constructor}
                beData={beData}
                realTarget={realTarget}
              />
            </Table>
          </TableContainer>

          {hasImportFailed && (
            <Alert
              severity="error"
              sx={{
                marginTop: '1rem',
              }}
            >
              <p>
                {realTarget?.failed} contacts couldn&apos;t be imported. This
                may be due to an incorrect format or missing information.
              </p>

              {realTarget?.failed_creations?.contacts?.map((contact, index) => {
                const emailError = contact?.error?.email?.error;
                return emailError ? (
                  <div key={contact}>
                    {`Error for contact ${index + 1}: ${emailError}`}
                  </div>
                ) : null;
              })}
            </Alert>
          )}
        </Box>

        <Box
          className="AlBottom footer"
          sx={{
            position: 'fixed',
            bottom: '1px',
            width: { xl: '85%', lg: '80%', md: '98%' },
            alignItems: 'end',
            paddingBottom: '1rem',
            marginBottom: '1rem',
            border: '1px solid #ccc',
            boxShadow: 'unset',
          }}
        >
          <ul className="footer-list">
            <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
              Import File
            </li>
            <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
              Map Fields
            </li>
            <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
              {/* ! '@Type/Tags related logic'. */}
              {/* Type */}
              Ready to Import!
            </li>
            <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
              Exceptions
            </li>
          </ul>
          <div className="AlRight dd-import-group">
            {hasImportFailed && (
              <Alert severity="info">
                <span
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: '#0288d1',
                  }}
                  onClick={() => downloadCSV()}
                  aria-label="Download CSV"
                >
                  Download Failed Contacts CSV
                </span>
              </Alert>
            )}

            <button
              type="button"
              className="smBtn"
              onClick={() => {
                dispatch(clearRecord(true));
                routeTo(appRoutes.people);

                sessionStorage.removeItem('importContactsFileName');
              }}
              style={{
                marginBottom: '0.4rem',
              }}
            >
              Finish
            </button>
          </div>
        </Box>
      </Box>
    </section>
  );
}

export default FifthStep;
