import { useState } from 'react';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import FifthStep from './FifthStep';

import './ImportSteps.css';

function Import({ setIsImportingStill, isImportingStill, screenHeight }) {
  const [step, setStep] = useState(1);
  const [constructor, setConstructor] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [importData, setImportData] = useState({});
  const [prevData, setPrevData] = useState({});
  const [exceptionCount, setExceptionCount] = useState(0);
  const [newParser, setNewParser] = useState([]);
  const [beData, setBeData] = useState({});

  return (
    <section className={step === 3 ? 'oppor-section auto' : 'oppor-section'}>
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">Import People & Companies</h3>
        </div>
      </div>
      <section
        className={step > 2 ? 'coloumn' : 'myCenter p-1'}
        style={{
          flexDirection: 'column',
          overflowY: 'auto',
          height: screenHeight - 150,
        }}
      >
        {step === 1 && (
          <FirstStep
            step={step}
            setStep={setStep}
            screenHeight={screenHeight}
          />
        )}
        {step === 2 && (
          <SecondStep
            step={step}
            setStep={setStep}
            setError={setError}
            setErrorMessage={setErrorMessage}
            error={error}
            errorMessage={errorMessage}
            setImportData={setImportData}
            setNewParser={setNewParser}
            screenHeight={screenHeight}
          />
        )}
        {step === 3 && (
          <ThirdStep
            step={step}
            setStep={setStep}
            setError={setError}
            importData={importData}
            setPrevData={setPrevData}
            exceptionCount={exceptionCount}
            setExceptionCount={setExceptionCount}
            newParser={newParser}
            setConstructor={setConstructor}
            screenHeight={screenHeight}
          />
        )}
        {step === 4 && (
          <FourthStep
            step={step}
            setStep={setStep}
            prevData={prevData}
            setPrevData={setPrevData}
            setExceptionCount={setExceptionCount}
            constructor={constructor}
            exceptionCount={exceptionCount}
            setBeData={setBeData}
            newParser={newParser}
            isImporting={isImportingStill}
            setIsImportingStill={setIsImportingStill}
            screenHeight={screenHeight}
          />
        )}
        {step === 5 && (
          <FifthStep
            step={step}
            setStep={setStep}
            prevData={prevData}
            exceptionCount={exceptionCount}
            constructor={constructor}
            beData={beData}
            screenHeight={screenHeight}
          />
        )}
      </section>
    </section>
  );
}
export default Import;
