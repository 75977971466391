import { useState, useEffect, useRef, React } from 'react';
import ListKeymap from '@tiptap/extension-list-keymap';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
// eslint-disable-next-line
import TextStyle from '@tiptap/extension-text-style';
import { EditorProvider, useCurrentEditor, ReactRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import './RichTextEditor.css';
// eslint-disable-next-line
import { PluginKey } from 'prosemirror-state';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import tippy from 'tippy.js';
// eslint-disable-next-line
import MentionList from './MentionList.jsx';
import { Box } from '@mui/system';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Link from '@tiptap/extension-link';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import Code from '@tiptap/extension-code';
import TextAlign from '@tiptap/extension-text-align';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { BiCodeBlock } from 'react-icons/bi';
// eslint-disable-next-line
import Paragraph from '@tiptap/extension-paragraph';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import { VscMention } from 'react-icons/vsc';
import { TbTilde } from 'react-icons/tb';
// eslint-disable-next-line
import CodeBlock from '@tiptap/extension-code-block';
// eslint-disable-next-line
import { Extension, Command } from '@tiptap/core';
// eslint-disable-next-line
import '@tiptap/extension-text-style';
import { Button, Typography } from '@mui/material';
import BulletList from '@tiptap/extension-bullet-list';
// eslint-disable-next-line
import OrderedList from '@tiptap/extension-ordered-list';
import SimpleDialog from './SimpleDialog';

const buttonStyle = {
  position: 'relative',
  TextAlign: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#3b3c36',
  fontSize: '25px',
  fontWeight: 100,
  cursor: 'pointer',
  marginRight: '10px',
};

const tooltipStyle = {
  position: 'absolute',
  top: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#333',
  color: 'white',
  fontSize: '11px',
  padding: '5px',
  borderRadius: '3px',
  display: 'inline-block',
  zIndex: 1,
};

const iconStyle = {
  fontSize: '24px',
};

const colorPickerStyle = {
  cursor: 'pointer',
};

const colorOptionsStyle = {
  position: 'absolute',
  top: '30px',
  left: '0',
  display: 'flex',
  flexWrap: 'wrap',
  background: 'white',
  border: '1px solid #ccc',
  padding: '5px',
  borderRadius: '4px',
  zIndex: '1',
  width: '120px',
};

const colorOptionStyle = {
  width: '20px',
  height: '20px',
  margin: '2px',
  border: 'none',
  cursor: 'pointer',
};

const fontSelect = {
  width: '100%',
  fontSize: '12px',
  paddingLeft: '7px',
  color: 'black',
  background: 'transparent',
  justifyContent: 'start',
  textAlign: 'start',
  '&:hover': {
    backgroundColor: 'white',
  },
};

const FontSize = Extension.create({
  name: 'fontSize',

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) =>
              element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { fontSize: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

function RichTextEditor({
  setHtml,
  html,
  check,
  mentions,
  deliverbales,
  currentTarget,
  setNotRun,
  bottom,
  currentAllDil,
  open,
  setOpen,
  my_logic,
  btn,
  autofocus,
}) {
  // * In case the setNotRun prop is not passed, we define a dummy function to prevent errors.
  const safeSetNotRun = setNotRun || (() => {});

  const [linkUrl, setLinkUrl] = useState('');

  const setLink = () => {
    const url = linkUrl.trim(); // Trim whitespace from the input URL

    // Update link only if the URL is not empty
    if (url !== '') {
      btn.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }

    setOpen(false);
    setLinkUrl('');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // eslint-disable-next-line
  function MenuBar() {
    const { editor } = useCurrentEditor({
      editorProps: {
        attributes: {
          class: 'editor',
        },
      },
    });

    if (!editor) {
      return null;
    }

    const [showBg, setShowBg] = useState(false);
    const [color, setColor] = useState('#000');
    const [bg, setBg] = useState(false);
    const handleColorChange = (newColor) => {
      setColor(newColor);
      editor.chain().focus().setColor(newColor).run();
      setShowBg(false);
    };

    const handleColorChangeBg = (newColor) => {
      editor.chain().focus().toggleHighlight({ color: newColor }).run();
      setBg(false);
    };
    const openMentionSuggestions = (char) => {
      const { state, dispatch } = editor.view;
      const { from } = state.selection;

      const tr = state.tr.insertText(` ${char}`, from, from);
      dispatch(tr);
      editor.view.focus();
    };

    const [align, setAlign] = useState(false);

    const [showUndoTooltip, setShowUndoTooltip] = useState(false);
    const [showRedoTooltip, setShowRedoTooltip] = useState(false);
    const [showCodeTooltip, setShowCodeTooltip] = useState(false);
    const [showBoldTooltip, setShowBoldTooltip] = useState(false);
    const [ShowRemoveFormatTooltip, setShowRemoveFormatTooltip] =
      useState(false);
    const [showItalicTooltip, setShowItalicTooltip] = useState(false);
    const [showStrickThroughTooltip, setshowStrickThroughTooltip] =
      useState(false);
    const [showUnderlineTooltip, setShowUnderlineTooltip] = useState(false);
    const [showBackgroundTooltip, setShowBackgroundTooltip] = useState(false);
    const [showAlignmentTooltip, setShowAlignmentTooltip] = useState(false);
    const [showListBulletedTooltip, setShowListBulletedTooltip] =
      useState(false);
    const [showNumberWiseTooltip, setShowNumberWisetooltip] = useState(false);
    const [showColorTooltip, setShowColorTooltip] = useState(false);
    const [showLinkTooltip, setShowLinkTooltip] = useState(false);
    const [showMentionTooltip, setShowMentionsTooltip] = useState(false);
    const [showDeliverableTooltip, setShowDeliverableTooltip] = useState(false);
    const [showTasklistTooltip, setShowTaskListTooltip] = useState(false);
    const [showCodeBlockTooltip, setShowCodeBlocktooltip] = useState(false);

    // eslint-disable-next-line
    function FontSizeSelector({ editor }) {
      const [drop, setdrop] = useState(false); // State to manage popover visibility
      const [showFontSizeTooltip, setShowFontSizeTooltip] = useState(false);

      const handleFontSizeChange = (size) => {
        editor.commands.setFontSize(`${size}px`);
      };

      return (
        <div style={{ position: 'relative', marginRight: '10px' }}>
          <button
            type="button"
            onMouseEnter={() => setShowFontSizeTooltip(true)}
            onMouseLeave={() => setShowFontSizeTooltip(false)}
            style={{
              ...iconStyle,
              ...colorPickerStyle,
              background: 'transparent',
              marginBottom: '4px',
            }}
            onClick={() => setdrop(true)}
          >
            <FormatSizeIcon
              sx={{
                fontSize: '26px',
                color: '#3b3c36',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
            {showFontSizeTooltip && <h1 style={tooltipStyle}>FontSize</h1>}
          </button>
          {drop && (
            <Box
              sx={{
                position: 'absolute',
                top: '30px',
                left: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-Start',
                alignItems: 'flex-start',
                textAlign: 'flex-start',

                color: 'black',
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                zIndex: '1',
                width: '44px',
              }}
            >
              <Button
                style={{ ...fontSelect, width: '100%', paddingRigt: '4px' }}
                onClick={() => handleFontSizeChange(9)}
              >
                9
              </Button>
              <Button sx={fontSelect} onClick={() => handleFontSizeChange(12)}>
                12
              </Button>
              <Button
                style={fontSelect}
                onClick={() => handleFontSizeChange(16)}
              >
                16
              </Button>
              <Button
                style={fontSelect}
                onClick={() => handleFontSizeChange(22)}
              >
                22
              </Button>
              <Button
                style={fontSelect}
                onClick={() => handleFontSizeChange(28)}
              >
                28
              </Button>
              <Button
                style={fontSelect}
                onClick={() => handleFontSizeChange(31)}
              >
                31
              </Button>
              <Button
                style={fontSelect}
                onClick={() => handleFontSizeChange(44)}
              >
                44
              </Button>
            </Box>
          )}
        </div>
      );
    }
    const buttonRef = useRef(null);
    return (
      <div
        style={{
          width: '100%',
          background: 'red',
          display: 'flex',
          flexWrap: bottom ? 'nowrap' : 'wrap',
          textAlign: 'center',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: 'transparent',
          borderBottom: !bottom && '1px solid #a9a9a9',
          padding: '6px',
          color: 'white',
          transition: 'border-color 0.3s ease', // Add smooth transition for the border color change
          '.& :hover': {
            border: '1px solid #710FFF', // Change border color on hover
          },
        }}
      >
        <FontSizeSelector editor={editor} />
        <button
          type="button"
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().undo().run() ? 1 : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().undo().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
            marginLeft: '2px',
          }}
          onClick={() => editor.chain().focus().undo().run()}
          onMouseEnter={() => setShowUndoTooltip(true)}
          onMouseLeave={() => setShowUndoTooltip(false)}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <UndoIcon
            sx={{
              fontSize: '26px',
              padding: '2px',
              '&:hover': {
                background: '#e5e4e2',
              },
            }}
            color="red"
          />
          {showUndoTooltip && (
            <Typography style={tooltipStyle}>Undo</Typography>
          )}
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().redo().run() ? 1 : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().redo().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowRedoTooltip(true)}
          onMouseLeave={() => setShowRedoTooltip(false)}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <RedoIcon
            sx={{
              fontSize: '26px',
              padding: '2px',
              '&:hover': {
                background: '#e5e4e2',
              },
            }}
          />
          {showRedoTooltip && <h1 style={tooltipStyle}>Redo</h1>}
        </button>
        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 10px',
          }}
        />
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().toggleBold().run() ? 1 : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().toggleBold().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
            marginLeft: '2px',
          }}
          onMouseEnter={() => setShowBoldTooltip(true)}
          onMouseLeave={() => setShowBoldTooltip(false)}
        >
          <span
            style={{ color: editor.isActive('bold') ? '#710FFF' : '#3b3c36' }}
          >
            <FormatBoldIcon
              sx={{
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '28px',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
          </span>
          {showBoldTooltip && <h1 style={tooltipStyle}>Bold</h1>}
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().toggleItalic().run()
              ? 1
              : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().toggleItalic().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowItalicTooltip(true)}
          onMouseLeave={() => setShowItalicTooltip(false)}
        >
          <span
            style={{ color: editor.isActive('italic') ? '#710FFF' : '#3b3c36' }}
          >
            {' '}
            <FormatItalicIcon
              sx={{
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '27px',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
          </span>
          {showItalicTooltip && <h1 style={tooltipStyle}>Italic</h1>}
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().toggleUnderline().run()
              ? 1
              : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().toggleUnderline().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowUnderlineTooltip(true)}
          onMouseLeave={() => setShowUnderlineTooltip(false)}
        >
          <span
            style={{
              color: editor.isActive('underline') ? '#710FFF' : '#3b3c36',
            }}
          >
            {' '}
            <FormatUnderlinedIcon
              sx={{
                fontSize: '27px',
                marginRight: '2px',
                marginBottom: '0.5px',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
          </span>
          {showUnderlineTooltip && <h1 style={tooltipStyle}>Underline</h1>}
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().toggleStrike().run()
              ? 1
              : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().toggleStrike().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
          }}
          onMouseEnter={() => setshowStrickThroughTooltip(true)}
          onMouseLeave={() => setshowStrickThroughTooltip(false)}
        >
          <span
            style={{ color: editor.isActive('strike') ? '#710FFF' : '#3b3c36' }}
          >
            {' '}
            <StrikethroughSIcon
              sx={{
                fontSize: '29px',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />{' '}
          </span>
          {showStrickThroughTooltip && (
            <h1 style={tooltipStyle}>StrikeThrough</h1>
          )}
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          style={{ ...buttonStyle, background: 'transparent' }}
          onMouseEnter={() => setShowRemoveFormatTooltip(true)}
          onMouseLeave={() => setShowRemoveFormatTooltip(false)}
        >
          <span
            style={{ color: editor.isActive('bold') ? '#710FFF' : '#3b3c36' }}
          >
            <BlockRoundedIcon
              sx={{
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '26px',
                padding: '2px',
                marginBottom: '3px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />{' '}
          </span>
          {ShowRemoveFormatTooltip && (
            <h1 style={tooltipStyle}>Clear Formats</h1>
          )}
        </button>
        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 9px',
            marginTop: '2px',
          }}
        />

        <button
          type="button"
          onMouseEnter={() => setShowBackgroundTooltip(true)}
          onMouseLeave={() => setShowBackgroundTooltip(false)}
          style={{
            position: 'relative',
            marginLeft: '9px',
            marginRight: '14px',
            background: 'white',
          }}
        >
          <div
            style={{
              ...colorPickerStyle,
              background: color,
              width: '16px',
              height: '16.9px',
              borderRadius: '5px',
              marginTop: '6px',
            }}
            onClick={() => setShowBg(!showBg)}
          />
          {showBackgroundTooltip && <h1 style={tooltipStyle}>Color</h1>}
          {showBg && (
            <div style={colorOptionsStyle}>
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#000')}
                style={{ ...colorOptionStyle, backgroundColor: '#000' }}
              />
              <button
                aria-label="color"
                type="button"
                onClick={() => handleColorChange('#c7c8c9')}
                style={{ ...colorOptionStyle, backgroundColor: '#c7c8c9' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#710FFF')}
                style={{ ...colorOptionStyle, backgroundColor: '#710FFF' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#3fc0fc')}
                style={{ ...colorOptionStyle, backgroundColor: '#3fc0fc' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#51f57f')}
                style={{ ...colorOptionStyle, backgroundColor: '#51f57f' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#e0518f')}
                style={{ ...colorOptionStyle, backgroundColor: '#e0518f' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#f5e133')}
                style={{ ...colorOptionStyle, backgroundColor: '#f5e133' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#56dbc7')}
                style={{ ...colorOptionStyle, backgroundColor: '#56dbc7' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#958DF1')}
                style={{ ...colorOptionStyle, backgroundColor: '#958DF1' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#8ce99a')}
                style={{ ...colorOptionStyle, backgroundColor: '#8ce99a' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChange('#74c0fc')}
                style={{ ...colorOptionStyle, backgroundColor: '#74c0fc' }}
              />
            </div>
          )}
        </button>
        <div style={{ position: 'relative', marginRight: '7px' }}>
          <button
            type="button"
            onMouseEnter={() => setShowColorTooltip(true)}
            onMouseLeave={() => setShowColorTooltip(false)}
            style={{
              ...iconStyle,
              ...colorPickerStyle,
              background: 'transparent',
              marginBottom: '3px',
            }}
            onClick={() => setBg(!bg)}
          >
            <FormatColorFillIcon
              sx={{
                fontSize: '24px',
                color: '#3b3c36',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
            {showColorTooltip && <h1 style={tooltipStyle}>Background Color</h1>}
          </button>
          {bg && (
            <div style={colorOptionsStyle}>
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#000')}
                style={{ ...colorOptionStyle, backgroundColor: '#000' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#c7c8c9')}
                style={{ ...colorOptionStyle, backgroundColor: '#c7c8c9' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#710FFF')}
                style={{ ...colorOptionStyle, backgroundColor: '#710FFF' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#3fc0fc')}
                style={{ ...colorOptionStyle, backgroundColor: '#3fc0fc' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#51f57f')}
                style={{ ...colorOptionStyle, backgroundColor: '#51f57f' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#e0518f')}
                style={{ ...colorOptionStyle, backgroundColor: '#e0518f' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#f5e133')}
                style={{ ...colorOptionStyle, backgroundColor: '#f5e133' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#56dbc7')}
                style={{ ...colorOptionStyle, backgroundColor: '#56dbc7' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#958DF1')}
                style={{ ...colorOptionStyle, backgroundColor: '#958DF1' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#8ce99a')}
                style={{ ...colorOptionStyle, backgroundColor: '#8ce99a' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#74c0fc')}
                style={{ ...colorOptionStyle, backgroundColor: '#74c0fc' }}
              />
              <button
                type="button"
                aria-label="color"
                onClick={() => handleColorChangeBg('#fff')}
                style={{ ...colorOptionStyle, backgroundColor: '#fff' }}
              />
            </div>
          )}
        </div>

        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 9px',
            marginTop: '2px',
          }}
        />

        <div>
          <button
            type="button"
            aria-label="color"
            ref={buttonRef}
            onMouseEnter={() => setShowLinkTooltip(true)}
            onMouseLeave={() => setShowLinkTooltip(false)}
            onClick={
              editor.isActive('link')
                ? () => editor.chain().focus().unsetLink().run()
                : () => handleClickOpen()
            }
            style={{
              position: 'relative',
              TextAlign: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: '#3b3c36',
              fontSize: '25px',
              fontWeight: 100,
              cursor: 'pointer',
              marginRight: '4px',
              marginLeft: '3px',
            }}
          >
            <AddLinkIcon
              sx={{
                background: 'transparent',
                fontSize: '28px',
                color: editor.isActive('link') ? '#710FFF' : '#3b3c36',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
            {showLinkTooltip && <h1 style={tooltipStyle}>Link</h1>}
          </button>
        </div>

        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 9px',
            marginTop: '2px',
          }}
        />

        <div
          style={{
            position: 'relative',
            marginTop: '4.9px',
            marginRight: '6px',
            marginLeft: '6px',
          }}
        >
          <button
            type="button"
            aria-label="color"
            style={{ background: 'transparent' }}
            onMouseEnter={() => setShowAlignmentTooltip(true)}
            onMouseLeave={() => setShowAlignmentTooltip(false)}
            onClick={() => setAlign(!align)}
          >
            <AlignHorizontalLeftIcon
              sx={{
                fontSize: '24px',
                color: '#3b3c36',
                cursor: 'pointer',
                padding: '2px',
                '&:hover': {
                  background: '#e5e4e2',
                },
              }}
            />
            {showAlignmentTooltip && <h1 style={tooltipStyle}>Alignments</h1>}
          </button>
          {align && (
            <div
              style={{
                flexDirection: 'column',
                background: '#fff',
                color: '#3b3c36',
                justifyContent: 'flex-Start',
                alignItems: 'flex-start',
                textAlign: 'flex-start',
                position: 'absolute',
                top: '30px',
                left: '0',
                display: 'flex',
                flexWrap: 'wrap',
                border: '1px solid #ccc',
                padding: '5px',
                borderRadius: '4px',
                zIndex: '1',
                width: '120px',
              }}
            >
              <button
                type="button"
                style={{ padding: '2px 0', background: 'transparent' }}
                onClick={() => {
                  editor.chain().focus().setTextAlign('left').run();
                  setAlign(false);
                }}
                className={
                  editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''
                }
              >
                <FormatAlignLeftIcon /> Left
              </button>
              <button
                type="button"
                style={{ padding: '2px 0', background: 'transparent' }}
                onClick={() => {
                  editor.chain().focus().setTextAlign('right').run();
                  setAlign(false);
                }}
                className={
                  editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''
                }
              >
                <FormatAlignRightIcon /> Right
              </button>
              <button
                type="button"
                style={{ padding: '2px 0', background: 'transparent' }}
                onClick={() => {
                  editor.chain().focus().setTextAlign('center').run();
                  setAlign(false);
                }}
                className={
                  editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
                }
              >
                <FormatAlignCenterIcon /> Center
              </button>

              <button
                type="button"
                style={{ padding: '2px 0', background: 'transparent' }}
                onClick={() => {
                  editor.chain().focus().setTextAlign('justify').run();
                  setAlign(false);
                }}
                className={
                  editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''
                }
              >
                <FormatAlignJustifyIcon /> Justify
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 9px',
            marginTop: '2px',
          }}
        />
        <button
          type="button"
          aria-label="color"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          style={{
            ...buttonStyle,
            color: editor.isActive('bulletList') ? '#710FFF' : '#3b3c36',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowListBulletedTooltip(true)}
          onMouseLeave={() => setShowListBulletedTooltip(false)}
        >
          <FormatListBulletedIcon
            sx={{
              fontSize: '26px',
              color: editor.isActive('bulletList') ? '#710FFF' : '#3b3c36',
              padding: '2px',
              '&:hover': {
                background: '#e5e4e2',
              },
            }}
          />
          {showListBulletedTooltip && (
            <h1 style={tooltipStyle}>List Bulletes</h1>
          )}
        </button>
        <button
          type="button"
          onClick={() => {
            editor.commands.toggleOrderedList();
          }}
          onMouseEnter={() => setShowNumberWisetooltip(true)}
          onMouseLeave={() => setShowNumberWisetooltip(false)}
          style={{ ...buttonStyle, background: 'transparent' }}
        >
          <FormatListNumberedIcon
            sx={{
              fontSize: '25.6px',
              color: editor.isActive('orderedList') ? '#710FFF' : '#3b3c36',
              padding: '2px',
              '&:hover': {
                background: '#e5e4e2',
              },
            }}
          />
          {showNumberWiseTooltip && <h1 style={tooltipStyle}>List Numbers</h1>}
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleTaskList().run()}
          style={{
            position: 'relative',
            TextAlign: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#3b3c36',
            fontSize: '25px',
            fontWeight: 100,
            cursor: 'pointer',
            marginRight: '3px',
            marginTop: '1px',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowTaskListTooltip(true)}
          onMouseLeave={() => setShowTaskListTooltip(false)}
        >
          <PlaylistAddCheckIcon
            sx={{
              background: 'transparent',
              fontSize: '32px',
              padding: '2px',
              '&:hover': {
                background: '#e5e4e2',
              },
            }}
          />

          {showTasklistTooltip && <h1 style={tooltipStyle}>TaskList</h1>}
        </button>

        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 9px',
            marginTop: '2px',
          }}
        />

        <button
          type="button"
          onClick={() =>
            editor.isActive('code')
              ? editor.chain().focus().unsetCode().run()
              : editor.chain().focus().setCode().run()
          }
          style={{
            ...buttonStyle,
            opacity: editor.can().chain().focus().unsetCode().run() ? 1 : 0.5, // Adjust the opacity based on the undo availability
            cursor: editor.can().chain().focus().unsetCode().run()
              ? 'pointer'
              : 'not-allowed',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowCodeTooltip(true)}
          onMouseLeave={() => setShowCodeTooltip(false)}
        >
          <CodeIcon
            sx={{
              fontSize: '27px',
              padding: '2px',
              '&:hover': {
                background: '#e5e4e2',
              },
            }}
          />
          {showCodeTooltip && <h1 style={tooltipStyle}>Code</h1>}
        </button>
        <button
          type="button"
          onClick={() => editor.commands.toggleCodeBlock()}
          style={{
            position: 'relative',
            TextAlign: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#3b3c36',
            fontSize: '25px',
            fontWeight: 100,
            cursor: 'pointer',
            marginRight: '3px',
            background: 'transparent',
          }}
          onMouseEnter={() => setShowCodeBlocktooltip(true)}
          onMouseLeave={() => setShowCodeBlocktooltip(false)}
        >
          <Box
            component={BiCodeBlock}
            sx={{
              marginRight: '2px',
              fontSize: '27px',
              padding: '2px',
              '&:hover': {
                backgroundColor: '#e5e4e2',
              },
            }}
          />
          {showCodeBlockTooltip && <h1 style={tooltipStyle}>CodeBlock</h1>}
        </button>
        <div
          style={{
            alignItems: 'center',
            height: '30px',
            borderRight: '1px solid #a9a9a9',
            margin: '0 9px',
            marginTop: '2px',
          }}
        />
        {Array.isArray(mentions) && mentions.length && (
          <button
            type="button"
            onMouseEnter={() => setShowMentionsTooltip(true)}
            onMouseLeave={() => setShowMentionsTooltip(false)}
            onClick={() => openMentionSuggestions('@')}
            style={buttonStyle}
          >
            <Box
              component={VscMention}
              sx={{
                marginRight: '1px',
                fontSize: '31px',
                padding: '2px',
                color: '#710FFF',
                '&:hover': {
                  backgroundColor: '#e5e4e2',
                },
              }}
            />
            {showMentionTooltip && <h1 style={tooltipStyle}>Mentions</h1>}
          </button>
        )}
        {Array.isArray(deliverbales) && deliverbales.length && (
          <button
            type="button"
            onMouseEnter={() => setShowDeliverableTooltip(true)}
            onMouseLeave={() => setShowDeliverableTooltip(false)}
            onClick={() => openMentionSuggestions('~')}
            style={buttonStyle}
          >
            <Box
              component={TbTilde}
              sx={{
                marginRight: '4px',
                fontSize: '27px',
                padding: '2px',
                color: '#710FFF',
                '&:hover': {
                  backgroundColor: '#e5e4e2',
                },
              }}
            />
            {showDeliverableTooltip && (
              <h1 style={tooltipStyle}>Deliverables</h1>
            )}
          </button>
        )}
      </div>
    );
  }

  const regex = /~(?=@)/g;

  const formattedMentionsRef = useRef([]);
  // Use replace to remove ~

  const [editorContent, setEditorContent] = useState(html);

  const tiptapParent = document.querySelector('.tiptap')?.parentNode;
  tiptapParent?.classList.add('custom-class');

  const queryFn = async ({ query }) => {
    // Replace this with your actual data fetching logic
    return Array.isArray(mentions) && mentions.length
      ? mentions.filter((mention) =>
          mention.toLowerCase().includes(query.toLowerCase()),
        )
      : ['No Mention'].filter((mention) =>
          mention.toLowerCase().includes(query.toLowerCase()),
        );
  };

  const queryFn2 = async ({ query }) => {
    // Replace this with your actual data fetching logic
    return Array.isArray(deliverbales) && deliverbales.length
      ? deliverbales.filter((mention) =>
          mention.toLowerCase().includes(query.toLowerCase()),
        )
      : ['No Deliverable'].filter((mention) =>
          mention.toLowerCase().includes(query.toLowerCase()),
        );
  };
  const [formattedMentions, setFormattedMentions] = useState([]);

  const initial = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.querySelectorAll('.deliverable-mention');
    const values = Array.from(elements)
      .map((element) => element.textContent.trim())
      .filter((item) => item.startsWith('~') && !item.startsWith('~/'));

    let lastValue = values[values.length - 1];
    if (typeof lastValue !== 'string') {
      lastValue = String(lastValue);
    }
    lastValue = lastValue.replace('~', '');

    if (lastValue) {
      const taskMention =
        currentAllDil &&
        currentAllDil.find((flt) => lastValue === flt?.deliverable_name)
          ?.task_deliverable;
      if (taskMention && taskMention.length > 0) {
        setFormattedMentions(taskMention.map((obj) => `/ ${obj.task_name}`));
      } else {
        setFormattedMentions(['This Deliverable Task Empty']);
      }
    }
  };

  useEffect(() => {
    initial();
  }, [currentTarget, html]);

  useEffect(() => {
    formattedMentionsRef.current = formattedMentions;
  }, [formattedMentions]);

  const queryFn3 = async ({ query }) => {
    const mentions = formattedMentionsRef.current;
    return Array.isArray(mentions) && mentions.length
      ? mentions.filter((mention) =>
          mention.toLowerCase().includes(query.toLowerCase()),
        )
      : ['No Task Mention'].filter((mention) =>
          mention.toLowerCase().includes(query.toLowerCase()),
        );
  };

  const mentionConfig1 = Mention.configure({
    HTMLAttributes: {
      class: 'people-mention',
    },
    suggestion: {
      items: queryFn,
      char: '@',
      pluginKey: new PluginKey('atKey'),
      render: () => {
        let reactRenderer;
        let popup;

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });
            safeSetNotRun(true);
            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
          },
          onUpdate(props) {
            reactRenderer.updateProps(props);

            popup?.[0]?.setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props) {
            return reactRenderer.ref?.onKeyDown(props);
          },
          onExit() {
            popup?.[0]?.destroy();
            reactRenderer.destroy();
            setTimeout(() => {
              safeSetNotRun(false);
            }, 1000);
          },
        };
      },
      'data-type': 'mention1',
    },
  });
  function initiateNewMentionList(editor, position, currentTask, currentDil) {
    const reactRenderer = new ReactRenderer(MentionList, {
      props: {
        items: currentTask,
        dilName: `${currentDil[0]?.deliverable_name} /`,
        onSelect: (item) => {
          // Handle selection of an item from the list

          const { state, dispatch } = editor.view;
          const { from } = state.selection;

          const tr = state.tr.insertText(` ${item}`, from, from);
          dispatch(tr);
          editor.view.focus();

          // eslint-disable-next-line
          if (popup) {
            // eslint-disable-next-line
            popup.destroy();
          }
        },
        editor,
      },
      editor,
    });

    const popup = tippy('body', {
      content: reactRenderer.element,
      showOnCreate: true,
      trigger: 'manual',
      placement: 'bottom-start',
      getReferenceClientRect: () => position,
      interactive: true,
      appendTo: () => document.body,
    });
    return { popup, reactRenderer };
  }

  const mentionConfig2 = Mention.configure({
    HTMLAttributes: {
      class: 'deliverable-mention',
    },
    suggestion: {
      items: queryFn2,
      char: '~',
      pluginKey: new PluginKey('hashKey'),
      render: () => {
        let reactRenderer;
        let popup;
        let editor;

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });
            safeSetNotRun(true);
            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
            // Save the editor reference
            editor = props.editor;
            const mentionItems =
              reactRenderer.element.querySelectorAll('.item');
            mentionItems.forEach((item) => {
              // Create an icon element
              const icon = document.createElement('span');
              icon.classList.add('mention-icon');
              icon.textContent = 'v'; // Use your desired icon here

              // Append the icon to the mention item
              item.appendChild(icon);

              let isPopupOpen = false; // Flag to track the state of the popup
              let currentPopup = null; // Reference to the current popup
              let currentRenderer = null; // Reference to the current ReactRenderer

              icon.addEventListener('click', (event) => {
                // Stop event propagation

                event.stopPropagation();
                const clickedIcon = event.target;

                // Navigate to the parent element (mention item)
                const mentionItem = clickedIcon.parentElement;

                // Assuming the value you want is stored as text content or in an attribute
                const mentionValue =
                  mentionItem.textContent ||
                  mentionItem.getAttribute('data-value');

                // Log the value to the console

                const currentDil =
                  Array.isArray(currentAllDil) &&
                  currentAllDil.length &&
                  currentAllDil?.filter(
                    (it) => `${it.deliverable_name} v` === mentionValue,
                  );
                const currentTask =
                  Array.isArray(currentDil) &&
                  currentDil.length &&
                  Array.isArray(currentDil[0].task_deliverable) &&
                  currentDil[0].task_deliverable.length
                    ? currentDil[0].task_deliverable.map((fuk) => fuk.task_name)
                    : ['No Task'];
                // Calculate the position of the clicked name element

                const position = item.getBoundingClientRect();

                // Toggle the popup state
                if (isPopupOpen) {
                  // If the popup is open, close it
                  if (currentPopup && typeof currentPopup.hide === 'function') {
                    currentPopup.hide();
                    currentPopup.destroy();
                    currentPopup = null;
                  }
                  if (currentRenderer) {
                    currentRenderer.destroy();
                    currentRenderer = null;
                  }
                  isPopupOpen = false; // Reset the flag
                } else {
                  // If the popup is closed, open a new one
                  // Initiate a new mention list
                  const { popup, reactRenderer } = initiateNewMentionList(
                    editor,
                    position,
                    currentTask,
                    currentDil,
                  );

                  // Update references to the new popup and renderer
                  currentPopup = popup;
                  currentRenderer = reactRenderer;
                  isPopupOpen = true; // Set the flag to true to indicate the popup is open
                }
              });
            });
          },

          onUpdate(props) {
            reactRenderer.updateProps(props);

            popup?.[0]?.setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props) {
            return reactRenderer.ref?.onKeyDown(props);
          },

          onExit() {
            popup?.[0]?.destroy();
            reactRenderer.destroy();
            setTimeout(() => {
              safeSetNotRun(false);
            }, 1000);
          },
        };
      },
      'data-type': 'mention2',
    },
  });

  const mentionConfig3 = Mention.configure({
    HTMLAttributes: (node) => {
      return {
        class: node.attrs.id.startsWith('/') ? 'task-mention' : 'fukrah',
      };
    },
    suggestion: {
      items: queryFn3,
      char: '/',
      pluginKey: new PluginKey('slashKey'),

      render: () => {
        let reactRenderer;
        let popup;
        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });
            safeSetNotRun(true);
            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
          },
          onUpdate(props) {
            reactRenderer.updateProps(props);

            popup?.[0]?.setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props) {
            return reactRenderer.ref?.onKeyDown(props);
          },

          onExit() {
            popup?.[0]?.destroy();
            reactRenderer.destroy();
            setTimeout(() => {
              safeSetNotRun(false);
            }, 1000);
          },
        };
      },
      'data-type': 'mention3',
    },
  });

  const extensions = [
    mentionConfig1,
    mentionConfig3,
    mentionConfig2,
    Code,
    ListKeymap,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Link.configure({
      openOnClick: true,
      autolink: true,
    }),
    Link.extend({
      inclusive: false,
    }),
    Code.configure({
      HTMLAttributes: {
        class: 'my-custom-class',
      },
    }),

    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },

      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),

    Highlight.configure({ multicolor: true }),
    Underline,
    Paragraph,
    Subscript,
    Superscript,

    TaskList.configure({
      itemTypeName: 'taskItem',
      HTMLAttributes: {
        class: 'my-custom',
      },
    }),
    TaskItem.configure({
      nested: false,
    }),
    CodeBlock.configure({
      HTMLAttributes: {
        class: 'my-custom-class',
      },
    }),
    FontSize,
    BulletList.configure({
      itemTypeName: 'listItem',
      keepMarks: true,
      keepAttributes: true,
      HTMLAttributes: {
        class: 'my-custom-list',
      },
    }),
    OrderedList.configure({
      keepMarks: true,
      keepAttributes: true,
    }),
  ];

  return (
    <div className="rich-text-editor-wrapper">
      <EditorProvider
        autofocus={autofocus ? 'end' : false}
        onFocus={(e) => {
          if (check) {
            check(e);
          }
        }}
        onUpdate={(e) => {
          const htmlEditor = e.editor.getHTML().replace(regex, '');

          if (check) {
            check(e);
          }

          // * Only update if the content has changed to prevent unnecessary updates.
          if (htmlEditor !== editorContent) {
            setHtml(htmlEditor);
            setEditorContent(htmlEditor);
          }
        }}
        slotBefore={<MenuBar />}
        extensions={extensions}
        content={editorContent}
      />

      {my_logic && (
        <SimpleDialog
          open={open}
          setOpen={setOpen}
          linkUrl={linkUrl}
          setLinkUrl={setLinkUrl}
          setLink={setLink}
        />
      )}
    </div>
  );
}

export default RichTextEditor;

RichTextEditor.defaultProps = {
  currentTarget: null,
};
