import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './authors.css';
import { getAuthors } from './authorsReducer';
import { appRoutes } from '../../../../appRoutes';
import NetNetTable from '../../../../Componenets/atomic-organisms/Tables/NetNetTable';
import TitleBar from '../../../../Componenets/atomic-molecules/Headers/TitleBar';
import { useRouteTo } from '../../../../hooks/useRouteTo';

const dummyAuthor = [
  {
    authorName: 'NetNet',
    company: 'AliTech',
    url: '//netnet.com',
    email: 'thenetnet08@gmail.com',
    id: 1,
  },
  {
    authorName: 'Elon Musk',
    company: 'SpaceX',
    url: '//spaceX.com',
    email: 'thespacex08@gmail.com',
    id: 2,
  },
  {
    authorName: 'Elon Musk2',
    company: 'Space2X',
    url: '//spaceX2.com',
    email: 'thespacex0822@gmail.com',
    id: 3,
  },
  {
    authorName: 'Elon Musk2',
    company: 'Space2X',
    url: '//spaceX2.com',
    email: 'thespacex0822@gmail.com',
    id: 3,
  },
  {
    authorName: 'Elon2',
    company: 'Space2X',
    url: '//spaceX2.com',
    email: 'thespacex0822@gmail.com',
    id: 3,
  },
];

function Authors({ screenHeight }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const routeTo = useRouteTo();
  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getAuthors(meta))
      .then(() => {})
      .catch((error) => {
        // eslint-disable-next-line
        console.error('Error fetching authors:', error);
      });
  }, [dispatch, access_token]);

  const columns = [
    {
      field: 'authorName',
      headerName: 'author Name',
      width: 180,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Person Name
          <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">
          <img
            alt="author"
            src={params.value}
            style={{
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              marginRight: '3px',
            }}
          />
          {params.row.authorName}
        </div>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 160,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Company <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">{params.value}</div>
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      minWidth: 120,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          URL <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">{params.value}</div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 120,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Email <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">{params.value}</div>
      ),
    },
  ];

  return (
    <section className="oppor-section">
      <TitleBar
        sectionTitle="Net Net U Authors"
        buttonRequired={true}
        buttonTitle="New"
        buttonFunction={() =>
          routeTo(
            `${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.newNetNetUAuthors}`,
          )
        }
      />
      <div
        style={{
          padding: '4px 18px',
        }}
        className="authorsWrapper"
      >
        <Box
          sx={{
            height: screenHeight - 0,
            overflowY: 'auto',
          }}
        >
          <NetNetTable
            rows={
              Array.isArray(dummyAuthor) && dummyAuthor.length
                ? dummyAuthor
                : []
            }
            columns={columns}
            rowHeight={76}
            headerHeight={78}
            sx={{
              '.MuiDataGrid-row': {
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
                letterSpacing: '0.5px',
                backgroundColor: '#FDFDFE',
                maxHeight: '76px',
              },
              '.MuiDataGrid-columnHeader': {
                color: '#03071E99',
                backgroundColor: '#F2F2F2',
                fontFamily: 'Open Sans, sans-serif',
              },
              '.css-1q27lj3-MuiDataGrid-root': {
                borderRadius: '10px',
              },
              '.MuiDataGrid-columnHeaders ': {
                zIndex: 1,
                backgroundColor: '#F2F2F2',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              },
              '.tableHeader': {
                color: ' #03071E99',
              },
              minHeight: screenHeight - 170,
              maxHeight: screenHeight - 170,
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: '10px',
              margin: '1.38%',
            }}
            checkboxSelection={true}
          />
        </Box>
      </div>
    </section>
  );
}

export default Authors;
