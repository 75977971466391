import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Bounce from 'react-reveal/Bounce';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { NetNetCheckBox } from '../../../Componenets/Misc';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import helpVid from '../../Opportunity/assets/Section_Help-idle.svg';
import WelComeHelpVideo from './WelComeHelpVideo';
import { userData } from '../../../Screens/InnerApp/userData';
import WelcomeNewHelpVideo from './WelcomeNewHelpVideo';
import WorkTypes from '../../../Assets/welcomeScreen/worktypes.jpg';
import Welcometonetnet from '../../../Assets/welcomeScreen/welcometonetnet.mp4';
import Vector from '../../../Assets/welcomeScreen/Vector.svg';
import WelcomeToNet from '../../../Assets/welcomeScreen/Welcometonet.jpg';
import ReadyForTour from '../../../Assets/welcomeScreen/Readyfortour.jpg';
import Rectangle from '../../../Assets/welcomeScreen/Rectangle.jpg';
import Rectangle1 from '../../../Assets/welcomeScreen/Rectangle1.jpg';
import Rectangle2 from '../../../Assets/welcomeScreen/Rectangle2.jpg';
import Rectangle3 from '../../../Assets/welcomeScreen/Rectangle3.jpg';
import Rectangle4 from '../../../Assets/welcomeScreen/Rectangle4.jpg';
import Rectangle5 from '../../../Assets/welcomeScreen/Rectangle5.jpg';
import Rectangle6 from '../../../Assets/welcomeScreen/Rectangle6.jpg';
import Dive from '../../../Assets/welcomeScreen/Dive.jpg';
import Dive1 from '../../../Assets/welcomeScreen/Dive1.jpg';
import Dive2 from '../../../Assets/welcomeScreen/Dive2.jpg';
import Dive3 from '../../../Assets/welcomeScreen/Dive3.jpg';
import Dive4 from '../../../Assets/welcomeScreen/Dive4.jpg';
import Dive5 from '../../../Assets/welcomeScreen/Dive5.jpg';
import Dive6 from '../../../Assets/welcomeScreen/Dive6.jpg';
import Dive7 from '../../../Assets/welcomeScreen/Dive7.jpg';
import Dive8 from '../../../Assets/welcomeScreen/Dive8.jpg';
import Dive9 from '../../../Assets/welcomeScreen/Dive9.jpg';
import Dive10 from '../../../Assets/welcomeScreen/Dive10.jpg';
import Dive11 from '../../../Assets/welcomeScreen/Dive11.jpg';
import Dive12 from '../../../Assets/welcomeScreen/Dive12.jpg';
import Dive13 from '../../../Assets/welcomeScreen/Dive13.jpg';
import TitleBar from '../../../Componenets/atomic-molecules/Headers/TitleBar';

export default function Welcome({ screenSize, screenHeight }) {
  const [show, setShow] = useState();

  const currentUser = useSelector((state) => state.userData.userData);
  const access = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();

  const data = [
    {
      img: WelcomeToNet,
      url: Welcometonetnet,
      title: 'Welcome To Net Net',
      para: 'If this is your first time here, please watch this video! We’ll give you the broad strokes to get you started.',
    },
    {
      img: ReadyForTour,
      url: Welcometonetnet,
      title: ' Ready For A Tour?',
      para: 'If you have 10 minutes, sit back, grab a beverage and have a look at our guided tour that will take you from novice to pro in no time.',
    },
  ];

  const data2 = [
    {
      img: WorkTypes,
      title: 'Work Types',
      para: 'What you offer to clients & how it’s priced',
    },
    {
      img: Rectangle,
      title: 'Sales: Opportunities & Estimates',
      para: 'We’ve simplified sales unlike any other CRM',
    },
    {
      img: Rectangle1,
      title: 'Time',
      para: 'Estimated, Assigned & Actual time',
    },
    {
      img: Rectangle2,
      title: 'Deliverables & Tasks',
      para: 'How grouping tasks into deliverables works',
    },
    {
      img: Rectangle3,
      title: 'Quick Tasks vs. Job Tasks',
      para: 'Comparing the different types of tasks',
    },
    {
      img: Rectangle4,
      title: 'Task Statuses',
      para: 'Your work will flow with our simple statuses',
    },
    {
      img: Rectangle5,
      title: 'Team Access Levels',
      para: "We'll cover the role types and their capabilities",
    },
    {
      img: Rectangle6,
      title: 'Estimate vs. Actual for Reporting',
      para: 'This is where the juice is, the open secret about how you will scale your firm with Net Net',
    },
  ];

  const dive = [
    {
      img: Dive,
      title: 'Managing Work Types',
      para: 'Work Types are the cornerstone of Net Net.  Learn how to add and modify work types in seconds.',
    },
    {
      img: Dive1,
      title: 'Managing Your Team',
      para: "Get your entire team on boarded into Net Net. We'll cover invitations & roles.",
    },
    {
      img: Dive2,
      title: 'Adding & Importing Your Contact',
      para: 'Getting all of your contacts into Net Net is a breeze!',
    },
    {
      img: Dive3,
      title: 'Creating Estimates',
      para: "Learn how to create your estimates in no time . You'll get an overview of the deliverable templates, setting LOE, pricing and more!",
    },
    {
      img: Dive4,
      title: 'Converting Estimates Into Jobs',
      para: 'In 5 minutes any of your estimates can be set up and ready to go into production. Ready, set, activate!',
    },
    {
      img: Dive5,
      title: 'Start A Job Without An Estimate',
      para: "If you have internal or external projects or retainers that your don't want to use Net Net's money part, you can still run them.",
    },
    {
      img: Dive6,
      title: 'Managing Jobs',
      para: 'Project management is a breeze. Assiging and managing tasks utilizing statuses and integrated time tracking will give you insight.',
    },
    {
      img: Dive7,
      title: 'Quick Tasks',
      para: 'Because not everything you do internally and for clients falls in a Job, Learn how to navigate one-off and internal tasks!',
    },
    {
      img: Dive8,
      title: 'Time Tracking',
      para: 'Time tracking is a breeze with Net Net. Timers and Manual Entries work equally well. Here’s your time primer!',
    },
    {
      img: Dive9,
      title: 'Utilizing Chat with Smart Mentions!',
      para: 'Sure you can @ anyone, but can you tilde ~deliverables/tasks?  Find out how chat with smart mentions ups your collaboration.',
    },
    {
      img: Dive10,
      title: 'Change Orders for Your Jobs',
      para: 'Is it in scope or out?  You can issue change orders that can be added to any of your projects or retainers at any point.',
    },
    {
      img: Dive11,
      title: 'Job KPI’s',
      para: 'KPI’s are essential for all of your projects and retainers.  Net Net keeps them front and center so you’re in the know!',
    },
    {
      img: Dive12,
      title: 'Reports',
      para: 'Slice and dice all aspects of your firm from sales, to time, to work types, team members and more.  Net Net’s promise comes down to actionable insight. This is it!',
    },
    {
      img: Dive13,
      title: 'Manage Billing',
      para: 'Learn more about our active Job pricing, how to set up your package and see everything about the exciting world of billing!',
    },
  ];

  const [clickData, setClickData] = useState();

  const [showHelp, setShowHelp] = useState();

  return (
    <Bounce left>
      <section className="oppor-section">
        <TitleBar
          helpIconFunction={() => setShow(true)}
          sectionTitle="Welcome"
          buttonRequired={false}
        />
        <Grid
          container
          sx={{
            width: '100%',
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'start',
            overflowY: 'auto',
            maxHeight: screenHeight - 155,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={4.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              pt: 4,
              pl: 1,
              pr: 1,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px',
                padding: '12px 12px',
                width: '100%',
                backgroundColor: '#E7DAFF',
                borderRadius: '15px',
              }}
            >
              {data.map((item) => {
                return (
                  <Box
                    key={item?.title}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      height: '150px',
                    }}
                  >
                    <div style={{ width: '240px', height: '150px' }}>
                      <img
                        alt="img"
                        src={item.img}
                        frameBorder="8"
                        style={{
                          borderRadius: '15px',
                          width: '240px',
                          height: '150px',
                        }}
                      />
                      <img
                        src={Vector}
                        style={{
                          position: 'absolute',
                          top: 50,
                          left: 100,
                          width: '40px',
                          height: '40px',
                        }}
                        alt="vector"
                      />
                    </div>

                    <Box
                      sx={{
                        background: 'none',
                        position: 'absolute',
                        width: '95%',
                        height: '150px',
                        left: '0',
                        top: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowHelp(true);
                        setClickData({
                          ...item,
                          yt_link:
                            'https://videos.hellonetnet.com/watch/m61IhS2t',
                        });
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        padding: '0px 0px 0px 12px',
                        height: '150px',
                        overflow: 'hidden',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        {item.title}
                      </p>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="div"
                        sx={{
                          fontSize: { sm: '14px', md: '14px', lg: '14px' },
                          marginTop: { sm: '15px', md: '10px', lg: '15px' },
                        }}
                      >
                        {item.para}
                      </Typography>
                    </div>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                overflow: 'hidden',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '10px 15px',
              }}
            >
              <Box
                sx={{ display: 'flex', width: '100%', marginBottom: '10px' }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#03071E',
                  }}
                >
                  Net Net Core Concepts
                </p>
              </Box>
              {data2.map((itemo) => {
                return (
                  <Box
                    key={itemo?.title}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      width: '100%',
                      overflow: 'hidden',
                      height: '70px',
                      marginBottom: '15px',
                    }}
                  >
                    <div style={{ width: '100px', height: '65px' }}>
                      <img
                        alt="img"
                        src={itemo.img}
                        frameBorder="8"
                        style={{
                          borderRadius: '8px',
                          width: '100px',
                          height: '65px',
                          objectPosition: 'center',
                          objectFit: 'cover',
                        }}
                      />
                      <img
                        src={Vector}
                        style={{
                          position: 'absolute',
                          top: 25,
                          left: 40,
                          width: '20px',
                          height: '20px',
                        }}
                        alt="vector"
                      />
                    </div>
                    <Box
                      sx={{
                        background: 'none',
                        position: 'absolute',
                        width: '95%',
                        height: '120px',
                        left: '0',
                        top: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowHelp(true);
                        setClickData({
                          ...itemo,
                          yt_link:
                            'https://videos.hellonetnet.com/watch/m61IhS2t',
                        });
                      }}
                    />
                    <div style={{ width: '100%', padding: '4px 12px' }}>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        {itemo.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="div"
                        sx={{ fontSize: '14px', width: '100%' }}
                      >
                        {itemo.para}
                      </Typography>
                    </div>
                  </Box>
                );
              })}
            </Box>
            {screenSize > 899 ? (
              <Box
                sx={{
                  width: '96%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
              >
                <Box
                  sx={{
                    width: {
                      xl: screenSize < 1860 ? '100%' : '100%',
                      lg: '100%',
                    },
                    display: 'flex',
                    padding: '10px 15px',
                    backgroundColor: '#e7e7e7',
                    borderRadius: '8px',
                    alignItems: 'center',
                    gap: '.5rem',
                  }}
                >
                  <NetNetCheckBox
                    checked={currentUser?.skiped_welcome}
                    onChange={async () => {
                      const userUpdateData = new FormData();

                      // Add fields to the formData object
                      userUpdateData.append('profile_updated', 1);
                      userUpdateData.append('personalization_updated', 1);
                      userUpdateData.append('email', currentUser?.email);
                      userUpdateData.append(
                        'first_name',
                        currentUser?.first_name,
                      );
                      userUpdateData.append(
                        'last_name',
                        currentUser?.last_name,
                      );
                      userUpdateData.append('password', 'P@ssword123');
                      userUpdateData.append('username', currentUser?.username);
                      // userUpdateData.append('avatar', null);
                      userUpdateData.append(
                        'skiped_welcome',
                        currentUser.skiped_welcome ? 0 : 1,
                      );
                      // eslint-disable-next-line
                      const res = await fetch(
                        `${ENDPOINT}/api/user/update/${currentUser.id}/`,
                        {
                          method: 'PUT',
                          headers: {
                            Authorization: `JWT ${access}`,
                          },
                          body: userUpdateData,
                        },
                      );

                      const response = await fetch(
                        `${ENDPOINT}/api/user/userdetail/`,
                        {
                          method: 'GET',
                          headers: {
                            Authorization: `JWT ${access}`,
                            'Content-Type': 'application/json',
                          },
                        },
                      );
                      const dataGet = await response.json();
                      if (response.ok) {
                        dispatch(userData(dataGet));
                      }
                    }}
                  />
                  <Typography sx={{ fontSize: '15px', color: '#000' }}>
                    Do not show me Welcome Screen again
                  </Typography>
                </Box>
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={7.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              borderRadius: '8px',
              alignItems: 'start',
              pt: { xs: 2, sm: 4 },
              pl: { xs: 1, sm: 1, md: 2, lg: 6 },
              pr: { xs: 1, sm: 1, md: 1, lg: 3 },

              gap: { xs: 4, md: 4 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                overflowX: 'hidden',
                backgroundColor: '#fff',
                borderRadius: '12px',
                padding: '10px 10px 0px 15px',
              }}
            >
              <Box
                sx={{ display: 'flex', width: '100%', marginBottom: '10px' }}
              >
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#03071E',
                  }}
                >
                  Dive Into Net Net
                </p>
              </Box>
              {dive.map((dives) => {
                return (
                  <Box
                    key={dives?.title}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      width: '100%',
                      marginBottom: '10px',
                      overflow: 'hidden',
                      height: '90px',
                    }}
                  >
                    <div style={{ width: '125px', height: '80px' }}>
                      <img
                        alt="img"
                        src={dives.img}
                        frameBorder="8"
                        style={{
                          borderRadius: '8px',
                          width: '125px',
                          height: '80px',
                        }}
                      />
                      <img
                        src={Vector}
                        style={{
                          position: 'absolute',
                          top: 25,
                          left: 50,
                          width: '30px',
                          height: '30px',
                        }}
                        alt="vector"
                      />
                    </div>
                    <Box
                      sx={{
                        background: 'none',
                        position: 'absolute',
                        width: '95%',
                        height: '120px',
                        left: '0',
                        top: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowHelp(true);
                        setClickData({
                          ...dives,
                          yt_link:
                            'https://videos.hellonetnet.com/watch/m61IhS2t',
                        });
                      }}
                    />
                    <div style={{ width: '100%', padding: '4px 0px 4px 15px' }}>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        {dives.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="div"
                        sx={{
                          fontSize: '14px',
                          width: { sm: '100%', xl: '90%' },
                        }}
                      >
                        {dives.para}
                      </Typography>
                    </div>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          {screenSize >= 600 && screenSize < 900 && (
            <Box
              sx={{
                width: '96%',
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '2rem',
                marginBottom: '2rem',
                pl: 2,
              }}
            >
              <Box
                sx={{
                  width: { xl: screenSize < 1860 ? '30%' : '30%', lg: '40%' },
                  display: 'flex',
                  padding: '10px 15px',
                  backgroundColor: '#e7e7e7',
                  borderRadius: '8px',
                  alignItems: 'center',
                  gap: '.5rem',
                }}
              >
                <NetNetCheckBox
                  checked={currentUser?.skiped_welcome}
                  onChange={async () => {
                    const userUpdateData = new FormData();

                    // Add fields to the formData object
                    userUpdateData.append('profile_updated', 1);
                    userUpdateData.append('personalization_updated', 1);
                    userUpdateData.append('email', currentUser?.email);
                    userUpdateData.append(
                      'first_name',
                      currentUser?.first_name,
                    );
                    userUpdateData.append('last_name', currentUser?.last_name);
                    userUpdateData.append('password', 'P@ssword123');
                    userUpdateData.append('username', currentUser?.username);
                    userUpdateData.append(
                      'skiped_welcome',
                      currentUser.skiped_welcome ? 0 : 1,
                    );
                    // eslint-disable-next-line
                    const res = await fetch(
                      `${ENDPOINT}/api/user/update/${currentUser.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access}`,
                        },
                        body: userUpdateData,
                      },
                    );

                    const response = await fetch(
                      `${ENDPOINT}/api/user/userdetail/`,
                      {
                        method: 'GET',
                        headers: {
                          Authorization: `JWT ${access}`,
                          'Content-Type': 'application/json',
                        },
                      },
                    );
                    const dataGet = await response.json();
                    if (response.ok) {
                      dispatch(userData(dataGet));
                    }
                  }}
                />
                <Typography sx={{ fontSize: '15px', color: '#000' }}>
                  Do not show me Welcome Screen again
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <WelComeHelpVideo show={show} setShow={setShow} />
        <WelcomeNewHelpVideo
          showHelp={showHelp}
          setShowHelp={setShowHelp}
          screenSize={screenSize}
          clickData={clickData}
        />
      </section>
    </Bounce>
  );
}
