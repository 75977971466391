import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

// eslint-disable-next-line import/prefer-default-export
export async function getJobDetail(accessToken, jobID) {
  const response = await fetch(`${ENDPOINT}/api/project/detail/${jobID}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${accessToken}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(`Failed to get Job Details: ${errorData}`);
  }

  return response.json();
}
