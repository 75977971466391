import {
  Grid,
  Box,
  Menu,
  MenuItem,
  Collapse,
  Tooltip,
  TextField,
  Typography,
  Avatar,
  Alert,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Autocomplete from '@mui/material/Autocomplete';
import { stateToHTML } from 'draft-js-export-html';
import {
  EditorState,
  convertToRaw,
  RichUtils,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ENDPOINT } from '../Redux/oppor';
import {
  DashedField2,
  NetNetSwitch,
  DashedField,
} from '../../../Componenets/Misc';
import Duplicate from '../../../Assets/NavLink/Duplicate.svg';
import addTask from '../../../Assets/NavLink/Add-Tasks.svg';
import subTask from '../../../Assets/NavLink/SubTasks.svg';
import hideTask from '../../../Assets/NavLink/Hide-Tasks.svg';
import showTask from '../../../Assets/NavLink/Show-Tasks.svg';
import updateTask from '../../../Assets/NavLink/Update_Deliverable_Template.svg';
import newTask from '../../../Assets/NavLink/New_Deliverable_Template.svg';
import DeleteIcon from '../../../Assets/NavLink/Delete.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import uuid from 'react-uuid';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CloseIcon from '@mui/icons-material/Close';
import taskIcon from '../../../Assets/NavLink/Sub-Task_Item.svg';
import RichTextEditor from '../../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';

const colors = ['#cef6ff', '#ece7fb', '#8feeb2'];
const spanSx = {
  fontWeight: '600',
  fontSize: '1.1rem',
};
const HeadCell = ({ arg, open, currentTarget }) => (
  <TableCell align="center" style={{ textAlign: 'center' }}>
    <div
      className="tcell"
      style={{ background: open ? currentTarget.color : null }}
    >
      {arg}
    </div>
  </TableCell>
);

const reduceTaskWorktypes = (taskWorktypes) => {
  const reducedArray = [];

  if (Array.isArray(taskWorktypes) && taskWorktypes.length) {
    taskWorktypes.forEach((item) => {
      if (
        item.task_worktype &&
        Array.isArray(item.task_worktype) &&
        item.task_worktype.length
      ) {
        item.task_worktype.forEach((worktype) => {
          const existingItem = reducedArray.find(
            (item) => item.name === worktype.name,
          );

          if (existingItem) {
            existingItem.hours += parseInt(worktype.hours ? worktype.hours : 0);
          } else {
            reducedArray.push({
              name: worktype.name,
              hours: parseInt(worktype.hours ? worktype.hours : 0),
            });
          }
        });
      }
    });
  }

  return reducedArray;
};
const calculateTotalHours = (deliverables, worktypeId) => {
  return deliverables.reduce((total, deliverable) => {
    if (
      Array.isArray(deliverable.deliverable_worktype) &&
      deliverable.deliverable_worktype.length
    ) {
      return (
        total +
        deliverable.deliverable_worktype.reduce((subTotal, worktype) => {
          if (worktype.name === worktypeId.name && worktype.hours !== '') {
            return subTotal + parseInt(worktype.hours ? worktype.hours : 0);
          } else {
            return subTotal;
          }
        }, 0)
      );
    } else {
      return total;
    }
  }, 0);
};

function calculateTotalLOE(array) {
  if (!array) {
    return false;
  }
  if (!Array.isArray(array) && !array.length) {
    return false;
  }
  return array.reduce((total, obj) => {
    return total + obj.total_loe;
  }, 0);
}

const LastRow = ({
  formData,
  dileverable,
  counter,
  currentTarget,
  Np,
  realTarget,
}) => {
  const [totalHours, setTotalHours] = useState([]);
  const [collabHours, setCollabHours] = useState([]);
  const colabs = useSelector((state) => state.Misc.collabs);

  useEffect(() => {
    if (Array.isArray(dileverable) && dileverable.length) {
      const hours = dileverable[0]?.deliverable_worktype?.map((worktypeId) => {
        return calculateTotalHours(dileverable, worktypeId);
      });
      setTotalHours(hours);
    }
    if (
      Array.isArray(realTarget?.collaborator_deliverable) &&
      realTarget?.collaborator_deliverable.length
    ) {
      const hours =
        realTarget?.collaborator_deliverable[0]?.deliverable_worktype?.map(
          (worktypeId) => {
            return calculateTotalHours(
              realTarget.collaborator_deliverable,
              worktypeId,
            );
          },
        );

      setCollabHours(hours);
    }
  }, [formData, dileverable, counter, realTarget]);

  const NetTotal = calculateTotalLOE(dileverable);
  const totalAll = () => {
    if (Array.isArray(formData?.deliverable) && formData?.deliverable.length) {
      return formData?.deliverable.reduce((total, newArr) => {
        return (
          total +
          newArr?.deliverable_worktype?.reduce(
            (newTotal, item) => newTotal + Number(item.hours),
            0,
          )
        );
      }, 0);
    } else {
      return 0;
    }
  };

  return (
    <TableRow
      sx={{ backgroundColor: '#1e0052', color: '#fff', height: '110px' }}
    >
      <TableCell style={{ textAlign: 'left' }}>
        <div className="fstxt-wh">Total</div>
      </TableCell>
      {Array.isArray(formData.collaborator) && formData.collaborator.length
        ? formData.collaborator.map((item, index) => {
            const target =
              Array.isArray(colabs) && colabs.length
                ? colabs.find(
                    (ele) =>
                      Number(ele?.collab?.id) === Number(item.collab_detail),
                  )
                : false;

            const currentTargetCollab = formData.collaborator?.find(
              (per) =>
                Number(per?.collab_detail) === Number(target?.collab?.id),
            );

            let collabtotalHours = '0Hr'; // Initialize collabtotalHours to a default value

            if (
              Array.isArray(currentTargetCollab?.collaborator_deliverable) &&
              currentTargetCollab.collaborator_deliverable.length
            ) {
              const hours =
                currentTargetCollab.collaborator_deliverable?.reduce(
                  (total, worktype) => {
                    return (
                      total +
                      worktype.deliverable_worktype.reduce((subTotal, hour) => {
                        return subTotal + Number(hour?.hours);
                      }, 0)
                    );
                  },
                  0,
                );
              collabtotalHours = hours + 'Hr'; // Update collabtotalHours with the computed value
            }

            return (
              <TableCell
                align="center"
                key={index} // Add a unique key prop
              >
                <div className="fstxt-wh"> {collabtotalHours} </div>
              </TableCell>
            );
          })
        : null}

      {Np &&
      Array.isArray(realTarget.collaborator_deliverable[0].deliverable_worktype)
        ? [...realTarget.collaborator_deliverable[0].deliverable_worktype].map(
            (item, ing) => {
              return item.collab_worktype ? (
                <TableCell align="center" key={uuid()}>
                  <div className="fstxt-wh">
                    {collabHours[ing] ? `${collabHours[ing]}hr` : ' 0hr'}
                  </div>
                </TableCell>
              ) : (
                <TableCell align="center" key={ing + 6565 + Math.random()}>
                  {' '}
                </TableCell>
              );
            },
          )
        : null}

      {Array.isArray(formData.deliverable) && formData.deliverable.length
        ? formData?.deliverable[0]?.deliverable_worktype.map(
            (worktypeId, index) => (
              <TableCell align="center" key={index}>
                <div className="fstxt-wh">
                  {totalHours[index] ? `${totalHours[index]}hr` : ' 0hr'}
                </div>
              </TableCell>
            ),
          )
        : formData?.work_type.map((worktypeId, index) => (
            <TableCell align="center" key={index}>
              <div className="fstxt-wh">
                {totalHours[index] ? `${totalHours[index]}hr` : ' 0hr'}
              </div>
            </TableCell>
          ))}
      <TableCell align="center">
        <div
          className="fstxt-wh"
          style={{
            fontSize: '1.5rem',
            border: '2px solid white',
            padding: '0.5rem',
          }}
        >
          {totalAll() ? `${totalAll()}hr` : '0hr'}
        </div>
      </TableCell>
    </TableRow>
  );
};

const THead = ({
  formData,
  realTarget,
  setCurrentTarget,
  open,
  setOpen,
  prevDil,
  setDeliverable,
  setIsTyping,
  user,
}) => {
  const colabs = useSelector((state) => state.Misc.collabs);
  return (
    <TableHead
      sx={{
        height: '110px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="tcell">Deliverables </div>
        </TableCell>
        {Array.isArray(formData.collaborator) && formData.collaborator.length
          ? formData.collaborator.map((item, index) => {
              const target =
                Array.isArray(colabs) && colabs.length
                  ? colabs.find(
                      (ele) =>
                        Number(ele?.collab?.id) === Number(item.collab_detail),
                    )
                  : false;
              const currentTargetCollab = formData.collaborator?.find(
                (per) =>
                  Number(per?.collab_detail) === Number(target?.collab?.id),
              );
              return (
                <TableCell
                  align="left"
                  sx={{
                    transition: 'all 0.7s ease-in-out',
                  }}
                >
                  <Tooltip
                    title={
                      Array.isArray(
                        currentTargetCollab?.collaborator_deliverable,
                      ) && currentTargetCollab?.collaborator_deliverable?.length
                        ? ''
                        : 'Waiting for Collaborator to add their delieverables.'
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        padding: '0',
                        backgroundColor: colors[index],
                        borderRadius: '8px',
                        cursor: 'pointer',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div
                        className="tcell"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          src={target ? target.collab.avatar : null}
                          alt={target ? target.collab.first_name : null}
                        />
                        <Typography>
                          {target ? target.collab.first_name : null}
                        </Typography>
                      </div>

                      {open ? (
                        <KeyboardArrowLeftIcon
                          onClick={() => {
                            if (!Array.isArray(prevDil) && !prevDil.length) {
                              return;
                            }
                            setOpen(false);
                            setCurrentTarget(false);
                            setIsTyping(false);
                            setDeliverable(formData.deliverable);
                          }}
                        />
                      ) : (
                        <KeyboardArrowRightIcon
                          id="cypress-updateProjectTask-arrowButton"
                          onClick={() => {
                            if (
                              !Array.isArray(
                                currentTargetCollab.collaborator_deliverable,
                              ) ||
                              !currentTargetCollab.collaborator_deliverable
                                .length
                            ) {
                              return;
                            }

                            const shallow_copy = prevDil.filter(
                              (dil) =>
                                dil.created_by ===
                                  currentTargetCollab.collab_detail ||
                                dil.created_by === user.id,
                            );
                            setDeliverable(shallow_copy);
                            setCurrentTarget({
                              ...currentTargetCollab,
                              color: colors[index],
                            });
                            setIsTyping(true);
                            setOpen(true);
                          }}
                        />
                      )}
                    </Box>
                  </Tooltip>
                </TableCell>
              );
            })
          : null}

        {!open
          ? formData?.deliverable &&
            Array.isArray(formData?.deliverable) &&
            formData?.deliverable.length
            ? formData.deliverable[0].deliverable_worktype.map((item) => {
                return <HeadCell arg={item.name} />;
              })
            : formData?.work_type?.map((item) => {
                return (
                  item.collab_worktype === null && <HeadCell arg={item.name} />
                );
              })
          : open &&
              Array.isArray(
                realTarget.collaborator_deliverable[0]?.deliverable_worktype,
              ) &&
              Array.isArray(formData.deliverable[0].deliverable_worktype)
            ? [
                ...realTarget.collaborator_deliverable[0]?.deliverable_worktype,
                ...formData.deliverable[0].deliverable_worktype,
              ].map((wt) => {
                return (
                  <HeadCell
                    arg={wt.name}
                    open={wt.collab_worktype ? open : false}
                    currentTarget={realTarget}
                  />
                );
              })
            : 'Pending Worktypes...'}
        <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Total
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const AddRows = ({
  formData,
  dileverable,
  setDeliverable,
  setErrorState,
  handleSeventhStep,
  currentTarget,
  setNum,
  open,
}) => {
  const [tempState, setTempState] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const end = new Date(
    new Date(formData.est_startdate).setDate(
      new Date(formData.est_startdate).getDate() + 7,
    ),
  ).toISOString();

  return (
    <TableRow
      sx={{
        width: '100%',
        height: '100px',
      }}
    >
      <TableCell
        align="left"
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'flex-start',
          gap: '1rem',
          borderBottom: 'none',
        }}
      >
        {!open && (
          <DashedField
            id="Cypress-AddDeliverable"
            label="Add Deliverable"
            value={tempState}
            onChange={(e) => setTempState(e.target.value)}
            onKeyPress={async (e) => {
              if (e.key === 'Enter') {
                const StateCheck =
                  Array.isArray(dileverable) && dileverable.length
                    ? dileverable.find(
                        (item) => item.deliverable_name === tempState,
                      )
                    : false;

                if (StateCheck) {
                  setErrorState({
                    error: true,
                    message: 'A Deliverable with this name already exists',
                  });
                  return;
                }
                const res = await fetch(
                  `${ENDPOINT}/api/project/estimate/deliverable/create/`,
                  {
                    method: 'POST',
                    headers: {
                      Authorization: `JWT ${access_token}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      deliverable_estimate: formData.id,
                      deliverable_name: tempState,
                      description: '',
                      sort_order:
                        Array.isArray(dileverable) && dileverable?.length
                          ? dileverable[dileverable.length - 1]?.sort_order + 1
                          : 1,
                      deliverable_worktype:
                        Array.isArray(formData.deliverable) &&
                        formData.deliverable.length &&
                        formData.deliverable[0]?.deliverable_collaborator ===
                          null
                          ? formData.deliverable[0]?.deliverable_worktype?.map(
                              (i, n) => {
                                return {
                                  id: i.id,
                                  hours: null,
                                };
                              },
                            )
                          : formData?.work_type.map(
                              (i, n) =>
                                i.collab_worktype === null && {
                                  id: i.id,
                                  hours: null,
                                },
                            ),

                      deliverable_task: [],
                      deliverable_multiplier: [],
                      deliverable_startdate: new Date(formData.est_startdate),
                      deliverable_enddate: end,
                    }),
                  },
                );

                const data = await res.json();
                if (!res.ok) {
                  alert('Couldn’t Update');
                  return;
                }
                setTempState('');
                const resGet = await fetch(
                  `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
                  {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                  },
                );
                const dataGet = await resGet.json();
                if (
                  dataGet &&
                  Array.isArray(dataGet.deliverable) &&
                  dataGet.deliverable.length
                ) {
                  const sortedDeliverable = dataGet.deliverable
                    .map((deliverable) => ({
                      ...deliverable,
                      deliverable_worktype:
                        deliverable.deliverable_worktype.sort((a, b) =>
                          a.name.localeCompare(b.name),
                        ),
                    }))
                    .sort((a, b) => a.sort_order - b.sort_order);

                  setDeliverable(sortedDeliverable);
                }
              }
              return 0;
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const DetailView = ({
  setShow, // Prop FUnc
  show, // Prop Bool
  p, // p = the deliverable that's detail u want
  dileverable, // dileverable array
  setDeliverable, // func to set delieverable array
  formData, // u know it,
  setIsTyping,
  currentTarget,
  access_token,
  setFormData,
}) => {
  const [html, setHtml] = useState(p?.internal_note ?? '');

  const customOptions = dileverable.filter(
    (item) => item.deliverable_name !== p.deliverable_name,
  );
  const [deliverName, setDeliverName] = useState();
  const handleClose = () => setShow(false);
  const UpdateRequest = async () => {
    const shallowCopy = {
      ...formData,
    };
    const index = dileverable.findIndex(
      (ent) => Number(ent.id) === Number(p.id),
    );
    dileverable[index].deliverable_name = deliverName
      ? deliverName
      : dileverable[index].deliverable_name;
    dileverable[index].description = htmlDesc
      ? htmlDesc
      : dileverable[index].description;
    dileverable[index].internal_note = html
      ? html
      : dileverable[index].internal_note;

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
                ...shallowCopy,
                estimate_deliverable: dileverable?.map((item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.deliverable_multiplier;
                  delete item.task_deliverable;
                  const startDate =
                    item.deliverable_dependency.id &&
                    new Date(item.deliverable_dependency.deliverable_enddate);
                  startDate?.setDate(startDate.getDate() + 7);
                  return {
                    ...item,
                    deliverable_startdate: item.deliverable_dependency?.id
                      ? item.deliverable_dependency.deliverable_enddate
                      : item.deliverable_startdate,
                    deliverable_enddate:
                      item.deliverable_dependency?.id &&
                      new Date(startDate) >= new Date(item.deliverable_enddate)
                        ? startDate
                        : item.deliverable_enddate,
                    deliver_id: item.id,
                    deliverable_name: item.deliverable_name,
                    deliverable_dependency: item.deliverable_dependency
                      ? item.deliverable_dependency.id
                        ? item.deliverable_dependency.id
                        : item.deliverable_dependency
                      : null,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
                collaborator: formData?.collaborator?.map((item) => item?.id),
              })
            : JSON.stringify({
                ...shallowCopy,
                estimate_deliverable: dileverable?.map((item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.task_deliverable;
                  delete item.deliverable_multiplier;
                  const startDate = item.deliverable_dependency
                    ? item.deliverable_dependency.id
                      ? new Date(
                          item.deliverable_dependency.deliverable_enddate,
                        )
                      : new Date(
                          item.deliverable_dependency &&
                            dileverable.find(
                              (dil) => dil.id === item.deliverable_dependency,
                            ).deliverable_enddate,
                        )
                    : new Date(item.deliverable_startdate);

                  if (startDate) {
                    startDate.setDate(startDate.getDate() + 7);
                  }
                  const newDate =
                    new Date(item.deliverable_enddate) -
                    new Date(item.deliverable_startdate);
                  const startDateOfDeliverable = item.deliverable_dependency
                    ? item.deliverable_dependency?.id
                      ? item.deliverable_dependency.deliverable_enddate
                      : dileverable.find(
                          (dil) => dil.id === item.deliverable_dependency,
                        ).deliverable_enddate
                    : item.deliverable_startdate;
                  const totalDur = Math.round(
                    newDate / (24 * 60 * 60 * 1000) / 7,
                  );
                  const durationInDays = newDate / (24 * 60 * 60 * 1000);
                  const startDateOfDil = item?.deliverable_dependency?.id
                    ? dileverable.find(
                        (dil) => dil.id === item?.deliverable_dependency.id,
                      ).deliverable_enddate
                    : item.deliverable_startdate;
                  const endDateOfDil = item?.deliverable_dependency?.id
                    ? new Date(
                        new Date(startDateOfDil).setDate(
                          new Date(startDateOfDil).getDate() + durationInDays,
                        ),
                      ).toISOString()
                    : item.deliverable_enddate;
                  return {
                    ...item,
                    deliverable_startdate: startDateOfDil,
                    deliverable_enddate: endDateOfDil,
                    deliver_id: item.id,
                    deliverable_name: item.deliverable_name,
                    description: item.description ? item.description : null,
                    deliverable_dependency: item.deliverable_dependency
                      ? item.deliverable_dependency.id
                        ? item.deliverable_dependency.id
                        : item.deliverable_dependency
                      : null,
                    internal_note: item.internal_note
                      ? item.internal_note
                      : null,

                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
              }),
      },
    );

    const data = await res.json();
    if (!res.ok) {
      setError(true);
      setErrorMessage('Couldn’t Update');
      setIsTyping(false);
      return;
    }
    const response = await fetch(
      `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );
    const dataGet = await response.json();
    if (!response.ok) {
    }
    setFormData(dataGet);
    if (
      dataGet &&
      Array.isArray(dataGet.deliverable) &&
      dataGet.deliverable.length
    ) {
      const sortedDeliverable = dataGet.deliverable
        .map((deliverable) => ({
          ...deliverable,
          deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
        }))
        .sort((a, b) => a.sort_order - b.sort_order);
      setIsTyping(false);
      setDeliverable(sortedDeliverable);
    }
  };

  function calculateTotalWeeks(deliverableStartDate, deliverableEndDate) {
    const startDate = new Date(deliverableStartDate);
    const endDate = new Date(deliverableEndDate);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to weeks
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate the number of weeks (5-day weeks) based on the days difference
    const weeksDifference = Math.ceil(daysDifference / 7);

    return weeksDifference;
  }
  const [disData, setDisData] = useState();

  useEffect(() => {
    const htmlContent = p.description ?? '<p></p>';
    // Convert HTML to ContentState
    const blocksFromHTML = convertFromHTML(htmlContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    // Convert ContentState to string
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    setDisData(contentStateString);
  }, []);

  const [durationWeek, setDurationWeek] = useState('');
  const [htmlDesc, setHtmlDesc] = useState(p?.description ?? '');
  const [openLink, setOpenLink] = useState(false);
  const [btn, setBtn] = useState();
  const check = (e) => {
    setBtn(e);
  };
  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => setShow(false)} />
      </Offcanvas.Header>
      <Offcanvas.Body style={{ zIndex: 9999 }}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '96%',
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                textAlign: 'left',
                padding: { xl: '0.50rem 4.5rem', lg: '0.50rem .2rem' },
              }}
            >
              <TextField
                variant="standard"
                value={deliverName ?? p?.deliverable_name}
                sx={{
                  width: `calc(${(deliverName ?? p?.deliverable_name).length}ch + 0.3rem)`, // Dynamically adjusts width
                  '& .MuiInputBase-input': {
                    fontSize: '1.5rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    color: '#000000',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '1px dashed #fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '1px dashed #000000',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px dashed #000000',
                  },
                }}
                onChange={(e) => {
                  setDeliverName(e.target.value);
                }}
              />
            </Box>
            <Grid
              item
              container
              sx={{ width: '100%', gap: '1rem' }}
              justifyContent="center"
              alignItem="center"
            >
              {formData.type === 'project' && (
                <>
                  <Grid item xl={5} lg={4.1} md={5.5}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '8px',
                        border: '1px solid #d9d9d9',
                        padding: '0.44rem 0.88rem',
                        gap: '1rem',
                        height: '200px',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '1rem',
                          fontWeight: '600',
                          fontFamily: 'Open Sans',
                        }}
                      >
                        Details
                      </p>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                          backgroundColor: '#f2ebff',
                          borderRadius: '8px',
                          padding: '0.44rem 0.88rem',
                        }}
                      >
                        <Box className="myJam">
                          <span
                            className="modalSpan"
                            style={{ color: 'GrayText' }}
                          >
                            Duration{' '}
                          </span>
                          <Box
                            sx={{
                              width: '300px',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {!p.durr ? (
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                color="secondary"
                                placeholder="Enter Value"
                                defaultValue={calculateTotalWeeks(
                                  p.deliverable_startdate,
                                  p.deliverable_enddate,
                                )}
                                sx={{
                                  width: '60%',
                                  outline: 'none',
                                  borderTopLeftRadius: '10px',
                                  borderStartStartRadius: '10px',
                                  backgroundColor: 'white',
                                  textAlign: 'center',
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '1px solid #f2ebff',
                                    },
                                    '&:hover fieldset': {
                                      border: '1px solid #711fff',
                                    },
                                    '&.Mui-focused fieldset': {
                                      border: '1px solid #5a0bdf',
                                    },
                                    '&.Mui-error fieldset': {
                                      border: '1px solid #f2ebff',
                                    },
                                  },
                                  '& .MuiFormLabel-root': {
                                    color: '#03071e66',
                                  },
                                  '& .MuiFormLabel-root.Mui-focused': {
                                    color: '#5a0bdf',
                                  },
                                  '& .MuiFormLabel-root.Mui-error': {
                                    color: 'red',
                                  },
                                }}
                                type={'number'}
                                InputProps={{
                                  inputProps: { min: 1 },
                                }}
                                onChange={(e) => {
                                  setDurationWeek(Number(e.target.value));
                                  setDeliverable((prev) => {
                                    const shallowCopy = prev.map((item) =>
                                      Object.assign({}, item),
                                    );

                                    const index = shallowCopy.findIndex(
                                      (int) => Number(int.id) === p.id,
                                    );
                                    const start = moment(
                                      p?.deliverable_startdate?.split('T')[0],
                                    ).toDate();
                                    const end = moment(start)
                                      .add(Number(e.target.value) * 7, 'days')
                                      .toDate();

                                    shallowCopy[index].deliverable_enddate =
                                      end;
                                    shallowCopy[index].durr = e.target.value;

                                    return shallowCopy;
                                  });
                                }}
                              />
                            ) : (
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                color="secondary"
                                placeholder="Enter Value"
                                defaultValue={calculateTotalWeeks(
                                  p.deliverable_startdate,
                                  p.deliverable_enddate,
                                )}
                                sx={{
                                  width: '60%',
                                  outline: 'none',
                                  borderTopLeftRadius: '10px',
                                  borderStartStartRadius: '10px',
                                  backgroundColor: 'white',
                                  textAlign: 'center',
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '1px solid #f2ebff',
                                    },
                                    '&:hover fieldset': {
                                      border: '1px solid #711fff',
                                    },
                                    '&.Mui-focused fieldset': {
                                      border: '1px solid #5a0bdf',
                                    },
                                    '&.Mui-error fieldset': {
                                      border: '1px solid #f2ebff',
                                    },
                                  },
                                  '& .MuiFormLabel-root': {
                                    color: '#03071e66',
                                  },
                                  '& .MuiFormLabel-root.Mui-focused': {
                                    color: '#5a0bdf',
                                  },
                                  '& .MuiFormLabel-root.Mui-error': {
                                    color: 'red',
                                  },
                                }}
                                type={'number'}
                                InputProps={{
                                  inputProps: { min: 1 },
                                }}
                                value={p.durr}
                                onChange={(e) => {
                                  setDeliverable((prev) => {
                                    const shallowCopy = prev.map((item) =>
                                      Object.assign({}, item),
                                    );

                                    const index = shallowCopy.findIndex(
                                      (int) => Number(int.id) === p.id,
                                    );
                                    const start = moment(
                                      p?.deliverable_startdate?.split('T')[0],
                                    ).toDate();
                                    const end = moment(start)
                                      .add(Number(e.target.value) * 7, 'days')
                                      .toDate();
                                    const startDate = new Date();
                                    shallowCopy[index].deliverable_enddate =
                                      end;

                                    shallowCopy[index].durr = e.target.value;

                                    return shallowCopy;
                                  });
                                }}
                              />
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                backgroundColor: '#f2f2f2',
                                width: '40%',
                                color: 'GrayText',
                                borderTopRightRadius: '10px',
                                borderEndEndRadius: '10px',
                                height: '45px',
                                paddingLeft: '10px',
                              }}
                            >
                              <Typography sx={{ fontSize: '14px' }}>
                                Week
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="myJam">
                          <span
                            className="modalSpan"
                            style={{ color: 'GrayText' }}
                          >
                            Dependency{' '}
                          </span>
                          <Autocomplete
                            id="company"
                            options={customOptions}
                            defaultValue={
                              p.deliverable_dependency &&
                              dileverable.find(
                                (item) => item.id === p.deliverable_dependency,
                              )
                            }
                            getOptionLabel={(option) =>
                              option.deliverable_name
                                ? option.deliverable_name
                                : ''
                            }
                            onChange={(_, value) => {
                              setDeliverable((prevDeliverables) => {
                                const updatedDeliverables =
                                  prevDeliverables.map((deliverable) => {
                                    if (
                                      Number(deliverable.id) === Number(p.id)
                                    ) {
                                      return {
                                        ...deliverable,
                                        deliverable_dependency: value,
                                      };
                                    }
                                    return deliverable;
                                  });

                                return updatedDeliverables;
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                color="secondary"
                                InputProps={{
                                  ...params.InputProps,
                                  style: { width: 280 },
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}

              <Grid
                item
                xl={formData.type === 'project' ? 6 : 12}
                lg={formData.type === 'project' ? 7.7 : 12}
                md={formData.type === 'project' ? 5.5 : 12}
              >
                <Box
                  sx={{
                    width:
                      formData.type === 'project'
                        ? '100%'
                        : { xl: '93%', lg: '98.5%', md: '98%', sm: '90%' },
                    backgroundColor: 'white',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    margin: formData.type === 'project' ? 0 : '0 auto',
                    minHeight:
                      formData.type === 'project'
                        ? { xl: '170px', lg: '160px', md: '154px' }
                        : '145px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Description
                  </span>
                  <Box
                    sx={{
                      height: '85%',
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}
                  >
                    <RichTextEditor
                      check={check}
                      btn={btn}
                      my_logic={true}
                      open={openLink}
                      setOpen={setOpenLink}
                      bottom={true}
                      html={htmlDesc}
                      setHtml={setHtmlDesc}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12}>
                <Box
                  sx={{
                    width: { xl: '93%', lg: '98.5%', md: '98%', sm: '90%' },
                    backgroundColor: '#fafafa',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    margin: '0 auto',
                    minHeight: '145px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Internal Notes
                  </span>
                  <Box
                    sx={{
                      height: '85%',
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}
                  >
                    {' '}
                    <RichTextEditor
                      check={check}
                      btn={btn}
                      my_logic={true}
                      open={openLink}
                      setOpen={setOpenLink}
                      bottom={true}
                      html={html}
                      setHtml={setHtml}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                width: { xl: '90%', lg: '98%', md: '98%' },
                height: 'auto',
                padding: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setShow(false);
                    setIsTyping(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={() => {
                    setShow(false);
                    UpdateRequest();
                    setIsTyping(false);
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const CreateTemplate = ({ setOpenTemp, openTemp, p }) => {
  const work_types = useSelector((state) => state.Misc.workTypes);

  const newWork =
    work_types &&
    work_types?.filter((item) => item.id === p?.deliverable_worktype[0]?.id);

  const [html, setHtml] = useState('');

  const access_token = useSelector((state) => state.auth.user.access);

  const [jamData, setJamData] = useState({
    deliverable_name: '',
    description: '',
    tags: '',
  });

  const totalHours = p?.deliverable_worktype?.reduce((sum, worktype) => {
    return sum + (worktype.hours || 0);
  }, 0);

  useEffect(() => {
    setJamData({
      ...jamData,
      description: html,
    });
  }, [html]);

  const [localErr, setLocalErr] = useState(false);
  const [localErrMsg, setLocalErrMsg] = useState('');
  const [localSucc, setLocalSucc] = useState(false);
  const myTheme = createTheme({});

  const handleSubmision = async (e) => {
    if (!jamData.deliverable_name) {
      setLocalErr(true);
      setLocalErrMsg('Please Add a Deliverable Name');
      return;
    }

    const res = await fetch(
      `${ENDPOINT}/api/project/deliverable/template/create/`,
      {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: jamData.deliverable_name,
          description: jamData.description,
          duration: totalHours,
          deliverable: [
            {
              ...p,
              deliverable_task: p.task_deliverable.map((item, index) => ({
                ...item,
                name: item.task_name,
                work_types: item.task_worktype.map((wt) => ({
                  ...wt,
                  name: wt.name,
                  hours: wt.hours ? wt.hours : null,
                })),
              })),
            },
          ],
        }),
      },
    );
    const data = await res.json();
    if (!res.ok) {
      setLocalErr(true);
      return;
    }

    setOpenTemp(false);
    setLocalSucc(true);
  };
  const [openLink, setOpenLink] = useState(false);
  const [btn, setBtn] = useState();
  const check = (e) => {
    setBtn(e);
  };

  return (
    <Offcanvas
      show={openTemp}
      onHide={() => setOpenTemp(false)}
      placement="bottom "
    >
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => setOpenTemp(false)} />
      </Offcanvas.Header>
      <Offcanvas.Body sx={{ maxHeight: '400px' }}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '100%',
                fontSize: '1.5rem',
                fontWeight: '600',
                textAlign: 'left',
                padding: {
                  xl: '0.30rem 5.5rem',
                  lg: '0.30rem 4.5rem',
                  md: '0.30rem 3.5rem',
                },
                color: '#000',
              }}
            >
              Add New Delieverable Template
            </Box>
            <Box
              sx={{
                padding: {
                  xl: '0.30rem 5.5rem',
                  lg: '0.30rem 4.5rem',
                  md: '0.30rem 3.5rem',
                },
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',

                  fontSize: '17px',
                }}
              >
                Current Delieverable
              </Typography>

              <Box
                sx={{
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  padding: '.5rem 1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: { xl: '30%', lg: '40%', md: '50%' },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      color: '#000',
                    }}
                  >
                    {p.deliverable_name ? p.deliverable_name : ''}
                    <Typography sx={{ color: '#9c61ff', fontSize: '12px' }}>
                      {Array.isArray(p.task_deliverable) &&
                      p.task_deliverable.length
                        ? p.task_deliverable.length
                        : '0'}{' '}
                      TASKS
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  style={{
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#711FFF',
                    borderRadius: '5px',
                    color: 'var(--white-100)',
                    border: 'none',
                    textAlign: 'center',
                    fontSize: '13px',
                    padding: '0.3rem 1.3rem',
                  }}
                >
                  {newWork ? newWork[0]?.name : ''}
                </Box>
              </Box>
            </Box>
            <Grid
              item
              container
              sx={{
                width: { xl: '93%', lg: '93%', md: '95%' },
                gap: '1rem',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                padding: '2rem 1.5rem',
                margin: '0 auto',
              }}
              justifyContent="center"
              alignItem="center"
              direction="column"
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '20%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#000',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  New Details
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.88rem 1.44rem',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    padding: '18px 24px 18px 24px',
                    borderRadius: '8px',
                    gap: '1rem',
                    boxShadow: '0px 8px 25px 0px #00000000',
                    backgroundColor: '#F1F3F5E5',
                    width: '48%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '0.5rem',
                      width: '100%',
                    }}
                  >
                    <span style={spanSx}>Enter Your New Delieverable Name</span>

                    <TextField
                      placeholder="Delieverable Name"
                      variant="outlined"
                      // className="MUI-P6-M5"
                      // sx={{ width: '100%' }}
                      value={jamData.deliverable_name}
                      onChange={(e) => {
                        setJamData({
                          ...jamData,
                          deliverable_name: e.target.value,
                        });
                      }}
                      color="secondary"
                      sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#dddfe0e5',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'start',

                    gap: '1.5rem',
                    width: '48%',
                    height: '110px',
                  }}
                >
                  <Typography sx={{ fontSize: '17px' }}>
                    Add More details to your Estimate
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xl={12}
              lg={12}
              sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
            >
              <Box
                sx={{
                  width: { xl: '93%', lg: '98.5%', md: '98%', sm: '90%' },
                  backgroundColor: 'white',
                  padding: '0.88rem 1rem',
                  borderRadius: '8px',
                  border: '1px solid #d9d9d9',
                  margin: '0 auto',
                  minHeight: '145px',
                }}
              >
                <span
                  style={{
                    fontWeight: '600',
                    fontFamily: 'Open Sans',
                  }}
                >
                  Description
                </span>
                <Box
                  sx={{
                    height: '85%',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  }}
                >
                  <RichTextEditor
                    check={check}
                    btn={btn}
                    my_logic={true}
                    open={openLink}
                    setOpen={setOpenLink}
                    bottom={true}
                    html={html}
                    setHtml={setHtml}
                  />
                </Box>
              </Box>
            </Grid>
            {localErr && (
              <Alert x={{ width: '93%', margin: '0 auto' }} severity="error">
                {localErrMsg}
              </Alert>
            )}
            {localSucc && (
              <Alert sx={{ width: '93%', margin: '0 auto' }} severity="success">
                Template created Successfully
              </Alert>
            )}

            <Grid item sx={{ width: '97%', height: 'auto', padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button
                  className="cancel-btn"
                  onClick={() => setOpenTemp(false)}
                >
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={() => {
                    handleSubmision();
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const CustomCell = ({
  p,
  setOpen,
  dileverable,
  setDeliverable,
  formData,
  setOpenTemp,
  open,
  setIsDetOpen,
  setIsTyping,
  currentTarget,
  access_token,
  setFormData,
  isTyping,
}) => {
  const [show, setShow] = useState(false);
  const [menuIconEl, setMenuIconEl] = useState(false);
  const [showStuff, setShowStuff] = useState(false);
  useEffect(() => {
    setIsDetOpen(!show);
  }, [show]);

  const [loading, setLoading] = useState(false);
  const disrapancy = p.deliverable_worktype.some((worktype) => {
    return Number(worktype.hours);
  });

  const handleDublicateDeliverable = async (dil) => {
    let titleNum = 1;
    let titleName = `${dil?.deliverable_name} - Duplicate ${titleNum}`;
    while (dil.deliverable_name === titleName) {
      titleNum++;
      titleName = `${dil?.deliverable_name} - Duplicate ${titleNum}`;
    }

    const res = await fetch(
      `${ENDPOINT}/api/project/estimate/deliverable/create/`,
      {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deliverable_estimate: p?.deliverable_estimate,
          deliverable_name: titleName,
          description: p?.description ?? '',
          sort_order: p?.sort_order + 1,
          deliverable_worktype:
            Array.isArray(p.deliverable_worktype) &&
            formData.deliverable.length &&
            p?.deliverable_collaborator === null
              ? p?.deliverable_worktype?.map((i, n) => {
                  return {
                    id: i.id,
                    hours: i.hours,
                  };
                })
              : p?.work_type.map(
                  (i, n) =>
                    i.collab_worktype === null && {
                      id: i.id,
                      hours: i.hours,
                    },
                ),
          deliverable_task:
            Array.isArray(p?.task_deliverable) &&
            p?.task_deliverable.map((task, index) => {
              return {
                name: task.task_name,
                task_worktypes:
                  Array.isArray(task.task_worktype) &&
                  task.task_worktype.map((worktype, index) => {
                    return {
                      id: worktype.id,
                      hours: worktype.hours,
                    };
                  }),
              };
            }),
          deliverable_multiplier: [],
          deliverable_startdate: new Date(formData.est_startdate),
          deliverable_enddate: new Date(formData.est_enddate),
          internal_note: p?.internal_note,
          loe_price: p?.loe_price,
          total_hours: p?.total_hours,
          user_price: p?.user_price,
        }),
      },
    );

    const data = await res.json();
    if (!data) {
      alert('Couldn’t Update');
      return;
    }
    setMenuIconEl(null);

    const resGet = await fetch(
      `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );
    const dataGet = await resGet.json();
    if (!resGet.ok) {
    }

    if (
      dataGet &&
      Array.isArray(dataGet.deliverable) &&
      dataGet.deliverable.length
    ) {
      const sortedDeliverable = dataGet.deliverable
        .map((deliverable) => ({
          ...deliverable,
          deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
        }))
        .sort((a, b) => a.sort_order - b.sort_order);

      setDeliverable(sortedDeliverable);
    }

    return 0;
  };

  const handeUpdateDeliverableTemplete = async (Dil) => {
    const res = await fetch(
      `${ENDPOINT}/api/project/deliverable/template/update/${Dil?.deliverable_template}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          deliver_id: Dil?.id,
          deliverable_name: `${Dil.deliverable_name} - Updated`,
          description: `${Dil.description} - Updated`,
          loe_price: Dil?.loe_price,
          user_price: Dil?.user_price,
          deliverable_startdate: Dil?.deliverable_startdate,
          deliverable_enddate: Dil?.deliverable_startdate,
          change_reason: Dil?.change_reason,
          changed: Dil?.changed,
          internal_note: Dil?.internal_note,
          sort_order: Dil.sort_order,
          deliverable_worktype: Dil?.deliverable_worktype,
          task_deliverable: Dil?.task_deliverable,
        }),
      },
    );

    const response = await res.json();
    if (!response) {
    }
    setIsTyping(false);
    setMenuIconEl(null);
  };

  return (
    <>
      <TableCell
        align="left"
        component="th"
        scope="row"
        onMouseOver={() => setShowStuff(true)}
        onMouseLeave={() => setShowStuff(false)}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '1rem',
            color: '#03071E',
            fontFamily: 'Open Sans',
            fontSize: '1rem',
            fontWeight: '400',
          }}
        >
          <DragIndicatorIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
          />

          <img
            src={subTask}
            alt="taskIcon"
            style={{
              width: '18px',
              height: '18px',
              opacity:
                Array.isArray(p.task_deliverable) && p.task_deliverable.length
                  ? '1'
                  : 0,
              transition: 'all 0.3s ease-in-out',
            }}
          />
          <p
            className="fstxt"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              {
                setShow(true);
                setIsTyping(true);
              }
            }}
          >
            {p.deliverable_name}
          </p>

          <MoreHorizIcon
            sx={{
              cursor: 'pointer',
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
            id="company-icon"
            aria-haspopup="true"
            aria-controls={menuIconEl ? 'company-menu' : undefined}
            aria-expanded={menuIconEl ? 'true' : undefined}
            onClick={(e) => setMenuIconEl(e.currentTarget)}
          />
          <Menu
            id="company-icon-menu"
            sx={{
              width: 'auto',
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
                width: 'auto',
              },
              '& .MuiMenu-list': {
                margin: '5px 5px 5px 5px',
                overflowY: 'hidden',
                maxHeight: '350px',
                width: 'auto',
              },
            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <Tooltip
              title={
                disrapancy
                  ? 'You must zero out all the hours before you can add Tasks'
                  : ''
              }
            >
              <MenuItem
                className="mb-2"
                onClick={() => {
                  if (disrapancy) {
                    return;
                  }
                  setOpen(true);
                  setMenuIconEl(null);
                }}
              >
                <img
                  src={addTask}
                  style={{ width: '22px', height: '24px' }}
                  className="icon-sm"
                />
                Add Tasks
              </MenuItem>
            </Tooltip>
            {p.task_deliverable &&
            Array.isArray(p.task_deliverable) &&
            p.deliverable_collaborator === null &&
            p.task_deliverable.length ? (
              <MenuItem
                className="mb-2"
                onClick={() => {
                  setOpen(open ? false : true);
                  setMenuIconEl(null);
                }}
              >
                <img
                  src={open ? hideTask : showTask}
                  style={{ width: '22px', height: '24px' }}
                  className="icon-sm"
                />{' '}
                {open ? 'Hide Tasks' : 'Show Tasks'}
              </MenuItem>
            ) : null}
            <MenuItem
              className="mb-2"
              onClick={() => {
                setMenuIconEl(null);
                setOpenTemp(true);
              }}
            >
              <img
                src={newTask}
                style={{ width: '22px', height: '24px' }}
                className="icon-sm"
              />
              New Deliverable Template
            </MenuItem>
            {p?.deliverable_template !== null ? (
              <>
                <MenuItem
                  className="mb-2"
                  onClick={() => handeUpdateDeliverableTemplete(p)}
                >
                  <img
                    src={updateTask}
                    style={{ width: '22px', height: '24px' }}
                    className="icon-sm"
                  />
                  Update Deliverable Template
                </MenuItem>
              </>
            ) : null}
            <MenuItem
              className="mb-2"
              onClick={() => handleDublicateDeliverable(p)}
            >
              <img
                src={Duplicate}
                style={{ width: '22px', height: '24px' }}
                className="icon-sm"
              />
              Duplicate
            </MenuItem>
            <MenuItem
              className="text-danger mb-2"
              onClick={async () => {
                const response = await fetch(
                  `${ENDPOINT}/api/project/deliverable/delete/${p?.id}/`,
                  {
                    method: 'DELETE',
                    headers: {
                      Authorization: `JWT ${access_token}`,
                      'Content-Type': 'application/json',
                    },
                  },
                );

                if (response.ok) {
                  setIsTyping(false);
                } else {
                  const data = await response.json();
                }
              }}
            >
              <img
                src={DeleteIcon}
                style={{ width: '22px', height: '24px' }}
                className="icon-sm"
              />
              Delete
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
      {isTyping && (
        <DetailView
          show={show}
          setShow={setShow}
          p={p}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
          formData={formData}
          setIsTyping={setIsTyping}
          currentTarget={currentTarget}
          access_token={access_token}
          setFormData={setFormData}
        />
      )}
    </>
  );
};

const SubTaskCell = ({
  c,
  p,
  i,
  dileverable,
  setDeliverable,
  setIsTyping,
  formData,
  access_token,
}) => (
  <TableCell align="right">
    <div
      id={`${c.id}`}
      style={{
        color: '#711fff',
        fontWeight: '600',
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '0.88rem 1rem',
      }}
    >
      <img
        src={taskIcon}
        style={{ width: '18px', height: '18px' }}
        className="icon-sm"
      />
      <DashedField
        value={c.task_name}
        onChange={(e) => {
          setIsTyping(true);
          setDeliverable((prevDil) => {
            const shallow_copy = prevDil.map((item) => ({ ...item }));
            const index = shallow_copy.findIndex((item) => item.id === p.id);
            shallow_copy[index].task_deliverable[i].task_name = e.target.value;
            return shallow_copy;
          });
        }}
        onBlur={async () => {
          const shallowCopy = {
            ...formData,
          };

          delete shallowCopy.work_type;
          delete shallowCopy.estimate_hardcost;
          const res = await fetch(
            `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
            {
              method: 'PUT',
              headers: {
                Authorization: `JWT ${access_token}`,
                'Content-Type': 'application/json',
              },
              body:
                Array.isArray(formData.collaborator) &&
                formData.collaborator.length
                  ? JSON.stringify({
                      ...shallowCopy,

                      estimate_deliverable: dileverable?.map((item) => {
                        const start = moment();
                        const end = start.add(7, 'days').toISOString();
                        delete item.deliverable_multiplier;

                        return {
                          ...item,

                          deliver_id: item.id,
                          deliverable_worktype: item.deliverable_worktype.map(
                            (wtt) => ({
                              ...wtt,
                              hours: wtt.hours ? Number(wtt.hours) : null,
                            }),
                          ),
                          task_deliverable: item.task_deliverable.map((tsk) => {
                            return {
                              ...tsk,
                              task_id: tsk.id,
                              task_name: tsk.task_name,
                              task_worktype: tsk.task_worktype.map((twt) => ({
                                ...twt,
                                hours: twt.hours ? twt.hours : null,
                              })),
                            };
                          }),
                        };
                      }),

                      collaborator: formData?.collaborator?.map(
                        (item) => item?.id,
                      ),
                    })
                  : JSON.stringify({
                      ...shallowCopy,

                      estimate_deliverable: dileverable?.map((item) => {
                        const start = moment();
                        const end = start.add(7, 'days').toISOString();
                        delete item.deliverable_multiplier;

                        return {
                          ...item,

                          deliver_id: item.id,

                          deliverable_worktype: item.deliverable_worktype.map(
                            (wtt) => ({
                              ...wtt,
                              hours: wtt.hours ? Number(wtt.hours) : null,
                            }),
                          ),
                          task_deliverable: item.task_deliverable.map((tsk) => {
                            return {
                              // ...tsk,
                              task_id: tsk.id,
                              task_name: tsk.task_name,
                              description: tsk.description
                                ? tsk.description
                                : '',
                              task_worktypes: tsk.task_worktype.map((twt) => ({
                                ...twt,
                                worktype_id: twt.id,
                                hours: twt.hours ? twt.hours : null,
                              })),
                            };
                          }),
                        };
                      }),
                    }),
            },
          );
          const data = await res.json();
          if (!res.ok) {
            setError(true);
            setErrorMessage('Couldn’t Update');
            setIsTyping(false);
            return;
          } else {
            setIsTyping(false);
          }
        }}
      />
    </div>
  </TableCell>
);
const AddTask = ({ setDeliverable, p, setOpen, formData, currentTarget }) => {
  const [str, setStr] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  return (
    <Tooltip
      title="Press Enter to Add a New Task"
      arrow
      open={str.length > 2}
      placement="bottom"
    >
      <div
        style={{
          width: 'fit-content',
          padding: '1.44rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <img
          src={taskIcon}
          style={{ width: '18px', height: '18px' }}
          className="icon-sm"
        />
        <DashedField
          label="Add New Task"
          value={str}
          onChange={(e) => {
            setStr(e.target.value);
          }}
          onKeyPress={async (e) => {
            if (e.key === 'Enter' && str) {
              setDeliverable((drev) => {
                const prev = [...drev.map((item) => Object.assign({}, item))];
                const find = prev.find((ele) => ele.id === p.id);
                const int = prev.indexOf(find);
                if (int !== -1) {
                  if ('sub_task' in prev[int] && prev[int].sub_task.length) {
                    prev[int].sub_task = [
                      ...prev[int].sub_task,
                      {
                        id: prev[int].sub_task.length + 1,
                        name: e.target.value,
                        task_worktypes:
                          Array.isArray(formData.deliverable) &&
                          formData.deliverable.length
                            ? formData.deliverable[0].deliverable_worktype.map(
                                (i, n) => {
                                  return {
                                    id: i.id,
                                    hours: '',
                                  };
                                },
                              )
                            : formData.work_type.map((i, n) => {
                                return {
                                  id: i,
                                  hours: '',
                                };
                              }),
                        collaborators:
                          Array.isArray(formData.collaborators) &&
                          formData.collaborators.length
                            ? formData.collaborators
                            : [],
                      },
                    ];
                  } else {
                    prev[int].sub_task = [
                      {
                        id: 1,
                        name: e.target.value,
                        task_worktypes:
                          Array.isArray(formData.deliverable) &&
                          formData.deliverable.length
                            ? formData.deliverable[0].deliverable_worktype.map(
                                (i, n) => {
                                  return {
                                    id: i.id,
                                    hours: '',
                                  };
                                },
                              )
                            : formData.work_type.map((i, n) => {
                                return {
                                  id: i,
                                  hours: '',
                                };
                              }),
                        collaborators:
                          Array.isArray(formData.collaborators) &&
                          formData.collaborators.length
                            ? formData.collaborators
                            : [],
                      },
                    ];
                  }
                } else {
                }
                return prev;
              });
              setStr('');

              const shallowCopy = {
                ...currentTarget,
              };

              delete shallowCopy.work_type;
              const res = await fetch(`${ENDPOINT}/api/project/task/create/`, {
                method: 'POST',
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  task_name: str,
                  budget: 0,
                  title: formData?.title,
                  task_type: 'estimate_task',
                  task_deliverable: p.id,
                  target_start: new Date().toISOString().split('T')[0],
                  task_estimate: formData.id,
                  task_worktypes: p.deliverable_worktype.map((item, ind) => ({
                    id: item.id,
                    hours: '0',
                  })),
                }),
              });

              const data = await res.json();
              if (!res.ok) {
                setError(true);
                setErrorMessage('Couldn’t Update');
                return;
              }
            }
          }}
        />
      </div>
    </Tooltip>
  );
};

function calculateTotalHoursAcumm2(task) {
  if (task && Array.isArray(task.task_worktype) && task.task_worktype.length) {
    return task.task_worktype.reduce((total, worktype) => {
      const net = total + Number(worktype.hours);
      return net;
    }, 0);
  }
  return 0;
}

function NewCalcXaxis(deliverable) {
  return deliverable.deliverable_worktype.reduce((total, hour) => {
    return total + Number(hour?.hours ? hour.hours : 0);
  }, 0);
}

const CustomRow = ({
  provided,
  index,
  p,
  formData,
  setDeliverable,
  dileverable,
  counter,
  setCounter,
  setIsDetOpen,
  handleSeventhStep,
  currentTarget,
  setNum,
  setIsTyping,
  realTarget,
  Np,
  maxLength,
  user,
  setFormData,
  showAllTasks,
  setBudgetLoading,
  allWorktypes,
  isTyping,
}) => {
  let filteredLength = [];

  for (const item of dileverable) {
    if (item.deliverable_collaborator !== null) {
      break;
    }
    filteredLength.push(item);
  }
  const collaboratorWorktypes =
    (realTarget &&
      realTarget?.collaborator_deliverable[index - filteredLength.length]
        ?.deliverable_worktype) ||
    [];
  if (!Np && p.deliverable_collaborator !== null) {
    return;
  }

  const [open, setOpen] = useState(false);
  const [openTemp, setOpenTemp] = useState(false);

  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    setIsDetOpen(!openTemp);
  }, [openTemp]);

  useEffect(() => {
    const prev = dileverable;
    const shallowCopy = [...prev.map((item) => Object.assign({}, item))];
    const index = shallowCopy.findIndex(
      (ele) => Number(ele.id) === Number(p.id),
    );
    const currentElement = shallowCopy[index];
    if (
      currentElement &&
      currentElement.task_deliverable &&
      Array.isArray(currentElement.task_deliverable) &&
      currentElement.task_deliverable.length
    ) {
      const trout = reduceTaskWorktypes(currentElement.task_deliverable);
      if (trout) {
        setDeliverable((zas) => {
          return zas.map((ma) => {
            if (Number(ma.id) === Number(p.id)) {
              ma.deliverable_worktype.forEach((worktype) => {
                const matchingTrout = trout.find(
                  (ele) => worktype.name === ele.name,
                );
                if (matchingTrout) {
                  worktype.hours = matchingTrout.hours;
                }
              });
            }
            return ma;
          });
        });
      }
    }
  }, [counter]);

  const netHours = NewCalcXaxis(p);

  useEffect(() => {
    setDeliverable((prev) => {
      const shallowCopy = [...prev];
      const index = shallowCopy.findIndex((dat) => dat.id === p.id);
      shallowCopy[index].total_loe = Number(netHours);
      return shallowCopy;
    });
  }, [netHours]);

  const calculateLOERate = (deliverable, work) => {
    if (
      !deliverable ||
      !Array.isArray(deliverable.deliverable_worktype) ||
      !deliverable.deliverable_worktype.length ||
      !Array.isArray(work) ||
      !work.length
    ) {
      return 0;
    }

    let total = 0;
    deliverable.deliverable_worktype.forEach((worktype) => {
      const matchingWork = work.find((item) => item.name === worktype.name);

      if (matchingWork) {
        total +=
          Number(matchingWork.user_rate) *
          Number(worktype.hours ? worktype.hours : 0);
      }
    });

    return total;
  };

  function actualCalculate(deliverable) {
    const total = deliverable.map((item) => {
      const subTotal = item.deliverable_worktype.reduce((subAcc, acc) => {
        subAcc += acc.hours ? Number(acc.hours) * Number(acc?.user_rate) : 0;
        return subAcc; // Add this return statement
      }, 0);

      return subTotal; // Add this return statement
    });
    return total.reduce((sub, acc) => {
      return sub + acc;
    }, 0);
  }

  const UpdateRequest = async () => {
    const shallowCopy = {
      ...formData,
    };
    const dileverable_copy = [...dileverable];
    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
                ...shallowCopy,
                actual: actualCalculate(dileverable_copy),

                estimate_deliverable: dileverable_copy?.map((item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.deliverable_multiplier;
                  delete item.task_deliverable;
                  return {
                    ...item,
                    deliverable_startdate: item.deliverable_startdate
                      ? item.deliverable_startdate
                      : moment().toISOString(),
                    deliverable_enddate: item.deliverable_enddate,
                    user_price:
                      item.user_price &&
                      item.user_price === calculateLOERate(item, allWorktypes)
                        ? item.user_price
                        : calculateLOERate(item, allWorktypes),

                    deliver_id: item.id,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),

                collaborator: formData?.collaborator?.map((item) => item?.id),
              })
            : JSON.stringify({
                ...shallowCopy,

                actual: actualCalculate(dileverable_copy),
                estimate_deliverable: dileverable_copy?.map((item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.deliverable_multiplier;
                  delete item.task_deliverable;
                  return {
                    ...item,
                    deliverable_startdate: item.deliverable_startdate
                      ? item.deliverable_startdate
                      : moment().toISOString(),
                    user_price:
                      item.user_price &&
                      item.user_price === calculateLOERate(item, allWorktypes)
                        ? item.user_price
                        : calculateLOERate(item, allWorktypes),
                    deliver_id: item.id,
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
              }),
      },
    );

    const data = await res.json();
    if (!res.ok) {
      setError(true);
      setErrorMessage('Couldn’t Update');
      return;
    }
  };

  return (
    <>
      <TableRow
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          width: '100%',
          height: '100px',
          borderBottom: 'none',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        hover
        key={index}
      >
        <CustomCell
          p={p}
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          dileverable={dileverable}
          formData={formData}
          setOpenTemp={setOpenTemp}
          open={open}
          setIsDetOpen={setIsDetOpen}
          setIsTyping={setIsTyping}
          access_token={access_token}
          currentTarget={currentTarget}
          setFormData={setFormData}
          isTyping={isTyping}
        />
        {Array.isArray(formData?.collaborator) && formData?.collaborator.length
          ? formData?.collaborator?.map((item) => (
              <TableCell align="left"> </TableCell>
            ))
          : null}

        {!Np &&
        p &&
        Array.isArray(p.deliverable_worktype) &&
        p.deliverable_worktype.length
          ? p.deliverable_worktype?.map((i, n) => {
              const Objection =
                Array.isArray(p.task_deliverable) && p.task_deliverable.length
                  ? true
                  : false;
              return (
                <TableCell
                  align="center"
                  key={n}
                  sx={{
                    transition: 'all 0.7s ease-in-out',
                  }}
                >
                  <div
                    className="tcell"
                    style={{ background: Np ? realTarget.color : null }}
                  >
                    {Array.isArray(p.task_deliverable) &&
                    p.task_deliverable.length ? (
                      <p style={{ color: '#000', fontSize: '13px' }}>
                        {i.hours ? i.hours : ''}
                      </p>
                    ) : (
                      <DashedField2
                        value={i.hours}
                        onChange={(e) => {
                          if (!Objection) {
                            setIsTyping(true);
                            setDeliverable((prev) => {
                              const shallowCopy = [...prev];
                              const int = shallowCopy.findIndex(
                                (vil) => vil.id === p.id,
                              );
                              const thisInt = shallowCopy[
                                int
                              ].deliverable_worktype.findIndex(
                                (num) => parseInt(num.id, 10) === i.id,
                              );
                              shallowCopy[int].deliverable_worktype[
                                thisInt
                              ].hours = e.target.value;

                              return shallowCopy;
                            });

                            if (
                              dileverable &&
                              Array.isArray(dileverable) &&
                              dileverable.length
                            ) {
                              const hasAllUserPriceDefined = dileverable.every(
                                (item) => {
                                  return Number(item.user_price) >= 0;
                                },
                              );
                              if (hasAllUserPriceDefined) {
                                setBudgetLoading(true);
                              }
                            }
                          }
                        }}
                        onFocus={() => {
                          setIsTyping(true);
                        }}
                        onBlur={() => {
                          UpdateRequest();
                          setIsTyping(false);
                        }}
                      />
                    )}
                  </div>
                </TableCell>
              );
            })
          : Np &&
              Array.isArray(
                realTarget?.collaborator_deliverable[0]?.deliverable_worktype,
              ) &&
              Number(p.created_by) === Number(user.id)
            ? [
                ...new Set([
                  ...realTarget?.collaborator_deliverable[0]
                    ?.deliverable_worktype,
                  ...p.deliverable_worktype,
                ]),
              ].map((k, no) => {
                return (
                  <Tooltip
                    title={
                      Np
                        ? 'Please close the collabarator section inorder to update the hours'
                        : ''
                    }
                  >
                    <TableCell
                      align="center"
                      key={no + 5646 + Math.random()}
                      sx={{
                        transition: 'all 0.7s ease-in-out',
                      }}
                    >
                      <div
                        className="tcell"
                        style={{
                          background: k.collab_worktype
                            ? realTarget.color
                            : null,
                        }}
                      >
                        {!k.collab_worktype ? (
                          <DashedField2
                            defaultValue="0h"
                            value={k.hours}
                            onChange={(e) => {
                              if (Np) {
                                return;
                              }
                              setDeliverable((prev) => {
                                const shallowCopy = [...prev];
                                const int = shallowCopy.findIndex(
                                  (vil) => vil.id === p.id,
                                );
                                const thisInt = shallowCopy[
                                  int
                                ].deliverable_worktype.findIndex(
                                  (num) => parseInt(num.id, 10) === i.id,
                                );
                                shallowCopy[int].deliverable_worktype[
                                  thisInt
                                ].hours = e.target.value;

                                return shallowCopy;
                              });
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </TableCell>
                  </Tooltip>
                );
              })
            : Np &&
                Array.isArray(
                  realTarget.collaborator_deliverable[0].deliverable_worktype,
                ) &&
                Number(p.created_by) !== Number(user.id)
              ? [
                  ...collaboratorWorktypes,
                  ...dileverable.filter(
                    (dik) => Number(dik.created_by) === Number(user.id),
                  )[0].deliverable_worktype,
                ].map((o, q) => {
                  return o.collab_worktype ? (
                    <TableCell
                      align="center"
                      key={q + 5646 + Math.random()}
                      sx={{
                        transition: 'all 0.7s ease-in-out',
                      }}
                    >
                      <div
                        className="tcell"
                        style={{
                          background: o.collab_worktype
                            ? realTarget.color
                            : null,
                        }}
                      >
                        {true ? <div>{o.hours}</div> : ' '}
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell align="center"> </TableCell>
                  );
                })
              : null}

        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {netHours ? `${netHours}hr` : '0h'}
        </TableCell>
      </TableRow>

      {open ||
      (showAllTasks &&
        p.task_deliverable &&
        Array.isArray(p?.task_deliverable) &&
        p?.task_deliverable.length)
        ? p?.task_deliverable?.map((c, i) => (
            <TableRow style={{ height: '90px', background: '#f8f9fa' }}>
              <SubTaskCell
                p={p}
                c={c}
                i={i}
                setDeliverable={setDeliverable}
                dileverable={dileverable}
                setIsTyping={setIsTyping}
                formData={formData}
                access_token={access_token}
              />
              {Array.isArray(formData.collaborator) &&
              formData?.collaborator.length
                ? formData?.collaborator.map((_) => (
                    <TableCell align="left"> </TableCell>
                  ))
                : null}
              {Np &&
                p.deliverable_collaborator === null &&
                realTarget.collaborator_deliverable[0].deliverable_worktype.map(
                  (item, ind) => {
                    return <TableCell> </TableCell>;
                  },
                )}
              {c.task_worktype.map((i, n) => (
                <TableCell
                  style={{ textAlign: 'center', background: '#f8f9fa' }}
                  key={n}
                >
                  {i.collab_worktype === null ? (
                    <DashedField2
                      value={i.hours}
                      onChange={(e) => {
                        setIsTyping(true);
                        setCounter(counter + 1);
                        setDeliverable((prev) => {
                          const shallowCopy = [
                            ...prev.map((item) => Object.assign({}, item)),
                          ];
                          const FIndex = shallowCopy.findIndex(
                            (ent) => Number(ent.id) === Number(p.id),
                          );
                          const SIndex = shallowCopy[
                            FIndex
                          ].task_deliverable.findIndex(
                            (dent) => Number(dent.id) === Number(c.id),
                          );
                          const FinalIndex = shallowCopy[
                            FIndex
                          ].task_deliverable[SIndex].task_worktype.findIndex(
                            (mle) => Number(mle.id) === Number(i.id),
                          );
                          shallowCopy[FIndex].task_deliverable[
                            SIndex
                          ].task_worktype[FinalIndex].hours = Number(
                            e.target.value,
                          );
                          return shallowCopy;
                        });

                        setBudgetLoading(true);
                      }}
                      onFocus={() => {
                        setIsTyping(true);
                      }}
                      onBlur={async () => {
                        const shallowCopy = {
                          ...formData,
                        };
                        delete shallowCopy.work_type;
                        delete shallowCopy.estimate_hardcost;
                        const res = await fetch(
                          `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
                          {
                            method: 'PUT',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                            body:
                              Array.isArray(formData.collaborator) &&
                              formData.collaborator.length
                                ? JSON.stringify({
                                    ...shallowCopy,
                                    estimate_deliverable: dileverable?.map(
                                      (item) => {
                                        const start = moment();
                                        const end = start
                                          .add(7, 'days')
                                          .toISOString();
                                        delete item.deliverable_multiplier;
                                        return {
                                          ...item,
                                          deliver_id: item.id,

                                          deliverable_worktype:
                                            item.deliverable_worktype.map(
                                              (wtt) => ({
                                                ...wtt,
                                                hours: wtt.hours
                                                  ? Number(wtt.hours)
                                                  : null,
                                              }),
                                            ),
                                          task_deliverable:
                                            item.task_deliverable.map(
                                              (task, ind) => ({
                                                task_id: task.id,
                                                task_name: task.task_name,
                                                description: '',
                                                task_worktypes:
                                                  task.task_worktype.map(
                                                    (worktype) => ({
                                                      id: worktype.id,
                                                      hours: worktype.hours,
                                                    }),
                                                  ),
                                              }),
                                            ),
                                        };
                                      },
                                    ),

                                    collaborator: formData?.collaborator?.map(
                                      (item) => item?.id,
                                    ),
                                  })
                                : JSON.stringify({
                                    ...shallowCopy,
                                    actual: actualCalculate(
                                      dileverable,
                                      allWorktypes,
                                    ),
                                    estimate_deliverable: dileverable?.map(
                                      (item) => {
                                        const start = moment();
                                        const end = start
                                          .add(7, 'days')
                                          .toISOString();
                                        delete item.deliverable_multiplier;
                                        return {
                                          ...item,
                                          user_price:
                                            item.user_price &&
                                            item.user_price ===
                                              calculateLOERate(
                                                item,
                                                allWorktypes,
                                              )
                                              ? item.user_price
                                              : calculateLOERate(
                                                  item,
                                                  allWorktypes,
                                                ),
                                          task_deliverable:
                                            item.task_deliverable.map(
                                              (task, ind) => ({
                                                task_id: task.id,
                                                task_name: task.task_name,
                                                description: '',
                                                task_worktypes:
                                                  task.task_worktype.map(
                                                    (worktype) => ({
                                                      id: worktype.id,
                                                      hours: worktype.hours,
                                                    }),
                                                  ),
                                              }),
                                            ),

                                          deliver_id: item.id,
                                          deliverable_worktype:
                                            item.deliverable_worktype.map(
                                              (wtt) => ({
                                                ...wtt,
                                                hours: wtt.hours
                                                  ? Number(wtt.hours)
                                                  : null,
                                              }),
                                            ),
                                        };
                                      },
                                    ),
                                  }),
                          },
                        );

                        const data = await res.json();
                        if (!res.ok) {
                          setError(true);
                          setErrorMessage('Couldn’t Update');
                          return;
                        }
                        setIsTyping(false);
                      }}
                    />
                  ) : (
                    <div> {i.hours ? i.hours : ''} </div>
                  )}
                </TableCell>
              ))}
              <TableCell align="center">
                <div style={{ width: '100%' }}>
                  {calculateTotalHoursAcumm2(c)}
                </div>
              </TableCell>
            </TableRow>
          ))
        : null}

      {open && p.deliverable_collaborator === null && (
        <AddTask
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          p={p}
          formData={formData}
          currentTarget={currentTarget}
        />
      )}

      <CreateTemplate openTemp={openTemp} setOpenTemp={setOpenTemp} p={p} />
    </>
  );
};

const EstTable = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  setErrorState,
  setIsDetOpen,
  handleSeventhStep,
  currentTarget,
  setNum,
  setIsTyping,
  prevDil,
  setCurrentTarget,
  realTarget,
  setOpen,
  open,
  showAllTasks,
  setShowAllDescription,
  showAllDescription,
  setBudgetLoading,
  access_token,
  allWorktypes,
  isTyping,
}) => {
  const [counter, setCounter] = useState(0);
  const user = useSelector((state) => state.userData.userData);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const shallow_copy = { ...formData };
    const items = Array.from(dileverable);
    const [reorderediItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderediItem);
    const updatedItems = items.map((item, index) => {
      return {
        ...item,
        sort_order: index + 1, // Assuming sort_order starts from 1
      };
    });

    setDeliverable(updatedItems);
    delete shallow_copy.work_type;
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
                ...shallow_copy,
                estimate_deliverable: updatedItems?.map((item, index) => {
                  delete item.deliverable_multiplier;
                  delete item.task_deliverable;
                  return {
                    ...item,
                    deliver_id: item.id,
                    sort_order: item.sort_order,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
                collaborator: formData?.collaborator?.map((item) => item?.id),
              })
            : JSON.stringify({
                ...shallow_copy,
                estimate_deliverable: updatedItems?.map((item, index) => {
                  delete item.deliverable_multiplier;
                  delete item.task_deliverable;
                  return {
                    ...item,
                    deliver_id: item.id,
                    sort_order: item.sort_order,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      }),
                    ),
                  };
                }),
              }),
      },
    );

    const data = await res.json();
    if (!res.ok) {
      return;
    } else {
      const resGet = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (!resGet.ok) {
      }

      setFormData(dataGet);
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable) &&
        dataGet.deliverable.length
      ) {
        const sortedDeliverable = dataGet.deliverable
          .map((deliverable) => ({
            ...deliverable,
            deliverable_worktype: deliverable.deliverable_worktype.sort(
              (a, b) => a.name.localeCompare(b.name),
            ),
          }))
          .sort((a, b) => a.sort_order - b.sort_order);

        setDeliverable(sortedDeliverable);
      }
    }
  };

  function calculateTotalWeeks(deliverableStartDate, deliverableEndDate) {
    const startDate = new Date(deliverableStartDate);
    const endDate = new Date(deliverableEndDate);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to weeks
    const weeksDifference = Math.ceil(
      timeDifference / (1000 * 60 * 60 * 24 * 7),
    );

    return weeksDifference;
  }
  const maxLength = Math.max(
    ...dileverable.map((item) => item.deliverable_worktype.length),
  );

  function deliverableDependencey(id) {
    const depend = id
      ? dileverable.find((item) => item.id === id)
      : { deliverable_name: 'None' };
    return depend.deliverable_name;
  }

  return !showAllDescription ? (
    dileverable.length ? (
      <>
        {formData.type === 'retainer' && (
          <Alert severity="info">
            PLEASE REMEMBER! THESER ARE TOTAL HOURS PER MONTH
          </Alert>
        )}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="rows">
            {(provided) => (
              <TableContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
                component={Paper}
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  maxHeight: '660px',
                  overflow: 'auto',
                }}
              >
                <Table aria-label="simple table" stickyHeader>
                  <THead
                    formData={formData}
                    open={open}
                    setOpen={setOpen}
                    realTarget={realTarget}
                    setCurrentTarget={setCurrentTarget}
                    prevDil={prevDil}
                    setIsTyping={setIsTyping}
                    setDeliverable={setDeliverable}
                    user={user}
                  />
                  <TableBody>
                    {dileverable.map((p, index) => (
                      <Draggable
                        key={p.id}
                        draggableId={p.deliverable_name}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <CustomRow
                            provided={provided}
                            snapshot={snapshot}
                            index={index}
                            p={p}
                            formData={formData}
                            setDeliverable={setDeliverable}
                            dileverable={dileverable}
                            counter={counter}
                            setCounter={setCounter}
                            setIsDetOpen={setIsDetOpen}
                            handleSeventhStep={handleSeventhStep}
                            currentTarget={currentTarget}
                            setNum={setNum}
                            setIsTyping={setIsTyping}
                            realTarget={realTarget}
                            Np={open}
                            maxLength={maxLength}
                            user={user}
                            setFormData={setFormData}
                            showAllTasks={showAllTasks}
                            setBudgetLoading={setBudgetLoading}
                            allWorktypes={allWorktypes}
                            isTyping={isTyping}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <AddRows
                      formData={formData}
                      dileverable={dileverable}
                      setDeliverable={setDeliverable}
                      setErrorState={setErrorState}
                      handleSeventhStep={handleSeventhStep}
                      currentTarget={currentTarget}
                      setNum={setNum}
                      open={open}
                    />
                    {Array.isArray(dileverable) && dileverable.length && (
                      <LastRow
                        formData={formData}
                        dileverable={dileverable}
                        counter={counter}
                        setCounter={setCounter}
                        currentTarget={currentTarget}
                        Np={open}
                        realTarget={realTarget}
                      />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </>
    ) : (
      <Table aria-label="simple table" stickyHeader>
        <THead
          formData={formData}
          open={open}
          setOpen={setOpen}
          realTarget={realTarget}
          setCurrentTarget={setCurrentTarget}
          prevDil={prevDil}
          setDeliverable={setDeliverable}
          setIsTyping={setIsTyping}
          user={user}
        />
        <TableBody>
          <AddRows
            formData={formData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setErrorState={setErrorState}
            handleSeventhStep={handleSeventhStep}
            currentTarget={currentTarget}
            open={open}
          />
        </TableBody>
      </Table>
    )
  ) : (
    <Grid>
      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
          alignItems: 'center',
          height: '50px',
          borderBottom: '0.5px solid #d7d7d7',
          width: '100%',
          padding: '0px 10px',
        }}
      >
        <Typography sx={{ fontSize: '15px' }}> Deliverables </Typography>
      </Box>
      <Box sx={{ padding: '10px 10px' }}>
        {Array.isArray(dileverable) &&
          dileverable.length &&
          dileverable.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  marginTop: '10px',
                  borderBottom: '0.5px solid #d7d7d7',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xl: '16px', lg: '15px', md: '15px' },
                    fontWeight: '600',
                  }}
                >
                  {' '}
                  {item.deliverable_name}
                </Typography>

                <Box sx={{ display: 'flex', gap: '1.2rem' }}>
                  <Typography
                    sx={{ fontSize: { xl: '16px', lg: '15px', md: '15px' } }}
                  >
                    {' '}
                    Duration{' '}
                  </Typography>
                  <input
                    defaultValue={calculateTotalWeeks(
                      item.deliverable_startdate,
                      item.deliverable_enddate,
                    )}
                    type="number"
                    style={{ border: 'none', outline: 'none' }}
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: '1.2rem' }}>
                  <Typography
                    sx={{ fontSize: { xl: '16px', lg: '15px', md: '15px' } }}
                  >
                    {' '}
                    Dependency{' '}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '.3rem',
                      padding: '2px 7px',
                      background: '#aef5e5',
                      borderRadius: '5px',
                      fontSize: '15px',
                    }}
                  >
                    {' '}
                    {deliverableDependencey(item.deliverable_dependency)}{' '}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ width: '48%' }}>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '17px',
                        color: '#000',
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      value={item.description ? item.description : ''}
                      onChange={(e) => {
                        setDeliverable((prev) => {
                          const shallowCopy = [
                            ...prev.map((item) => Object.assign({}, item)),
                          ];

                          shallowCopy[index].description = e.target.value;
                          return shallowCopy;
                        });
                      }}
                      sx={{
                        width: '100%',
                        marginLeft: '-2px',
                        marginTop: '10px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                            padding: '10.6px 16px',
                            paddingLeft: '18px',
                          },
                          '&:hover fieldset': {
                            borderColor: '#fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#fff',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: '#fff',
                          },

                          '&.Mui-disabled fieldset': {
                            borderColor: 'grey',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#000',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#fff',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: '#000',
                        },
                      }}
                      multiline
                      rows={6}
                      columns={8}
                      placeholder="Your Description Here"
                    />
                  </Box>

                  <Box sx={{ width: '48%' }}>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '17px',
                        color: '#000',
                      }}
                    >
                      {' '}
                      Internal Notes
                    </Typography>
                    <TextField
                      value={item.internal_note ? item.internal_note : ''}
                      onChange={(e) => {
                        setDeliverable((prev) => {
                          const shallowCopy = [
                            ...prev.map((item) => Object.assign({}, item)),
                          ];
                          shallowCopy[index].internal_note = e.target.value;
                          return shallowCopy;
                        });
                      }}
                      sx={{
                        width: '100%',
                        marginLeft: '-2px',
                        marginTop: '10px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                            padding: '10.6px 16px',
                          },
                          '&:hover fieldset': {
                            borderColor: '#fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#fff',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: '#fff',
                          },

                          '&.Mui-disabled fieldset': {
                            borderColor: 'grey',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#000',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#fff',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: '#000',
                        },
                      }}
                      multiline
                      rows={6}
                      columns={8}
                      placeholder="Your Internal Notes Here"
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Grid>
  );
};

const ThirdStep = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  setErrorState,
  setIsDetOpen,
  handleSeventhStep,
  setNum,
  setEstimate_termsconditions,
  setEstimate_paymentterms,
  showAllTasks,
  setShowAllDescription,
  showAllDescription,
  setBudgetLoading,
  allWorktypes,
}) => {
  const estimates = useSelector((state) => state.oppertunity.estimates);
  const currentTarget =
    Array.isArray(estimates) && estimates.length
      ? estimates.find((est) => est.title === formData?.title)
      : false;

  const access_token = useSelector((state) => state.auth.user.access);
  const [isTyping, setIsTyping] = useState(false);
  const [open, setOpen] = useState(false);
  const [realTarget, setCurrentTarget] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const data = await res.json();
      if (!res.ok) {
      }

      // return data;
      setFormData(data);

      if (open) {
        const target2 = data?.collaborator.find(
          (ele) =>
            Number(ele.collab_detail) === Number(realTarget?.collab_detail),
        );
        if (target2) {
          setCurrentTarget({ ...target2, color: realTarget?.color });
        }
      }

      if (data && Array.isArray(data.deliverable) && data.deliverable.length) {
        const sortedDeliverable = data.deliverable
          .map((deliverable) => ({
            ...deliverable,
            deliverable_worktype: deliverable.deliverable_worktype.sort(
              (a, b) => a.name.localeCompare(b.name),
            ),
          }))
          .sort((a, b) => a.sort_order - b.sort_order);
        setDeliverable(sortedDeliverable);
        setBudgetLoading(false);
      }
    };

    // Set up the interval
    const intervalId = setInterval(() => {
      if (isTyping === false && showAllDescription === false) {
        fetchData();
      }
    }, 3000);

    // Clean up the interval when the component unmounts or when some condition is met
    return () => {
      clearInterval(intervalId);
    };
  }, [currentTarget, isTyping, showAllDescription]);

  return (
    <Grid item container direction="column">
      {/* <EstHeader /> */}
      <EstTable
        formData={formData}
        setFormData={setFormData}
        steps={steps}
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        setErrorState={setErrorState}
        setIsDetOpen={setIsDetOpen}
        handleSeventhStep={handleSeventhStep}
        prevDil={dileverable}
        currentTarget={currentTarget}
        setNum={setNum}
        setIsTyping={setIsTyping}
        open={open}
        setOpen={setOpen}
        realTarget={realTarget}
        setCurrentTarget={setCurrentTarget}
        showAllTasks={showAllTasks}
        setShowAllDescription={setShowAllDescription}
        showAllDescription={showAllDescription}
        setBudgetLoading={setBudgetLoading}
        access_token={access_token}
        allWorktypes={allWorktypes}
        isTyping={isTyping}
      />
    </Grid>
  );
};

export default ThirdStep;
