// ! 'Quick Tasks' and 'Team' reports are commented out for now. (Marc's request - 21-10-2024).

const initalConfig = [
  {
    title: 'Time Report',
    bg: '#711fff',
    desc: `Time reports lets you know where everyone's effort is going. Filter on jobs, tasks,
    people, etc. Break it all down and export for billing.`,
    param: 'time',
  },
  {
    title: 'Job Report',
    bg: '#310085',
    desc: `Job reports give you a detailed view of all your jobs.
    Filter by Companies, Job statuses, and Jobs, etc. Get your KPIs all break down and export it.`,
    param: 'est',
  },
  //   {
  //     title: 'Quick  Tasks Reports',
  //     bg: '#cbaeff',
  //     desc: `You Easily Get Quick Task Information That Can Be Analyzed And Exported For billing.`,
  //     param: 'tasks',
  //   },
  //   {
  //     title: 'Team Report',
  //     bg: '#8bc7fe',
  //     desc: 'Hows Your Team Doing.. Are They Individually Delivering As Expected This Report Will Help You Analyze That',
  //     param: 'team',
  //   },
  {
    title: 'Sales Report',
    bg: '#6232c9',
    desc: `Nothing Ever Happens Until A Sale Is Made. Take A Look At The Past And Upcoming Sales Performance.`,
    param: 'sales',
  },
];

export default initalConfig;
