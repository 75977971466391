import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './netnetU.css';
import VideoThumbnail from '../../Componenets/VideoThumbnail';
import { appRoutes } from '../../appRoutes';
import { Tooltip } from '@mui/material';

function AllLesson() {
  const course = useSelector((state) => state.NetNetU.courses);
  return (
    <section className="oppor-section">
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">All Courses</h3>
        </div>
      </div>
      <div className="btn-group btn-group-deliverable">
        <Tooltip title="Coming Soon 👀" arrow>
          <Link to={appRoutes.netnetU}>NeNet U</Link>
        </Tooltip>
        <ChevronRightIcon />
        <Link to={appRoutes.allCourses}>All Courses</Link>
      </div>
      <Box sx={{ flexGrow: 1, marginTop: '30px' }}>
        <Grid container gap="2rem">
          {course.map((item) => {
            return (
              <Grid item key={item?.id}>
                <VideoThumbnail
                  thumbnail={item.chapters[0]?.lessons[0]?.thumbnail}
                  subTitle={item.title}
                  title={item.short_description}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </section>
  );
}

export default AllLesson;
