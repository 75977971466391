import React from 'react';
import { Switch, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';

import ListIco from '../../../pages/Projects/assets/ListIco.png';
import kanBanIco from '../../../pages/Projects/assets/KanBanIco.png';
import { SelectDropdown } from '../Dropdowns/SelectDropdown';

function FilterBar({
  setKanBanShow,
  kanBanShow,
  handleChange,
  checked,
  filterDropdownArray,
  hideKanbanIcon,
  hideListIcon,
}) {
  return (
    <Box
      sx={{
        backgroundColor: 'var(--light-primary-color)',
        borderRadius: '8px',
        borderTopRightRadius: '8px',
        height: 'fit-content',
        width: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1.5px solid  #d9d9d9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1rem',
        marginBottom: '0.5rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: { xxl: '80%', xl: '80%', lg: '80%', md: '80%' },
          gap: '1rem',
        }}
      >
        {Array.isArray(filterDropdownArray) && filterDropdownArray.length
          ? filterDropdownArray.map((item, index) => {
              return (
                <SelectDropdown
                  index={index}
                  key={item.labelId}
                  id={item.labelId}
                  value={item.value}
                  selectedFilter={item.selectedFilter}
                  onChange={item.onChange}
                  options={item.options}
                  defaultValue={item.defaultValue}
                />
              );
            })
          : null}

        {kanBanShow && (
          <Box
            sx={{
              fontSize: '16px',
              display: 'flex',
              direction: 'row',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
              sx={{
                color: '#03071E',
                fontSize: '15px',
                fontWeight: 400,
                width: '8rem',
                lineHeight: '19px',
              }}
            >
              {' '}
              Expend All Tasks{' '}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: { xxl: '20%', xl: '20%', lg: '20%', md: '20%' },
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        {hideListIcon ? null : (
          <img
            alt="list-icon"
            src={ListIco}
            onClick={() => setKanBanShow(false)}
            style={{ cursor: 'pointer' }}
          />
        )}
        {hideKanbanIcon ? null : (
          <Tooltip title="Coming Soon 👀" arrow>
            <img
              alt="kanban-icon"
              src={kanBanIco}
              // ! **Showing the kanban** has been commented out as part of the #121 issue (turn off features).
              // onClick={() => setKanBanShow(true)}
              style={{
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
// eslint-disable-next-line
export { FilterBar };
