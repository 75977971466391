import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { HiMiniUserCircle } from 'react-icons/hi2';

import {
  Button,
  Grid,
  Paper,
  Popover,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';

import { Box } from '@mui/system';
import * as XLSX from 'xlsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { CalendarMonthOutlined } from '@mui/icons-material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';

import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { ENDPOINT } from '../Opportunity/Redux/oppor';
import BarCharts from '../../Componenets/Reports/Time/BarChart';
import TimeTableHeader from '../../Componenets/Reports/Time/TimeTableHeader';
import DefaultGroupTableBody from '../../Componenets/Reports/Time/DefaultGroupTableBody';
import WorkTypeGroupTableBody from '../../Componenets/Reports/Time/WorkTypeGroupTableBody';
import TeamMemberGroupTableBody from '../../Componenets/Reports/Time/TeamMemberGroupTableBody';
import { PreLoading } from '../Contacts/People/EditContact';

import SearchableListField from '../../Componenets/Reports/SearchableListField';

function Time() {
  const buttonNames = [
    'This Month',
    'Group By',
    'Billable',
    'Job & Quick Tasks',
    'Export As',
  ];

  const workTypesFromGlobalState = useSelector((state) => state.Misc.workTypes);
  const access_token = useSelector((state) => state.auth.user.access);
  const companiesFromGlobalStateReal = useSelector(
    (state) => state.Contacts.companies,
  );
  const companiesFromGlobalState =
    Array.isArray(companiesFromGlobalStateReal) &&
    companiesFromGlobalStateReal.length
      ? companiesFromGlobalStateReal.map((item) => ({ ...item }))
      : [];
  // * For '<SearchableListField />' component.
  const companiesFromGlobalStateOrderedByName = companiesFromGlobalState.sort(
    (a, b) => a.name.localeCompare(b.name),
  );

  const allTasks = useSelector((state) => state.projects.tasks);

  const [day, setDay] = useState('This Week');
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const userTeam = useSelector((state) => state.userTeam.team.member);
  const [myArr, setMyArr] = useState([]);
  const [groupBy, setGroupBy] = useState(0);

  const [billableName, setBillableName] = useState('Billable & Non-Billable'); // * ------------------------
  const [tasksGroupedByType, setTasksGroupedByType] =
    useState('Job & Quick Tasks');

  const [groupName, setGroupName] = useState('None');

  // * 'Select By Task' state logic.
  // * We will keep it commented for now as we are not using it, but we will use it in future (requested by Marc).
  // const [selectedDiliverables, setSelectedDiliverables] = useState([]);
  // const [taskOfDiliverables, setTaskOfDiliverables] = useState([]);
  // const [Deliverableaccordian, setDeliverableAccordian] = useState('');
  // const [deliverableValue, setDeliverableValue] = useState([
  //   { name: "Admin", discription: "Dillas Tx, Usa", img: "" },
  // ]);
  // const [allDeliverables, setAllDeliverables] = useState();
  // const [filterDeliverablePopop, setFilterDeliverablePopop] = useState(false);

  const [billable, setBillable] = useState(null);

  const [tasksGroupedByTypeSelected, setTasksGroupedByTypeSelected] = useState({
    index: 0,
    type: 'Job & Quick Tasks',
  });

  const [exportAs, setExportAs] = useState(0);
  const [AllProjects, setAllProjects] = useState();

  const [filtering, setFiltering] = useState({
    peopleList: [],
    companyList: [],
    projectList: [],
    taskList: [],
    deliverableList: [],
    workTypeList: [],
  });

  const [Projects, setProjects] = useState(null);
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);

  // useEffect(() => {
  //   const deliverables = myArr.reduce((acc, item) => {
  //     return acc.concat(item?.deliverable_project || []);
  //   }, []);
  //   setAllDeliverables(deliverables);
  // }, [myArr]);

  // * Please, leave this comment here for future reference.
  // ! We need to 'force a re-render' for every action that may change the values of
  // ! 'tasksToBeRendered' and arrays that derive from it to properly update the tables (
  // ! 'DefaultGroupTableBody', 'WorkTypeGroupTableBody', 'TeamMemberGroupTableBody').
  // ! This is because of the poor state management in this component, that with it,
  // ! we are not able to properly update the tables when the values change.
  // ! This is a good solution for now, but must be refactored in the future. <<<<<<<<<<<<<<<<<<<<<<<<<<
  const [keyToForceRerender, setKeyToForceRerender] = useState(0);
  const forceReRenderTable = () => setKeyToForceRerender((key) => key + 1);

  const apiInitialProject = async () => {
    try {
      const response = await fetch(` ${ENDPOINT}/api/project/list/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        const pro = data.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });

        setMyArr(pro);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('api/project/list/ error', error);
    }

    try {
      const response = await fetch(` ${ENDPOINT}/api/project/list/reports/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        const pro = data.sort((a, b) => {
          // Assuming 'created_at' is a property in your project objects
          const dateA = new Date(a?.created_at);
          const dateB = new Date(b?.created_at);
          // Sort in descending order (newest created first)
          return dateB - dateA;
        });

        setProjects(pro);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('api/project/list/reports/ error', error);
    }
  };

  useEffect(() => {
    apiInitialProject();
  }, []);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);

    forceReRenderTable();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);

    forceReRenderTable();
  };

  const handleClickGroupBy = (v, i) => {
    setGroupBy(i);
    setGroupName(v);
    handleClose();

    forceReRenderTable();
  };

  const handleClickBillable = (v) => {
    setBillableName(v);

    if (v === 'Billable') {
      setBillable(true);
    } else if (v === 'Non-Billable') {
      setBillable(false);
    } else if (v === 'Billable & Non-Billable') {
      setBillable(null);
    }
    handleClose();

    forceReRenderTable();
  };

  const handleDateRange = (v) => {
    setDateRange(v);

    forceReRenderTable();
  };

  const handleClickDay = (v) => {
    setDay(v);
    const today = dayjs();

    if (v === 'Today') {
      setDateRange([today.startOf('day'), today.endOf('day')]);
    } else if (v === 'Yesterday') {
      const yesterday = today.subtract(1, 'day');
      setDateRange([yesterday.startOf('day'), yesterday.endOf('day')]);
    } else if (v === 'This Week') {
      setDateRange([today.startOf('week'), today.endOf('week')]);
    } else if (v === 'Last Week') {
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')]);
    } else if (v === 'This Month') {
      setDateRange([today.startOf('month'), today.endOf('month')]);
    } else if (v === 'Last Month') {
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);
    } else if (v === 'This Year') {
      setDateRange([today.startOf('year'), today.endOf('year')]);
    } else if (v === 'Last Year') {
      setDateRange([
        today.startOf('year').subtract(1, 'year'),
        today.endOf('year').subtract(1, 'year'),
      ]);
    }

    forceReRenderTable();
  };

  const handleClickFilterByTaskType = (v, i) => {
    if (v === 'Quick Tasks') {
      setTasksGroupedByTypeSelected({ index: i, type: 'quick_task' });
    } else if (v === 'Job Tasks') {
      setTasksGroupedByTypeSelected({ index: i, type: 'project_task' });
    } else {
      setTasksGroupedByTypeSelected({ index: i, type: 'Job & Quick Tasks' });
    }

    setTasksGroupedByType(v);

    handleClose();
    forceReRenderTable();
  };

  const firstFilteredTasks = allTasks.filter(
    (task) => task.time_tracking.length > 0,
  );

  const filteredTasks = [];

  firstFilteredTasks.forEach((parent) => {
    parent.time_tracking.forEach((timeTrack) => {
      const { time_tracking_start, time_tracking_stop } = timeTrack;
      if (new Date(time_tracking_start) < new Date(time_tracking_stop)) {
        const clonedParent = { ...parent };
        clonedParent.time_tracking = [timeTrack];
        filteredTasks.push(clonedParent);
      }
    });
  });

  const handleFilterByCompany = (value) => {
    setFiltering((prev) => ({
      ...prev,
      companyList: value,
    }));

    forceReRenderTable();
  };

  const handleFilterByProject = (value) => {
    const selectedProjectsIDList = value.map((project) => project.id);

    setFiltering((prev) => ({
      ...prev,
      projectList: selectedProjectsIDList,
    }));

    forceReRenderTable();
  };

  const handleFilterByWorkType = (value) => {
    const selectedWorkTypesList = value.map((workType) => workType.name);

    setFiltering((prev) => ({
      ...prev,
      workTypeList: selectedWorkTypesList,
    }));

    forceReRenderTable();
  };

  const handleFilterByPeople = (value) => {
    const selectedPeopleList = value.map((person) => person.id);

    setFiltering((prev) => ({
      ...prev,
      peopleList: selectedPeopleList,
    }));

    forceReRenderTable();
  };

  // * 'Select By Task' logic.
  // * We will keep it commented for now as we are not using it, but we will use it in future (requested by Marc).
  // const handleDeliverableOpen = (v, i) => {
  //   setSelectedDiliverables([...selectedDiliverables, v]);

  //   const selectedDeliverablesID = v.id;

  //   setFiltering((prevFiltering) => {
  //     return {
  //       ...prevFiltering,
  //       deliverable: [...prevFiltering.deliverable, selectedDeliverablesID],
  //     };
  //   });
  // };

  // const handleAccordianOpen = (v, i) => {
  //   setDeliverableAccordian(v.deliverable_name);
  //   if (Deliverableaccordian === v.deliverable_name) {
  //     setDeliverableAccordian("");
  //   }
  // };

  // * 'Select By Task' logic.
  // * We will keep it commented for now as we are not using it, but we will use it in future (requested by Marc).
  // const handleSelectTask = (item, i) => {
  //   const isTaskSelected = taskOfDiliverables.some(
  //     (task) => task.id === item.id
  //   );

  //   setFiltering((prevFiltering) => {
  //     return {
  //       ...filtering,
  //       tasks: isTaskSelected
  //         ? prevFiltering.tasks.filter((taskId) => taskId !== selectedTaskID)
  //         : [...prevFiltering.tasks, selectedTaskID],
  //     };
  //   });

  //   if (!isTaskSelected) {
  //     setTaskOfDiliverables([...taskOfDiliverables, { ...item, index: i }]);
  //   } else {
  //     setTaskOfDiliverables(
  //       taskOfDiliverables.filter((task) => task.id !== item.id)
  //     );
  //   }

  //   const selectedTaskID = item?.id;
  // };
  //   setSelectedPeople([...selectedPeople, v]);

  //   // * 'Select By Task' logic.
  //   // selectedPeople.map((data) => {
  //   //   if (data.task_name === v?.task_name) {
  //   //     setTaskOfDiliverables([]);
  //   //   }
  //   // });

  //   const selectedPerson = v?.id;
  //   setFiltering((prevFiltering) => {
  //     return {
  //       ...prevFiltering,
  //       people: [...(prevFiltering?.people || []), selectedPerson],
  //     };
  //   });

  //   forceReRenderTable();
  // };

  const filterByCompanies =
    Array.isArray(filtering?.companyList) && filtering?.companyList?.length
      ? filteredTasks.filter((item) =>
          filtering.companyList.some((company) => item?.company === company.id),
        )
      : filteredTasks;

  const filterByProject =
    Array.isArray(filtering?.projectList) && filtering?.projectList.length
      ? filterByCompanies.filter((item) =>
          filtering.projectList.some((ele) => item?.task_project === ele),
        )
      : filterByCompanies;

  const filterByDeliverables =
    Array.isArray(filtering?.deliverableList) &&
    filtering?.deliverableList.length
      ? filterByProject.filter((item) =>
          filtering.deliverableList.some(
            (ele) => item?.task_deliverable === ele,
          ),
        )
      : filterByProject;

  const filterByTask =
    Array.isArray(filtering?.taskList) && filtering?.taskList.length
      ? filterByDeliverables.filter((item) =>
          filtering.taskList.some((ele) => item?.id === ele),
        )
      : filterByDeliverables;

  const filterByWorkType =
    Array.isArray(filtering?.workTypeList) && filtering?.workTypeList.length
      ? filterByTask.filter((item) => {
          const userTrackingTimeWorkType = item.assignee_task.filter(
            (ele) =>
              ele.assignee_user === item.time_tracking[0]?.created_by?.id,
          )[0]?.assignee_work_type?.name; // * string.

          return filtering?.workTypeList.some(
            (workTypeName) => userTrackingTimeWorkType === workTypeName,
          );
        })
      : filterByTask;

  const filterByPerson =
    Array.isArray(filtering?.peopleList) && filtering?.peopleList.length
      ? filterByWorkType.filter((item) =>
          filtering.peopleList.some(
            (ele) => item?.time_tracking[0]?.created_by?.id === ele,
          ),
        )
      : filterByWorkType;

  const Tasks = Array.isArray(filterByPerson) ? filterByPerson : filteredTasks;

  const filteringTask = Tasks?.filter((item) => {
    if (!item.time_tracking || !item.time_tracking[0]) {
      return false;
    }

    const date = item.time_tracking[0].date
      ? item.time_tracking[0].date
      : item.time_tracking[0].time_tracking_start;

    const d = new Date(date);
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);

    if (Number.isNaN(d.getTime())) {
      return false;
    }

    return d >= startDate && d <= endDate;
  });

  const withBillableValue = filteringTask.filter(
    (item) => billable === null || item.billable === billable,
  );

  const tasksFilterByType = withBillableValue.filter((item) => {
    if (
      item.task_type === tasksGroupedByTypeSelected.type ||
      tasksGroupedByTypeSelected.type === 'Job & Quick Tasks'
    ) {
      return true;
    }

    return false;
  });

  // * TASKS THAT WILL BE RENDERED-----------------------------------------------------<<---TASKS THAT WILL BE RENDERED----<<---
  const tasksToBeRendered = tasksFilterByType
    .map((item) => ({
      ...item,
      time_tracking: item?.time_tracking.filter(
        (ele) => ele?.time_tracking_stop,
      ),
    }))
    .sort((a, b) => {
      const aDate = a.time_tracking[0]?.date;
      const bDate = b.time_tracking[0]?.date;

      if (aDate && bDate) {
        // Both dates exist, compare them
        return new Date(aDate) - new Date(bDate);
      }

      if (aDate) {
        // Only a has a date, it comes first
        return -1;
      }

      if (bDate) {
        // Only b has a date, it comes first
        return 1;
      }

      // Both dates are null, compare by created_by
      return a?.time_tracking[0]?.time_tracking_start.localeCompare(
        b?.time_tracking[0]?.time_tracking_start,
      );
    })
    .reverse();

  useEffect(() => {
    // * For '<SearchableListField />' component.
    const allProjectsOrderedByNames = myArr.sort((a, b) =>
      a.title.localeCompare(b.title),
    );

    setAllProjects(allProjectsOrderedByNames);
  }, [myArr]);

  const MergedWorkTypes = [];
  workTypesFromGlobalState?.forEach((item) => {
    const exists = MergedWorkTypes.some(
      (existingWorktype) => existingWorktype.name === item.name,
    );

    if (!exists) {
      MergedWorkTypes.push(item);
    }
  });

  // * For '<SearchableListField />' component.
  const mergedWorkTypesOrderedByName = MergedWorkTypes.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  // * 'Select By Task' logic.
  // * We will keep it commented for now as we are not using it, but we will use it in future (requested by Marc).
  // const handleRemoveDeliverable = () => {
  //   setSelectedDiliverables([]);
  //   setFiltering((prevFiltering) => {
  //     const updatedDeliverables = (prevFiltering.deliverable = []);
  //     const updatedTasks = (prevFiltering.deliverable = []);

  //     return {
  //       ...prevFiltering,
  //       deliverable: updatedDeliverables,
  //       tasks: updatedTasks,
  //     };
  //   });
  // };

  const UpdatedworktypeTasks = MergedWorkTypes.map((worktype) => {
    const tasksWithWorktype = tasksToBeRendered.filter((item) => {
      const userTrackingTimeWorkType = item.assignee_task.filter(
        (task) => task.assignee_user === item.time_tracking[0]?.created_by?.id,
      )[0]?.assignee_work_type?.name; // * string.

      return worktype.name === userTrackingTimeWorkType;
    });

    return {
      ...worktype,
      tasks: tasksWithWorktype,
    };
  });

  const UserTeamFind =
    Array.isArray(userTeam) &&
    userTeam.map((item) => {
      const tasksWithAssignee = tasksToBeRendered.filter((ele) =>
        ele?.time_tracking.some((int) => int?.created_by?.id === item.id),
      );
      return {
        ...item,
        tasks: tasksWithAssignee,
      };
    });

  const Team =
    Array.isArray(UserTeamFind) &&
    UserTeamFind.filter((item) => item.first_name !== 'First Name');

  // * For '<SearchableListField />' component.
  const teamOrderedByLastName = Team.sort((a, b) =>
    a.last_name.localeCompare(b.last_name),
  );

  // * 'Select By Task' logic.
  // * We will keep it commented for now as we are not using it, but we will use it in future (requested by Marc).
  // const handleDeliverablePopopToggle = useCallback(() => {
  //   setFilterDeliverablePopop(!filterDeliverablePopop);
  //   setFilterPeoplePopop(false);
  //   setFilterClientPopop(false);
  //   setFilterWorktypePopop(false);
  //   setFilterProjectPopop(false);
  // }, [filterDeliverablePopop, setFilterDeliverablePopop, setFilterPeoplePopop]);

  const totalHoursAndMinutes = (data) => {
    const totalTimeTracking = [];
    for (let i = 0; i < data.length; i += 1) {
      totalTimeTracking.push(data[i].time_tracking[0]);
    }

    let totalHours = 0;
    let totalMinutes = 0;

    totalTimeTracking?.forEach((entry) => {
      if (entry.tracked_time) {
        const [hours, minutes] = entry.tracked_time.split(':').map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      }
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return { totalHours, totalMinutes };
  };

  // * Total Duration of all tasks to be rendered on top of the Chart (BarCharts) component.
  const totalDurationGroupByNone = totalHoursAndMinutes(tasksToBeRendered);

  const downloadCSV = () => {
    const data =
      tasksToBeRendered.length > 0 &&
      tasksToBeRendered
        .map((task) => {
          const taskType = task?.task_type ?? '';
          const taskProject = task?.task_project ?? '';
          const taskName = task?.task_name ?? '';
          const taskDescription = task?.description
            ? task?.description.replace(/<\/?[^>]+(>|$)/g, '')
            : '';
          const taskWorktype = task?.task_worktype[0]?.name ?? '';

          const taskMember =
            // eslint-disable-next-line
            task?.time_tracking[0]?.created_by?.first_name +
              // eslint-disable-next-line
              task?.time_tracking[0]?.created_by?.last_name ?? '';

          const billable = task?.billable ? 'Yes' : 'No';
          const startDate =
            new Date(task?.time_tracking[0]?.time_tracking_start)
              .toISOString()
              .split('T')[0] ?? '';
          const startTime =
            new Date(task?.time_tracking[0]?.time_tracking_start)
              .toISOString()
              .split('T')[1]
              .split('Z')[0]
              .split(':')
              .slice(0, 2)
              .join(':') ?? '';
          const endDate =
            new Date(task?.time_tracking[0]?.time_tracking_stop)
              .toISOString()
              .split('T')[0] ?? '';
          const endTime =
            new Date(task?.time_tracking[0]?.time_tracking_stop)
              .toISOString()
              .split('T')[1]
              .split('Z')[0]
              .split(':')
              .slice(0, 2)
              .join(':') ?? '';
          const note = task.time_tracking[0]?.note
            ? task.time_tracking[0]?.note.replace(/<\/?[^>]+(>|$)/g, '')
            : '';
          const time = task.time_tracking[0]?.tracked_time ?? '';
          const project_name =
            Array.isArray(Projects) && Projects.length && task?.task_project
              ? Projects.find((item) => item?.id === task?.task_project)?.title
              : '';
          const companys =
            Array.isArray(companiesFromGlobalState) &&
            companiesFromGlobalState?.length &&
            task?.task_project
              ? companiesFromGlobalState.find(
                  (fnd) =>
                    Projects.find((som) => som?.id === task?.task_project)
                      ?.company === fnd?.id,
                )?.name
              : '';

          function timeToDecimal(time) {
            // Split the time into hours, minutes, and seconds
            const [hours, minutes, seconds] = time.split(':').map(Number);

            // Calculate the total hours as a decimal, including minutes and seconds as fractions
            const totalHours = hours + minutes / 60 + seconds / 3600;

            // Format to two decimal places
            return totalHours.toFixed(2);
          }
          const decimalTime = timeToDecimal(time);

          return [
            `${taskType}, ${taskProject}, ${project_name}, ${companys}, ${taskName}, ${taskDescription}, ${note}, ${taskWorktype}, ${taskMember}, ${billable}, ${startDate}, ${startTime}, ${endDate}, ${endTime}, ${time}, ${decimalTime}, , `,
          ];
        })
        .sort((a, b) => {
          if (a.date === 'No Time Tracked') return 1;
          if (b.date === 'No Time Tracked') return -1;
          return new Date(a.date) - new Date(b.date);
        });

    const headers = [
      'Type',
      'Job#',
      'Job Name',
      'company Name',
      'Task Name',
      'Task Description',
      'Time Entry Notes',
      'Work Type',
      'Team Member',
      'Billable',
      'Start Date',
      'Start Time',
      'End Date',
      'End Time',
      'Total Time (H)',
      'Total Time (decimal)',
      'Rate (USD)',
      'Total',
    ];

    const csvContent = [
      headers.join(','),
      ...data.map((e) => e.join(',')),
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Time-Report-CSV-${new Date()}.csv`;
    link.click();
  };

  const downloadXLS = () => {
    const data =
      tasksToBeRendered.length > 0 &&
      tasksToBeRendered
        .map((task) => {
          const taskType = task?.task_type ?? '';
          const taskProject = task?.task_project ?? '';
          const taskName = task?.task_name ?? '';
          const taskDescription = task?.description
            ? task?.description.replace(/<\/?[^>]+(>|$)/g, '')
            : '';
          const taskWorktype = task?.task_worktype[0]?.name ?? '';

          const taskMember =
            // eslint-disable-next-line
            task?.time_tracking[0]?.created_by?.first_name +
              // eslint-disable-next-line
              task?.time_tracking[0]?.created_by?.last_name ?? '';

          const billable = task?.billable ? 'Yes' : 'No';
          const startDate =
            new Date(task?.time_tracking[0]?.time_tracking_start)
              .toISOString()
              .split('T')[0] ?? '';
          const startTime =
            new Date(task?.time_tracking[0]?.time_tracking_start)
              .toISOString()
              .split('T')[1]
              .split('Z')[0]
              .split(':')
              .slice(0, 2)
              .join(':') ?? '';
          const endDate =
            new Date(task?.time_tracking[0]?.time_tracking_stop)
              .toISOString()
              .split('T')[0] ?? '';
          const endTime =
            new Date(task?.time_tracking[0]?.time_tracking_stop)
              .toISOString()
              .split('T')[1]
              .split('Z')[0]
              .split(':')
              .slice(0, 2)
              .join(':') ?? '';
          const note = task.time_tracking[0]?.note
            ? task.time_tracking[0]?.note.replace(/<\/?[^>]+(>|$)/g, '')
            : '';
          const time = task.time_tracking[0]?.tracked_time ?? '';
          const project_name =
            Array.isArray(Projects) && Projects.length && task?.task_project
              ? Projects.find((item) => item?.id === task?.task_project)?.title
              : '';
          const clients =
            Array.isArray(companiesFromGlobalState) &&
            companiesFromGlobalState?.length &&
            task?.task_project
              ? companiesFromGlobalState.find(
                  (fnd) =>
                    Projects.find((som) => som?.id === task?.task_project)
                      ?.company === fnd?.id,
                )?.name
              : '';

          function timeToDecimal(time) {
            // Split the time into hours, minutes, and seconds
            const [hours, minutes, seconds] = time.split(':').map(Number);

            // Calculate the total hours as a decimal, including minutes and seconds as fractions
            const totalHours = hours + minutes / 60 + seconds / 3600;

            // Format to two decimal places
            return totalHours.toFixed(2);
          }
          const decimalTime = timeToDecimal(time);

          return [
            taskType,
            taskProject,
            project_name,
            clients,
            taskName,
            taskDescription,
            note,
            taskWorktype,
            taskMember,
            billable,
            startDate,
            startTime,
            endDate,
            endTime,
            time,
            decimalTime,
            '',
            '',
          ];
        })
        .sort((a, b) => {
          if (a.date === 'No Time Tracked') return 1;
          if (b.date === 'No Time Tracked') return -1;
          return new Date(a.date) - new Date(b.date);
        });

    const headers = [
      'Type',
      'Job#',
      'Job Name',
      'Client Name',
      'Task Name',
      'Task Description',
      'Time Entry Notes',
      'Work Type',
      'Team Member',
      'Billable',
      'Start Date',
      'Start Time',
      'End Date',
      'End Time',
      'Total Time (H)',
      'Total Time (decimal)',
      'Rate (USD)',
      'Total',
    ];

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    saveAs(blob, `Time-Report-XLS-${new Date()}`);
  };

  const stripHtml = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  };

  const printDocument = () => {
    const element = document.getElementById('time-chart');

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // eslint-disable-next-line
      const doc = new jsPDF({
        format: 'letter',
        compress: true,
        unit: 'pt',
      });

      const margin = 72;
      const pageWidth = doc.internal.pageSize.getWidth() - margin * 2;
      const startY = margin;
      let currentY = startY + 100;
      if (groupBy === 0) {
        // Title: Data Overview
        doc.setFontSize(16);
        doc.setTextColor(113, 31, 255); // Set text color to #711FFF
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.text('Data Overview', margin, startY); // Title at (10, startY)

        // Entries text
        doc.setFontSize(9);
        doc.setTextColor(0, 0, 0); // Reset text color to default (black)
        const entriesText = `Entries: ${tasksToBeRendered.length}`;

        // Measure width of the entries text for positioning
        const entriesWidth = doc.getTextWidth(entriesText);

        // Position the entries text at the end of the page width (210 is the width of the page - margin)
        doc.text(entriesText, pageWidth + margin - entriesWidth, startY, {
          align: 'right',
        }); // Right-aligned text

        // Move down for the image (adjust the Y coordinate as needed)
        doc.addImage(imgData, 'PNG', margin, startY + 10, pageWidth, 100); // Adjust 10 to move the image closer or further from the text

        // Move currentY down for the total duration text
        // Start Y for total duration, adjust this value as necessary

        // Set general font size for the document
        doc.setFontSize(9);
        doc.setTextColor(0, 0, 0); // Reset text color to default (black)
        doc.text(
          `Total Duration: ${totalDurationGroupByNone.totalHours}h:${totalDurationGroupByNone.totalMinutes}m`,
          margin,
          currentY + 60,
        );

        currentY += 70; // Move Y down after Total Duration text

        // Function to strip HTML tags
        const taskTableData = tasksToBeRendered.map((task) => {
          const project_name =
            Array.isArray(Projects) && Projects.length && task?.task_project
              ? Projects.find((pro) => pro?.id === task?.task_project)?.title
              : '';

          const userTrackingTimeWorkType = task.assignee_task.filter(
            (item) =>
              item.assignee_user === task.time_tracking[0]?.created_by?.id,
          )[0]?.assignee_work_type?.name;

          const start =
            Array.isArray(task.time_tracking) &&
            task.time_tracking.length &&
            task.time_tracking[task.time_tracking.length - 1]
              .time_tracking_start;
          const end =
            Array.isArray(task.time_tracking) &&
            task.time_tracking.length &&
            task.time_tracking[task.time_tracking.length - 1]
              .time_tracking_stop;

          const startDateTime = new Date(start);
          const endDateTime = new Date(end);

          const timeDifference = endDateTime - startDateTime;
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
          );

          const startTime = new Date(start).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          const endTime = new Date(end).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          // Strip HTML from the task note (if available)
          const plainTextDescription = task.time_tracking[0]?.note
            ? stripHtml(task.time_tracking[0].note)
            : '';

          const companyID =
            Projects?.find((project) => project.company === task?.company)
              ?.company ||
            task?.company ||
            '';

          const companyName =
            companiesFromGlobalState?.find(
              (company) => company.id === companyID,
            )?.name ?? '----------';

          return [
            `${task?.task_name}\n${project_name ? `-${project_name}` : ''}`,
            companyName,
            plainTextDescription,
            `${task?.time_tracking[0]?.created_by?.first_name ?? ''} ${task?.time_tracking[0]?.created_by?.last_name ?? ''}`,
            userTrackingTimeWorkType,
            `${timeDifference === 0 ? '' : startTime} - ${timeDifference === 0 ? '' : endTime}\n ${
              moment(task?.time_tracking[0]?.date || end).format(
                'MMMM DD, yy',
              ) !== 'January 01, 1970'
                ? moment(task?.time_tracking[0]?.date ?? end).format(
                    'MMMM DD, yy',
                  )
                : 'No Time Tracked'
            }`, // Start & End Time
            `${hours}h:${minutes}m`, // Duration
          ];
        });

        // Generate table with reduced font size
        doc.autoTable({
          head: [
            [
              'Task Name',
              'Company',
              'Description',
              'Team Member',
              'Work type',
              'Time',
              'Duration',
            ],
          ],
          body: taskTableData,
          startY: currentY + 10,
          theme: 'striped',
          styles: { fontSize: 7 },
          margin: { left: margin, right: margin },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 'auto' },
            2: { cellWidth: 60 },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
            5: { cellWidth: 'auto' },
            6: { cellWidth: 40 },
          },
          tableLineWidth: 0,
          headStyles: {
            fillColor: [113, 31, 255],
            textColor: [255, 255, 255],
            fontStyle: 'bold',
          },
        });
        currentY = doc.previousAutoTable.finalY + 20;
      }

      const pageHeight = doc.internal.pageSize.height;
      if (currentY > pageHeight - 40) {
        doc.addPage();
        currentY = 40;
      }

      if (groupBy === 1 && Team?.length > 0) {
        doc.setFontSize(12);
        doc.setTextColor(113, 31, 255);
        doc.setFont('helvetica', 'bold');
        doc.text('Data Overview', margin, startY);
        doc.addImage(imgData, 'PNG', margin, startY + 10, pageWidth, 100);
        doc.setFontSize(11);
        doc.text('Team Members', margin, currentY + 30);

        Team.forEach((member) => {
          const totalDuration = totalHoursAndMinutes(member.tasks);
          currentY += 50;

          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0);

          doc.setFont('helvetica', 'bold');
          const memberText = `${member.first_name} ${member?.last_name} - ${member.tasks.length} Entries`;
          const totalDurationText = `Total Duration: ${totalDuration.totalHours}h:${totalDuration.totalMinutes}m`;

          const totalDurationWidth = doc.getTextWidth(totalDurationText);
          const pageWidth = doc.internal.pageSize.getWidth();
          const positionXForDuration = pageWidth - totalDurationWidth - margin;

          doc.text(memberText, margin, currentY);

          doc.text(totalDurationText, positionXForDuration, currentY);

          const memberTaskData = member.tasks.map((task) => {
            const project_name =
              Array.isArray(Projects) && Projects.length && task?.task_project
                ? Projects.find((pro) => pro?.id === task?.task_project)?.title
                : '';

            const userTrackingTimeWorkType = task.assignee_task.filter(
              (item) =>
                item.assignee_user === task.time_tracking[0]?.created_by?.id,
            )[0]?.assignee_work_type?.name;

            const start =
              Array.isArray(task.time_tracking) &&
              task.time_tracking.length &&
              task.time_tracking[task.time_tracking.length - 1]
                .time_tracking_start;
            const end =
              Array.isArray(task.time_tracking) &&
              task.time_tracking.length &&
              task.time_tracking[task.time_tracking.length - 1]
                .time_tracking_stop;

            const startDateTime = new Date(start);
            const endDateTime = new Date(end);

            const timeDifference = endDateTime - startDateTime;
            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const minutes = Math.floor(
              (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
            );

            const startTime = new Date(start).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            });

            const endTime = new Date(end).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            });

            const plainTextDescription = task.time_tracking[0]?.note
              ? stripHtml(task.time_tracking[0].note)
              : '';

            const companyID =
              Projects?.find((project) => project.company === task?.company)
                ?.company ||
              task?.company ||
              '';

            const companyName =
              companiesFromGlobalState?.find(
                (company) => company.id === companyID,
              )?.name ?? '----------';

            return [
              `${task?.task_name}\n${project_name ? `-${project_name}` : ''}`,
              companyName,
              plainTextDescription,
              userTrackingTimeWorkType,
              `${timeDifference === 0 ? '' : startTime} - ${timeDifference === 0 ? '' : endTime}\n ${
                moment(task?.time_tracking[0]?.date || end).format(
                  'MMMM DD, yy',
                ) !== 'January 01, 1970'
                  ? moment(task?.time_tracking[0]?.date ?? end).format(
                      'MMMM DD, yy',
                    )
                  : 'No Time Tracked'
              }`,
              `${hours}h:${minutes}m`,
            ];
          });

          doc.autoTable({
            head: [
              [
                'Task Name',
                'Company',
                'Description',
                'Team Member',
                'Work type',
                'Time',
                'Duration',
              ],
            ],
            body: memberTaskData,
            startY: currentY + 10,
            theme: 'striped',
            styles: { fontSize: 7 },
            margin: { left: margin, right: margin },
            columnStyles: {
              0: { cellWidth: 'auto' },
              1: { cellWidth: 'auto' },
              2: { cellWidth: 60 },
              3: { cellWidth: 'auto' },
              4: { cellWidth: 'auto' },
              5: { cellWidth: 'auto' },
              6: { cellWidth: 20 },
            },
            tableLineWidth: 0,
            headStyles: {
              fillColor: [113, 31, 255],
              textColor: [255, 255, 255],
              fontStyle: 'bold',
            },
          });

          currentY = doc.previousAutoTable.finalY + 10;
        });
      }

      if (currentY > pageHeight - 40) {
        doc.addPage();
        currentY = 40;
      }

      if (groupBy === 2 && UpdatedworktypeTasks?.length > 0) {
        doc.setFontSize(12);
        doc.setTextColor(113, 31, 255);
        doc.setFont('helvetica', 'bold');
        doc.text('Data Overview', margin, startY);
        doc.addImage(imgData, 'PNG', margin, startY + 10, pageWidth, 100);
        doc.setFontSize(11);
        doc.text('Worktypes', margin, currentY + 30);

        UpdatedworktypeTasks.forEach((worktype) => {
          const totalDuration = totalHoursAndMinutes(worktype.tasks);
          currentY += 45;

          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0);

          doc.setFont('helvetica', 'bold');
          const workTypeText = `${worktype.name} - ${worktype.tasks.length} Entries`;
          const totalDurationText = `Total Duration: ${totalDuration.totalHours}h:${totalDuration.totalMinutes}m`;

          const totalDurationWidth = doc.getTextWidth(totalDurationText);
          const pageWidth = doc.internal.pageSize.getWidth();
          const positionXForDuration = pageWidth - totalDurationWidth - margin;

          doc.text(workTypeText, margin, currentY);

          doc.text(totalDurationText, positionXForDuration, currentY);

          const worktypeTaskData = worktype.tasks.map((task) => {
            const project_name =
              Array.isArray(Projects) && Projects.length && task?.task_project
                ? Projects.find((pro) => pro?.id === task?.task_project)?.title
                : '';

            const start =
              Array.isArray(task.time_tracking) &&
              task.time_tracking.length &&
              task.time_tracking[task.time_tracking.length - 1]
                .time_tracking_start;
            const end =
              Array.isArray(task.time_tracking) &&
              task.time_tracking.length &&
              task.time_tracking[task.time_tracking.length - 1]
                .time_tracking_stop;

            const startDateTime = new Date(start);
            const endDateTime = new Date(end);

            const timeDifference = endDateTime - startDateTime;
            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const minutes = Math.floor(
              (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
            );

            const startTime = new Date(start).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            });

            const endTime = new Date(end).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            });

            const plainTextDescription = task.time_tracking[0]?.note
              ? stripHtml(task.time_tracking[0].note)
              : 'No description available';

            const companyID =
              Projects?.find((project) => project.company === task?.company)
                ?.company ||
              task?.company ||
              '';

            const companyName =
              companiesFromGlobalState?.find(
                (company) => company.id === companyID,
              )?.name ?? '----------';

            return [
              `${task?.task_name}\n${project_name ? `-${project_name}` : ''}`,
              companyName,
              plainTextDescription,
              `${task?.time_tracking[0]?.created_by?.first_name ?? ''} ${task?.time_tracking[0]?.created_by?.last_name ?? ''}`,
              `${timeDifference === 0 ? '' : startTime} - ${timeDifference === 0 ? '' : endTime}\n ${
                moment(task?.time_tracking[0]?.date || end).format(
                  'MMMM DD, yy',
                ) !== 'January 01, 1970'
                  ? moment(task?.time_tracking[0]?.date ?? end).format(
                      'MMMM DD, yy',
                    )
                  : 'No Time Tracked'
              }`,
              `${hours}h:${minutes}m`,
            ];
          });

          doc.autoTable({
            head: [
              [
                'Task Name',
                'Company',
                'Description',
                'Team Member',
                'Time',
                'Duration',
              ],
            ],
            body: worktypeTaskData,
            startY: currentY + 10,
            theme: 'striped',
            styles: { fontSize: 7 },
            margin: { left: margin, right: margin },
            columnStyles: {
              0: { cellWidth: 'auto' },
              1: { cellWidth: 'auto' },
              2: { cellWidth: 60 },
              3: { cellWidth: 'auto' },
              4: { cellWidth: 'auto' },
              5: { cellWidth: 'auto' },
              6: { cellWidth: 20 },
            },
            tableLineWidth: 0,
            headStyles: {
              fillColor: [113, 31, 255],
              textColor: [255, 255, 255],
              fontStyle: 'bold',
            },
          });

          currentY = doc.previousAutoTable.finalY + 10;
        });
      }
      doc.save(`Time-Report-${new Date()}.pdf`);
    });
  };

  const handleClickExportAs = (v, i) => {
    if (v === 'PDF') {
      printDocument();
    }

    if (v === 'CSV') {
      downloadCSV();
    }

    if (v === 'XLS') {
      downloadXLS();
    }

    setExportAs(i);
    handleClose();
  };

  return Array.isArray(allTasks) && allTasks?.length > 0 ? (
    <>
      <Grid
        sx={{
          backgroundColor: 'inherit',
          padding: '1rem 0rem',
          borderBottom: '1px solid #e2e2e2',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: '2.9rem',
            width: '95%',
            backgroundColor: 'var(--light-primary-color)',
            padding: '1rem 1rem',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >
          <div>
            {buttonNames.map((name, index) => (
              <>
                <Button
                  variant="contained"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  startIcon={index === 0 && <CalendarMonthOutlined />}
                  endIcon={
                    <ExpandMoreSharpIcon
                      style={{
                        width: '18px',
                        height: '18px',
                        fontWeight: 200,
                        color: '#03071E',
                      }}
                    />
                  }
                  style={{
                    marginRight: 10,
                    backgroundColor: '#fff',
                    color: 'black',
                    textTransform: 'inherit',
                    boxShadow: 'inherit',
                    fontWeight: 400,
                    fontSize: '14px',
                    '&:hover': {
                      backgroundColor: '#E0E0DF',
                      color: 'black',
                      boxShadow: 'inherit',
                    },
                  }}
                >
                  {index === 1
                    ? // eslint-disable-next-line
                      name + ' ' + (groupName === 'None' ? '' : groupName)
                    : index === 0
                      ? day
                      : index === 2
                        ? billableName
                        : index === 3
                          ? tasksGroupedByType
                          : name}
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'inherit',
                      border: '1px solid #E0E0DF',
                      borderRadius: 2,
                    },
                  }}
                >
                  {index === 0 && (
                    <Box
                      align="center"
                      sx={{
                        width: 'auto',
                        height: 'auto',
                        px: '30px',
                        display: { xs: 'block', md: 'flex' },
                      }}
                    >
                      <Box
                        align="left"
                        sx={{
                          backgroundColor: '#F8F9FA',
                          width: { xs: '100%', md: '200px' },
                          p: 1,
                          pb: 3,
                          my: 4,
                          borderRadius: 2,
                        }}
                      >
                        {[
                          'Today',
                          'Yesterday',
                          'This Week',
                          'Last Week',
                          'This Month',
                          'Last Month',
                          'This Year',
                          'Last Year',
                        ].map((v, i) => {
                          return (
                            <Typography
                              key={name || index}
                              onClick={() => handleClickDay(v, i)}
                              sx={
                                day === v
                                  ? {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#711FFF',
                                      my: 3,
                                      ml: 2,
                                    }
                                  : {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#03071E',
                                      my: 3,
                                      ml: 2,
                                    }
                              }
                            >
                              {v}
                            </Typography>
                          );
                        })}
                      </Box>
                      <Box sx={{ mx: 4, my: 4 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateRangeCalendar
                            value={dateRange}
                            onChange={(newValue) => handleDateRange(newValue)}
                            sx={{
                              '& .css-10wpov9-MuiTypography-root ': {
                                fontWeight: '700 !important',
                              },
                              '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval':
                                {
                                  color: '#03071E !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085 !important',
                                color: 'white !important',
                                borderRadius: '0px !important',
                              },
                              '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: 'white !important',
                                  opacity: 'inherit !important',
                                  borderRadius: '0px',
                                  border: 'none !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: '#e569db !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)':
                                {
                                  borderRight: 'none !important',
                                },
                              '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                flexDirection: 'column !important',
                              },

                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  color: 'inherit',
                                  fontSize: '16px !important',
                                  opacity: 'inherit',
                                  fontWeight: 500,
                                },
                              '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: 'inherit',
                                  color: 'black !important',
                                  borderRadius: '50%',
                                  border: '1px solid gray',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .MuiTypography-subtitle1': {
                                color: ' #03071E !important',
                                fontSize: '16px',
                                fontWeight: 700,
                              },
                              '& .MuiDayCalendar-weekDayLabel': {
                                color: ' #03071ECC !important',
                                fontSize: '16px',
                                fontWeight: 400,
                              },
                              '& .MuiSvgIcon-fontSizeInherit': {
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '50%',
                              },
                              '& .MuiPickersDay-today': {
                                backgroundColor: '#711FFF',
                                color: 'white',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .MuiDateRangePickerDay-notSelectedDate': {},
                            }}
                          />
                        </LocalizationProvider>

                        <Box sx={{ display: 'flex', my: 4 }}>
                          <Box sx={{ display: 'flex', mr: 4 }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#711FFF',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Start Date and End Date
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#310085',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Date Range
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        align=""
                        my={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: '',
                        }}
                      >
                        <Box align="left" mt={4}>
                          <Box align="left">
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              Start Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {dateRange[0] &&
                                dateRange[0].format().slice(0, 10)}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              End Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {dateRange[1] &&
                                dateRange[1].format().slice(0, 10)}
                            </Typography>
                          </Box>
                        </Box>

                        <Box align="right">
                          <Button
                            variant="text"
                            sx={{
                              textDecoration: 'inherit',
                              color: 'black',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                              textDecoration: 'inherit',
                              backgroundColor: '#711FFF',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                              '&:hover': {
                                backgroundColor: '#711FFF',
                              },
                            }}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {index === 1 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {['None', 'Team Members', 'Work Type'].map((v, i) => {
                        return (
                          <Typography
                            key={v.id || i}
                            fullWidth
                            onClick={() => handleClickGroupBy(v, i)}
                            sx={
                              groupBy === i
                                ? {
                                    color: 'white',
                                    backgroundColor: '#711FFF',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    '&:hover': {
                                      backgroundColor: '#E0E0DF',
                                      cursor: 'pointer',
                                    },
                                  }
                                : {
                                    color: 'black',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    '&:hover': {
                                      backgroundColor: '#E0E0DF',
                                      cursor: 'pointer',
                                    },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {index === 2 && (
                    <Box sx={{ pb: 2, width: '200px', textAlign: 'left' }}>
                      {[
                        'Billable',
                        'Non-Billable',
                        'Billable & Non-Billable',
                      ].map((v, i) => {
                        return (
                          <Typography
                            key={v.id || i}
                            fullWidth
                            onClick={() => handleClickBillable(v, i)}
                            sx={
                              billable === i
                                ? {
                                    cursor: 'pointer',
                                    color: 'white',
                                    backgroundColor: '#711FFF',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    '&:hover': { backgroundColor: '#E0E0DF' },
                                  }
                                : {
                                    cursor: 'pointer',
                                    color: 'black',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    '&:hover': { backgroundColor: '#E0E0DF' },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}

                  {index === 3 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {['Job Tasks', 'Quick Tasks', 'Job & Quick Tasks'].map(
                        (v, i) => {
                          return (
                            <Typography
                              key={v.id || i}
                              fullWidth
                              onClick={() => handleClickFilterByTaskType(v, i)}
                              sx={
                                tasksGroupedByTypeSelected.index === i
                                  ? {
                                      color: 'white',
                                      backgroundColor: '#711FFF',
                                      borderRadius: 1,
                                      textTransform: 'inherit',
                                      fontSize: '14px',
                                      m: 2,
                                      px: 1,
                                      cursor: 'pointer',
                                      '&:hover': {
                                        backgroundColor: '#E0E0DF',
                                      },
                                    }
                                  : {
                                      color: 'black',
                                      borderRadius: 1,
                                      textTransform: 'inherit',
                                      fontSize: '14px',
                                      m: 2,
                                      px: 1,
                                      cursor: 'pointer',
                                      '&:hover': {
                                        backgroundColor: '#E0E0DF',
                                      },
                                    }
                              }
                            >
                              {v}
                            </Typography>
                          );
                        },
                      )}
                    </Box>
                  )}

                  {index === 4 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {['PDF', 'XLS', 'CSV'].map((v, i) => {
                        return (
                          <Typography
                            key={v.id || i}
                            fullWidth
                            onClick={() => handleClickExportAs(v, i)}
                            sx={
                              exportAs === i
                                ? {
                                    color: 'white',
                                    backgroundColor: '#711FFF',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    cursor: 'pointer',
                                    '&:hover': { backgroundColor: '#E0E0DF' },
                                  }
                                : {
                                    color: 'black',
                                    borderRadius: 1,
                                    textTransform: 'inherit',
                                    fontSize: '14px',
                                    m: 2,
                                    px: 1,
                                    cursor: 'pointer',
                                    '&:hover': { backgroundColor: '#E0E0DF' },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                </Popover>
              </>
            ))}
          </div>
        </Box>

        <Box sx={{ mx: 3, mb: 1, display: 'flex', flexWrap: 'wrap' }}>
          <SearchableListField
            searchableListFieldID="timeReport-FilterByCompany"
            label="Filter By Company"
            listOfOptions={companiesFromGlobalStateOrderedByName}
            propertiesToDisplay={['name']}
            onChangeFunction={(value) => handleFilterByCompany(value)}
          />

          <SearchableListField
            searchableListFieldID="timeReport-FilterByProject"
            label="Filter By Job"
            listOfOptions={AllProjects}
            propertiesToDisplay={['title']}
            onChangeFunction={(value) => handleFilterByProject(value)}
          />

          <SearchableListField
            searchableListFieldID="timeReport-FilterByWorkType"
            label="Filter By WorkType"
            listOfOptions={mergedWorkTypesOrderedByName}
            propertiesToDisplay={['name']}
            onChangeFunction={(value) => handleFilterByWorkType(value)}
          />

          <SearchableListField
            searchableListFieldID="timeReport-filterByPeople"
            label="Filter By People"
            listOfOptions={teamOrderedByLastName}
            propertiesToDisplay={['first_name', 'last_name']}
            onChangeFunction={(value) => handleFilterByPeople(value)}
          />
        </Box>
      </Grid>

      <div id="div-to-print">
        <Box
          sx={{
            p: '0.14rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            m: '1rem 0',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                alignSelf: 'center',
                color: '#310085',
                fontWeight: '600',
                fontSize: '1rem',
                textTransform: 'uppercase',
                m: '0 1rem',
              }}
            >
              Data Overview
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  background: '#F1F3F5',
                  borderRadius: '10px',
                  padding: '8px 19px',
                }}
              >
                <p
                  style={{
                    background: '#d2b8ff',
                    borderRadius: '50%',
                    padding: '3px 5px',
                    marginRight: '6px',
                    fontSize: '14px',
                    color: '#310085',
                    fontWeight: 600,
                  }}
                >
                  {' '}
                  {tasksToBeRendered.length}{' '}
                </p>
                <Typography
                  sx={{
                    fontSize: '13px',
                  }}
                >
                  Entries
                </Typography>
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  background: '#F1F3F5',
                  borderRadius: '10px',
                  padding: '8px 19px',
                  marginLeft: '14px',
                }}
              >
                <p
                  style={{
                    padding: '3px 5px',
                    marginRight: '6px',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  Total Duration{' '}
                </p>
                <Typography
                  sx={{
                    background: '#D2B8FF',
                    color: '#310085',
                    fontSize: '13px',
                    borderRadius: '6px',
                    fontWeight: 600,
                    padding: '2px 10px',
                  }}
                >
                  {`${totalDurationGroupByNone.totalHours}h:${totalDurationGroupByNone.totalMinutes}m`}
                </Typography>
              </Box>
            </Box>
          </Box>

          <BarCharts apiData={tasksToBeRendered} />

          <div id="div-to-csv">
            {groupBy === 0 ? (
              <>
                {[''].map((i) => {
                  return (
                    <Box key={i + 1}>
                      <TableContainer
                        component={Paper}
                        sx={{
                          width: '100%',
                          borderRadius: '8px',
                          m: '1rem 0',
                        }}
                      >
                        <Table>
                          <TimeTableHeader name={true} />
                          <DefaultGroupTableBody
                            key={keyToForceRerender}
                            tasksToBeRendered={tasksToBeRendered}
                            Projects={Projects}
                          />
                        </Table>
                      </TableContainer>
                    </Box>
                  );
                })}
              </>
            ) : (
              ''
            )}
            {groupBy === 1 ? (
              <>
                <Typography sx={{ fontSize: '17px', fontWeight: 900, p: 1 }}>
                  Team Members{' '}
                </Typography>

                {Team?.length > 0 &&
                  Team?.map((item, i) => {
                    const totalDuration = totalHoursAndMinutes(item?.tasks);
                    return (
                      <Box
                        key={item.id || i}
                        sx={{
                          backgroundColor: '#F8F9FA',
                          p: 1,
                          borderRadius: 2,
                          mb: 10,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            direction: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            my: 1.5,
                            mx: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <HiMiniUserCircle style={{ fontSize: '24px' }} />
                            <Typography sx={{ fontSize: '16px', ml: 1 }}>
                              {`${item?.first_name} ${item?.last_name}`}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              direction: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                            }}
                          >
                            <Box
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                background: '#F1F3F5',
                                borderRadius: '10px',
                                padding: '8px 19px',
                              }}
                            >
                              <p
                                style={{
                                  background: '#d2b8ff',
                                  color: '#310085',
                                  borderRadius: '50%',
                                  padding: '3px 5px',
                                  marginRight: '6px',
                                  fontSize: '12px',
                                  fontWeight: 600,
                                }}
                              >
                                {' '}
                                {item.tasks.length}{' '}
                              </p>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                }}
                              >
                                Entries
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                background: '#F1F3F5',
                                borderRadius: '10px',
                                padding: '8px 19px',
                                marginLeft: '14px',
                              }}
                            >
                              <p
                                style={{
                                  padding: '3px 5px',
                                  marginRight: '6px',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                }}
                              >
                                {' '}
                                Total Duration{' '}
                              </p>
                              <Typography
                                sx={{
                                  background: '#D2B8FF',
                                  color: '#310085',
                                  fontSize: '13px',
                                  borderRadius: '6px',
                                  fontWeight: 600,
                                  padding: '2px 10px',
                                }}
                              >
                                {`${totalDuration.totalHours}h:${totalDuration.totalMinutes}m`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <TableContainer
                          component={Paper}
                          sx={{
                            width: '100%',
                            borderRadius: '8px',
                            m: '1rem 0',
                          }}
                        >
                          <Table>
                            <TimeTableHeader />
                            <TeamMemberGroupTableBody
                              key={keyToForceRerender}
                              item={item}
                              Projects={Projects}
                            />
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  })}
              </>
            ) : (
              ''
            )}
            {groupBy === 2 ? (
              <>
                <Typography sx={{ fontSize: '17px', fontWeight: 900, p: 1 }}>
                  WorkTypes{' '}
                </Typography>

                {UpdatedworktypeTasks?.length > 0 &&
                  UpdatedworktypeTasks?.map((item, i) => {
                    const totalDuration = totalHoursAndMinutes(item.tasks);

                    return (
                      <Box
                        key={item.id || i}
                        sx={{
                          backgroundColor: '#F8F9FA',
                          p: 1,
                          borderRadius: 2,
                          mb: 10,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            direction: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            my: 1.5,
                            mx: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <HiMiniUserCircle style={{ fontSize: '24px' }} />
                            <Typography sx={{ fontSize: '16px', ml: 1 }}>
                              {item?.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              direction: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                            }}
                          >
                            <Box
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                background: '#F1F3F5',
                                borderRadius: '10px',
                                padding: '8px 19px',
                              }}
                            >
                              <p
                                style={{
                                  background: '#d2b8ff',
                                  color: '#310085',
                                  borderRadius: '50%',
                                  padding: '3px 5px',
                                  marginRight: '6px',
                                  fontSize: '12px',
                                  fontWeight: 600,
                                }}
                              >
                                {item.tasks.length}
                              </p>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                }}
                              >
                                Entries
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                background: '#F1F3F5',
                                borderRadius: '10px',
                                padding: '8px 19px',
                                marginLeft: '14px',
                              }}
                            >
                              <p
                                style={{
                                  padding: '3px 5px',
                                  marginRight: '6px',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                }}
                              >
                                {' '}
                                Total Duration{' '}
                              </p>
                              <Typography
                                sx={{
                                  background: '#D2B8FF',
                                  color: '#310085',
                                  fontSize: '13px',
                                  borderRadius: '6px',
                                  fontWeight: 600,
                                  padding: '2px 10px',
                                }}
                              >
                                {`${totalDuration.totalHours}h:${totalDuration.totalMinutes}m`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <TableContainer
                          component={Paper}
                          sx={{
                            width: '100%',
                            borderRadius: '8px',
                            m: '1rem 0',
                          }}
                        >
                          <Table>
                            <TimeTableHeader worktype={true} />
                            <WorkTypeGroupTableBody
                              key={keyToForceRerender}
                              item={item}
                              Projects={Projects}
                            />
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  })}
              </>
            ) : (
              ''
            )}
          </div>
        </Box>
      </div>
    </>
  ) : (
    <PreLoading />
  );
}

export default Time;
