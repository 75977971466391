import React from 'react';
import { Tooltip } from '@mui/material';
import { AddCircleOutline, ArrowBack } from '@mui/icons-material';
import { useRouteTo } from '../../../hooks/useRouteTo';
// eslint-disable-next-line
import { ActionBar } from './ActionBar';
import { SearchFieldWithIcon } from '../../atomic-atoms/SearchFieldWithIcon';

function TitleBar({
  sectionTitle,
  helpIconFunction,
  buttonFunction,
  buttonTitle,
  buttonRequired,
  menuDotRequired,
  Dotopen,
  handleDotDropDown,
  searchQuery,
  setSearchQuery,
  searchBarRequired,
  smallTitle,
  componentToRender,
  showArrow,
}) {
  const routeTo = useRouteTo();
  return (
    <div>
      <ActionBar
        helpIconFunction={helpIconFunction}
        Dotopen={Dotopen}
        handleDotDropDown={handleDotDropDown}
        menuDotRequired={menuDotRequired}
      />
      <div className="oppor-row">
        <div className="oppor-col">
          {showArrow ? (
            <ArrowBack
              sx={{
                fontSize: '2rem',
                color: '#a6a6a6',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => routeTo(-1)}
            />
          ) : null}
          <Tooltip title={sectionTitle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 className="fstxtPro">{sectionTitle}</h3>
              {smallTitle ? <h5>{smallTitle}</h5> : null}
            </div>
          </Tooltip>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          {searchBarRequired ? (
            <div style={{ marginLeft: '1rem', minWidth: '350px' }}>
              {' '}
              <SearchFieldWithIcon
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          ) : null}
          {buttonRequired ? (
            <button
              type="button"
              className="create-btn"
              id="c-btn"
              onClick={buttonFunction}
            >
              <AddCircleOutline
                style={{
                  fontSize: '1.5rem',
                  marginRight: '5px',
                  marginTop: '-3px',
                }}
              />
              {buttonTitle}
            </button>
          ) : null}
        </div>
        {componentToRender ?? null}
      </div>
    </div>
  );
}

export default TitleBar;
