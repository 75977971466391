import { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';

import { NetNetCheckBox } from '../../../Componenets/Misc';
import { PreLoading } from '../People/EditContact';

const fieldMappingOptions = [
  { name: 'Skip Column', inputValue: 'skip_col', cat: 'Skip' },
  { name: 'First Name', inputValue: 'first_name', cat: 'People' },
  { name: 'Last Name', inputValue: 'last_name', cat: 'People' },
  { name: 'Email', inputValue: 'email', cat: 'People' },
  { name: 'Mobile Number', inputValue: 'mobile_num', cat: 'People' },
  { name: 'Job Title', inputValue: 'title', cat: 'People' },
  { name: 'Country', inputValue: 'country', cat: 'People' },
  { name: 'State', inputValue: 'state', cat: 'People' },
  { name: 'Description', inputValue: 'description', cat: 'People' },
  { name: 'City', inputValue: 'city', cat: 'People' },
  { name: 'Zip Code ', inputValue: 'address_zip', cat: 'People' },
  { name: 'Tags', inputValue: 'tags', cat: 'People' },
  { name: 'Address 1', inputValue: 'street', cat: 'People' },
  { name: 'Address 2', inputValue: 'street2', cat: 'People' },
  { name: 'LinkedIn', inputValue: 'linkedin_url', cat: 'People' },
  { name: 'Facebook', inputValue: 'facebook_url', cat: 'People' },
  { name: 'X', inputValue: 'twitter_url', cat: 'People' },
  { name: 'Instagram', inputValue: 'instagram_url', cat: 'People' },
  { name: 'Notes', inputValue: 'notes', cat: 'People' },
  {
    name: 'Type',
    inputValue: 'contact_type',
    cat: 'People',
  },
  { name: 'Company Name', inputValue: 'company', cat: 'Company' },

  {
    name: 'Company Title',
    inputValue: 'company_title',
    cat: 'Company',
  },
  {
    name: 'Company Tags',
    inputValue: 'company_tags',
    cat: 'Company',
  },
  { name: 'NAICS Industry', inputValue: 'company_industry', cat: 'Company' },
  { name: 'Company Type', inputValue: 'company_type', cat: 'Company' },
  { name: 'Office Number', inputValue: 'company_office_num', cat: 'Company' },
  {
    name: 'Company Status',
    inputValue: 'company_status',
    cat: 'Company',
  },
  { name: 'Notes', inputValue: 'notes', cat: 'Company' },
  { name: 'Company URL', inputValue: 'company_url', cat: 'Company' },
  {
    name: 'Company LinkedIn',
    inputValue: 'company_linkedin_url',
    cat: 'Company',
  },
  {
    name: 'Company Facebook ',
    inputValue: 'company_facebook_url',
    cat: 'Company',
  },
  {
    name: 'Company X',
    inputValue: 'company_twitter_url',
    cat: 'Company',
  },
  {
    name: 'Company Instagram',
    inputValue: 'company_instagram_url',
    cat: 'Company',
  },
  {
    name: 'Company Address 1',
    inputValue: 'company_street',
    cat: 'Company',
  },
  {
    name: 'Company Address 2',
    inputValue: 'company_street2',
    cat: 'Company',
  },
  {
    name: 'Company State',
    inputValue: 'company_state',
    cat: 'Company',
  },
  {
    name: 'Company City',
    inputValue: 'company_city',
    cat: 'Company',
  },
  { name: 'Company Country', inputValue: 'company_country', cat: 'Company' },
  {
    name: 'Company Zip',
    inputValue: 'company_address_zip',
    cat: 'Company',
  },
  {
    name: 'Company Description',
    inputValue: 'company_description',
    cat: 'Company',
  },
  {
    name: 'Company Email',
    inputValue: 'company_email',
    cat: 'Company',
  },
  {
    name: 'Company Email2',
    inputValue: 'company_email2',
    cat: 'Company',
  },
];

const fieldMappingDictionary = {
  'First Name': 'first_name',
  'Last Name': 'last_name',
  Email: 'email',
  'Mobile Number': 'mobile_num',
  'Company Name': 'company',
  'Person Address Line 1': 'street',
  'Person Address Line 2': 'street2',
  'Person City': 'city',
  'Person State / Province': 'state',
  'Person Zip / Postal Code': 'address_zip',
  'Person Country': 'country',
  'Personal LinkedIn': 'linkedin_url',
  'Personal X': 'twitter_url',
  'Personal Facebook': 'facebook_url',
  'Personal Instagram': 'instagram_url',
  Title: 'title',
  Description: 'description',
  Tags: 'tags',
  'Company Title': 'company_title',
  'Company Tags': 'company_tags',
  'Company Industry': 'company_industry',
  'Company Type': 'company_type',
  'Company Office Number': 'company_office_num',
  'Company Status': 'company_status',
  'Company Description': 'company_description',
  'Company Address Line 1': 'company_street',
  'Company Address Line 2': 'company_street2',
  'Company City': 'company_city',
  'Company State / Province': 'company_state',
  'Company Zip / Postal Code': 'company_address_zip',
  'Company Country': 'company_country',
  'Company LinkedIn': 'company_linkedin_url',
  'Company Facebook': 'company_facebook_url',
  'Company X': 'company_twitter_url',
  'Company Instagram': 'company_instagram_url',
  'Company Email': 'company_email',
  'Company Email2': 'company_email2',
  'Company URL': 'company_url',
};

const initialNetProps = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_num: '',
  title: '',
  tags: '',
  description: '',
  website: '',
  company: '',
  country: '',
  state: '',
  city: '',
  street: '',
  street2: '',
  address_zip: '',
  contact_type: '',
  linkedin_url: '',
  facebook_url: '',
  twitter_url: '',
  instagram_url: '',
  company_email: '',
  company_email2: '',
  company_description: '',
  company_address_zip: '',
  company_country: '',
  company_city: '',
  company_state: '',
  company_street: '',
  company_street2: '',
  company_instagram_url: '',
  company_twitter_url: '',
  company_linkedin_url: '',
  company_facebook_url: '',
  company_office_num: '',
  company_industry: '',
  company_tags: '',
  company_type: '',
  company_title: '',
  company_url: '',
};

function MapFields({
  item,
  num,
  setConstructor,
  setIsError,
  setErrMsg,
  newParser,
  setMapped,
  importOptions,
  setRequiredFields,
  selectedMappings,
  onMappingSelect,
}) {
  const filter = createFilterOptions();
  const [previousInputValue, setPreviousInputValue] = useState('');
  const [newFieldMappingOptions, setNewFieldMappingOptions] = useState([]);

  const [reRenderForRequiredFields, setReRenderForRequiredFields] =
    useState(false);

  // ! Important to set 'allRequiredFieldsValid' back to false at 'ThirdStep' component.
  useEffect(() => {
    if (reRenderForRequiredFields) {
      if (previousInputValue === 'Email') {
        setRequiredFields((prev) => ({
          ...prev,
          email: false,
        }));

        setReRenderForRequiredFields(false);
      } else if (previousInputValue === 'Company Name') {
        setRequiredFields((prev) => ({
          ...prev,
          company: false,
        }));

        setReRenderForRequiredFields(false);
      }
    }
  }, [reRenderForRequiredFields]);

  const handleMapping = (value) => {
    if (!value || value === '') {
      setIsError(true);
      setErrMsg('Please select a valid mapping option.');
      return;
    }

    if (value.inputValue === 'email') {
      setRequiredFields((prev) => ({
        ...prev,
        email: true,
      }));
    } else if (value.inputValue === 'company') {
      setRequiredFields((prev) => ({
        ...prev,
        company: true,
      }));
    }

    if (onMappingSelect) {
      onMappingSelect(value, 'add');
    }

    setMapped(true);
    setIsError(false);
    const myValues = newParser.map((ele) => ele[item]);
    const fieldKey =
      value.inputValue ?? fieldMappingDictionary[value.name] ?? value.name;

    const find = newParser.map((_, index) => {
      const newProps = { ...initialNetProps };

      if (fieldKey in initialNetProps) {
        newProps[fieldKey] = myValues[index];
      }

      return newProps;
    });

    // * Handle skip column case.
    if (fieldKey === 'skip_col') {
      find.forEach((mapping) => {
        // eslint-disable-next-line no-param-reassign
        mapping[item] = '';
      });
    }

    setConstructor((prev) => {
      if (prev.length < 1) {
        return find;
      }

      return prev.map((existing, index) => {
        const newMapping = { ...find[index] };
        // * Remove empty values.
        Object.keys(newMapping).forEach((key) => {
          if (!newMapping[key]) {
            delete newMapping[key];
          }
        });

        // * Merge with existing mappings.
        return {
          ...existing,
          ...newMapping,
        };
      });
    });
  };

  const handleClearMapping = () => {
    setIsError(true);
    setErrMsg('Please select a valid mapping option');
    setMapped(false);

    setConstructor((prev) =>
      prev.map((existing) => ({
        ...existing,
        [item]: '', // * Clears the corresponding field value.
      })),
    );
  };

  // * To autocomplete the field's value and constructor based on the import option selected. See 'handleMapping' function.
  useEffect(() => {
    const matchingOption = fieldMappingOptions.find((option) => {
      const validFieldMapping =
        fieldMappingDictionary[item] === option.inputValue;
      const validForPeople =
        validFieldMapping &&
        importOptions.importPeople &&
        option.cat === 'People';

      const validForCompanies =
        validFieldMapping &&
        importOptions.importCompanies &&
        option.cat === 'Company';

      if (validForPeople && validForCompanies) {
        return option;
      }

      if (validForPeople) {
        return option;
      }

      if (validForCompanies) {
        return option;
      }

      return false;
    });

    if (matchingOption) {
      handleMapping(matchingOption);
    }
  }, [item, importOptions]);

  const filterFieldMappingOptions = (importOptions) => {
    if (importOptions.importPeople && importOptions.importCompanies) {
      return fieldMappingOptions;
    }

    if (importOptions.importPeople) {
      return fieldMappingOptions.filter((option) => option.cat === 'People');
    }

    if (importOptions.importCompanies) {
      return fieldMappingOptions.filter((option) => option.cat === 'Company');
    }

    return [];
  };

  useEffect(() => {
    const newFieldOptions = filterFieldMappingOptions(importOptions);
    setNewFieldMappingOptions(newFieldOptions);
  }, [importOptions]);

  return (
    <Autocomplete
      key={num}
      id={`autocomplete-${num}`}
      style={{ width: 300, marginTop: '0.7rem' }}
      options={newFieldMappingOptions}
      value={
        newFieldMappingOptions.find((option) => option.name === item) || null
      }
      groupBy={(option) => option.cat}
      includeInputInList
      // eslint-disable-next-line no-unused-vars
      onChange={(event, value) => {
        if (!value) {
          setReRenderForRequiredFields(true);

          onMappingSelect(selectedMappings[item], 'remove');
          handleClearMapping();
          return;
        }
        handleMapping(value);
      }}
      // eslint-disable-next-line no-unused-vars
      onInputChange={(event, inputValue, reason) => {
        if (reason === 'clear') {
          setReRenderForRequiredFields(true);

          onMappingSelect(selectedMappings[item], 'remove');
          handleClearMapping();
        } else {
          setPreviousInputValue(inputValue);
        }
      }}
      freeSolo
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.name;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add New Column "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Net Net Field"
          variant="standard"
          color="secondary"
          InputProps={{
            ...params.InputProps,
            style: { width: 300 },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) =>
        option.inputValue === value.inputValue || option.name === value.name
      }
    />
  );
}

function ImportTHead() {
  return (
    <TableHead
      sx={{
        height: '90.12px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="tcell">Column Header From File</div>
        </TableCell>
        <TableCell align="left">
          <div className="tcell">Preview Information</div>
        </TableCell>
        <TableCell align="left">
          <div className="tcell">Net Net Field</div>
        </TableCell>
        <TableCell align="right">
          <div className="tcell">Overwrite Existing Data In Net Net</div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

// ! Change: 'Don't Overwrite Existing Values' -> 'Overwrite Existing Data In Net Net' and the logic attach to it.

function ThirdStep({ step, setStep, newParser, setConstructor }) {
  const [miniLoading, setMiniLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorWithRequiredFields, setIsErrorWithRequiredFields] =
    useState(false);
  const [showToggleMessage, setToggleCheckboxMessage] = useState({
    email: false,
    companyName: false,
  });

  const [
    uncheckedImportOptionsMoreThanOnce,
    setUncheckedImportOptionsMoreThanOnce,
  ] = useState({
    email: false,
    companyName: false,
  });

  const [mapped, setMapped] = useState(false);
  const [allRequiredFieldsValid, setAllRequiredFieldsValid] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [column, setColumn] = useState([]);

  const [overwriteExistingValuesObject, setOverwriteExistingValuesObject] =
    useState({});

  // ! Important to keep track of selected mappings so the checkboxes can be enabled/disabled.
  const [selectedMappings, setSelectedMappings] = useState({});

  const [importOptions, setImportOptions] = useState({
    importPeople: false,
    importCompanies: false,
  });
  const [requiredFields, setRequiredFields] = useState({
    email: false,
    company: false,
  });

  const hasSelectImportOption =
    importOptions.importPeople || importOptions.importCompanies;

  useEffect(() => {
    if (newParser && newParser[0]) {
      setColumn(Object.keys(newParser[0]));
    } else {
      setColumn([]);
      setIsError(true);
      setErrMsg(
        'Please ensure your CSV file contains at least one row with column headers.',
      );
    }
  }, [mapped]);

  // * This is so when the unchecks any import option, the required fields are reset from everywhere.
  // * See 'useEffect' below.
  const resetField = (
    field,
    setRequiredFields,
    setSelectedMappings,
    setConstructor,
    setOverwriteExistingValuesObject,
  ) => {
    setRequiredFields((prev) => ({
      ...prev,
      [field]: false,
    }));

    setSelectedMappings((prev) => {
      const newPrev = { ...prev };
      delete newPrev[field];
      return newPrev;
    });

    setConstructor((prev) =>
      prev.map((existing) => {
        const newExisting = { ...existing };
        newExisting[field] = '';
        return newExisting;
      }),
    );

    setOverwriteExistingValuesObject((prev) => {
      const dynamicField = field === 'company_name' ? 'company' : 'email';

      const newPrev = { ...prev };
      delete newPrev[dynamicField];
      return newPrev;
    });
  };

  const showError = (
    setIsErrorWithRequiredFields,
    setAllRequiredFieldsValid,
    setErrMsg,
    msg,
  ) => {
    setIsErrorWithRequiredFields(true);
    setAllRequiredFieldsValid(false);
    setErrMsg(msg);
  };

  useEffect(() => {
    if (!hasSelectImportOption) {
      if (!isErrorWithRequiredFields) {
        showError(
          setIsErrorWithRequiredFields,
          setAllRequiredFieldsValid,
          setErrMsg,
          'Please select at least one import option to continue.',
        );
      }
      return;
    }

    // Handle resetting the required field state when options are unchecked
    const resetEmailField =
      !importOptions.importPeople && requiredFields.email !== false;
    const resetCompanyField =
      !importOptions.importCompanies && requiredFields.company !== false;

    if (resetEmailField) {
      resetField(
        'email',
        setRequiredFields,
        setSelectedMappings,
        setConstructor,
        setOverwriteExistingValuesObject,
      );

      setUncheckedImportOptionsMoreThanOnce((prev) => ({
        ...prev,
        email: true,
      }));

      return;
    }

    if (resetCompanyField) {
      resetField(
        'company_name',
        setRequiredFields,
        setSelectedMappings,
        setConstructor,
        setOverwriteExistingValuesObject,
      );

      setUncheckedImportOptionsMoreThanOnce((prev) => ({
        ...prev,
        companyName: true,
      }));

      return;
    }

    // * Check if the required fields are still valid.
    const emailMissing = importOptions.importPeople && !requiredFields.email;
    const companyMissing =
      importOptions.importCompanies && !requiredFields.company;

    if (emailMissing) {
      showError(
        setIsErrorWithRequiredFields,
        setAllRequiredFieldsValid,
        setErrMsg,
        'Please map the Email field to continue.',
      );
      return;
    }

    if (companyMissing) {
      showError(
        setIsErrorWithRequiredFields,
        setAllRequiredFieldsValid,
        setErrMsg,
        'Please map the Company Name field to continue.',
      );
      return;
    }

    if (
      importOptions.importPeople &&
      importOptions.importCompanies &&
      (emailMissing || companyMissing)
    ) {
      showError(
        setIsErrorWithRequiredFields,
        setAllRequiredFieldsValid,
        setErrMsg,
        'Please map both Email and Company Name fields to continue.',
      );
      return;
    }

    setIsErrorWithRequiredFields(false);
    setAllRequiredFieldsValid(true);
    setErrMsg('');
  }, [
    importOptions,
    requiredFields.email,
    requiredFields.company,
    hasSelectImportOption,
    setConstructor,
    isErrorWithRequiredFields,
  ]);

  const handleCheckboxChange = (item) => (event) => {
    if (!selectedMappings[item]) {
      return;
    }

    const isChecked = event.target.checked;

    const keyForValue = selectedMappings[item].inputValue;

    setOverwriteExistingValuesObject((prevValues) => ({
      ...prevValues,
      [keyForValue]: isChecked ? !prevValues[keyForValue] : false,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setConstructor((prev) =>
      prev.map((existing) => ({
        ...existing,
        overwrite_existing_values: overwriteExistingValuesObject,
      })),
    );

    setMiniLoading(true);
    setStep(4);
  };

  const handleOnMappingSelect = (item, value, action) => {
    if (action === 'add') {
      setSelectedMappings((prev) => ({
        ...prev,
        [item]: value,
      }));

      setOverwriteExistingValuesObject((prev) => ({
        ...prev,
        [value?.inputValue ?? '']: false,
      }));
    } else if (action === 'remove') {
      setSelectedMappings((prev) => {
        const newPrev = { ...prev };
        delete newPrev[item];
        return newPrev;
      });

      setOverwriteExistingValuesObject((prev) => {
        const newPrev = { ...prev };
        delete newPrev[value?.inputValue ?? ''];
        return newPrev;
      });
    }
  };

  useEffect(() => {
    setToggleCheckboxMessage((prev) => ({
      ...prev,
      email: Object.prototype.hasOwnProperty.call(
        overwriteExistingValuesObject,
        'email',
      ),
      companyName: Object.prototype.hasOwnProperty.call(
        overwriteExistingValuesObject,
        'company',
      ),
    }));
  }, [overwriteExistingValuesObject]);

  return (
    <Box className="import-contacts-map-fields">
      {miniLoading && <PreLoading />}
      <div className="steps-container">
        <h3 className="txtCenter">
          Importing Contacts & Companies : Step 2 - Map Fields
        </h3>
        <p className="txtCenter initialParagraph txtESM">
          Each column header below should be mapped to a People/Company property
          in Net Net. Some of these have already been mapped based on their
          names. Anything that hasn&apos;t been mapped yet can be manually
          mapped to a People/Company property with each dropdown. You can always
          create a new property or choose “Skip Column”.
        </p>
      </div>

      {(isError || isErrorWithRequiredFields) && (
        <Alert severity="info">{errMsg}</Alert>
      )}

      {!importOptions.importPeople &&
        uncheckedImportOptionsMoreThanOnce.email && (
          <Alert severity="info">
            If you have unchecked the <strong>People</strong> import option, you
            will need to map the <strong>Email</strong> again to import all
            People contacts.
          </Alert>
        )}

      {!importOptions.importCompanies &&
        uncheckedImportOptionsMoreThanOnce.companyName && (
          <Alert severity="info">
            If you have unchecked the <strong>Companies</strong> import option,
            you will need to map the <strong>Company</strong> Name again to
            import all Company contacts.
          </Alert>
        )}

      <div className="import-options">
        <div>
          <NetNetCheckBox
            checked={importOptions.importPeople}
            onChange={() =>
              setImportOptions((prev) => ({
                ...prev,
                importPeople: !prev.importPeople,
              }))
            }
          />
          {/* eslint-disable-next-line */}
          <label
            style={{
              marginLeft: '0.5rem',
            }}
            htmlFor="importPeople"
          >
            Import People
          </label>

          <div
            style={{
              marginLeft: '1rem',
              color: showToggleMessage.email ? '#5ee690' : '#ccc',
              textDecoration: showToggleMessage.email ? 'none' : 'line-through',
            }}
          >
            {importOptions.importPeople && (
              <>{showToggleMessage.email ? '✔️' : ''} Email</>
            )}
          </div>
        </div>

        <div>
          <NetNetCheckBox
            checked={importOptions.importCompanies}
            onChange={() =>
              setImportOptions((prev) => ({
                ...prev,
                importCompanies: !prev.importCompanies,
              }))
            }
          />
          {/* eslint-disable-next-line */}
          <label
            style={{
              marginLeft: '0.5rem',
            }}
            htmlFor="importCompanies"
          >
            Import Companies
          </label>

          <div
            style={{
              marginLeft: '1rem',
              color: showToggleMessage.companyName ? '#5ee690' : '#ccc',
              textDecoration: showToggleMessage.companyName
                ? 'none'
                : 'line-through',
            }}
          >
            {importOptions.importCompanies && (
              <>{showToggleMessage.companyName ? '✔️' : ''} Company Name</>
            )}
          </div>
        </div>
      </div>

      <div
        className="tableOverlay-wrapper"
        style={{
          position: 'relative',
          width: '100%',
          pointerEvents: hasSelectImportOption ? 'unset' : 'none',
        }}
      >
        <div
          className="tableOverlay"
          style={{
            display: hasSelectImportOption ? 'none' : 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#cbcbcb5e',
            borderRadius: '8px',
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            width: '100%',
            height: '100%',
          }}
        />
        <Box sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              marginBottom: '70px',
            }}
          >
            <Table aria-label="simple table">
              <ImportTHead />
              <TableBody>
                {column.map((item, num) => (
                  <TableRow
                    key={item}
                    sx={{
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#e9ecef',
                      },
                      height: '110px',
                    }}
                    hover
                  >
                    <TableCell>
                      <div
                        className="fstxt"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item === 'First Name'
                          ? 'First Name'
                          : item === 'Last Name'
                            ? 'Last Name'
                            : item === 'Email'
                              ? 'Email'
                              : item}
                      </div>
                    </TableCell>

                    <TableCell>
                      {newParser.length > 3
                        ? newParser.slice(0, 3).map((ele) => (
                            <div key={ele[item]} className="fstxt">
                              {ele[item]}
                            </div>
                          ))
                        : newParser.map((ins) => (
                            <div key={ins[item]} className="fstxt">
                              {ins[item]}
                            </div>
                          ))}
                    </TableCell>
                    <TableCell align="left">
                      <MapFields
                        setConstructor={setConstructor}
                        item={item}
                        num={num}
                        setIsError={setIsError}
                        setErrMsg={setErrMsg}
                        newParser={newParser}
                        mapped={mapped}
                        setMapped={setMapped}
                        columns={column}
                        importOptions={importOptions}
                        setRequiredFields={setRequiredFields}
                        selectedMappings={selectedMappings}
                        onMappingSelect={(value, action) =>
                          handleOnMappingSelect(item, value, action)
                        }
                      />
                    </TableCell>

                    <TableCell align="center">
                      <NetNetCheckBox
                        checked={
                          overwriteExistingValuesObject[
                            selectedMappings[item]?.inputValue
                          ] ?? false
                        }
                        onChange={handleCheckboxChange(item)}
                        disabled={!selectedMappings[item]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>

      <Box
        sx={{
          position: 'fixed',
          bottom: '1px',
          width: { xl: '85%', lg: '80%', md: '98%' },
          alignItems: 'end',
          paddingBottom: '1rem',
          marginBottom: '1rem',
          border: '1px solid #ccc',
          boxShadow: 'unset',
        }}
        className="AlBottom footer"
      >
        <ul className="footer-list">
          <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
            Import File
          </li>
          <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
            Map Fields
          </li>
          <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
            {/* ! '@Type/Tags'. (See 'FourthStep.jsx' for more.) */}
            {/* Type */}
            Ready to Import!{' '}
          </li>
          <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
            Exceptions
          </li>
        </ul>

        <div className="AlRight dd-import-group">
          <button
            type="button"
            className="cancel-btn alRight fsESM border"
            onClick={() => setStep(2)}
            style={{
              borderRadius: '4px',
            }}
          >
            Back
          </button>

          <Tooltip
            title={
              !allRequiredFieldsValid
                ? 'Please first map all the required fields...'
                : ''
            }
          >
            <button
              type="button"
              className="smBtn"
              disabled={!allRequiredFieldsValid}
              onClick={(e) => handleSubmit(e)}
            >
              Next
            </button>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}

export default ThirdStep;
