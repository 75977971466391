import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

/**
 ** Usage:
 **
 **  <NetNetSwitch
 **    topLabel="Toggle this to show..."
 **    leftLabel="Off"
 **    rightLabel="On"
 **    thumbColor="#hashCodeForColor"
 **    trackColor="#hashCodeForColor"
 **    defaultChecked
 **    onChange={(e) => handleOnChange(e.target.checked)}
 **   />
 **
 */

const CustomSwitch = styled(Switch)(
  ({ theme, thumbColor = '#711fff', trackColor = '#fff' }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: thumbColor,
      '&:hover': {
        backgroundColor: alpha(thumbColor, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: thumbColor,
    },
    '& .MuiSwitch-track': {
      backgroundColor: trackColor,
    },
  }),
);

// eslint-disable-next-line
export function NetNetSwitch({
  topLabel,
  leftLabel,
  rightLabel,
  thumbColor,
  trackColor,
  ...props
}) {
  return (
    <div style={{ textAlign: 'center' }}>
      {topLabel && <p style={{ paddingBottom: '5px' }}>{topLabel}</p>}
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {leftLabel && <Typography variant="body2">{leftLabel}</Typography>}
        <div
          style={{
            backgroundColor: '#dcd4fd',
            borderRadius: '20px',
          }}
        >
          <CustomSwitch
            thumbColor={thumbColor}
            trackColor={trackColor}
            {...props}
          />
        </div>
        {rightLabel && <Typography variant="body2">{rightLabel}</Typography>}
      </div>
    </div>
  );
}
