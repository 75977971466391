import ComingSoon from "../../../../Componenets/atomic-atoms/ComingSoon";
import SectionHeader from "../../../../Componenets/atomic-atoms/SectionHeader";

function HelpTickets() {
  return (
    <>
     <SectionHeader title="Help Tickets" />
     <ComingSoon title="Help Tickets" />
    </>
  );
}

export default HelpTickets;
