import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

function SectionHeader({ title }) {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: ' 1% 1.38% 1% 1.38%',
        boxSizing: 'border-box',
        textAlign: 'center',
        fontSize: '29px',
        color: '#03071e',
        fontFamily: "'Open Sans'",
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '20px',
        }}
      >
        <b style={{ position: 'relative', lineHeight: '100%' }}>{title}</b>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgba(3, 7, 30, 0.4)',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '5px 0',
          fontSize: '14px',
          color: '#fff',
        }}
      >
        {/* eslint-disable-next-line */}
        <Link to="">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: 'capitalize',
              fontWeight: '300',
              backgroundColor: '#711FFF',
              lineHeight: '19px',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#711DDD',
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default SectionHeader;
