import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Shake from 'react-reveal/Shake';
import { ViewMode } from 'gantt-task-react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment';
import Offcanvas from 'react-bootstrap/Offcanvas';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Alert, Grid, Box, ButtonGroup } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DescriptionIcon from '@mui/icons-material/Description';
import Slider from '@mui/material/Slider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import NotesIcon from '@mui/icons-material/Notes';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { getTemplates } from '../User/redux';
import helpVid from './assets/Section_Help-idle.svg';
import NewEstimateHelpVideos from './NewEstimateHelpVideos';
import FirstStep from './estSteps/FirstStep';
import SecondStep from './estSteps/SecondStep';
import ThirdStep from './estSteps/ThirdStep';
import FourthStep from './estSteps/FourthStep';
import FifthStep from './estDetails/Fifth';
import { ENDPOINT, storeStep, getEstimates } from './Redux/oppor';
import SixthStep, { formatMoneyString } from './estSteps/SixthStep';
import Final from './estDetails/Final';
import PreLoader, { NetNetSwitch } from '../../Componenets/Misc';
import DescriptionEstSixthStep from './DescriptionEstSixthStep';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';

export const payment = `We bill using a progressive invoicing method with no deposit required for this job.
Progressive Invoicing occurs two times per month on the 1st and 16th for the previous periods.
A time log report will be provided with the invoice for the total hours accrued for that period. All
invoices are due and payable Net 10 from issuance.

RH bills for the actual time incurred, should the hours be less than the estimate.
The hours are billed at a standard rate of $185/hour. Changes in scope or understanding may
affect the completeness of deliverables and/or extend the effort and may result in additional
costs to Client.`;

export const conditions = `This Job Order presented by Right Here Interactive, LLC. (“RHI”) to Client represents the full and complete understanding of the deliverables.  Modifications or additions to the deliverables listed in this Job Order are subject to additional charges (“Change Orders”).  RHI warrants the deliverables will be free from mechanical defect caused solely by RHI for a period of Thirty (30) days from the date of delivery.  If material defects are found, RHI shall be responsible for correcting them without unreasonable delay, at their expense. This shall be the exclusive warranty available to Client.  This warranty is considered nullified, void or otherwise not valid if any other party than those authorized by RHI in any way access or modify the deliverables or any components in part or whole without the express written consent of RHI. Client acknowledges that RHI does not warrant any third-party software licensed, purchased or by any other means acquired for use in the creation of the deliverables, nor that software will remain active, available, updated or registered for more than Thirty (30) days.  RHI does not warrant servers to which the deliverables are run on, stored or hosted.  

For the period during and One (1) year after the completion of this Job Order, neither party shall directly or indirectly employ or contract to any employees, contractors or freelancers of the other party without the prior written approval of the other party.  Client does hereby allow RHI to use their name, URL(s), taglines, logo(s) and/or quotes for promotional purposes. 

Client acknowledges that RHI must allocate resources over a period of time to complete the deliverables and that the scheduling of those resources creates costs for RHI. If after the commencement of work, Client creates a delay in RHI’s work effort for more than Ten (10) consecutive days for any reason, the job will be considered “Stalled” and RHI may temporarily remove it from production.  Once Client is able to resume the Job Order and is approved by RHI, Client will pay in advance any remaining balance that brings them to Ninety Five Percent (95%) of the sum total of the Job Order and any Change Orders, plus a Reinstatement Fee of Seven and a Half Percent (7.5%) of the Job Order total.  Once the amounts are paid in full, production will resume with a new schedule issued by RHI.

If this Job Order is terminated for any reason, Client agrees that no refunds will be given to Client for any payments made to RHI up to the date of termination. Upon termination, RHI will issue a final invoice for the fees in excess of payments made by Client, which at the discretion of RHI will be determined either by hours incurred at a rate of One Hundred Fifty Dollars ($150) per hour or by the value of the deliverables completed, plus Change Orders fees, plus a Cancellation Fee of Twenty Percent (20%) of the Job Order total.  The total amount of the final invoice plus payments already made by Client will not exceed the sum total of the Job Order, plus Change Orders, plus if applicable the Reinstatement Fee. Client agrees they will pay the invoice in full within Ten (10) days.  
`;

const step = [
  {
    label: 'Summary',
  },

  // {
  //   label: 'Collaboration',
  // },
  {
    label: 'Level of Efforts',
  },
  {
    label: 'Timeline',
  },
  {
    label: 'Pricing',
  },
  {
    label: 'Net Net',
  },
  {
    label: 'Estimate',
  },
];

const calculateLOERate = (deliverable, work) => {
  if (
    !deliverable ||
    !Array.isArray(deliverable.deliverable_worktype) ||
    !deliverable.deliverable_worktype.length ||
    !Array.isArray(work) ||
    !work.length
  ) {
    return 0;
  }

  let total = 0;
  deliverable.deliverable_worktype.forEach((worktype) => {
    const matchingWork = work.find((item) => item.id === worktype.id);
    if (matchingWork) {
      total += Number(matchingWork.user_rate) * Number(worktype.hours);
    }
  });
  return total;
};

function VerticalLinearSteppe({ formData, isDetOpen, stepsRedux }) {
  const [activeStep] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let stepsNums = 0;

  const [show, setShow] = useState(true);
  return isDetOpen ? (
    <Box sx={{ zIndex: 99 }}>
      <Box
        className="firstMenu"
        sx={{
          backgroundColor: 'white',
          padding: '1rem 0rem',
          borderRadius: '10px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5);',
          display: show ? 'flex' : 'none',
          jusitfyContent: 'center',
          position: 'fixed',
          top: '19%',
          opacity: show ? 1 : 0,
          Zindex: show ? 99 : 1,
          transition: 'opacity 0.3s ease-in-out',
          height: show ? 'auto' : '0',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ margin: '0 auto', width: '90%' }}
        >
          {step.map((step, index) => {
            if (formData.type && formData.type !== 'project') {
              if (index === 2) {
                return;
              }
            } // eslint-disable-next-line
            return (
              <Step key={step.label}>
                <button
                  type="button"
                  onClick={() => {
                    if (index + 1 <= stepsRedux) {
                      dispatch(storeStep(index + 1));
                    }
                  }}
                  style={{
                    gap: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    borderRadius: '10px',
                    border: 'none',
                    backgroundColor:
                      stepsRedux === index + 1
                        ? '#cbb6fc'
                        : stepsRedux > index + 1
                          ? '#7323fa'
                          : '#f6f5f7',
                    height: '40px',
                    cursor: 'pointer',
                    paddingLeft: '8px',
                  }}
                >
                  <CheckIcon
                    style={{
                      color:
                        stepsRedux === index + 1
                          ? '#af8dfc'
                          : stepsRedux > index + 1
                            ? 'white'
                            : '#efe8ff',
                    }}
                  />
                  <Typography
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      padding: '1px 3px',
                      fontSize: '12px',
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    {++stepsNums}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        stepsRedux === index + 1
                          ? '#171717'
                          : stepsRedux > index + 1
                            ? 'white'
                            : '#575656',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                  >
                    {step.label}
                  </Typography>
                </button>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box
        sx={{
          marginLeft: '5px',
          position: 'fixed',
          top: { xl: '19%', lg: '20%', sm: '21%', md: '20.5%' },
        }}
        className="secondMenu"
      >
        <p
          onClick={() => setShow(!show)}
          style={{
            cursor: 'pointer',
            writingMode: 'vertical-rl',
            textOrientation: 'revert-layer',
            padding: '15px 2px',
            backgroundColor: show ? '#ac7cfc' : '#c6a7fa',
            borderStartEndRadius: screenSize > 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize < 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            color: show ? 'white' : 'black',
            letterSpacing: '0.1rem',
          }}
        >
          STEPS
        </p>
      </Box>
    </Box>
  ) : null;
}

function EstHeader({ setOpenCanva, screenSize }) {
  return (
    <Box
      style={{
        border: '1px solid #D9D9D9',
        padding: '0.7rem .5rem',
        borderRadius: '8px',
        width: { xl: '42%', lg: '50%', sm: '55%', md: '60%' },
        backgroundColor: '#e7deff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100px',
        marginRight: '1.3rem',
        boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '.8rem',
        }}
      >
        <button
          type="button"
          className="netnetc-btn-act2"
          onClick={(e) => {
            e.preventDefault();
            setOpenCanva(true);
          }}
        >
          Deliverable Templates
        </button>
      </Box>{' '}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <NetNetSwitch label={screenSize < 900 ? 'Tasks' : 'Show all Tasks'} />
        <NetNetSwitch
          label={screenSize < 900 ? 'Descriptions' : 'Show all Description '}
        />
      </Box>
    </Box>
  );
}

function BudgetCheck({ dileverable, formData, tracker, setGI }) {
  const [totalCost, setTotalCost] = useState(0);

  const sumUserPrices = (deliverable) => {
    if (!deliverable || !Array.isArray(deliverable) || !deliverable.length) {
      return 0;
    }

    return deliverable.reduce((acc, item) => {
      const userPrice = Number(item.user_price);
      // eslint-disable-next-line
      if (!isNaN(userPrice)) {
        // eslint-disable-next-line
        acc += userPrice;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    let cost = 0;

    if (!tracker) {
      if (dileverable && Array.isArray(dileverable) && dileverable.length) {
        const hasAllUserPriceDefined = dileverable.every((item) => {
          return Number(item.user_price) > 0;
        });
        if (!hasAllUserPriceDefined) {
          const result = dileverable.reduce((acc, curr) => {
            curr?.deliverable_worktype?.forEach((item) => {
              if (acc[item.id]) {
                acc[item.id].hours += Number(item.hours);
              } else {
                acc[item.id] = { id: item.id, hours: Number(item.hours) };
              }
            });
            return acc;
          }, {});

          const finalResult = Object.values(result);

          finalResult.forEach((item) => {
            const matchingType = formData?.work_type.find(
              (type) => type.id === item.id,
            );

            if (matchingType) {
              cost += item.hours * Number(matchingType.user_rate);
            }
          });
          setGI(cost);
          setTotalCost(cost);
        } else {
          const rawCost = sumUserPrices(dileverable);

          setGI(rawCost);
          setTotalCost(rawCost);
        }
      }
    } else {
      setGI(tracker);
      setTotalCost(tracker);
    }
  }, [dileverable, formData]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return formData && formData.type === 'project' ? (
    <Box
      sx={{
        boxShadow: '1px 1px 3px  rgba(0, 0, 0, 0.3)',
        width: { xl: '30%', lg: '31%', md: '32%', sm: '33%' },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginRight: '5px',
        borderRadius: '10px',
        alignItems: 'center',
        padding: {
          xl: '0.7rem .5rem',
          lg: '0.98rem .5rem',
          md: '0.60rem .5rem',
          sm: '0.60rem .5rem',
        },
      }}
    >
      <div>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {' '}
          Total
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {totalCost ? formatMoneyString(`${totalCost}`) : '$0'}
        </Typography>
      </div>
      <div style={{ borderLeft: '1px solid gray', paddingLeft: '15px' }}>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {screenSize < 1100 ? null : 'Target '}Budget
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            fontWeight: 'bold',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {formData.budget
            ? formatMoneyString(
                `${parseFloat(formData?.budget).toLocaleString('en-US', { maximumFractionDigits: 0 })}`,
              )
            : '0'}
        </Typography>
      </div>
    </Box>
  ) : (
    <Box
      sx={{
        boxShadow: '1px 1px 3px  rgba(0, 0, 0, 0.3)',
        width: { xl: '30%', lg: '33%', md: '33%', sm: '35%' },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginRight: '5px',
        borderRadius: '10px',
        alignItems: 'center',
        padding: {
          xl: '0.7rem .5rem',
          lg: '0.98rem .5rem',
          md: '0.60rem .5rem',
          sm: '0.60rem .5rem',
        },
      }}
    >
      <div>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {' '}
          Total
        </Typography>
        <Typography
          sx={{ fontSize: { xl: '13px', lg: '12px', md: '10px', sm: '10px' } }}
        >
          {totalCost ? `${formatMoneyString(`${totalCost}`)} /month` : '$0'}
        </Typography>
      </div>
      <div style={{ borderLeft: '1px solid gray', paddingLeft: '15px' }}>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {screenSize < 1100 ? null : 'Target '}Budget
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            fontWeight: 'bold',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {formData.budget
            ? formatMoneyString(
                `${parseFloat(formData?.budget).toLocaleString('en-US', { maximumFractionDigits: 0 })}`,
              )
            : 0}
        </Typography>
      </div>
    </Box>
  );
}

function DilTemplate({ setDeliverable, setOpenCanva, openCanva, formData }) {
  const [tableData, setTableData] = useState([]);
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const realTemplates = useSelector((state) => state.Misc.templates);
  useEffect(() => {
    dispatch(getTemplates({ access_token }));
  }, []);

  useEffect(() => {
    if (Array.isArray(realTemplates) && realTemplates.length) {
      setTableData(realTemplates);
    }
  }, [realTemplates]);

  const [selected, setSelected] = useState([]);
  return (
    <Offcanvas
      placement="bottom"
      show={openCanva}
      onHide={() => setOpenCanva(false)}
    >
      <Offcanvas.Header>
        <button
          type="button"
          aria-label="colse modal"
          className="close-modal"
          onClick={() => setOpenCanva(false)}
        />
      </Offcanvas.Header>

      <Offcanvas.Body>
        <Grid container direction="column">
          <Grid item container justifyContent="flex-end" alignItems="center">
            <button
              type="button"
              style={{
                background: '#c6a5ff',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                borderRadius: '8px',
              }}
              className="noBorder"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  padding: '0.88rem 1.44rem',
                  borderRight: '1px solid #000',
                  color: '#fff',
                }}
                onClick={() => {
                  setDeliverable(
                    selected.map((item) => {
                      return {
                        ...item,
                        deliverable_worktype: formData.work_type.map((i) => {
                          return {
                            id: i,
                            hours: '',
                          };
                        }),
                      };
                    }),
                  );
                }}
              >
                <ContentCopyIcon />
                Add To Estimate
              </Box>
              <Box>
                {Array.isArray(selected) && selected.length
                  ? `${selected.length} Selected`
                  : null}
              </Box>
            </button>
          </Grid>
          <Grid item container spacing={5}>
            {Array.isArray(tableData) && tableData.length ? (
              tableData.map((e) => {
                const disrapancy = selected.findIndex(
                  (ie) => Number(ie.id) === Number(e.id),
                );
                return (
                  <Grid
                    key={e?.id ?? e?.deliverable_name}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    xxl={2}
                    onClick={() => {
                      if (disrapancy !== -1) {
                        setSelected((prev) =>
                          prev.filter(() => !prev[disrapancy]),
                        );
                      } else {
                        setSelected([...selected, e]);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '1rem',
                        alignItems: 'center',
                        border: '1px solid lightGray',
                        borderTop: 0,
                        borderLeft: 0,
                        cursor: 'pointer',
                      }}
                    >
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '1rem',
                          backgroundColor:
                            disrapancy !== -1 ? '#c6a5ff' : '#f1f3f5',
                          borderRadius: '8px',
                          padding: '0.5rem',
                        }}
                      >
                        <li
                          style={{
                            paddingLeft: '0px',
                            marginBottom: '0px',
                          }}
                        >
                          <h5
                            style={{
                              marginBottom: '0px',
                            }}
                            key={e.id}
                          >
                            {' '}
                            {e.deliverable_name}
                          </h5>
                          <p
                            style={{
                              fontSize: '12px',
                              marginBottom: '0px',
                              color: '#8743fd',
                            }}
                          >
                            {' '}
                            {e.task} Tasks
                          </p>
                        </li>
                        <li>
                          <button
                            type="button"
                            style={{
                              backgroundColor: '#9152ff',
                              borderRadius: ' 6px',
                              height: '1.6rem',
                              width: '6rem',
                              color: 'white',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '0.8rem',
                              }}
                            >
                              Web Design
                            </span>
                          </button>
                        </li>
                      </ul>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          backgroundColor: '#e4ddf0',
                          borderRadius: ' 8px',
                          height: '2rem',
                          width: '7rem',
                          color: 'black',
                        }}
                      >
                        <AccessTimeOutlinedIcon
                          style={{
                            fontSize: '1.3rem',
                          }}
                        />
                        <div>
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            {' '}
                            {e.hours}
                          </span>{' '}
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            Hours{' '}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: '#f8f9fa',
                          borderRadius: '8px',
                          padding: '4px',
                          marginTop: '1rem',
                        }}
                      >
                        <h6
                          style={{
                            marginBottom: '0px',
                          }}
                        >
                          Description:
                        </h6>
                        <p
                          style={{
                            marginBottom: '0px',
                          }}
                          // eslint-disable-next-line
                          dangerouslySetInnerHTML={{
                            __html: e.description ? e.description : '<p> </p>',
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Grid
                container
                item
                sx={{ height: '100%', width: '100%', margin: '1rem 0' }}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Alert severity="info">You do not have any Templates. </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function NewEstimate({ converToEst, updateInnerNetNetTabs }) {
  const [loading, setLoading] = useState(false);
  const routeTo = useRouteTo();
  const [GI, setGI] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [steps, setSteps] = useState(1);
  const [tracker, setTracker] = useState(0);
  const [openCanva, setOpenCanva] = useState(false);
  const [isDetOpen, setIsDetOpen] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
  });
  const [dileverable, setDeliverable] = useState([]);
  const [tic, setTic] = useState(0);
  const access_token = useSelector((state) => state.auth.user.access);
  const [pic, setPic] = useState(null);
  const [view, setView] = useState(ViewMode.Week);
  const dispatch = useDispatch();
  const [worktypes, setWorktypes] = useState([]);
  const work = useSelector((state) => state.Misc.workTypes);
  const stepsRedux = useSelector((item) => item.oppertunity.storedNumber);
  const contacts_and_companies = useSelector((state) => state.Contacts);
  const [description, setDiscription] = useState('');
  const estimates = useSelector((state) => state.oppertunity.estimates);

  const setErrorState = ({ error, message }) => {
    setError(error);
    setErrorMessage(message);
  };

  useEffect(() => {
    const unique_id = estimates.find(
      (est) => est.id === formData.id,
    )?.unique_id;
    if (unique_id) {
      dispatch(storeStep(2));
      updateInnerNetNetTabs(
        'Estimate Detail',
        `${appRoutes.estimateDetail}/${unique_id}`,
      );
      routeTo(`${appRoutes.estimateDetail}/${unique_id}`);
      setLoading(false);
    }
  }, [estimates]);

  const currentUser = useSelector((state) => state.userData.userData);

  useEffect(() => {
    if (converToEst) {
      const companyID = contacts_and_companies?.companies.find(
        (item) => item.name === converToEst?.company,
      )?.id;
      setFormData({
        title: converToEst.name,
        type:
          converToEst.opportunity_type === 'Retainer Opportunity'
            ? 'retainer'
            : 'project',
        budget: converToEst.budget ? converToEst.budget : null,
        description: converToEst.description ? converToEst.description : '',
        company: converToEst.company ? companyID : null,
        est_start_date: converToEst.target_start
          ? converToEst.target_start
          : null,
        est_finish_date: converToEst.target_end ? converToEst.target_end : null,
        contact: converToEst.contact,
      });
      if (converToEst.description?.length > 0) {
        setDiscription(converToEst.description);
      }
    }
  }, [converToEst]);

  useEffect(() => {
    if (
      Array.isArray(formData.worktype) &&
      formData.worktype.length &&
      Array.isArray(work) &&
      work.length
    ) {
      const dataaa = work.filter((item) => formData.worktype.includes(item.id));
      setWorktypes(dataaa);
    } else {
      // eslint-disable-next-line
      return;
    }
  }, [formData]);

  function getNextEstimateNumber(estimates) {
    const nextNumber = estimates?.length || 1;
    const maxLength = estimates?.length.toString().length;
    return nextNumber.toString().padStart(maxLength + 1, '0');
  }

  const handleFirstStep = async () => {
    const nextEstimateNumber = getNextEstimateNumber(estimates);
    if (!formData.title) {
      setError(true);
      setErrorMessage('Estimate Name is Required.');
    } else if (!formData.worktype) {
      setError(true);
      setErrorMessage('Please Specify Work Types.');
    } else {
      setLoading(true);
      setError(false);
      setErrorMessage('');
      const res = await fetch(`${ENDPOINT}/api/opportunity/create-estimate/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          formData.type === 'project'
            ? JSON.stringify({
                ...formData,
                budget: Number(formData.budget ? formData.budget : 0),
                est_startdate: formData.est_start_date
                  ? formData.est_start_date
                  : new Date(
                      new Date().setDate(new Date().getDate() + 14),
                    ).toISOString(),
                est_enddate: formData.est_finish_date
                  ? formData.est_finish_date
                  : null,
                estimate_number: nextEstimateNumber,
                members:
                  Array.isArray(formData.members) && formData.members.length
                    ? formData.members
                    : [currentUser.email],
                Multiplier: [],
                estimate_worktype: worktypes.map((item) => ({
                  id: item.id,
                  hours: 0,
                })),
              })
            : JSON.stringify({
                ...formData,
                estimate_deliverable: [],
                estimate_number: nextEstimateNumber,
                est_startdate: formData.est_start_date
                  ? formData.est_start_date
                  : new Date(
                      new Date().setDate(new Date().getDate() + 14),
                    ).toISOString(),
                est_enddate: formData.est_finish_date
                  ? formData.est_finish_date
                  : null,
                members:
                  Array.isArray(formData.members) && formData.members.length
                    ? formData.members
                    : [currentUser.email],
                estimate_worktype: worktypes.map((item) => ({
                  id: item.id,
                  hours: 0,
                })),
              }),
      });
      const data = await res.json();
      if (!res.ok) {
        setLoading(false);
        setErrorState({
          error: true,
          message: 'Something went wrong Creating your Estimate',
        });
        setLoading(false);
      } else {
        if (converToEst && converToEst.isOpa) {
          // eslint-disable-next-line
          const res2 = fetch(
            `${ENDPOINT}/api/opportunity/update/${converToEst.id}/`,
            {
              method: 'PUT',
              headers: {
                Authorization: `JWT ${access_token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: converToEst.name,
                status_updated_at: moment().toISOString(),                
                opportunity_status: 'archieved',
              }),
            },
          );
        }
        setFormData(data);
        dispatch(getEstimates({ access_token }));
      }
    }
  };

  const handleSecondStep = () => {
    if (formData.type !== 'project') {
      dispatch(storeStep(4));
    } else {
      dispatch(storeStep(3));
    }
  };

  const [temp, setTemp] = useState();

  const [estimate_paymentterms, setEstimate_paymentterms] = useState({
    template_id: 1,
    payment_terms: `We bill using a progressive invoicing method with no deposit required for this job.
      Progressive Invoicing occurs two times per month on the 1st and 16th for the previous periods.
      A time log report will be provided with the invoice for the total hours accrued for that period. All
      invoices are due and payable Net 10 from issuance.
      
      RH bills for the actual time incurred, should the hours be less than the estimate.
      The hours are billed at a standard rate of $185/hour. Changes in scope or understanding may
      affect the completeness of deliverables and/or extend the effort and may result in additional
      costs to Client.`,
  });

  const [estimate_termsconditions, setEstimate_termsconditions] = useState({
    template_id: 1,
    terms_conditions: `This Job Order presented by Right Here Interactive, LLC. (“RHI”) to Client represents the full and complete understanding of the deliverables.  Modifications or additions to the deliverables listed in this Job Order are subject to additional charges (“Change Orders”).  RHI warrants the deliverables will be free from mechanical defect caused solely by RHI for a period of Thirty (30) days from the date of delivery.  If material defects are found, RHI shall be responsible for correcting them without unreasonable delay, at their expense. This shall be the exclusive warranty available to Client.  This warranty is considered nullified, void or otherwise not valid if any other party than those authorized by RHI in any way access or modify the deliverables or any components in part or whole without the express written consent of RHI. Client acknowledges that RHI does not warrant any third-party software licensed, purchased or by any other means acquired for use in the creation of the deliverables, nor that software will remain active, available, updated or registered for more than Thirty (30) days.  RHI does not warrant servers to which the deliverables are run on, stored or hosted.  

      For the period during and One (1) year after the completion of this Job Order, neither party shall directly or indirectly employ or contract to any employees, contractors or freelancers of the other party without the prior written approval of the other party.  Client does hereby allow RHI to use their name, URL(s), taglines, logo(s) and/or quotes for promotional purposes. 
      
      Client acknowledges that RHI must allocate resources over a period of time to complete the deliverables and that the scheduling of those resources creates costs for RHI. If after the commencement of work, Client creates a delay in RHI’s work effort for more than Ten (10) consecutive days for any reason, the job will be considered “Stalled” and RHI may temporarily remove it from production.  Once Client is able to resume the Job Order and is approved by RHI, Client will pay in advance any remaining balance that brings them to Ninety Five Percent (95%) of the sum total of the Job Order and any Change Orders, plus a Reinstatement Fee of Seven and a Half Percent (7.5%) of the Job Order total.  Once the amounts are paid in full, production will resume with a new schedule issued by RHI.
      
      If this Job Order is terminated for any reason, Client agrees that no refunds will be given to Client for any payments made to RHI up to the date of termination. Upon termination, RHI will issue a final invoice for the fees in excess of payments made by Client, which at the discretion of RHI will be determined either by hours incurred at a rate of One Hundred Fifty Dollars ($150) per hour or by the value of the deliverables completed, plus Change Orders fees, plus a Cancellation Fee of Twenty Percent (20%) of the Job Order total.  The total amount of the final invoice plus payments already made by Client will not exceed the sum total of the Job Order, plus Change Orders, plus if applicable the Reinstatement Fee. Client agrees they will pay the invoice in full within Ten (10) days.  
      `,
  });

  const UpdateRequest = async () => {
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
                ...shallowCopy,
                payment_terms_selected: formData?.payment_terms
                  ? formData?.payment_terms[0]?.id
                  : null,
                terms_conditions_selected: formData?.terms_conditions
                  ? formData?.terms_conditions[0].id
                  : null,
                estimate_deliverable: dileverable?.map((item) => {
                  // eslint-disable-next-line
                  delete item.deliverable_multiplier;
                  return {
                    ...item,
                    deliver_id: item.id,
                    user_price: item.user_price
                      ? item.user_price
                      : calculateLOERate(item, formData.work_type),
                    deliverable_startdate: moment().toISOString(),
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : 0,
                      }),
                    ),
                  };
                }),

                collaborator: formData?.collaborator?.map((item) => item?.id),
              })
            : JSON.stringify({
                ...shallowCopy,
                estimate_deliverable: dileverable?.map((item) => {
                  // eslint-disable-next-line
                  delete item.task_deliverable;

                  if (item.deliverable_multiplier) {
                    return {
                      ...item,
                      user_price: item.user_price
                        ? item.user_price
                        : calculateLOERate(item, formData.work_type),
                      deliverable_startdate: moment().toISOString(),
                      payment_terms_selected: formData?.payment_terms[0]?.id,
                      terms_conditions_selected:
                        formData?.terms_conditions[0].id,
                      deliver_id: item.id,
                      deliverable_enddate: item.deliverable_enddate,
                      deliverable_multiplier: item.deliverable_multiplier.map(
                        (multi) => ({
                          multiplier_id: multi.id,
                          label: multi.label,
                          rate: multi.rate,
                          operation: multi.operation,
                        }),
                      ),
                      deliverable_worktype: item.deliverable_worktype.map(
                        (wtt) => ({
                          ...wtt,
                          hours: wtt.hours ? Number(wtt.hours) : 0,
                        }),
                      ),
                    };
                    // eslint-disable-next-line
                  } else {
                    return {
                      ...item,
                      user_price: item.user_price
                        ? item.user_price
                        : calculateLOERate(item, formData.work_type),
                      deliverable_startdate: moment().toISOString(),
                      payment_terms_selected: formData?.payment_terms[0]?.id,
                      terms_conditions_selected:
                        formData?.terms_conditions[0].id,
                      deliver_id: item.id,
                      deliverable_enddate: item.deliverable_enddate,
                      deliverable_worktype: item.deliverable_worktype.map(
                        (wtt) => ({
                          ...wtt,
                          hours: wtt.hours ? Number(wtt.hours) : 0,
                        }),
                      ),
                    };
                  }
                }),
              }),
      },
    );

    if (!res.ok) {
      setError(true);
      setErrorMessage('Couldn’t Update');
    }
  };

  const handleThirdStep = async () => {
    if (
      dileverable &&
      dileverable.length &&
      dileverable.every((delivery) => {
        return (
          Array.isArray(delivery.deliverable_worktype) &&
          delivery.deliverable_worktype.length > 0 &&
          delivery.deliverable_worktype.every(
            (thing) => thing.hours !== 'undefined',
          )
        );
      })
    ) {
      dispatch(storeStep(formData.type === 'project' ? 4 : 5));
      setEstimate_paymentterms(formData.payment_terms);
      setEstimate_termsconditions(formData.terms_conditions);
    } else {
      setErrorState({
        error: true,
        message:
          Array.isArray(formData.dileverable) && formData.dileverable.length
            ? 'Level of effort must be defined'
            : 'At least one Deliverable is required for an Estimate',
      });
    }
  };

  function captureChartSnapshot() {
    // find the Gantt chart element by class name
    const chartElement = document.getElementsByClassName('gantt-target')[0];
    if (!chartElement) {
      return;
    }

    const fullHeight = chartElement.scrollHeight;
    const fullWidth = chartElement.scrollWidth;
    const canvas = document.createElement('canvas');
    canvas.width = fullWidth;
    canvas.height = fullHeight;
    const context = canvas.getContext('2d');
    html2canvas(chartElement, { backgroundColor: null })
      // eslint-disable-next-line
      .then(function (canvas) {
        context.drawImage(canvas, 0, 0);
        const snapshot = canvas.toDataURL('image/png');
        setPic(snapshot);
      }) // eslint-disable-next-line
      .catch(function (error) {});
  }

  const handleFourthStep = async () => {
    setDeliverable((prev) => {
      const softCopy = [...prev];
      const realData = softCopy.map((item) => {
        const shallowCopy = { ...item };
        const ele = temp.find(
          (entry) => Number(entry.dentifier) === Number(item.id),
        );
        return {
          ...shallowCopy,
          deliverable_startdate: new Date(ele.start)
            .toISOString()
            .split('T')[0],
          deliverable_enddate: new Date(ele.end).toISOString().split('T')[0],
          total_hours: item.total_loe,
          task_deliverable: item.sub_task
            ? item.sub_task.map((tk) => ({
                ...tk,
                task_name: tk.name,
                task_worktypes: tk.task_worktypes.map((twt) => ({
                  ...twt,
                  hours: twt.hours ? Number(twt.hours) : 0,
                })),
              }))
            : [],
        };
      });
      return realData;
    });

    dispatch(storeStep(5));
  };

  const handleFifthStep = () => {
    dispatch(storeStep(6));
  };

  const handleSixthStep = () => {
    const shallowCopy = dileverable.map((item) => ({ ...item }));
    setFormData({
      ...formData,
      estimate_deliverable: shallowCopy,
    });

    dispatch(storeStep(7));
  };

  const handleSeventhStep = async () => {
    updateInnerNetNetTabs('Sales', appRoutes.sales);
    routeTo(appRoutes.sales);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setErrorState({ error: false, message: '' });
      }, 3000);
    }
  }, [error]);

  const handleSubmit = () => {
    if (steps === 1) {
      handleFirstStep();
    } else if (steps === 2) {
      handleSecondStep();
    } else if (steps === 3) {
      handleThirdStep();
      UpdateRequest();
    } else if (steps === 4) {
      captureChartSnapshot();
      handleFourthStep();
    } else if (steps === 5) {
      handleFifthStep();
    } else if (steps === 6) {
      handleSixthStep();
    } else if (steps === 7) {
      handleSeventhStep();
    }
  };

  const [showDescription, setShowDescription] = useState(false);

  const printDocument = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input, {
      width: input.scrollWidth,
      height: input.scrollHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // eslint-disable-next-line
      const pdf = new jsPDF();
      pdf.addImage(
        imgData,
        'JPEG',
        0,
        0,
        pdf.internal.pageSize.width,
        pdf.internal.pageSize.height,
      );
      pdf.save('Estimate Document');
    });
  };

  const [companySelected, setCompanySelected] = useState(null);

  const [estimate_hardcost, setEstHardCost] = useState([]);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const start =
    formData.est_startdate && formData.est_startdate.endsWith('Z')
      ? formData.est_startdate.split('T')
      : null;
  const end =
    formData.est_enddate && formData.est_enddate.endsWith('Z')
      ? formData.est_enddate.split('T')
      : null;

  const [value1, setValue1] = useState([null, null]);

  function handleRangeChange(event) {
    setValue1(event);
  }

  const [tat, setTat] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [selectedWorkType, setSelectedWorkType] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCollabarators, setSelectedCollabarators] = useState([]);

  const [show, setShow] = useState(false);

  return (
    <>
      <section className="oppor-section5">
        <VerticalLinearSteppe
          setSteps={setSteps}
          steps={steps}
          formData={formData}
          isDetOpen={isDetOpen}
          stepsRedux={stepsRedux}
        />
        <div className="oppor-row" style={{ width: '100%' }}>
          <div>
            <div className="oppor-col">
              <h3
                className="fstxtPro"
                style={{ fontSize: screenSize < 1300 ? '22px' : '25px' }}
              >
                Job Estimate
              </h3>
              <img
                alt="help-video"
                src={helpVid}
                onClick={() => {
                  setShow(true);
                }}
                style={{
                  color: '#a6a6a6',
                  marginLeft: '10px',
                  width: '28px',
                  height: '28px',
                  cursor: 'pointer',
                }}
              />
            </div>
            {steps > 1 ? (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <KeyboardReturnIcon sx={{ transform: 'scaleX(-1)' }} />
                <p style={{ color: '#9257fa' }}>{formData.title}</p>
                {companySelected ? (
                  <p
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#ededed',
                      color: 'GrayText',
                      borderRadius: '8px',
                    }}
                  >
                    {companySelected}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>
          <div style={{ width: '60%' }}>
            {steps === 3 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <EstHeader
                  dileverable={dileverable}
                  setDeliverable={setDeliverable}
                  formData={formData}
                  openCanva={openCanva}
                  setOpenCanva={setOpenCanva}
                  screenSize={screenSize}
                />
                <BudgetCheck
                  dileverable={dileverable}
                  formData={formData}
                  tracker={tracker}
                  setTracker={setTracker}
                  setGI={setGI}
                  estimate_hardcost={estimate_hardcost}
                  tic={tic}
                />
              </div>
            ) : null}

            {steps === 4 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Box
                  sx={{
                    border: '1px solid #D9D9D9',
                    padding: '0.7rem .5rem',
                    borderRadius: '8px',
                    width: { xl: '42%', lg: '57%', md: '80%', sm: '85%' },
                    backgroundColor: '#e7deff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100px',
                    marginRight: '1.3rem',
                    boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      color: 'gray',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',

                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {value1 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',
                            justifyContent: 'space-around',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            <CalendarMonthOutlinedIcon
                              sx={{
                                cursor: 'pointer',
                                fontSize: {
                                  xl: '25px',
                                  lg: '22px',
                                  md: '20px',
                                  sm: '20px',
                                },
                              }}
                              onClick={() => setValue1(null)}
                            />
                            <Typography
                              sx={{
                                fontSize: {
                                  xl: '14px',
                                  lg: '13px',
                                  md: '12px',
                                  sm: '11px',
                                },
                              }}
                            >
                              Start:{' '}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: {
                                  xl: '14px',
                                  lg: '13px',
                                  md: '12px',
                                  sm: '11px',
                                },
                              }}
                              style={{ marginTop: '0px', fontWeight: 'normal' }}
                            >
                              {value1 && value1[0]
                                ? value1[0].toLocaleString('en-US', options)
                                : start[0]}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            <CalendarMonthOutlinedIcon
                              sx={{
                                cursor: 'pointer',
                                fontSize: {
                                  xl: '25px',
                                  lg: '22px',
                                  md: '20px',
                                  sm: '20px',
                                },
                              }}
                              onClick={() => setValue1(null)}
                            />
                            <Typography
                              sx={{
                                fontSize: {
                                  xl: '14px',
                                  lg: '13px',
                                  md: '12px',
                                  sm: '11px',
                                },
                              }}
                            >
                              End:{' '}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: {
                                  xl: '14px',
                                  lg: '13px',
                                  md: '12px',
                                  sm: '11px',
                                },
                              }}
                              style={{ marginTop: '0px', fontWeight: 'normal' }}
                            >
                              {value1 && value1[1]
                                ? value1[1].toLocaleString('en-US', options)
                                : end[0]}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <DateRangePicker
                            style={{
                              width: '180px',
                              backgroundColor: 'none',
                              border: 'none',
                            }}
                            placeholder="Start "
                            // eslint-disable-next-line
                            onChange={handleRangeChange}
                            defaultOpen={true}
                          />

                          <DateRangePicker
                            style={{
                              width: '180px',
                              backgroundColor: 'none',
                              border: 'none',
                            }}
                            placeholder="End"
                            // eslint-disable-next-line
                            onChange={handleRangeChange}
                          />
                        </div>
                      )}
                    </div>
                  </Box>{' '}
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      color: 'gray',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '25px',
                        marginTop: '0px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (view === ViewMode.Week) {
                          setView(ViewMode.Day);
                        } else if (view === ViewMode.Month) {
                          setView(ViewMode.Week);
                        } else {
                          setView(ViewMode.Month);
                        }
                      }}
                    >
                      -
                    </p>
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      value={
                        view === ViewMode.Day
                          ? 0
                          : view === ViewMode.Week
                            ? 1
                            : 2
                      }
                      valueLabelDisplay="auto"
                      step={1}
                      min={0}
                      max={2}
                      sx={{
                        color: 'gray',
                        width: { xl: '40%', lg: '40%', md: '35%', sm: '33%' },
                      }}
                      onChange={(e) => {
                        if (e.target.value === 0) {
                          setView(ViewMode.Day);
                        }
                        if (e.target.value === 1) {
                          setView(ViewMode.Week);
                        }
                        if (e.target.value === 2) {
                          setView(ViewMode.Month);
                        }
                      }}
                    />{' '}
                    <p
                      style={{
                        fontSize: '25px',
                        marginTop: '0px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (view === ViewMode.Week) {
                          setView(ViewMode.Month);
                        } else if (view === ViewMode.Month) {
                          setView(ViewMode.Day);
                        } else {
                          setView(ViewMode.Week);
                        }
                      }}
                    >
                      +
                    </p>
                  </Box>
                </Box>
                <BudgetCheck
                  formData={formData}
                  dileverable={dileverable}
                  tracker={tracker}
                  setTracker={setTracker}
                  setGI={setGI}
                  estimate_hardcost={estimate_hardcost}
                  tic={tic}
                />
              </div>
            ) : null}

            {steps === 5 ? (
              <div style={{ width: '100%', display: 'flex' }}>
                <div
                  style={{
                    width: '70%',
                    justifyContent: 'end',
                    display: 'flex',
                    alignItems: 'end',
                    marginRight: '1rem',
                  }}
                />
                <BudgetCheck
                  formData={formData}
                  dileverable={dileverable}
                  tracker={tracker}
                  setTracker={setTracker}
                  setGI={setGI}
                  estimate_hardcost={estimate_hardcost}
                  tic={tic}
                />
              </div>
            ) : null}

            {steps === 6 ? (
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '70%', marginRight: '1rem' }} />
                <BudgetCheck
                  formData={formData}
                  dileverable={dileverable}
                  tracker={tracker}
                  setTracker={setTracker}
                  setGI={setGI}
                  estimate_hardcost={estimate_hardcost}
                  tic={tic}
                />
              </div>
            ) : null}

            {steps === 7 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Box
                  sx={{
                    border: '1px solid #D9D9D9',
                    borderRadius: '8px',
                    width: { xl: '48%', lg: '60%', md: '65%', sm: '70%' },
                    backgroundColor: '#e7deff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    marginRight: '1.3rem',
                    boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                    padding: {
                      xl: '1rem 1rem',
                      lg: '1rem 0.7rem',
                      md: '.5rem 0.2rem',
                      sm: '.4rem 0.2rem',
                    },
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                      justifyContent: 'center',
                      color: 'gray',
                      gap: '.8rem',
                    }}
                  >
                    <LocalPrintshopIcon
                      sx={{
                        cursor: 'pointer',
                        fontSize: {
                          xl: '25px',
                          lg: '24px',
                          md: '22px',
                          sm: '20px',
                        },
                      }}
                      onClick={printDocument}
                    />
                    <DescriptionIcon
                      sx={{
                        cursor: 'pointer',
                        fontSize: {
                          xl: '25px',
                          lg: '24px',
                          md: '22px',
                          sm: '20px',
                        },
                      }}
                    />
                    <CalendarMonthIcon
                      sx={{
                        cursor: 'pointer',
                        fontSize: {
                          xl: '25px',
                          lg: '24px',
                          md: '22px',
                          sm: '20px',
                        },
                      }}
                    />

                    <ButtonGroup
                      sx={{
                        color: 'black',
                        padding: {
                          xl: '0rem 1rem',
                          lg: '0rem 1rem',
                          md: '0rem .5rem',
                          sm: '0rem .5rem',
                        },
                        borderRadius: {
                          xl: '10px',
                          lg: '10px',
                          md: '8px',
                          sm: '5px',
                        },
                        backgroundColor: '#cbb6fc',
                        border: 'none',
                        fontSize: {
                          xl: '15px',
                          lg: '13px',
                          md: '11px',
                          sm: '9px',
                        },
                        fontWeight: '500',
                        letterSpacing: { xl: '1px', lg: '0.5px' },
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => setShowDescription(!showDescription)}
                    >
                      <NotesIcon
                        sx={{
                          marginRight: '10px',
                          fontSize: {
                            xl: '18px',
                            lg: '15px',
                            md: '13px',
                            sm: '11px',
                          },
                        }}
                      />
                      {screenSize < 1300 ? 'DESCRIPTION' : 'ADD DESCRIPTION'}
                    </ButtonGroup>
                  </Box>{' '}
                </Box>
                {showDescription ? <DescriptionEstSixthStep /> : null}
                <BudgetCheck
                  formData={formData}
                  dileverable={dileverable}
                  tracker={tracker}
                  setTracker={setTracker}
                  setGI={setGI}
                  estimate_hardcost={estimate_hardcost}
                  tic={tic}
                />
              </div>
            ) : null}
          </div>
        </div>

        <section
          className={stepsRedux < 6 ? 'container-psU-p5' : null}
          style={stepsRedux !== 6 ? { padding: '2rem 1.5rem' } : {}}
        >
          {stepsRedux === 1 && (
            <FirstStep
              setCompanySelected={setCompanySelected}
              formData={formData}
              setFormData={setFormData}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              selectedWorkType={selectedWorkType}
              setSelectedWorkType={setSelectedWorkType}
              setSelectedTeam={setSelectedTeam}
              selectedTeam={selectedTeam}
              setSelectedCollabarators={setSelectedCollabarators}
              selectedCollabarators={selectedCollabarators}
              description={description}
              setDiscription={setDiscription}
            />
          )}
          {stepsRedux === 2 && (
            <SecondStep
              formData={formData}
              setFormData={setFormData}
              uniqueID="1"
            />
          )}

          {stepsRedux === 3 && formData?.id && (
            <ThirdStep
              formData={formData}
              setFormData={setFormData}
              steps={stepsRedux}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              setErrorState={setErrorState}
              setIsDetOpen={setIsDetOpen}
              handleSeventhStep={handleSeventhStep}
              setEstimate_paymentterms={setEstimate_paymentterms}
              setEstimate_termsconditions={setEstimate_termsconditions}
            />
          )}

          {stepsRedux === 4 && (
            <FourthStep
              formData={formData}
              setFormData={setFormData}
              temp={temp}
              setTemp={setTemp}
              setPic={setPic}
              view={view}
              setView={setView}
              dileverable={dileverable}
            />
          )}

          {stepsRedux === 5 && (
            <FifthStep
              formData={formData}
              setFormData={setFormData}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              estimate_hardcost={estimate_hardcost}
              setEstHardCost={setEstHardCost}
              setTracker={setTracker}
              setTat={setTat}
              tat={tat}
              setTic={setTic}
            />
          )}

          {stepsRedux === 6 && (
            <SixthStep
              formData={formData}
              dileverable={dileverable}
              GI={GI}
              estimate_hardcost={estimate_hardcost}
              setEstHardCost={setEstHardCost}
              setGI={setGI}
            />
          )}
          {stepsRedux === 7 && (
            <Final
              pic={pic}
              dileverable={dileverable}
              formData={formData}
              GI={GI}
              estimate_hardcost={estimate_hardcost}
              setDeliverable={setDeliverable}
              setTat={setTat}
              tat={tat}
              estimate_paymentterms={estimate_paymentterms}
              setEstimate_paymentterms={setEstimate_paymentterms}
              estimate_termsconditions={estimate_termsconditions}
              setEstimate_termsconditions={setEstimate_termsconditions}
            />
          )}
        </section>
        {error && (
          <Shake>
            <Alert severity="info">{errorMessage}</Alert>
          </Shake>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem 2.2rem',
            backgroundColor: '#fff',
            height: { xl: '90px', lg: '120px' },
            marginBottom: { xl: '.5rem', lg: '1rem' },
          }}
        >
          <button
            type="button"
            className="cancel-btn"
            // eslint-disable-next-line
            onClick={() => {
              if (stepsRedux === 1) {
                updateInnerNetNetTabs('Sales', appRoutes.sales);
                routeTo(appRoutes.sales);
              } else {
                // eslint-disable-next-line
                if (formData.type === 'retainer' && steps === 5) {
                  dispatch(storeStep(3));
                } else {
                  if (stepsRedux === 3 && !formData.collaborators) {
                    return dispatch(storeStep(1));
                  }
                  return dispatch(storeStep(stepsRedux - 1));
                }
              }
            }}
          >
            {stepsRedux === 1 ? 'Cancel' : 'Back'}
          </button>
          <Box>
            <button type="button" className="create-btn" onClick={handleSubmit}>
              {stepsRedux < 7 ? (
                loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.1rem',
                    }}
                  >
                    <PreLoader />
                  </Box>
                ) : (
                  'Next'
                )
              ) : (
                'Finish'
              )}
            </button>
          </Box>
        </Box>
      </section>
      <DilTemplate
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        setOpenCanva={setOpenCanva}
        openCanva={openCanva}
        formData={formData}
      />
      <NewEstimateHelpVideos show={show} setShow={setShow} steps={stepsRedux} />
    </>
  );
}

export default NewEstimate;
