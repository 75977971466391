import { Autocomplete, TextField } from '@mui/material';

function SearchableListField({
  searchableListFieldID,
  label,
  listOfOptions,
  propertiesToDisplay,
  onChangeFunction,
  multiple = true,
}) {
  // eslint-disable-next-line no-unused-vars
  const handleOnChange = (e, value) => {
    onChangeFunction(value || []);
  };

  const getLabel = (option) =>
    propertiesToDisplay
      .map((prop) => option[prop])
      .filter(Boolean)
      .join(' ');

  return (
    <Autocomplete
      id={searchableListFieldID}
      options={
        Array.isArray(listOfOptions) && listOfOptions.length
          ? listOfOptions
          : []
      }
      includeInputInList
      multiple={multiple}
      getOptionLabel={getLabel}
      onChange={handleOnChange}
      style={{ width: 300, marginTop: '0.7rem' }}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap',
          overflow: 'hidden',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          color="secondary"
          variant="standard"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '1rem',
              fontFamily: 'Open Sans',
              fontWeight: '500',
              color: '#000000',
              textAlign: 'left',
            },
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #711fff',
            },
            '& .MuiInput-underline:after': {
              borderBottom: '2px solid #711fff',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: '2px solid #711fff',
            },
          }}
          InputProps={{
            ...params.InputProps,
            style: { width: 250 },
          }}
        />
      )}
    />
  );
}

export default SearchableListField;
