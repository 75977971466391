import SectionHeader from '../../../../Componenets/atomic-atoms/SectionHeader';
import ComingSoon from '../../../../Componenets/atomic-atoms/ComingSoon';

function Categories() {
  return (
    <>
      <SectionHeader title="Net Net U Categories" />
      <ComingSoon title="Net Net U Categories" />
    </>
  );
}

export default Categories;
