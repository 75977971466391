/* eslint-disable */
import React from 'react';
import './responsive.css';
import './muiClasses.css';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../Screens/InnerApp/InnerApp.css';
import { useEffect, useState } from 'react';
import Dashboard from './Tools/Dashboard/Dashboard';
import DiscountEngine from './Tools/DiscountEngine/DiscountEngine';
import NotificationsandAlerts from './Tools/NotificationsandAlerts/NotificationsandAlerts';
import Courses from './NetNetU/Courses/Courses';
import Lessons from './NetNetU/Lessons/Lessons';
import Categories from './NetNetU/Categories/Categories';
import TemplateCategories from './Templates/TemplateCategories/TemplateCategories';
import Templates from './Templates/Templates/Templates';
import AccountReports from './Reports/AccountReports/AccountReports';
import LogFiles from './SystemAdmin/LogFiles/LogFiles';
import SystemAdminUsers from './SystemAdmin/Users/SystemAdminUsers';
import CustomerDetail from '../SuperAdmin/Tools/Customers/customersUpdated/customerDetail/CustomerDetail';
import NewDisc from './Tools/DiscountEngine/NewDisc';
import SideBarDrawer from '../../Componenets/SuperAdminSideNav/SideBarDrawer';
import CustomersNew from './Tools/Customers/customersUpdated/CustomersNew';
import AddNewCourses from './NetNetU/Courses/AddNewCourse/AddNewCourses';
import AddChapter from './NetNetU/Courses/AddNewChapter/AddChapter';
import ScreenOne1 from './screens/create-help-videos/ScreenOne1';
import DragDrop from './screens/help-videos-selection/DragDrop';
import SectionVideo from './NetNetU/SectionVideos/SectionVideo';
import Authors from './NetNetU/Authors/Authors';
import AuthorsDetail from './NetNetU/Authors/AuthorsDetail';
import AuthorsNew from './NetNetU/Authors/NewAuthors/AuthorsNew';
import NotificationAndAlerts from './Tools/NotificationsandAlerts/Notification&Alert/NotificationAndAlerts';
import NewNotiAndAlert from './Tools/NotificationsandAlerts/Notification&Alert/NewNotiAndAlert';
import HelpTickets from './Tools/HelpTickets/HelpTickets';
import NotAvailable from './SystemAdmin/NotAvailableInMobile/NotAvailable';
import HelpVideoScreen from './screens/create-help-videos/HelpVideoScreen';
import SubHelpVideoScreen from './screens/create-help-videos/SubHelpVideoScreen';
import { appRoutes } from '../../appRoutes';

export default function SuperAdmin() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize2 = () => {
    setScreenHeight(window.innerHeight); // Use window.innerHeight to get the correct height
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize2);
    return () => {
      window.removeEventListener('resize', handleResize2);
    };
  }, []);

  return (
    <>
      <section style={{ zIndex: '1200' }}>
        {screenSize < 650 ? (
          <NotAvailable />
        ) : (
          <div className="superAdminWrapper">
            <div className="d-none-in-mobile">
              <SideBarDrawer />
            </div>
            <div className="mainContent">
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Dashboard screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.tools.toolsCustomers}
                  element={<CustomersNew screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={`${appRoutes.superAdmin.tools.customerDetail}/:id`}
                  element={<CustomerDetail />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.tools.toolsDiscountEngine}
                  element={<DiscountEngine screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.tools.toolsNotificationsandAlerts}
                  element={
                    <NotificationAndAlerts screenHeight={screenHeight} />
                  }
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.tools.toolsDiscountEngineNew}
                  element={<NewDisc />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.tools.toolsNotificationNew}
                  element={<NewNotiAndAlert />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.tools.toolHelpTickets}
                  element={<HelpTickets screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.netnetUCatagories}
                  element={<Categories />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.netnetUCourses}
                  element={<Courses screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.newNetNetUCourses}
                  element={<AddNewCourses />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.newNetNetUCoursesChapter}
                  element={<AddChapter />}
                />

                {/* <Route exact path="/NetNetU/Courses/Lesson/New" element={<><AddLesson /></>} /> */}
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.netNetUAuthors}
                  element={<Authors screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.newNetNetUAuthors}
                  element={<AuthorsNew />}
                />
                <Route
                  exact
                  path={`${appRoutes.superAdmin.netNetU.authorsDetail}/:id`}
                  element={<AuthorsDetail />}
                />

                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.netNetUlessons}
                  element={<Lessons screenHeight={screenHeight} />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.netNetUSectionVideos}
                  element={<SectionVideo />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.createNetNetUHelpVideos}
                  element={<HelpVideoScreen />}
                />

                <Route
                  exact
                  path={`${appRoutes.superAdmin.netNetU.netNetUCreateHelpVideosDetail}/:id`}
                  element={<ScreenOne1 />}
                />

                <Route
                  exact
                  path={appRoutes.superAdmin.netNetU.netNetUselectionHelpVideos}
                  element={<DragDrop />}
                />

                <Route
                  exact
                  path={`${appRoutes.superAdmin.netNetU.subHelpVideosDetail}/:id`}
                  element={<SubHelpVideoScreen />}
                />

                <Route
                  exact
                  path={appRoutes.superAdmin.template.templatesCatagories}
                  element={<TemplateCategories />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.template.templatesSuperAdmin}
                  element={<Templates />}
                />

                <Route
                  exact
                  path={appRoutes.superAdmin.reports.accountReports}
                  element={<AccountReports />}
                />

                <Route
                  exact
                  path={appRoutes.superAdmin.systemAdmin.systemAdminUsers}
                  element={<SystemAdminUsers />}
                />
                <Route
                  exact
                  path={appRoutes.superAdmin.systemAdmin.systemAdminLogFiles}
                  element={<LogFiles />}
                />
              </Routes>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
