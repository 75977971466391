import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Grid, Box, Alert, TextField, Typography } from '@mui/material';

import { setUser } from '../Redux/auth';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';
import { userData } from '../../../Screens/InnerApp/userData';
import { PreLoader1 } from '../../Misc';

import { appRoutes } from '../../../appRoutes';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { useDebounce } from '../../../hooks/useDebounce';

const fieldStyles = {
  width: '100%',
  fontWeight: '600',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ADADAD',
    },
    '&:hover fieldset': {
      borderColor: '#711fff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#5a0bdf',
    },

    '&.Mui-error fieldset': {
      borderColor: 'red',
    },

    '&.Mui-disabled fieldset': {
      borderColor: 'grey',
    },
  },
  '& .MuiFormLabel-root': {
    color: '#03071e66',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#5a0bdf',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: 'red',
  },
  '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    top: '-1px',
  },
};

function IntroTxt() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.5rem',
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
    >
      <h3 className="sub-heading">Let&apos;s set up your account!</h3>
    </Box>
  );
}

function SetUpAccountForm({ setInvitation }) {
  const dispatch = useDispatch();
  const routeTo = useRouteTo();

  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState(NaN);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    repass: '',
  });

  const debouncedFirstName = useDebounce(formData.first_name, 500);
  const debouncedLastName = useDebounce(formData.last_name, 500);
  const debouncedEmail = useDebounce(formData.email, 500);
  const debouncedPassword = useDebounce(formData.password, 500);
  const debouncedRePassword = useDebounce(formData.repass, 500);

  const urlParams = new URLSearchParams(window.location.search);
  const urlAccessToken = urlParams.get('access_token');
  const urlRefreshToken = urlParams.get('refresh_token');
  const uid = urlParams.get('uid');
  const urlToken = urlParams.get('token');

  const settingData = {
    access: urlAccessToken,
    refresh: urlRefreshToken,
  };

  const [err, setErr] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr('');
      }, 3000);
    }
  }, [err]);

  useEffect(() => {
    if (formData.password !== formData.repass) {
      setPasswordError('Passwords do not match.');
    } else {
      setPasswordError('');
    }
  }, [formData.password, formData.repass]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const fetchAndUpdateUserDetails = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${urlAccessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user details.');
      }

      const data = await response.json();
      setID(data.id);
      dispatch(userData(data));
      dispatch(setUser(settingData));

      if (data && data.email) {
        setFormData({
          ...formData,
          email: data.email,
        });
      }

      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error at "fetchAndUpdateUserDetails"', error);
      return false;
    }
  };

  useEffect(() => {
    fetchAndUpdateUserDetails();
  }, []);

  const validateForm = (data) => {
    if (!data.first_name) {
      setErr('First Name is required.');
      return false;
    }

    if (!data.last_name) {
      setErr('Last Name is required.');
      return false;
    }

    if (!data.email) {
      setErr('Email is required.');
      return false;
    }

    if (!data.password) {
      setErr('Password is required.');
      return false;
    }

    if (!data.repass) {
      setErr('Repeat Password is required.');
      return false;
    }

    if (data.password !== data.repass) {
      setErr('Passwords do not match.');
      return false;
    }

    setErr('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordError) return;

    if (!validateForm(formData)) return;

    setLoading(true);
    try {
      const userUpdateData = new FormData();
      userUpdateData.append('profile_updated', 1);
      userUpdateData.append('personalization_updated', 1);
      userUpdateData.append('email', debouncedEmail);
      userUpdateData.append('first_name', debouncedFirstName);
      userUpdateData.append('last_name', debouncedLastName);
      userUpdateData.append('password', debouncedPassword);
      userUpdateData.append('re_password', debouncedRePassword);
      userUpdateData.append('username', debouncedEmail);
      userUpdateData.append('avatar', null);
      userUpdateData.append('skiped_welcome', 0);
      userUpdateData.append('token', urlToken);
      userUpdateData.append('uid', uid);

      const res = await fetch(`${ENDPOINT}/api/user/update/${ID}/`, {
        method: 'PUT',
        headers: { Authorization: `JWT ${urlAccessToken}` },
        body: userUpdateData,
      });

      if (!res.ok) throw new Error('Failed to update profile.');

      const fetchAndUpdateUserDetailsRequest =
        await fetchAndUpdateUserDetails();

      if (!fetchAndUpdateUserDetailsRequest) {
        throw new Error('Failed to fetch user details.');
      }

      setInvitation(true);
      routeTo(appRoutes.rootRoute);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error at "handleSubmit"', error);
      setErr(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
        gap: '1.1rem',
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
    >
      <TextField
        variant="outlined"
        type="text"
        sx={fieldStyles}
        label="First Name"
        name="first_name"
        onChange={handleInputChange}
      />

      <TextField
        variant="outlined"
        type="text"
        sx={fieldStyles}
        label="Last Name"
        name="last_name"
        onChange={handleInputChange}
      />

      <TextField
        variant="outlined"
        type="email"
        sx={fieldStyles}
        value={formData?.email}
        disabled={!!formData?.email}
        label="Email"
        name="email"
      />

      <TextField
        variant="outlined"
        sx={fieldStyles}
        type="password"
        label="Password"
        name="password"
        onChange={handleInputChange}
      />

      <TextField
        variant="outlined"
        sx={fieldStyles}
        type="password"
        label="Repeat Password"
        name="repass"
        onChange={handleInputChange}
      />

      {passwordError && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {passwordError}
        </Alert>
      )}

      {err && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {err}
        </Alert>
      )}

      <button type="submit" className="btn inline" onClick={handleSubmit}>
        {loading ? (
          <Box sx={{ width: '30px', height: '30px', margin: '0 auto' }}>
            <PreLoader1 />
          </Box>
        ) : (
          'Submit'
        )}
      </button>
    </Box>
  );
}

function Invitation() {
  const [invitation, setInvitation] = useState(false);

  return !invitation ? (
    <section
      className="AxisCenter"
      style={{
        textAlign: 'center',
      }}
    >
      <article className="box-txt">
        <Typography sx={{ fontSize: '2rem', fontWeight: 'bold' }}>
          Hey there!
        </Typography>
        <p
          style={{
            fontSize: '1.2rem',
          }}
        >
          You have been Invited to become a part of a Team on Net Net.
        </p>

        <button
          type="button"
          className="btn"
          onClick={() => setInvitation(true)}
        >
          Let&apos;s Roll
        </button>
      </article>
    </section>
  ) : (
    <Grid container alignItems="center" sx={{ height: '100vh' }}>
      <Grid
        item
        className="register-illustration"
        xs={0}
        sm={0}
        md={7}
        lg={7}
        xl={7}
      />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
        }}
        direction="column"
        alignItems="center"
      >
        <IntroTxt />
        <SetUpAccountForm setInvitation={setInvitation} />
      </Grid>
    </Grid>
  );
}

export default Invitation;
