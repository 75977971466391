import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import Bounce from 'rsuite/esm/Animation/Bounce';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import { Alert, Avatar, Grid, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ENDPOINT } from '../Redux/oppor';
import { NetnetField2 } from '../../../Componenets/NetField';
import { getCollabs, getWorkTypes } from '../../User/redux';
import RichTextEditor from '../../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';

function WorkTypes({
  formData,
  setFormData,
  setCurrentScope,
  setSelectedWorkType,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getWorkTypes({
        access_token,
      }),
    );
  }, []);

  const [newOpen] = useState(1);
  const work = useSelector((state) => state.Misc.workTypes);
  const cate = Array.isArray(work) && work.length ? work : false;
  const [tempArr, setTempArr] = useState(
    formData.worktype ? formData.worktype : [],
  );
  const filteredList =
    cate &&
    cate.filter((item) =>
      item.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
    );
  const listToRender = searchQuery ? filteredList : cate;

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h3 className="grey-txt" style={{ fontSize: '13px' }}>
          {' '}
          Please Select The Work Types For This Estimate.
        </h3>
        <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#8feeb2',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            *SELECT WORKTYPE(s)
          </Box>
          <TextField
            id="outlined-basic"
            placeholder="Search Work Type Category"
            variant="standard"
            color="secondary"
            style={{ width: '95%', margin: '1rem 0' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {' '}
                  <SearchIcon />{' '}
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {newOpen === 1 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.58rem',
                marginTop: '1rem',
                maxHeight: { xl: '310px', lg: '275px', md: '245px' },
                overflowY: 'auto',
              }}
            >
              {listToRender ? (
                listToRender.map((item) => (
                  <Box key={item?.id}>
                    <Box
                      className="fullTabV2"
                      sx={
                        tempArr.includes(item.id)
                          ? {
                              backgroundColor: '#9152ff',
                              color: '#fff',
                              fontWeight: '600',
                              fontFamily: 'Open Sans',
                              padding: '1rem 2rem',
                            }
                          : {
                              fontWeight: '400',
                              fontFamily: 'Open Sans',
                              padding: '1rem 2rem',
                            }
                      }
                      onClick={() =>
                        setTempArr((prev) => {
                          if (prev.length) {
                            if (prev.includes(item.id)) {
                              const index = prev.indexOf(item.id);
                              prev.splice(index, 1);
                              return [...prev];
                              // eslint-disable-next-line
                            } else {
                              return [...prev, item.id];
                            }
                            // eslint-disable-next-line
                          } else {
                            return [item.id];
                          }
                        })
                      }
                    >
                      {item.name}
                    </Box>
                  </Box>
                ))
              ) : (
                <Alert severity="info">
                  You do not any work types please add your own work types.
                </Alert>
              )}
            </Box>
          )}

          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className="create-btn"
              onClick={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  worktype: tempArr,
                });

                if (tempArr) {
                  const dataaa = cate.filter((item) =>
                    tempArr.includes(item.id),
                  );
                  setSelectedWorkType(dataaa);
                  setCurrentScope('colab');
                }
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

function TargetGoals({ setFormData, setCurrentScope, formData }) {
  const [dataa, setDataa] = useState({
    payment_type: 'fixed',
    budget: formData.budget ? parseFloat(formData?.budget) : null,
    est_start_date: formData.est_start_date
      ? dayjs(formData.est_start_date)
      : '',
    est_finish_date: formData.est_finish_date
      ? dayjs(formData.est_finish_date)
      : '',
    duration: '',
    month_to_month: null,
    date_selected: false,
  });
  const [repeat, setRepeat] = useState(2);

  function updateBudgetFormData(e) {
    const rawValue = e.target.value.replace(/\D/g, '');
    const numericValue = parseFloat(rawValue);

    if (!Number.isNaN(numericValue) && numericValue >= 0) {
      setDataa({
        ...dataa,
        budget: numericValue,
      });
    } else if (rawValue === '') {
      setDataa({
        ...dataa,
        budget: 0,
      });
    }
  }

  return (
    // eslint-disable-next-line
    <>
      {formData.type === 'retainer' ? (
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          sx={{
            padding: '1rem 0.56rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            {formData.type === 'retainer' ? (
              <h3 className="grey-txt" style={{ fontSize: '13px' }}>
                Please Select Duration Term, Budget and Start Dates
              </h3>
            ) : (
              <h3 className="grey-txt" style={{ fontSize: '13px' }}>
                Please Select Payment Type, Budget and Dates
              </h3>
            )}
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              padding: '1.56rem 1rem',
              backgroundColor: '#fff',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              margin: '1rem 0',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#f2f5f5',
                width: '100%',
                padding: '10px 10px',
                borderRadius: '10px',
              }}
            >
              <p style={{ textAlign: 'start', fontSize: '17px' }}>
                *Select your Term (Duration)
              </p>
              <Box sx={{ display: 'flex', gap: '15px', marginTop: '10px' }}>
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    placeholder="____  ____  ____"
                    value={dataa.duration}
                    onChange={(e) => {
                      setDataa({
                        ...dataa,
                        duration: e.target.value,
                      });
                    }}
                    style={{
                      width: 180,
                      backgroundColor: '#fff',
                      borderStartStartRadius: '10px',
                      borderEndStartRadius: '10px',
                    }}
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    type="number"
                  />
                  <Box
                    sx={{
                      width: '80px',
                      backgroundColor: '#e6e6e6',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 10px',
                      borderStartEndRadius: '10px',
                      borderEndEndRadius: '10px',
                    }}
                  >
                    {repeat === 2 ? (
                      <p>Month(s)</p>
                    ) : repeat === 3 ? (
                      <p>Year(s)</p>
                    ) : repeat === 1 ? (
                      <p>Week(s)</p>
                    ) : null}
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#ebeded',
                    width: { xl: '40%', lg: '50%', md: '50%' },
                    display: 'flex',
                    padding: '10px 10px',
                    gap: { xl: '15px', lg: '6px', md: '5px' },
                    borderRadius: '10px',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    type="checkbox"
                    color="secondary"
                    sx={{
                      outline: 'none',
                      width: '20px',
                      height: '20px',
                    }}
                    onChange={() =>
                      setDataa({
                        ...dataa,
                        month_to_month:
                          repeat === 2
                            ? 'month'
                            : repeat === 1
                              ? 'week'
                              : repeat === 3
                                ? 'year'
                                : null,
                      })
                    }
                  />
                  <RepeatIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (repeat === 1) {
                        setRepeat(2);
                      } else if (repeat === 2) {
                        setRepeat(3);
                      } else {
                        setRepeat(1);
                      }
                    }}
                  />
                  {repeat === 1 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xl: '16px', lg: '9px' },
                      }}
                    >
                      Week to Week
                    </Typography>
                  ) : repeat === 2 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xl: '16px', lg: '9px' },
                      }}
                    >
                      Month to Month
                    </Typography>
                  ) : repeat === 3 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xl: '16px', lg: '9px' },
                      }}
                    >
                      Year to Year
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d2b8ff',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Target Budget</p>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  placeholder="Enter Amount"
                  type="text"
                  defaultValue={
                    dataa.budget
                      ? Number(dataa.budget).toLocaleString()
                      : formData.budget
                        ? Number(formData.budget).toLocaleString()
                        : '0'
                  }
                  value={
                    dataa?.budget ? Number(dataa.budget).toLocaleString() : '0'
                  }
                  onChange={(e) => {
                    updateBudgetFormData(e);
                  }}
                  style={{
                    width: 150,
                    backgroundColor: '#fff',
                    borderStartStartRadius: '10px',
                    borderEndStartRadius: '10px',
                  }}
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },

                      '&.Mui-error fieldset': {
                        borderColor: 'red',
                      },

                      '&.Mui-disabled fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                />
                <Box
                  sx={{
                    width: '80px',
                    backgroundColor: '#e6e6e6',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px',
                    borderStartEndRadius: '10px',
                    borderEndEndRadius: '10px',
                  }}
                >
                  <p>/Month</p>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d4e8fb',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Est Start Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="dd-MM-yyyy"
                  value={dataa.est_start_date || null}
                  onChange={(newValue) => {
                    setDataa({
                      ...dataa,
                      est_start_date: newValue,
                      date_selected: true,
                    });
                  }}
                  sx={{
                    width: '100%',
                    height: '60px',
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '28px',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '60px',
                      padding: '0px',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      width: '100%',
                      height: '60px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&:hover fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&.Mui-error fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => {
                  setDataa({
                    payment_type: 'fixed',
                    budget: 0,
                    est_start_date: '',
                    est_finish_date: '',
                  });
                }}
              >
                Reset
              </button>
              <button
                type="button"
                className="create-btn"
                onClick={() => {
                  setFormData({
                    ...formData,
                    ...dataa,
                    est_start_date: dataa.est_start_date
                      ? new Date(dataa.est_start_date).toISOString()
                      : null,
                    est_finish_date: dataa.est_finish_date
                      ? new Date(dataa.est_finish_date).toISOString()
                      : null,
                  });
                  setCurrentScope('description');
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          sx={{
            padding: '1rem 0.56rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <h3 className="grey-txt" style={{ fontSize: '13px' }}>
              Please Select Payment Type, Budget and Dates
            </h3>
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              padding: '1.56rem 1rem',
              backgroundColor: '#fff',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              margin: '1rem 0',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d2b8ff',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Target Budget</p>
              <TextField
                label="Target Budget"
                type="text"
                defaultValue={
                  dataa.budget
                    ? Number(dataa.budget).toLocaleString()
                    : formData.budget
                      ? Number(formData.budget).toLocaleString()
                      : '0'
                }
                value={
                  dataa?.budget ? Number(dataa.budget).toLocaleString() : '0'
                }
                onChange={(e) => {
                  updateBudgetFormData(e);
                }}
                color="secondary"
                sx={{
                  width: '53%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#d2b8ff',
                    },
                    '&:hover fieldset': {
                      borderColor: '#711fff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#5a0bdf',
                    },

                    '&.Mui-error fieldset': {
                      borderColor: 'red',
                    },

                    '&.Mui-disabled fieldset': {
                      borderColor: 'grey',
                    },
                  },
                  '& .MuiFormLabel-root': {
                    color: '#03071e66',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#5a0bdf',
                  },
                  '& .MuiFormLabel-root.Mui-error': {
                    color: 'red',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d4e8fb',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Est Start Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM-dd-yyyy"
                  value={dataa.est_start_date || null}
                  onChange={(newValue) => {
                    setDataa({
                      ...dataa,
                      est_start_date: newValue,
                      date_selected: true,
                    });
                  }}
                  sx={{
                    width: '53%',
                    height: '60px',
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '28px',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '60px',
                      padding: '0px',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      width: '100%',
                      height: '60px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&:hover fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                          '&.Mui-error fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#d4e8fb',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className="baseTypography2">Est Finish Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Finish Date"
                  inputFormat="MM-dd-yyyy"
                  value={dataa.est_finish_date || null}
                  onChange={(newValue) => {
                    setDataa({
                      ...dataa,
                      est_finish_date: newValue,
                      date_selected: true,
                    });
                  }}
                  sx={{
                    width: '53%',
                    height: '60px',
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                      {
                        color: '#711FFF',
                      },
                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#711FFF',
                      },
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '28px',
                      left: '-6px',
                      borderRadius: '0px',
                      width: '100%',
                    },
                    '& .MuiSvgIcon-root': {
                      position: 'absolute',
                      right: '10px',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '100%',
                      height: '60px',
                      padding: '0px',
                      color: 'black',
                      marginLeft: '10px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      width: '100%',
                      height: '60px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        border: '1px solid gray',
                        '& .MuiOutlinedInput-root': {
                          border: '1px solid gray',
                          width: '280px',
                          padding: '0px',
                          '& fieldset': {
                            border: '1px solid #000',
                          },
                          '&:hover fieldset': {
                            border: '1px solid gray',
                          },
                          '&.Mui-error fieldset': {
                            border: '1px solid #d4e8fb',
                          },
                        },
                        '& .MuiFormLabel-root-MuiInputLabel-root .Mui-error': {
                          color: '#000',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => {
                  setFormData({
                    ...formData,
                    budget: 0,
                    est_start_date: '',
                    est_finish_date: '',
                  });
                  setDataa({
                    payment_type: 'fixed',
                    budget: 0,
                    est_start_date: '',
                    est_finish_date: '',
                  });
                }}
              >
                Reset
              </button>
              <button
                type="button"
                className="create-btn"
                onClick={() => {
                  if (dataa.est_start_date || dataa.est_finish_date) {
                    setFormData({
                      ...formData,
                      ...dataa,
                      est_start_date: dataa.est_start_date
                        ? new Date(dataa.est_start_date).toISOString()
                        : null,
                      est_finish_date: dataa.est_finish_date
                        ? new Date(dataa.est_finish_date).toISOString()
                        : null,
                    });
                  } else {
                    setFormData({
                      ...formData,
                      budget: dataa.budget ? dataa.budget : 0,
                    });
                  }
                  setCurrentScope('description');
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
}

// eslint-disable-next-line
function Collaborators({
  setFormData,
  setCurrentScope,
  formData,
  setSelectedCollabarators,
}) {
  const [collaborators, setCollaborators] = useState(
    formData?.collaborators ? formData?.collaborators : [],
  );
  const [show, setShow] = useState(true);
  const target = useSelector((state) => state.Misc.collabs);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    dispatch(
      getCollabs({
        access_token,
      }),
    );
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(target) && target.length) {
      const toBe = target.map((item) => {
        return {
          id: item.id,
          name:
            item?.collab?.first_name === 'first_name'
              ? item?.collab?.email?.split('@')[0] || ''
              : `${item?.collab?.first_name || ''} ${item?.collab?.last_name || ''}`,
          email: item?.collab?.email,
        };
      });
      setData(toBe);
    }
  }, [target]);
  const NewData = Array.isArray(data) && data.length ? data : false;
  const [searchQuery, setSearchQuery] = useState('');
  const filteredList = NewData
    ? NewData.filter((item) =>
        item.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
      )
    : NewData;
  const listToRender = searchQuery ? filteredList : NewData;
  // useEffect(()=>{

  // },[formData])

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: { xl: '100%', lg: '100%', md: '100%', sm: '100%' },
          alignItems: 'center',
        }}
      >
        <h3 className="grey-txt" style={{ fontSize: '13px' }}>
          Please Select Collaborators for this Estimate
        </h3>
        <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: { xl: '100%', lg: '100%', md: '100%', sm: '100%' },
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#BADEFF',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            SELECT COLLABORATOR(S)
          </Box>
          <TextField
            id="outlined-basic"
            placeholder="Search Collaborator"
            variant="standard"
            color="secondary"
            style={{ width: '95%', margin: '1rem 0' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {' '}
                  <SearchIcon />{' '}
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxHeight: { xl: '333px', lg: '295px', md: '250px' },
              overflowY: 'auto',
              gap: '1rem',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            {listToRender ? (
              listToRender.map((item) => (
                <article
                  key={item?.id}
                  className="fullTabV2"
                  style={{
                    cursor: 'pointer',
                    backgroundColor: collaborators.includes(item.id)
                      ? '#9152ff'
                      : 'white',
                  }}
                  onClick={() => {
                    if (show === true) {
                      setCollaborators([...collaborators, item.id]);
                      setShow(false);
                    } else {
                      setCollaborators(
                        collaborators.filter((param) => param !== item.id),
                      );
                      setShow(true);
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '1rem 1rem',
                      gap: '1rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      {/* <Avatar src={item.avatar} alt={item.name} /> */}
                      <Avatar
                        src={item.avatar ? item.avatar : item.name}
                        alt={item.name}
                      />

                      <p
                        style={{
                          color: collaborators.includes(item.id)
                            ? 'white'
                            : 'black',
                        }}
                        className="baseTypography2"
                      >
                        {item.name}
                      </p>
                    </Box>
                    <div className="dFlex" style={{ gap: '0.5rem' }}>
                      <p className="state">
                        {item && item.city ? item.city : ''}
                      </p>
                      <p className="state">
                        {item && item.state ? item.state : ''}
                      </p>
                    </div>
                  </Box>{' '}
                </article>
              ))
            ) : (
              <Alert severity="info">
                You dont have any collaborators yet.
              </Alert>
            )}
          </Box>
          <Box
            sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <button type="button" className="cancel-btn">
              Reset
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={() => {
                setFormData({
                  ...formData,
                  collaborators,
                });
                // ;
                if (collaborators) {
                  const filtered = data.filter((item) =>
                    collaborators.includes(item.id),
                  );
                  setSelectedCollabarators(filtered);
                  setCurrentScope(null);
                }
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

function Description({
  setDiscription,
  setFormData,
  formData,
  setCurrentScope,
}) {
  const [html, setHtml] = useState(formData.description ?? '<p></p>');

  const [openLink, setOpenLink] = useState(false);
  const [btn, setBtn] = useState();
  const check = (e) => {
    setBtn(e);
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
          height: 'fit-content',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            fontFamily: 'Open Sans',
            color: '#000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.8rem',
            padding: '1.56rem 1rem',
            width: '100%',
          }}
          className="Editor-para2"
        >
          <p className="baseTypography2" style={{ fontWeight: 'bold' }}>
            *Description
          </p>
          <RichTextEditor
            open={openLink}
            setOpen={setOpenLink}
            setHtml={setHtml}
            html={html}
            check={check}
            btn={btn}
            my_logic={true}
          />
        </Box>
        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <button
            type="button"
            className="create-btn"
            onClick={() => {
              setCurrentScope('team');
              setDiscription(html);
              setFormData({
                ...formData,
                description: html,
              });
            }}
          >
            Save
          </button>
        </Box>
      </Box>
    </Grid>
  );
}

function ClientSelector({ formData, setFormData, setCurrentScope }) {
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const currentPeoples1 = useSelector((state) => state.Contacts.persons);

  const [open, setOpen] = useState(
    formData.company && !formData.contact
      ? 'people'
      : formData.contact && !formData.company
        ? 'company'
        : !formData.contact && !formData.company
          ? 'company'
          : '',
  );
  const [selected, setSelected] = useState({
    contact: '',
    company: '',
    index: '',
  });
  const [myFormData, setMyFormData] = useState({
    contact: formData.contact ? formData.contact : '',
    company: formData.company ? formData.company : '',
  });
  const currentPeoples =
    currentPeoples1 && selected.company
      ? currentPeoples1.filter((item) => item.company === selected.company)
      : currentPeoples1;

  const [searchPeopleQuery, setSearchPeopleQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const currentCompaniesFiltered = currentCompanies
    ? currentCompanies.filter((item) =>
        item.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
      )
    : null;
  const currentPeopleFiltered = currentPeoples
    ? currentPeoples.filter((item) =>
        item.first_name
          .toLowerCase()
          .startsWith(searchPeopleQuery.toLowerCase()),
      )
    : null;

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h3 className="grey-txt" style={{ fontSize: '13px' }}>
          Please Select The Company and Its Representative
        </h3>
        <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#e7daff',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {selected.company || formData.company ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'blue',
                }}
              >
                <Avatar
                  src={
                    selected.company
                      ? selected.company
                      : formData.company
                        ? currentCompanies.find(
                            (item) => item.id === formData.company,
                          )?.avatar
                        : ''
                  }
                  alt={
                    selected.company
                      ? selected.company
                      : formData.company
                        ? currentCompanies.find(
                            (item) => item.id === formData.company,
                          )?.name
                        : ''
                  }
                  sx={{ marginRight: '1rem' }}
                />{' '}
                {selected.company
                  ? selected.company
                  : formData.company
                    ? currentCompanies.find(
                        (item) => item.id === formData.company,
                      )?.name
                    : ''}{' '}
              </div>
            ) : (
              '*Select Company'
            )}
            <ArrowDropDownIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (open === 'company') {
                  setOpen('people');
                } else {
                  setOpen('company');
                }
              }}
            />
          </Box>
          {open === 'company' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: '1rem',
              }}
            >
              <TextField
                id="outlined-basic"
                placeholder="Search Company"
                variant="standard"
                color="secondary"
                style={{ width: '100%', margin: '1rem 0' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {' '}
                      <SearchIcon />{' '}
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  maxHeight: {
                    xl: selected.company ? '250px' : '265px',
                    lg: selected.company ? '220px' : '239px',
                    md: selected.company ? '193px' : '212px',
                  },
                  overflowY: 'auto',
                  gap: '1rem',
                }}
              >
                {currentCompaniesFiltered ? (
                  currentCompaniesFiltered.map((item, index) => (
                    <article
                      key={item?.id}
                      className="fullTabV2"
                      style={{
                        backgroundColor:
                          selected.company && index === selected.index
                            ? '#D2B8FF'
                            : 'white',
                        padding: '10px',
                      }}
                      onClick={() => {
                        setSelected({
                          company: item.name,
                          index,
                        });
                        setOpen('people');
                        setMyFormData({
                          ...myFormData,
                          company: item.id,
                        });
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Avatar
                          src={item?.avatar ?? 'static/images/avatar/1.jpg'}
                          alt={item.name}
                          sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                        />{' '}
                        <p
                          className="company-name"
                          style={{
                            color:
                              selected.company && index === selected.index
                                ? '#ffffff'
                                : 'blue',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {item.name}
                          <div className="dFlex" style={{ gap: '0.5rem' }}>
                            <p
                              className="state"
                              style={{ fontSize: '12px', color: '#b8b8b8' }}
                            >
                              {item && item.city ? `${item.city},` : ''}
                            </p>
                            <p
                              className="state"
                              style={{ fontSize: '12px', color: '#b8b8b8' }}
                            >
                              {item && item.state ? item.state : ''}
                            </p>
                          </div>
                        </p>
                      </Box>{' '}
                    </article>
                  ))
                ) : (
                  <p className="txtCenter"> You Dont Have Any Companies</p>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: '#e7daff',
            padding: '1rem 1.5rem',
            borderRadius: '8px',
            color: '#000',
            width: '100%',
            fontFamily: 'Open Sans',
            fontSize: '1.1rem',
          }}
        >
          {selected.contact || formData.contact ? (
            <div
              className="dFlex"
              style={{ gap: '0.5rem', alignItems: 'center' }}
            >
              <Avatar
                src={`${ENDPOINT}${selected?.contact?.avatar}`}
                alt={
                  selected?.contact?.first_name
                    ? selected?.contact?.first_name
                    : currentPeoples.find(
                        (item) => item.id === formData.contact,
                      )?.first_name
                }
                sx={{ marginRight: '.8rem' }}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <p className="company-name" style={{ color: 'black' }}>
                  {selected?.contact?.first_name
                    ? selected?.contact?.first_name
                    : currentPeoples.find(
                        (item) => item.id === formData.contact,
                      )?.first_name}{' '}
                  {selected?.contact?.last_name
                    ? selected?.contact?.last_name
                    : currentPeoples?.find(
                        (item) => item.id === formData.contact,
                      )?.last_name}
                </p>

                <p className="state">
                  {selected?.contact && selected?.contact.title
                    ? selected?.contact.title
                    : ''}
                </p>
                <p className="state">
                  {selected && selected.contact.company
                    ? selected.contact.company.name
                    : ''}
                </p>
              </Box>
              <ArrowDropDownIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (open === 'company') {
                    setOpen('people');
                  } else {
                    setOpen('company');
                  }
                }}
              />
            </div>
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                if (open === 'company') {
                  setOpen('people');
                } else {
                  setOpen('company');
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <p> Select Person(s)</p>
              <ArrowDropDownIcon />
            </Box>
          )}
        </Box>
        {open === 'people' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              gap: '1rem',
            }}
          >
            <TextField
              id="outlined-basic"
              placeholder="Search Person"
              variant="standard"
              color="secondary"
              style={{ width: '100%', margin: '1rem 0' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    <SearchIcon />{' '}
                  </InputAdornment>
                ),
              }}
              value={searchPeopleQuery}
              onChange={(e) => setSearchPeopleQuery(e.target.value)}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxHeight: {
                  xl: selected.company ? '238px' : '240px',
                  lg: selected.company ? '208px' : '226px',
                  md: selected.company ? '180px' : '200px',
                },
                gap: '1rem',
                overflowY: 'scroll',
              }}
            >
              {currentPeopleFiltered ? (
                currentPeopleFiltered.map((item) => (
                  <article
                    key={item?.id}
                    className="fullTabV2"
                    onClick={() => {
                      setSelected({
                        ...selected,
                        contact: item,
                      });
                      if (formData.company) {
                        setMyFormData({
                          company: formData.company,
                          contact: item.id,
                        });
                      } else {
                        setMyFormData({
                          ...myFormData,
                          contact: item.id,
                        });
                      }
                      setOpen(null);
                    }}
                    style={{ padding: '10px' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        className="dFlex"
                        style={{ gap: '0.5rem', alignItems: 'center' }}
                      >
                        <Avatar
                          src={`${ENDPOINT}${item.avatar ? item.avatar : item.first_name}`}
                          alt={item.first_name}
                        />
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <p
                            className="company-name"
                            style={{ color: 'black' }}
                          >
                            {item.first_name} {item?.last_name}
                          </p>
                          <p
                            className="state"
                            style={{ color: '#c1a2fa', fontSize: '12px' }}
                          >
                            {item && item.contact_type ? item.contact_type : ''}
                          </p>
                          <p
                            className="state"
                            style={{ color: 'blue', fontSize: '12px' }}
                          >
                            {item && item.company ? item.company : ''}
                          </p>
                        </Box>
                      </div>
                    </Box>{' '}
                  </article>
                ))
              ) : (
                <p className="txtCenter"> You Dont Have Any People</p>
              )}
            </Box>
          </Box>
        )}
        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              setMyFormData({
                contact: '',
                company: '',
              });
              setSelected({
                contact: '',
                company: '',
              });
              setOpen('company');
            }}
          >
            Reset
          </button>
          <button
            type="button"
            className="create-btn"
            onClick={() => {
              setFormData({
                ...formData,
                ...myFormData,
              });
              setCurrentScope('goals');
            }}
          >
            Save
          </button>
        </Box>
      </Box>
    </Grid>
  );
}

function TeamMembers({
  setSelectedTeam,
  setFormData,
  formData,
  setCurrentScope,
}) {
  const currentTeam = useSelector((state) => state.userTeam.team);
  const currentUser = useSelector((state) => state.userData.userData);
  const [members, setMembers] = useState(
    formData?.members ? formData?.members : [currentUser.email],
  );
  const [searchQuery, setSearchQuery] = useState('');
  const realTeam = currentTeam.member
    ? currentTeam.member.filter((items) => items.first_name !== 'First Name')
    : null;
  const currentTeamFiltered = realTeam
    ? realTeam.filter((item) =>
        item.first_name.toLowerCase().startsWith(searchQuery.toLowerCase()),
      )
    : null;

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h3 className="grey-txt" style={{ fontSize: '13px' }}>
          Please Select Your Team For This Estimate
        </h3>
        <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#e9ecef',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            Select Your Team
          </Box>
          <TextField
            id="outlined-basic"
            placeholder="Search Team"
            variant="standard"
            color="secondary"
            style={{ width: '100%', margin: '1rem 0' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {' '}
                  <SearchIcon />{' '}
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Box
            sx={{
              width: '100%',
              maxHeight: { xl: '310px', lg: '275px', md: '245px' },
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '1rem',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            {currentTeamFiltered &&
            currentTeamFiltered &&
            !currentTeamFiltered.every(
              (enty) => enty.first_name === 'first_name',
            )
              ? currentTeamFiltered.map((item) => {
                  if (item.first_name === 'first_name') {
                    return;
                  }
                  // eslint-disable-next-line
                  return (
                    <Box
                      key={item.email}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        jusitfyContent: 'space-between',
                        padding: '1rem 1rem',
                        cursor: 'pointer',
                        backgroundColor: members.includes(item.email)
                          ? '#9152ff'
                          : 'white',
                      }}
                      className="fullTabV2"
                      onClick={() => {
                        if (!members.includes(item.email)) {
                          setMembers([...members, item.email]);
                        } else {
                          setMembers(
                            members.filter((param) => param !== item.email),
                          );
                        }
                      }}
                    >
                      <div
                        className="dFlex"
                        style={{ gap: '0.5rem', alignItems: 'center' }}
                      >
                        <Avatar
                          src={item.avatar ? item.avatar : item.first_name}
                          alt={item.first_name}
                        />
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            color: members.includes(item.email)
                              ? 'white'
                              : 'blue',
                          }}
                        >
                          <p
                            className="company-name"
                            style={{
                              color: members.includes(item.email)
                                ? 'white'
                                : 'blue',
                            }}
                          >
                            {item.first_name} {item.last_name}
                          </p>

                          <p className="state">{item.title}</p>
                          <p className="state">
                            {item.company ? item.company.name : ''}
                          </p>
                        </Box>
                      </div>
                    </Box>
                  );
                })
              : 'You dont have any Team'}
          </Box>
          <Box
            sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <button
              type="button"
              className="create-btn"
              onClick={() => {
                setFormData({
                  ...formData,
                  members,
                });
                if (members) {
                  const filtered = currentTeam.member.filter((item) =>
                    members.includes(item.email),
                  );
                  setSelectedTeam(filtered);
                  setCurrentScope('types');
                }
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

function FormStack({
  description,
  selectedTeam,
  formData,
  selectedWorkType,
  setCurrentScope,
  setCompanySelected,
  dileverable,
  setDeliverable,
  component1Ref,
}) {
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const currentPeoples = useSelector((state) => state.Contacts.persons);
  const isColab = false;
  const [realData, setRealData] = useState({
    company: '',
    contact: '',
  });

  useEffect(() => {
    if (formData.company || formData.contact) {
      setRealData({
        ...realData,
        contact: formData.contact
          ? currentPeoples.filter((item) => item.id === formData.contact)[0]
          : '',
        company: formData.company
          ? currentCompanies.filter((item) => item.id === formData.company)[0]
          : '',
      });
      const companyName = currentCompanies.filter(
        (item) => item.id === formData.company,
      )[0];
      setCompanySelected(companyName?.name);
    }
  }, [formData]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [hasMoreThanTwoLines, setHasMoreThanTwoLines] = useState(false);
  const descriptionRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(descriptionRef.current).lineHeight,
      );
      const maxHeight = lineHeight * 2;
      const descriptionHeight = descriptionRef.current.scrollHeight;
      setHasMoreThanTwoLines(descriptionHeight > maxHeight);
    }
  }, [description]);

  return (
    <Grid
      direction="column"
      alignItems="flex-start"
      container
      item
      xs={12}
      md={5.75}
      lg={5.73}
      xl={5.52}
      sx={{
        gap: '1rem',
        marginTop: '1rem',
        background: '#fff',
        zIndex: 2,
      }}
      ref={component1Ref}
    >
      {!isColab && (
        <Box
          sx={{
            backgroundColor: '#F2EBFF',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '100%' }}>
            {!formData.company ? (
              <div>
                <h3 className="sub-title2">Client Information</h3>
                <span>Select Company/Person </span>{' '}
              </div>
            ) : (
              <div>
                <h3 className="sub-title2">Client Information</h3>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Avatar
                      src="static/images/avatar/1.jpg"
                      alt={realData?.company?.name}
                      style={{ marginRight: '10px' }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xl: '15px',
                            lg: '12px',
                            md: '11px',
                            sm: '10px',
                          },
                          fontFamily: 'Open Sans',
                        }}
                        className="company-name"
                      >
                        {realData?.company?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xl: '14px',
                            lg: '12px',
                            md: '11px',
                            sm: '10px',
                          },
                          fontFamily: 'Open Sans',
                        }}
                        className="baseTypography"
                      >
                        {realData?.company?.city}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      padding: {
                        xl: '0 0 0 2.5rem',
                        lg: '0 0 0 1.5rem',
                        md: '0 0 0 1rem',
                        sm: '0 0 0 .7rem',
                      },
                      borderLeft: '1px solid #000',
                      gap: '1rem',
                    }}
                  >
                    <Avatar
                      src={realData?.contact?.first_name ?? ''}
                      alt={realData?.contact?.first_name ?? ''}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '15px',
                              lg: '13px',
                              md: '12px',
                              sm: '10px',
                            },
                            fontFamily: 'Open Sans',
                          }}
                          className="baseTypography"
                        >
                          {realData.contact
                            ? `${realData?.contact?.first_name || ''} ${realData?.contact?.last_name || ''}`.trim()
                            : 'No Person Selected'}
                        </Typography>
                        <Typography
                          className="company-name"
                          sx={{
                            color: '#000',
                            fontSize: {
                              xl: '13px',
                              lg: '11px',
                              md: '11px',
                              sm: '10px',
                            },
                          }}
                        >
                          {realData?.contact?.contact_type ?? ''}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '13px',
                              lg: '11px',
                              md: '11px',
                              sm: '10px',
                            },
                            fontFamily: 'Open Sans',
                          }}
                          className="company-name"
                        >
                          {realData?.contact?.company ?? ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            )}
          </Box>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('client')}
          />
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          padding: {
            xl: '14px 23px',
            lg: '10px 18px',
            md: '8px 15px',
            sm: '6px 12px',
          },
          position: 'relative',
          border: '1px solid #d9d9d9',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <h3 className="sub-title2">Target Goals</h3>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xl: '1rem', lg: '.6rem', md: '.4rem', sm: '.4rem' },
              paddingBottom: '1rem',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#8ADEC1',
                fontFamily: 'Open Sans',
                fontWeight: '500',
                padding: '9px 10px',
                borderRadius: '8px',
                fontSize: { xl: '15px', lg: '13px', md: '11px', sm: '9px' },
              }}
            >
              {formData?.payment_type ? (
                <p className="company-name">{formData?.payment_type}</p>
              ) : (
                'Payment Type'
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: '#D2B8FF',
                fontFamily: 'Open Sans',
                fontWeight: '500',
                padding: '9px 10px',
                borderRadius: '8px',
                fontSize: { xl: '15px', lg: '13px', md: '11px', sm: '9px' },
              }}
            >
              {formData.budget ? (
                <p className="company-name">
                  ${' '}
                  {parseFloat(formData?.budget).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  })}
                </p>
              ) : (
                'Target Budget'
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: '#D4E8FB',
                fontFamily: 'Open Sans',
                fontWeight: '500',
                padding: {
                  xl: '7px 10px',
                  lg: '6px 10px',
                  md: '6px 9px',
                  sm: '9px 10px',
                },
                borderRadius: '8px',
                fontSize: { xl: '15px', lg: '12px', md: '11px', sm: '9px' },
              }}
            >
              {formData.est_start_date || formData.est_finish_date ? (
                <Typography
                  sx={{
                    fontSize: {
                      xl: '15px',
                      lg: '11px',
                      md: '11px',
                      sm: '10px',
                    },
                    padding: {
                      xl: '0px 0px',
                      lg: '3px 0px',
                      md: '3px 0px',
                      sm: '2px 0px',
                    },
                  }}
                  className="company-name"
                >
                  EST START{' '}
                  {formData.est_start_date
                    ? dayjs(formData.est_start_date).format('MM-DD-YYYY')
                    : '----'}
                  |EST END{' '}
                  {formData.est_finish_date
                    ? dayjs(formData.est_finish_date).format('MM-DD-YYYY')
                    : '----'}
                </Typography>
              ) : (
                'EST START & FINISH DATE'
              )}
            </Box>
          </Box>
        </Box>
        <ArrowForwardIosIcon
          sx={{
            color: '#03071e99',
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
          onClick={() => setCurrentScope('goals')}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: '#D2B8FF',
          borderRadius: '8px',
          padding: {
            xl: '14px 23px',
            lg: '10px 18px',
            md: '8px 15px',
            sm: '6px 12px',
          },
          position: 'relative',
          border: '1px solid #d9d9d9',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3 className="sub-title2">Description</h3>
          {description && description?.length > 7 ? (
            <>
              <p
                ref={descriptionRef}
                style={{
                  maxHeight: isExpanded ? 'none' : '3em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: isExpanded ? 'none' : 2,
                  WebkitBoxOrient: 'vertical',
                }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {hasMoreThanTwoLines && (
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                  type="button"
                  onClick={toggleExpand}
                >
                  {isExpanded ? 'Read less' : 'Read more...'}
                </button>
              )}
            </>
          ) : (
            <p className="fstxt">
              Please summarize the Job in as much detail as you would like. It
              will appear at the top of the Estimate.
            </p>
          )}
        </div>
        <ArrowForwardIosIcon
          sx={{
            color: '#03071e99',
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
          onClick={() => setCurrentScope('description')}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: '#E9ECEF',
          borderRadius: '8px',
          padding: {
            xl: '14px 23px',
            lg: '10px 18px',
            md: '8px 15px',
            sm: '6px 12px',
          },
          position: 'relative',
          border: '1px solid #d9d9d9',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3 className="sub-title2">Team Members</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            {Array.isArray(selectedTeam) && selectedTeam.length ? (
              selectedTeam.map((item) => {
                return (
                  <div
                    key={item?.id ?? item?.first_name}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '15px',
                      backgroundColor: '#F2EBFF',
                      borderRadius: '10px',
                      padding: '1rem 1rem',
                    }}
                  >
                    <Avatar
                      src={item.avatar ? item.avatar : item.first_name}
                      alt={item.first_name}
                    />
                    <Box>
                      <p style={{ fontWeight: 'bold' }}>
                        {item.first_name ?? ''} {item.last_name ?? ''}
                      </p>
                    </Box>
                  </div>
                );
              })
            ) : Array.isArray(formData.project_subteam) &&
              formData.project_subteam.length &&
              Array.isArray(formData.project_subteam[0].members) &&
              formData.project_subteam[0].members.length ? (
              formData.project_subteam[0].members.map((item) => {
                return (
                  <div
                    key={item?.id ?? item?.first_name}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '15px',
                      backgroundColor: '#F2EBFF',
                      borderRadius: '10px',
                      padding: '1rem 1rem',
                    }}
                  >
                    <Avatar
                      src={item.avatar ? item.avatar : item.first_name}
                      alt={item.first_name}
                    />
                    <Box>
                      <p style={{ fontWeight: 'bold' }}>
                        {item.first_name ?? ''} {item.last_name ?? ''}
                      </p>
                    </Box>
                  </div>
                );
              })
            ) : (
              <p className="fstxt">
                Optionally, please choose any additional team members you want
                to collaborate with on this estimate
              </p>
            )}
          </div>
        </div>
        <ArrowForwardIosIcon
          sx={{
            color: '#03071e99',
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
          onClick={() => setCurrentScope('team')}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: '#5FCEA899',
          borderRadius: '8px',
          padding: {
            xl: '14px 23px',
            lg: '10px 18px',
            md: '8px 15px',
            sm: '6px 12px',
          },
          position: 'relative',
          border: '1px solid #d9d9d9',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3 className="sub-title2">Work Types</h3>
          {Array.isArray(selectedWorkType) && selectedWorkType.length ? (
            selectedWorkType.map((item) => {
              return (
                <button
                  key={item?.id ?? item.name}
                  type="button"
                  className="create-btn"
                  style={{ marginRight: '5px', marginTop: '5px' }}
                >
                  {item.name}
                </button>
              );
            })
          ) : (
            <p className="fstxt">
              Please choose all of the work types you want included in this
              estimate.
            </p>
          )}
        </div>
        <Tooltip
          title={
            Array.isArray(dileverable) && dileverable.length
              ? 'Changing work types will reset all deliverables.'
              : ''
          }
        >
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => {
              if (Array.isArray(dileverable) && dileverable.length) {
                setDeliverable([]);
                setCurrentScope('types');
              }
              setCurrentScope('types');
            }}
          />
        </Tooltip>
      </Box>
    </Grid>
  );
}

function FirstStep({
  formData,
  setFormData,
  amIColab,
  setCompanySelected,
  dileverable,
  setDeliverable,
  selectedWorkType,
  setSelectedWorkType,
  setSelectedTeam,
  selectedTeam,
  selectedCollabarators,
  setDiscription,
  description,
}) {
  const component1Ref = useRef(null);
  const [component1Height, setComponent1Height] = useState(0);

  useEffect(() => {
    if (component1Ref.current) {
      setComponent1Height(component1Ref.current.clientHeight);
    }
  }, [formData]);

  const [selected, setSelected] = useState(0);
  const [currentScope, setCurrentScope] = useState(null);
  // eslint-disable-next-line
  const [isColab, setIsColab] = useState(amIColab);

  const [showButton, setShowButton] = useState(false);
  const [showAllData, setShowAllData] = useState(false);

  useEffect(() => {
    if (formData?.title?.length > 2) {
      setShowButton(true);
      if (formData.type && formData.type === 'retainer') {
        setShowAllData(true);
        setSelected(2);
      } else if (formData.type && formData.type === 'project') {
        setShowAllData(true);
        setSelected(1);
      }
    }
  }, [formData]);
  useEffect(() => {
    if (selected === 1) {
      setFormData({
        ...formData,
        type: 'project',
      });
    }

    if (selected === 2) {
      setFormData({
        ...formData,
        type: 'retainer',
      });
    }
  }, [selected]);

  return (
    <Grid container>
      <Grid
        container
        alignItems="flex-end"
        sx={{
          padding: '1rem 0.8rem',
          backgroundColor: '#FBF8FF',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
            width: '49%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: { xl: '92%', lg: '95%', md: '95.5%', sm: '97%' },
              alignItems: 'center',
            }}
          >
            <h3 className="sub-title2">Estimate Details</h3>
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>
          <Box
            sx={{
              width: { xl: '92%', lg: '95%', md: '95.5%', sm: '97%' },
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <span className="fstxt" style={{ color: 'gray' }}>
              Enter your Estimate Name
            </span>

            <NetnetField2
              id="Cypress-EstimateName"
              sx={{ width: { xl: '92%', lg: '95%', md: '95.5%', sm: '97%' } }}
              placeholder="Enter Estimate"
              value={formData.title}
              onChange={(e) => {
                if (e.target.value.length > 3) {
                  setShowButton(true);
                } else {
                  setShowButton(false);
                }
                setFormData({
                  ...formData,
                  title: e.target.value,
                });
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: {
              xl: '1rem 1rem .1rem 4rem',
              lg: '1rem .8rem .1rem 1.8rem',
              md: '.8rem .7rem .1rem 1.4rem',
              sm: '.3rem .3rem .1rem .7rem',
            },
            borderLeft: '1px solid #000',
            gap: '1rem',
            width: '50%',
            transition: '0.5s ease-in-out',
            opacity: showButton ? '1' : '0',
          }}
        >
          <span>Select Your Estimate Type</span>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xl: '1rem', lg: '1rem', md: '1rem', sm: '.5rem' },
            }}
          >
            <button
              type="button"
              className={selected === 1 ? 'estaBtn slctd' : 'estaBtn'}
              onClick={() => {
                setShowAllData(true);
                setSelected(1);
              }}
            >
              Project
            </button>
            <button
              type="button"
              className={selected === 2 ? 'estaBtn slctd' : 'estaBtn'}
              onClick={() => {
                setShowAllData(true);
                setSelected(2);
              }}
            >
              Retainer
            </button>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          opacity: showAllData && showButton ? '1' : '0',
          transition: '0.5s ease-in-out',
        }}
      >
        <FormStack
          description={description}
          selectedTeam={selectedTeam}
          formData={formData}
          currentScope={currentScope}
          setCurrentScope={setCurrentScope}
          setFormData={setFormData}
          selectedWorkType={selectedWorkType}
          selectedCollabarators={selectedCollabarators}
          setCompanySelected={setCompanySelected}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
          component1Ref={component1Ref}
        />
        <Grid direction="column" alignItems="flex-start" xs={6} md={6} lg={6}>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            sx={{
              gap: '1rem',
            }}
          >
            {!isColab && currentScope === 'client' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <ClientSelector
                    formData={formData}
                    currentScope={currentScope}
                    setCurrentScope={setCurrentScope}
                    setFormData={setFormData}
                    component1Height={component1Height}
                  />
                </Bounce>
              </Box>
            )}
            {currentScope === 'goals' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <TargetGoals
                    setFormData={setFormData}
                    setCurrentScope={setCurrentScope}
                    formData={formData}
                    component1Height={component1Height}
                  />
                </Bounce>
              </Box>
            )}
            {currentScope === 'description' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <Description
                    setDiscription={setDiscription}
                    description={description}
                    formData={formData}
                    setFormData={setFormData}
                    setCurrentScope={setCurrentScope}
                    component1Height={component1Height}
                  />
                </Bounce>
              </Box>
            )}
            {currentScope === 'team' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <TeamMembers
                    setSelectedTeam={setSelectedTeam}
                    formData={formData}
                    currentScope={currentScope}
                    setCurrentScope={setCurrentScope}
                    setFormData={setFormData}
                    component1Height={component1Height}
                  />
                </Bounce>
              </Box>
            )}
            {currentScope === 'types' && (
              <Box sx={{ width: '100%' }}>
                <Bounce left>
                  <WorkTypes
                    formData={formData}
                    setFormData={setFormData}
                    setCurrentScope={setCurrentScope}
                    setSelectedWorkType={setSelectedWorkType}
                    selectedWorkType={selectedWorkType}
                    component1Height={component1Height}
                  />
                </Bounce>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FirstStep;
