import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Popover,
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro';
import { CalendarMonthOutlined } from '@mui/icons-material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import RefreshIcon from '@mui/icons-material/Refresh';

import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import moneySign from '../../Componenets/TopNav/Add/Estimates.png';
import { SelectDropdown } from '../../Componenets/atomic-molecules/Dropdowns/SelectDropdown';

import { globalFormats } from '../../utils/globalFormats';

const darkWonGreen = '#53ef99';
const darkLostRed = '#f75656';
const currencyLocaleParam = globalFormats.currency.locale;
const currencyOptions = globalFormats.currency.options;
const documentTimeStampFormat = globalFormats.timeStampFormatForExportFiles;

// eslint-disable-next-line
export function SalesReport() {
  const opportunities = useSelector((state) => state.oppertunity.opportunites);
  const estimates = useSelector((state) => state.oppertunity.estimates);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [realData, setRealData] = useState();

  const [day, setDay] = useState('This Month');
  const buttonNames = [day, 'Export As'];

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const [showExportUI, setShowExportUI] = useState(false);
  const [timeStampForDocument, setTimeStampForDocument] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [errorWhenExporting, setErrorWhenExporting] = useState(false);

  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ]);

  const stageOptions = [
    { value: 'All Stages', label: 'All Stages' },
    { value: 'Opportunity', label: 'Opportunity' },
    { value: 'Estimate', label: 'Estimate' },
    { value: 'Won', label: 'Won' },
    { value: 'Lost', label: 'Lost' },
  ];
  const [stage, setStage] = useState([
    ...stageOptions.map((option) => option.value),
  ]);

  const typeOptions = [
    { value: 'All Types', label: 'All Types' },
    { value: 'Project', label: 'Project' },
    { value: 'Retainer', label: 'Retainer' },
  ];
  const [type, setType] = useState([
    ...typeOptions.map((option) => option.value),
  ]);

  const [exportAs, setExportAs] = useState(0);

  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const opportunityTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter((estimate) => estimate?.opportunity_status !== 'lost')
          .reduce((total, item) => {
            const opportunityType = item?.opportunity_type || '';
            const budget = item?.budget ? Number(item.budget) : 0;
            const duration = item?.duration || 1;
            const monthToMonth = item?.month_to_month;

            return (
              total +
              (opportunityType === 'Project_opportunity' ||
              opportunityType === 'Retainer_opportunity'
                ? monthToMonth === null && duration
                  ? budget * duration
                  : budget
                : 0)
            );
          }, 0)
      : 0;

  const formattedOpportunityTotal = opportunityTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const totalBudgetForWonEstimates =
    Array.isArray(realData) && realData.length > 0
      ? realData
          .filter((estimate) => estimate?.estimate_status === 'won')
          .reduce((total, estimate) => {
            const type = estimate?.type || '';
            const actual = estimate?.actual ? Number(estimate.actual) : 0;
            const duration = estimate?.duration || 0;
            const monthToMonth = estimate?.month_to_month;

            return (
              total +
              (type === 'retainer'
                ? monthToMonth === null && duration > 0
                  ? actual * duration
                  : actual
                : actual)
            );
          }, 0)
      : 0;

  const formattedTotalForWonEstimates =
    totalBudgetForWonEstimates.toLocaleString(
      currencyLocaleParam,
      currencyOptions,
    );

  const totalBudgetForLostEstimates =
    Array.isArray(realData) && realData.length > 0
      ? realData
          .filter(
            (estimate) =>
              estimate?.estimate_status === 'lost' ||
              estimate?.opportunity_status === 'lost',
          )
          .reduce((total, estimate) => {
            const type = estimate?.type || '';
            const actual = estimate?.actual ? Number(estimate.actual) : 0;
            const budget = estimate?.budget || 0;
            const duration = estimate?.duration || 0;
            const monthToMonth = estimate?.month_to_month;

            return (
              total +
              (actual
                ? type === 'retainer'
                  ? monthToMonth === null && duration > 0
                    ? actual * duration
                    : actual
                  : actual
                : budget)
            );
          }, 0)
      : 0;

  const formateTotalForLostEstimates =
    totalBudgetForLostEstimates.toLocaleString(
      currencyLocaleParam,
      currencyOptions,
    );

  const estimateTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter((item) => item?.estimate_status === 'active')
          .reduce((total, item) => {
            const actual = item?.actual ? Number(item.actual) : 0;
            const duration = item?.duration || 0;
            const opportunityType = item?.opportunity_type || '';
            const monthToMonth = item?.month_to_month;

            return (
              total +
              (opportunityType === 'Project Estimate ' ||
              opportunityType === 'Retainer Estimate '
                ? monthToMonth === null && duration > 0
                  ? actual * duration
                  : actual
                : 0)
            );
          }, 0)
      : 0;

  const formattedEstimateTotal = estimateTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const project =
    Array.isArray(realData) && realData.length
      ? realData.filter(
          (item) =>
            item?.type === 'project' && item?.estimate_status === 'active',
        )
      : [];

  const projectTotal =
    Array.isArray(project) && project.length
      ? project.reduce((total, item) => {
          const actual = item?.actual ? Number(item.actual) : 0;
          return total + actual;
        }, 0)
      : 0;

  const formattedProjectTotal = projectTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const retainer =
    Array.isArray(realData) && realData.length
      ? realData.filter(
          (item) =>
            item?.type === 'retainer' && item?.estimate_status === 'active',
        )
      : [];

  const retainerTotal =
    Array.isArray(retainer) && retainer.length
      ? retainer.reduce((total, item) => {
          const actual = item?.actual ? Number(item.actual) : 0;
          const duration = item?.duration || 0;
          const monthToMonth = item?.month_to_month;

          return (
            total +
            (actual
              ? monthToMonth === null && duration > 0
                ? actual * duration
                : actual
              : 0)
          );
        }, 0)
      : 0;

  const formattedRetainerTotal = retainerTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const projects =
    Array.isArray(estimates) && Array.isArray(opportunities)
      ? [...estimates, ...opportunities]
      : [];

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const rows = useMemo(
    () =>
      projects
        .map((item) => {
          return {
            id: item?.id,
            name: item?.title ? item?.title : item?.name ? item.name : '-----',

            created_at: item?.created_at,
            status_updated_at: item?.status_updated_at,

            opportunity_type: item?.opportunity_type
              ? capitalizeFirstLetter(item?.opportunity_type)
              : `${capitalizeFirstLetter(item.type)} Estimate `,

            company:
              currentCompanies.find((c) => c.id === parseInt(item.company, 10))
                ?.name ?? '---------',
            target_start: item?.target_start,
            target_end: item?.finish_date,
            formattedStartDate: item?.target_start
              ? `${months[item?.target_start.split('-')[1]]} ${
                  item?.target_start.split('-')[2]
                }, ${item.target_start.split('-')[0]}`
              : false,

            formattedEndDate: item.target_end
              ? `${new Date(item.target_end).getDate()} ${
                  months[new Date(item.target_end).getMonth() + 1]
                } ${new Date(item.target_end).getFullYear()}`
              : false,
            duration: item?.duration ? Number(item?.duration) : null,

            est_startdate: item.est_startdate
              ? dayjs(item.est_startdate).format('MM-DD-YYYY')
              : '',
            est_enddate: item.est_enddate
              ? dayjs(item.est_enddate).format('MM-DD-YYYY')
              : '',
            // eslint-disable-next-line
            isOpa: 'name' in item ? true : false,
            unique_id: item.unique_id || item.id,
            collaborator: item.collaborator,
            budget: item.budget ?? null,
            // eslint-disable-next-line
            is_retainer: item.type === 'retainer' ? true : false,
            estimate_status: item?.estimate_status ?? null,
            opportunity_status: item?.opportunity_status ?? null,
            type: item?.type ?? null,
            month_to_month: item?.month_to_month ?? null,
            actual: item?.actual ?? null,
          };
        })
        .sort((a, b) => {
          // Assuming 'created_at' is a property in your project objects
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);

          // Sort in descending order (newest created first)
          return dateB - dateA;
        }),
    [projects, currentCompanies],
  );

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const handleDateRange = (v) => {
    setDateRange(v);
  };

  const handleClickDay = (v) => {
    setDay(v);
    const today = dayjs();

    if (v === 'Today') {
      setDateRange([today.startOf('day'), today.endOf('day')]);
    } else if (v === 'Yesterday') {
      const yesterday = today.subtract(1, 'day');
      setDateRange([yesterday.startOf('day'), yesterday.endOf('day')]);
    } else if (v === 'This Week') {
      setDateRange([today.startOf('week'), today.endOf('week')]);
    } else if (v === 'Last Week') {
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')]);
    } else if (v === 'This Month') {
      setDateRange([today.startOf('month'), today.endOf('month')]);
    } else if (v === 'Last Month') {
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);
    } else if (v === 'This Year') {
      setDateRange([today.startOf('year'), today.endOf('year')]);
    } else if (v === 'Last Year') {
      setDateRange([
        today.startOf('year').subtract(1, 'year'),
        today.endOf('year').subtract(1, 'year'),
      ]);
    }
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    const projectsFilteredByDate = rows?.filter((project) => {
      if (
        project.opportunity_type === 'Retainer_opportunity' ||
        project.opportunity_type === 'Project_opportunity'
      ) {
        const createdDate = new Date(project.created_at);
        const statusDate = new Date(project.status_updated_at);
        const rangeStart = new Date(dateRange[0]);
        const rangeEnd = addDays(new Date(dateRange[1]), 1);

        if (project.opportunity_status === 'active' && createdDate < rangeEnd) {
          return true;
        }

        if (
          ((project.opportunity_status === 'archieved' ||
            project.opportunity_status === 'lost') &&
            createdDate < rangeStart &&
            rangeEnd <= statusDate) ||
          (rangeStart < createdDate &&
            rangeEnd >= createdDate &&
            rangeEnd <= statusDate)
        ) {
          // eslint-disable-next-line
          project.opportunity_status = 'active';
          return true;
        }
        if (
          project.opportunity_status === 'lost' &&
          createdDate < rangeEnd &&
          statusDate > rangeStart
        ) {
          return true;
        }
      }

      if (
        project.opportunity_type === 'Retainer Estimate ' ||
        project.opportunity_type === 'Project Estimate '
      ) {
        const createdDate = new Date(project.created_at);
        const statusDate = new Date(project.status_updated_at);
        const rangeStart = new Date(dateRange[0]);
        const rangeEnd = addDays(new Date(dateRange[1]), 1);

        if (project.estimate_status === 'active' && createdDate < rangeEnd) {
          return true;
        }

        if (
          (project.estimate_status === 'won' ||
            project.estimate_status === 'lost') &&
          createdDate < rangeEnd &&
          rangeEnd < statusDate
        ) {
          // eslint-disable-next-line
          project.estimate_status = 'active';
          return true;
        }

        if (
          (project.estimate_status === 'won' ||
            project.estimate_status === 'lost') &&
          createdDate < rangeEnd &&
          statusDate > rangeStart
        ) {
          return true;
        }
      }
      return false; // Return true for other types of projects
    });

    let filteredProjects = projectsFilteredByDate;

    if (stage.length > 0) {
      if (stage.includes('All')) {
        filteredProjects = projectsFilteredByDate;
      } else {
        filteredProjects = projectsFilteredByDate?.filter((item) => {
          return stage.some((selectedStage) => {
            if (selectedStage === 'Estimate') {
              return (
                item.opportunity_type === 'Project Estimate ' ||
                item.opportunity_type === 'Retainer Estimate '
              ); // eslint-disable-next-line
            } else if (selectedStage === 'Opportunity') {
              return (
                item.opportunity_type === 'Project_opportunity' ||
                item.opportunity_type === 'Retainer_opportunity'
              );
            } else if (selectedStage === 'Won') {
              return item.estimate_status === 'won';
            } else if (selectedStage === 'Lost') {
              return (
                item.estimate_status === 'lost' ||
                item.opportunity_status === 'lost'
              );
            }
            return false;
          });
        });
      }
    }

    if (type.length > 0) {
      if (type.includes('All')) {
        // eslint-disable-next-line
        filteredProjects = filteredProjects;
      } else {
        filteredProjects = filteredProjects?.filter((item) => {
          return type.some((selectedType) => {
            if (selectedType === 'Retainer') {
              return (
                item.opportunity_type === 'Retainer Estimate ' ||
                item.opportunity_type === 'Retainer_opportunity'
              ); // eslint-disable-next-line
            } else if (selectedType === 'Project') {
              return (
                item.opportunity_type === 'Project Estimate ' ||
                item.opportunity_type === 'Project_opportunity'
              );
            }
            return false;
          });
        });
      }
    }

    setRealData(filteredProjects || []);
  }, [stage, type, dateRange]);

  const triggerExportProcess = (exportFunction, shouldShowExportUI) => {
    if (shouldShowExportUI) {
      setShowExportUI(true);
    }

    const currentTimeStamp = dayjs().format(documentTimeStampFormat);
    setTimeStampForDocument(currentTimeStamp);

    setTimeout(() => {
      exportFunction(currentTimeStamp);

      if (shouldShowExportUI) {
        setShowExportUI(false);
      }
    }, 1000);
  };

  const generateAndDownloadPDF = (currentTimeStamp) => {
    setIsExportLoading(true);
    // eslint-disable-next-line
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: 'letter',
      compress: true,
    });

    const margin = 0.5;
    const chartElement = document.getElementById('sales-report-to-export');
    if (!chartElement) {
      setIsExportLoading(false);
      return;
    }

    html2canvas(chartElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = doc.internal.pageSize.getWidth() - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        doc.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);

        const startY = imgHeight + margin + 0.3;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('', margin, startY);

        doc.save(`Sales-Report-${currentTimeStamp}.pdf`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error exporting "Sales Report" to PDF:', error);
        setErrorWhenExporting(true);
        setShowExportUI(false);
        setTimeStampForDocument(null);
      })
      .finally(() => {
        setIsExportLoading(false);
        setShowExportUI(false);
        setTimeStampForDocument(null);

        setTimeout(() => {
          setErrorWhenExporting(false);
        }, 3000);
      });
  };

  const downloadCSV = (currentTimeStamp) => {
    setIsExportLoading(true);
    try {
      const table = document.getElementById('div-to-csv');
      const rows = Array.from(table.getElementsByTagName('tr'));

      const csvData = rows.map((row) => {
        const cells = Array.from(row.getElementsByTagName('td'));
        return cells.map(
          (cell) => `"${cell.innerText.trim().replace(/"/g, '""')}"`,
        );
      });

      // Prepend column names to CSV data
      const columnNames = [
        'Name',
        'Company',
        'Type',
        'Last Status',
        'Start Date - End Date',
        'Total Amount',
      ].map((col) => `"${col}"`);

      const csvContent = [columnNames, ...csvData]
        .map((row) => row.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Sales-Report-CSV-${currentTimeStamp}.csv`;
      link.click();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error exporting "Sales Report" to CSV:', error);
      setErrorWhenExporting(true);
    } finally {
      setIsExportLoading(false);
      setTimeStampForDocument(null);

      setTimeout(() => {
        setErrorWhenExporting(false);
      }, 3000);
    }
  };

  const downloadXLS = (currentTimeStamp) => {
    setIsExportLoading(true);
    try {
      const table = document.getElementById('div-to-csv');
      const rows = Array.from(table.getElementsByTagName('tr'));

      const data = rows.map((row) => {
        const cells = Array.from(row.getElementsByTagName('td'));
        return cells.map((cell) => cell.innerText.trim());
      });

      const worksheet = XLSX.utils.aoa_to_sheet([
        [
          'Name',
          'Company',
          'Type',
          'Last Status',
          'Start Date - End Date',
          'Total Amount',
        ],
        ...data,
      ]);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });

      saveAs(blob, `Sales-Report-XLS-${currentTimeStamp}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error exporting "Sales Report" to XLS:', error);
      setErrorWhenExporting(true);
    } finally {
      setIsExportLoading(false);
      setTimeStampForDocument(null);

      setTimeout(() => {
        setErrorWhenExporting(false);
      }, 3000);
    }
  };

  const handleClickExportAs = (v, i) => {
    if (v === 'PDF') {
      triggerExportProcess(generateAndDownloadPDF, true);
    } else if (v === 'CSV') {
      triggerExportProcess(downloadCSV, false);
    } else if (v === 'XLS') {
      triggerExportProcess(downloadXLS, false);
    }

    setExportAs(i);
    handleClose();
  };

  const handleSelectStageChange = (e) => {
    let selectedValue = e.target.value;

    if (selectedValue.length === 0) {
      return;
    }

    const lastValue = selectedValue[selectedValue.length - 1];
    const unselected = stage.filter((item) => !selectedValue.includes(item));

    // * Case 1: If User selects all then all options should be selected.
    if (lastValue === 'All Stages' && !stage.includes('All Stages')) {
      selectedValue = stageOptions.map((option) => option.value);
      setStage(selectedValue);
    }

    // * Case 2: If all stages are selected, and user deselects "All", only "Opportunity" should remain selected.
    else if (
      unselected.includes('All Stages') &&
      stage.includes('All Stages')
    ) {
      setStage(['Opportunity']);
    }

    // * Case 3: If the user selects options one by one and reaches the limit then all automatically get selected
    else if (
      stageOptions
        .filter((option) => option.value !== 'All Stages')
        .every((option) => selectedValue.includes(option.value))
    ) {
      selectedValue = stageOptions.map((option) => option.value);
      setStage(selectedValue);
    }

    // * Case 3: Simple selection of other options if above conditions does not match
    else {
      selectedValue = selectedValue.filter((item) => item !== 'All Stages');
      setStage(selectedValue);
    }
  };

  const handleSelectTypeChange = (e) => {
    let selectedValue = e.target.value;

    if (selectedValue.length === 0) {
      return;
    }

    const lastValue = selectedValue[selectedValue.length - 1];
    const unselected = type.filter((item) => !selectedValue.includes(item));

    // * Case 1: If User selects all then all options should be selected.
    if (lastValue === 'All Types' && !type.includes('All Types')) {
      selectedValue = typeOptions.map((option) => option.value);
      setType(selectedValue);
    }

    // * Case 2: If all stages are selected, and user deselects "All", only "Project" should remain selected.
    else if (unselected.includes('All Types') && type.includes('All Types')) {
      setType(['Project']);
    }

    // * Case 3: If the user selects options one by one and reaches the limit then all automatically get selected
    else if (
      typeOptions
        .filter((option) => option.value !== 'All Types')
        .every((option) => selectedValue.includes(option.value))
    ) {
      selectedValue = typeOptions.map((option) => option.value);
      setType(selectedValue);
    }

    // * Case 3: Simple selection of other options if above conditions does not match
    else {
      selectedValue = selectedValue.filter((item) => item !== 'All Types');
      setType(selectedValue);
    }
  };

  const projectOpportunityTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter(
            (item) =>
              item?.opportunity_type === 'Project_opportunity' &&
              item?.opportunity_status !== 'lost',
          )
          .reduce((total, item) => {
            const budget = item?.budget ? Number(item.budget) : 0;
            const duration = item?.duration || 0;
            const monthToMonth = item?.month_to_month;

            return (
              total +
              (monthToMonth === null && duration > 0
                ? budget * duration
                : budget)
            );
          }, 0)
      : 0;

  const formattedProjectOpportunityTotal =
    projectOpportunityTotal.toLocaleString(
      currencyLocaleParam,
      currencyOptions,
    );

  const retainerOpportunityTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter((item) => item?.opportunity_type === 'Retainer_opportunity')
          .reduce((total, item) => {
            const budget = item?.budget ? Number(item.budget) : 0;
            const duration = item?.duration || 0;
            const monthToMonth = item?.month_to_month;

            return (
              total +
              (monthToMonth === null && duration > 0
                ? budget * duration
                : budget)
            );
          }, 0)
      : 0;

  const formattedRetainerOpportunityTotal =
    retainerOpportunityTotal.toLocaleString(
      currencyLocaleParam,
      currencyOptions,
    );

  const projectLostTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter(
            (item) =>
              item?.type === 'project' &&
              (item?.estimate_status === 'lost' ||
                item?.opportunity_status === 'lost'),
          )
          .reduce((total, item) => {
            const actual = item?.actual ? Number(item.actual) : 0;
            const budget = item?.budget || 0;

            return total + (actual || budget);
          }, 0)
      : 0;

  const formattedProjectLostTotal = projectLostTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const retainerLostTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter(
            (item) =>
              (item?.type === 'retainer' && item?.estimate_status === 'lost') ||
              (item?.opportunity_type === 'Retainer_opportunity' &&
                item?.opportunity_status === 'lost'),
          )
          .reduce((total, item) => {
            const actual = item?.actual ? Number(item.actual) : 0;
            const budget = item?.budget || 0;
            const duration = item?.duration || 0;
            const monthToMonth = item?.month_to_month;

            return (
              total +
              (actual
                ? monthToMonth === null && duration > 0
                  ? actual * duration
                  : actual
                : budget)
            );
          }, 0)
      : 0;

  const formattedRetainerLostTotal = retainerLostTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const projectWonTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter(
            (item) =>
              item?.type === 'project' && item?.estimate_status === 'won',
          )
          .reduce((total, item) => total + (Number(item?.actual) || 0), 0)
      : 0;

  const formattedProjectWonTotal = projectWonTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const retainerWonTotal =
    Array.isArray(realData) && realData.length
      ? realData
          .filter(
            (item) =>
              item?.type === 'retainer' && item?.estimate_status === 'won',
          )
          .reduce((total, item) => {
            const actual = item?.actual ? Number(item.actual) : 0;
            const duration = item?.duration || 0;
            const monthToMonth = item?.month_to_month;

            return (
              total +
              (monthToMonth === null && duration > 0
                ? actual * duration
                : actual)
            );
          }, 0)
      : 0;

  const formattedRetainerWonTotal = retainerWonTotal.toLocaleString(
    currencyLocaleParam,
    currencyOptions,
  );

  const cardsData = useMemo(
    () => [
      {
        title: 'Opportunities',
        cardColor: '#E0F0FF',
        totalColor: '#C6E4FF',
        formattedTotal: formattedOpportunityTotal,
        subTotal: [
          {
            title: 'Project',
            total: formattedProjectOpportunityTotal,
          },
          {
            title: 'Retainer',
            total: formattedRetainerOpportunityTotal,
          },
        ],
      },
      {
        title: 'Estimates',
        cardColor: '#b2daff',
        totalColor: '#93cbff',
        formattedTotal: formattedEstimateTotal,
        subTotal: [
          {
            title: 'Project',
            total: formattedProjectTotal,
          },
          {
            title: 'Retainer',
            total: formattedRetainerTotal,
          },
        ],
      },
      {
        title: 'Won',
        cardColor: '#a7efc8',
        totalColor: darkWonGreen,
        formattedTotal: formattedTotalForWonEstimates,
        subTotal: [
          {
            title: 'Project',
            total: formattedProjectWonTotal,
          },
          {
            title: 'Retainer',
            total: formattedRetainerWonTotal,
          },
        ],
      },
      {
        title: 'Lost',
        cardColor: '#f48d8d',
        totalColor: darkLostRed,
        formattedTotal: formateTotalForLostEstimates,
        subTotal: [
          {
            title: 'Project',
            total: formattedProjectLostTotal,
          },
          {
            title: 'Retainer',
            total: formattedRetainerLostTotal,
          },
        ],
      },
    ],
    [
      opportunityTotal,
      formattedOpportunityTotal,
      estimateTotal,
      formattedEstimateTotal,
      totalBudgetForWonEstimates,
      formattedTotalForWonEstimates,
      totalBudgetForLostEstimates,
      formateTotalForLostEstimates,
      formattedProjectOpportunityTotal,
      formattedRetainerOpportunityTotal,
      formattedProjectLostTotal,
      formattedRetainerLostTotal,
      formattedProjectWonTotal,
      formattedRetainerWonTotal,
    ],
  );

  const getStylesBasedOnIndex = (index1, index2) => {
    const baseStyles = {
      borderRadius: 1,
      textTransform: 'inherit',
      fontSize: '14px',
      m: 1,
      padding: '0.2rem 0.5rem',
      '&:hover': {
        backgroundColor: '#E0E0DF',
        cursor: 'pointer',
      },
    };

    return index1 === index2
      ? {
          ...baseStyles,
          color: 'white',
          backgroundColor: '#711FFF',
        }
      : {
          ...baseStyles,
          color: 'black',
        };
  };

  return (
    <>
      <Grid
        sx={{
          backgroundColor: 'inherit',
          padding: '1rem 1rem 0 1rem',
          borderBottom: '1px solid #e2e2e2',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2.9rem',
            width: '95%',
            backgroundColor: 'var(--light-primary-color)',
            padding: '1rem 1rem',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >
          <div>
            {buttonNames.map((name, index) => (
              <>
                <Button
                  variant="contained"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  startIcon={index === 0 && <CalendarMonthOutlined />}
                  endIcon={
                    <ExpandMoreSharpIcon
                      style={{
                        width: '18px',
                        height: '18px',
                        fontWeight: 200,
                        color: '#03071E',
                      }}
                    />
                  }
                  style={{
                    marginRight: 10,
                    backgroundColor: '#fff',
                    color: 'black',
                    textTransform: 'inherit',
                    boxShadow: 'inherit',
                    fontWeight: 400,
                    fontSize: '14px',
                  }}
                >
                  {index === 1 ? name : name}
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'inherit',
                      border: '1px solid #E0E0DF',
                      borderRadius: 2,
                    },
                  }}
                >
                  {index === 0 && (
                    <Box
                      align="center"
                      sx={{
                        width: 'auto',
                        height: 'auto',
                        px: '30px',
                        display: { xs: 'block', md: 'flex' },
                      }}
                    >
                      <Box
                        align="left"
                        sx={{
                          backgroundColor: '#F8F9FA',
                          width: { xs: '100%', md: '200px' },
                          p: 1,
                          pb: 3,
                          my: 4,
                          borderRadius: 2,
                        }}
                      >
                        {[
                          'Today',
                          'Yesterday',
                          'This Week',
                          'Last Week',
                          'This Month',
                          'Last Month',
                          'This Year',
                          'Last Year',
                        ].map((v, i) => {
                          return (
                            <Typography
                              // eslint-disable-next-line
                              key={i}
                              onClick={() => handleClickDay(v, i)}
                              sx={
                                day === v
                                  ? {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#711FFF',
                                      my: 3,
                                      ml: 2,
                                    }
                                  : {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#03071E',
                                      my: 3,
                                      ml: 2,
                                    }
                              }
                            >
                              {v}
                            </Typography>
                          );
                        })}
                      </Box>
                      <Box sx={{ mx: 4, my: 4 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateRangeCalendar
                            value={dateRange}
                            onChange={(newValue) => handleDateRange(newValue)}
                            sx={{
                              '& .css-10wpov9-MuiTypography-root ': {
                                fontWeight: '700 !important',
                              },
                              '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval':
                                {
                                  color: '#03071E !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085 !important',
                                color: 'white !important',
                                borderRadius: '0px !important',
                              },
                              '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: 'white !important',
                                  opacity: 'inherit !important',
                                  borderRadius: '0px',
                                  border: 'none !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: '#e569db !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)':
                                {
                                  borderRight: 'none !important',
                                },
                              '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                flexDirection: 'column !important',
                              },

                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  color: 'inherit',
                                  fontSize: '16px !important',
                                  opacity: 'inherit',
                                  fontWeight: 500,
                                },
                              '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: 'inherit',
                                  color: 'black !important',
                                  borderRadius: '50%',
                                  border: '1px solid gray',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .MuiTypography-subtitle1': {
                                color: ' #03071E !important',
                                fontSize: '16px',
                                fontWeight: 700,
                              },
                              '& .MuiDayCalendar-weekDayLabel': {
                                color: ' #03071ECC !important',
                                fontSize: '16px',
                                fontWeight: 400,
                              },
                              '& .MuiSvgIcon-fontSizeInherit': {
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '50%',
                              },
                              '& .MuiPickersDay-today': {
                                backgroundColor: '#711FFF',
                                color: 'white',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .MuiDateRangePickerDay-notSelectedDate': {},
                            }}
                          />
                        </LocalizationProvider>

                        <Box sx={{ display: 'flex', my: 4 }}>
                          <Box sx={{ display: 'flex', mr: 4 }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#711FFF',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Start Date and End Date
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#310085',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Date Range
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        align=""
                        my={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: '',
                        }}
                      >
                        <Box align="left" mt={4}>
                          <Box align="left">
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              Start Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {dateRange[0] &&
                                dateRange[0].format().slice(0, 10)}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              End Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {dateRange[1] &&
                                dateRange[1].format().slice(0, 10)}
                            </Typography>
                          </Box>
                        </Box>

                        <Box align="right">
                          <Button
                            variant="text"
                            sx={{
                              textDecoration: 'inherit',
                              color: 'black',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                              textDecoration: 'inherit',
                              backgroundColor: '#711FFF',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                              '&:hover': {
                                backgroundColor: '#711FFF',
                              },
                            }}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {index === 1 && (
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {['PDF', 'XLS', 'CSV'].map((v, i) => {
                        return (
                          <Typography
                            key={v}
                            fullWidth
                            onClick={() => handleClickExportAs(v, i)}
                            sx={{
                              ...getStylesBasedOnIndex(exportAs.index, i),
                            }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                </Popover>
              </>
            ))}

            {isExportLoading && (
              <RefreshIcon
                className={`loading-icon ${isExportLoading ? 'loading' : ''}`}
              />
            )}
          </div>
        </Box>

        <div className="sales-report-selects-container">
          <SelectDropdown
            id="sales-report-filter-by-stage"
            options={stageOptions}
            defaultValue={stage[0] ?? 'All Stages'}
            value={stage}
            selectedFilter={stage}
            onChange={handleSelectStageChange}
          />

          <SelectDropdown
            id="sales-report-filter-by-type"
            options={typeOptions}
            defaultValue={type[0] ?? 'All Types'}
            value={type}
            selectedFilter={type}
            onChange={handleSelectTypeChange}
          />
        </div>
      </Grid>

      {errorWhenExporting && (
        <Alert
          className="alert"
          severity="error"
          sx={{ width: 'max-content', margin: '0 auto', marginTop: '1rem' }}
        >
          Whoops... Something went wrong while exporting the{' '}
          {exportAs?.text ?? 'report'}, please try again.
        </Alert>
      )}

      <div id="sales-report-to-export">
        {showExportUI && (
          <div className="report-header">
            <h2>Sales Report</h2>

            <div className="divider">
              <hr />
            </div>

            <span className="text-for-export">
              Data from {timeStampForDocument ?? 'N/A'}
            </span>
          </div>
        )}
        <div id="" className="sales-report-card-container">
          {cardsData.map((card) => (
            <div
              key={card.title}
              className="sales-report-card"
              style={{
                backgroundColor: card.cardColor,
              }}
            >
              <img
                src={moneySign}
                alt="money sign"
                style={{ margin: '10px 0px' }}
              />
              <h5>{card.title}</h5>
              <div
                style={{
                  width: '100%',
                  background: card.totalColor,
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '10px',
                  padding: '0.5rem 0rem',
                }}
              >
                <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                  TOTAL
                </Typography>
                <Typography sx={{ fontWeight: '600', fontSize: '15px' }}>
                  {card?.formattedTotal ?? 0}
                </Typography>
              </div>
              {card?.subTotal.map((sub) => (
                <div
                  key={sub.title}
                  className="sales-report-card-subtotal"
                  style={{
                    background: card.totalColor,
                  }}
                >
                  <span>{sub.title}:</span>
                  <span>{sub.total}</span>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div id="div-to-csv">
          <Box sx={{ padding: '10px 10px' }}>
            <TableContainer
              component={Paper}
              sx={{
                width: '100%',
                border: '1px solid #adadad',
                borderRadius: '8px',
                m: '1rem 0',
              }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      height: '72px',
                      backgroundColor: '#f8f9fa',
                      fontSize: '1rem',
                    }}
                  >
                    <TableCell>Name</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Last Status</TableCell>
                    <TableCell>Start Date - End Date</TableCell>
                    <TableCell>Total Amount</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ padding: '0 10px' }}>
                  {realData?.map((item) => {
                    return (
                      <TableRow
                        key={item.id}
                        sx={{
                          height: '92px',
                          fontSize: '1rem',
                          color: '#000',
                        }}
                      >
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.company}</TableCell>
                        <TableCell>
                          {item.opportunity_type === 'Project_opportunity' ||
                          item.opportunity_type === 'Retainer_opportunity'
                            ? item.opportunity_type
                                .split('_')
                                .map((word) => capitalizeFirstLetter(word))
                                .join(' ')
                            : item.opportunity_type}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            color:
                              item.estimate_status === 'won'
                                ? darkWonGreen
                                : item.estimate_status === 'lost'
                                  ? darkLostRed
                                  : 'black',
                          }}
                        >
                          {item.estimate_status
                            ? item.estimate_status === 'active'
                              ? ''
                              : capitalizeFirstLetter(item.estimate_status)
                            : item.opportunity_status === 'active'
                              ? ''
                              : item.opportunity_status
                                ? capitalizeFirstLetter(item.opportunity_status)
                                : ''}
                        </TableCell>
                        <TableCell>
                          {item?.est_startdate ? item?.est_startdate : 'N/A'}
                          {' - '}
                          {item.est_enddate ? item?.est_enddate : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <strong>
                            {(() => {
                              if (
                                item?.opportunity_type ===
                                  'Project_opportunity' ||
                                item?.opportunity_type ===
                                  'Retainer_opportunity'
                              ) {
                                return (
                                  (item?.month_to_month === null &&
                                  item?.duration &&
                                  item?.duration !== 0
                                    ? Number(item?.budget) *
                                      Number(item?.duration)
                                    : Number(item?.budget)) ?? 0
                                ).toLocaleString(
                                  currencyLocaleParam,
                                  currencyOptions,
                                ); // eslint-disable-next-line
                              } else if (
                                item?.opportunity_type ===
                                  'Project Estimate ' ||
                                item?.opportunity_type === 'Retainer Estimate '
                              ) {
                                return (
                                  (item?.month_to_month === null &&
                                  item?.duration &&
                                  item?.duration !== 0
                                    ? Number(item?.actual) *
                                      Number(item?.duration)
                                    : Number(item?.actual)) ?? 0
                                ).toLocaleString(
                                  currencyLocaleParam,
                                  currencyOptions,
                                );
                              }
                              return '0';
                            })()}
                          </strong>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </>
  );
}
