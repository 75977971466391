import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { TextField,Skeleton } from "@mui/material";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { ENDPOINT,} from '../../../Opportunity/Redux/oppor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { getTeam } from '../../../Stream/streamRedux';
import { PreLoader1 } from '../../../../Componenets/Misc';


import {
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";
  import './stripe.css'
  
  const CheckoutForm = () => {
  
    const stripe = useStripe();
    const elements = useElements();
  
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
      if (!stripe) {
        return;
      }
  
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
  
      if (!clientSecret) {
        return;
      }
  
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }, [stripe]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.href?.slice(0, 21)}/Account_Billing`,
        },
        // redirect: "if_required",
      })
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      }
      else {
        setMessage("An unexpected error occurred.");
      }
  
      setIsLoading(false);
    };
  
    const paymentElementOptions = {
      layout: "tabs",
    }
  
  
    return (
      <form className='stripeForm' id="payment-form" onSubmit={handleSubmit}>
  
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button className='stripeButton' disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  }
  
  import { loadStripe } from "@stripe/stripe-js";
  import { Elements } from "@stripe/react-stripe-js";
  
  const Payment = ({ funcAlert, apiFunc, team_plan }) => {
    
  const access_token = useSelector((state) => state.auth.user.access);


  const [fields, setFields] = useState({
    company_name: "",
    phone: "",
    tax_id: null,
    office_tel: null,
    country: null,
    city: null,
    state: null,
    zip: null,
    invoice_mailing_address: null,
    billing_email: null,
    created_by: null,
  });


  const [payment, setPayment] = useState({
    card_holder_name: "",
    card_number: null,
    expiration_date: null,
    office_tel: null,
    country: "",
    city: "",
    state: "",
    zip: "",
    payment_mailing_address: "",
    billing_email: "",
    created_by: "",
  });


  const [form, setForm] = useState({
    payment: "", // Set this to the selected payment type
    payment_type: "", // Set this to the selected payment type
    first_name: "", // Replace with the actual first name from your form
    last_name: "", // Replace with the actual last name from your form
    email: "", // Replace with the actual email from your form
    card: {
      card_first_name: "", // Replace with the actual card holder's name from your form
      card_last_name: "", // You can add this if you have the last name in your form
      card_number: "", // Replace with the actual card number from your form
      card_expiry_month: 0,
      card_expiry_year: 0,
      card_cvv: "",
      billing_zip: '',
      billing_country: '',
    },
  })

  const [editModeInvoice, setEditModeInvoice] = useState(false);
  const [editModePayment, setEditModePayment] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loaderIcon, setLoaderIcon] = useState(false)


  const toggleEditModeInvoice = () => {
    setEditModeInvoice(!editModeInvoice);
  };

  const toggleEditModePayment = () => {
    setEditModePayment(!editModePayment);
  };


  const [paymentInfoExists, setPaymentInfoExists] = useState(false);

  const dispatch = useDispatch()

  const handleSave = async () => {
    setLoading(true)

    try {

      const response = await fetch(`${ENDPOINT}/api/sub/create-payment-information/`, {
        method: "POST",
        headers: {
          Authorization: `JWT ${access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      const data = await response.json();
      if (!response.ok) {
        setLoading(false)

        for (let i in data) {
          let msg = data[i]
          funcAlert(msg, false)
        }
    
      } else {
        setLoading(false)
        setPayment({
          card_holder_name: '',
          card_number: '',
          expiration_date: data[0]?.payment_method?.card?.exp_month + "/" + data[0]?.payment_method?.card?.exp_year,
          office_tel: null,
          country: data[0]?.payment_method?.card?.country,
          city: "",
          state: "",
          zip: '',
          payment_mailing_address: "",
          billing_email: "",
          created_by: "",
        })
        const responseGet = await fetch(`${ENDPOINT}/api/user/team/`, {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        });
        const dataGet = await responseGet.json();
        dispatch(getTeam({ access_token }))

        setPaymentInfoExists(true); // Payment information now exists

      }
    } catch (error) {

      console.error("An error occurred:", error);
    }
  };

  const team = team_plan

  const handleSaveInvoice = async () => {
    setLoaderIcon(true)
    if (team?.team_invoice === null) {

      try {

        const response = await fetch(`${ENDPOINT}/api/sub/create-invoice-information/`, {
          method: "POST",
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(fields),
        });

        const data = await response.json();
        if (!response.ok) {
          setLoaderIcon(false)

          for (let i in data) {
            let msg = data[i]
            funcAlert(msg, false)
          }

        } else {
          setLoaderIcon(false)
          apiFunc()
          setFields({
            company_name: data.company_name,
            phone: data.phone,
            tax_id: data.tax_id,
            office_tel: null,
            country: null,
            city: null,
            state: null,
            zip: null,
            invoice_mailing_address: data.invoice_mailing_address,
            billing_email: data.billing_email,
            created_by: null,
          })
  
          for (let i in data) {
            let msg = data[i]
            funcAlert("payment detail create successfully", true)
          }
          dispatch(getTeam({ access_token }))
          const responseGet = await fetch(`${ENDPOINT}/api/user/team/`, {
            method: 'GET',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          });
          const dataGet = await responseGet.json();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        setLoaderIcon(false)
      }
    } else {

      try {

        const response = await fetch(`${ENDPOINT}/api/sub/update-invoice-information/${team?.team_invoice}/`, {
          method: "PUT",
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(fields),
        });

        const data = await response.json();
        if (!response.ok) {
          setLoaderIcon(false)

          for (let i in data) {
            let msg = data[i]
            funcAlert(msg, false)
          }

        } else {
          setLoaderIcon(false)
          setFields({
            company_name: data.company_name,
            phone: data.phone,
            tax_id: data.tax_id,
            office_tel: null,
            country: null,
            city: null,
            state: null,
            zip: null,
            invoice_mailing_address: data.invoice_mailing_address,
            billing_email: data.billing_email,
            created_by: null,
          })
          for (let i in data) {
            let msg = data[i]
            funcAlert("Payment detail update successfully", true)
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
        setLoaderIcon(false)
      }

    }
  }

  ///////////////////////////////////////////////////////////////////////////////

  // this is stripe function

  const userData = useSelector((state) => state.userData.userData);

  const stripePromise = origin === 'https://netnetapp.com' ? loadStripe("pk_live_51MgVftF0dQwFAH5fLtyZMsMb6aSn3UgdHpYVvStfovcKAWS2nCeLoC0L0s3hrsZwGHcwKYo45NUySwC1QZ4QJ5UX00H6tm9fN6")
    :
    loadStripe("pk_test_51MgVftF0dQwFAH5fkHxOHxgL0oO9uTyCoBC8Xx14SiZsNM1TV8WfAu1Ni6C8vazTiL6lRkQehY0T8vcHhkIQapxy00bqx6GQbi")

  const [clientSecret, setClientSecret] = useState("");

  // Create PaymentIntent as soon as the page loads
  const apiStripe = async () => {
    try {

      const res = await fetch(`${ENDPOINT}/api/sub/create-payment-information/`, {
        method: "POST",
        headers: {
          Authorization: `JWT ${access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_gateway: "stripe",
          payment_type: "card",
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email
        }),
      })
      let data = await res.json()
      if (!res.ok) {

        for (let i in data) {
          let msg = data[i]
          funcAlert(msg, false)
        }
      }
      else {
        setClientSecret(data[1]?.client_secret)
      }

    } catch (error) {
      console.error(error)
    }
  }



  useEffect(() => {
    async function api() {

      setLoading(true)
      if (team?.team_invoice) {

        try {

          const response = await fetch(`${ENDPOINT}/api/sub/retrieve-invoice-information/`, {
            method: "POST",
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ "pk": team?.team_invoice })
          });

          const datanew = await response.json();
          const data = datanew[0]
          if (!response.ok) {
            setLoading(false)
          } else {
            dispatch(getTeam({ access_token }))
            setLoading(false)
            setPaymentInfoExists(true)
            setFields({
              company_name: data.company_name,
              phone: data.phone,
              tax_id: data.tax_id,
              office_tel: null,
              country: null,
              city: null,
              state: null,
              zip: null,
              invoice_mailing_address: data.invoice_mailing_address,
              billing_email: data.billing_email,
              created_by: null,
            })
          }
        } catch (error) {
          console.error("An error occurred:", error);
          setLoading(false)
        }
      }
      else {
        try {

          const response = await fetch(`${ENDPOINT}/api/sub/retrieve-payment-information/`, {
            method: "POST",
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "id": team.team_payment })
          });

          const data = await response.json();
          if (!response.ok) {
            setLoading(false)
            apiStripe()
          } else {
            let form_card = data[1]['stripe_payment_method']
            setLoading(false)
            if (data[0]?.paymentmethod_id) {
              setPaymentInfoExists(true)
            }
            else {
              apiStripe()
            }
            setPayment({
              card_holder_name: '',
              card_number: form_card?.card?.last4,
              expiration_date: form_card?.card?.exp_month + "/" + form_card?.card?.exp_year,
              office_tel: null,
              country: form_card?.billing_details?.address?.country,
              city: "",
              state: "",
              zip: data?.card?.billing_zip,
              payment_mailing_address: "",
              billing_email: "",
              created_by: "",
            })
          }
        }
        catch (error) {
          console.error('error throw api ', error)
          setLoading(false)

        }
      }
    }
    api()
  }, [])

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#711FFF',
    },
  };


  const options = {
    clientSecret,
    appearance,
  };
  
    return (
      <>
        {
  
          paymentInfoExists ? (
            <InvoiceInformation
              fields={fields}
              editModeInvoice={editModeInvoice}
              toggleEditModeInvoice={toggleEditModeInvoice}
              setFields={setFields}
              toggleEditModePayment={toggleEditModePayment}
              editModePayment={editModePayment}
              payment={payment}
              setPayment={setPayment}
              handleSave={handleSaveInvoice}
              loading={loading}
              setLoading={setLoading}
              loaderIcon={loaderIcon}
  
            />
          ) : (
  
            clientSecret &&
            <Box sx={{ display: 'flex', justifyContent: 'center', flex: '1', height: '70vh' }}>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </Box>
  
          )
        }
      </>
    );
  };

  const InvoiceInformation = ({ handleSave, payment, setPayment, toggleEditModePayment, editModePayment, fields, setFields, editModeInvoice, toggleEditModeInvoice, funcAlert, loading, setLoading, loaderIcon }) => {
    return (
      <>
        {loading ?
          <Box sx={{ width: '100%', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 3 }}>
            <Skeleton variant="rectangular" width={'100%'} height={300} sx={{ borderRadius: '8px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={300} sx={{ borderRadius: '8px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{ borderRadius: '8px' }} />
          </Box>
          :
          <div className="text-gray-600 flex gap-3 mt-10 body-font ">
            <div style={{ width: "100%", background: "#f2f2f5", borderRadius: "10px" }} className="py-2 mb-4 px-3 tw-bg-gray-100 rounded-md">
              <div className="d-flex flex-wrap w-100  -mx-2">
                <div style={{ marginBottom: "20px" }} className='d-flex w-100  justify-content-between'>
                  <p style={{ fontSize: "20px", fontWeight: 600 }} className='d-flex justify-content-center align-items-center text-black font-bold'>Invoice Information</p>
                  <p onClick={toggleEditModeInvoice} style={{ cursor: "pointer", fontSize: "18px" }} className='d-flex justify-content-center tw-cursor-pointer align-items-center text-black tw-text-lg'> <CreateOutlinedIcon /> <span style={{ marginLeft: "8px" }} className='tw-ml-2'>{!editModeInvoice ? "Edit" : "X"}</span></p>
                </div>
                <div className="p-2 mb-3 w-50">
                  <TextField
                    sx={{
                      width: "90%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF",
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    label="Company Name"
                    name="company_name" // Add the 'name' attribute to bind it to the 'fields' object
                    value={fields.company_name} // Bind the 'value' to the corresponding property in 'fields'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editModeInvoice }}
                    onChange={(e) => setFields({
                      ...fields,
                      company_name: e.target.value
                    })}
                  />
                </div>
                <div className="p-2 mb-3 w-50">
                  <TextField
                    sx={{
                      width: "90%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-number"
                    label="Phone Number"
                    name="phone"
                    value={fields.phone}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editModeInvoice }}
                    onChange={(e) => setFields({
                      ...fields,
                      phone: e.target.value
                    })}
                  />
                </div>
                <div className="p-2 mb-3 w-50">
                  <TextField
                    sx={{
                      width: "90%",
                      display: "flex",
                      flexWrap: "wrap",
  
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    name="mailingAddress"
                    label="Mailing Address"
                    value={fields.invoice_mailing_address}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    multiline
                    rows={2}
                    InputProps={{ readOnly: !editModeInvoice }}
                    onChange={(e) => setFields({
                      ...fields,
                      invoice_mailing_address: e.target.value
                    })}
                  />
                </div>
                <div className="p-2 mb-3 w-50">
                  <TextField
                    sx={{
                      width: "90%",
                      border: "none",
                      "& .MuiFilledInput-root": {
                        backgroundColor: "#f0eaf9",
                        borderRadius: "8px", // Set the desired !border radius value
                      },
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "12px",
                        fontWeight: 600,
                      },
                      "& .MuiFilledInput-underline:before": {
                        borderBottom: "none", // Remove the bottom line in the normal state
                      },
                      "& .MuiFilledInput-underline:after": {
                        borderBottom: "none", // Remove the bottom line after focusing
                      },
                      "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the hover line
                      },
                    }}
                    id="standard-required"
                    label="BILLING EMAIL ADDRESS"
                    name="billing_email"
                    value={fields.billing_email}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="filled"
                    InputProps={{ readOnly: !editModeInvoice }}
                    onChange={(e) => setFields({
                      ...fields,
                      billing_email: e.target.value
                    })}
                  />
                </div>
                <div className="p-2 mb-3 w-50">
                  <TextField
                    sx={{
                      width: "90%",
                      display: "flex",
                      flexWrap: "wrap",
  
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-number"
                    name="tax_id"
                    label="Tax ID (optional)"
                    value={fields.tax_id}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editModeInvoice }}
                    onChange={(e) => setFields({
                      ...fields,
                      tax_id: e.target.value
                    })}
                  />
                </div>
              </div>
            </div>
  
            {/* this is payment information */}
  
            <div style={{ border: "1px solid", width: "100%" }} className="rounded  px-4 pt-2 ">
              <div className="d-flex flex-wrap w-100  mb-2 -mx-2">
                <div className='d-flex w-100 pb-2 justify-content-between'>
                  <p style={{ fontSize: "20px", fontWeight: 600 }} className='d-flex justify-content-center align-items-center text-black font-bold text-lg'>Payment Information</p>
                  <p style={{ cursor: "pointer", fontSize: "18px" }} onClick={toggleEditModePayment} className='d-flex justify-content-center cursor-pointer align-items-center text-black text-lg'> <CreateOutlinedIcon /> <span className='ms-2'>{!editModePayment ? "Edit" : "X"}</span></p>
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    label="Card Holder Name"
                    name="card_holder_name"
                    value={payment.card_holder_name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editModePayment }}
                    onChange={(e) => setPayment({
                      ...payment,
                      card_holder_name: e.target.value
                    })}
                  />
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 position-relative sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                      "& .MuiInputBase-input": {
                        paddingLeft: "25px", // Add padding to the input field
                      },
                    }}
                    id="standard-required"
                    label="Debit/Credit Card"
                    name="card_number"
                    type="text"
                    value={payment.card_number}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 16,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editModePayment }}
                    onChange={(e) => setPayment({
                      ...payment,
                      card_number: e.target.value
                    })}
                  />
                  <span style={{ left: 1, top: 25 }} className='position-absolute left-1 top-7'>
                    {payment.card_number && payment.card_number.length === 16 ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <CancelIcon sx={{ color: "red" }} />
                    )}
                  </span>
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    label="Expiration Date"
                    name="expiration_date"
                    value={payment.expiration_date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ readOnly: !editModePayment }}
                    variant="standard"
                    onChange={(e) => setPayment({
                      ...payment,
                      expiration_date: e.target.value
                    })}
                  />
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    label="Mailing Address"
                    name="payment_mailing_address"
                    value={payment.payment_mailing_address}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editModePayment }}
                    onChange={(e) => setPayment({
                      ...payment,
                      payment_mailing_address: e.target.value
                    })}
                  />
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    label="City/State"
                    name="city"
                    value={payment.city}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ readOnly: !editModePayment }}
                    variant="standard"
                    onChange={(e) => setPayment({
                      ...payment,
                      city: e.target.value
                    })}
                  />
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-number"
                    type="number"
                    label="Zip Code"
                    name="zip"
                    value={payment.zip}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ readOnly: !editModePayment }}
                    variant="standard"
                    onChange={(e) => setPayment({
                      ...payment,
                      zip: e.target.value
                    })}
                  />
                </div>
                <div style={{ width: "33%" }} className="p-2 mb-3 sm:w-1/3 w-full">
                  <TextField
                    sx={{
                      width: "70%",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Add this line to remove hover color
                      },
                    }}
                    id="standard-required"
                    type="text"
                    label="Country"
                    name="country"
                    value={payment.country}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ readOnly: !editModePayment }}
                    variant="standard"
                    onChange={(e) => setPayment({
                      ...payment,
                      country: e.target.value
                    })}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex pb-5 my-4 justify-content-end gap-5 align-items-center '>
              <button className='payBtns px-4 py-2 rounded tw-text-[#711FFF] hover:tw-bg-[#711FFF] hover:tw-text-white tw-border tw-border-[#711FFF] tw-rounded'>Cancel</button>
              {loaderIcon ?
                <button style={{ border: "1px solid #711FFF" }} onClick={() => handleSave(true)} className='payBtn rounded px-4 py-1 tw-text-white tw-border tw-border-[#711FFF] tw-bg-[#711FFF] tw-rounded'><Box width={'27px'} height={'27px'} sx={{ px: 0.2 }}><PreLoader1 /></Box></button>
                :
                <button style={{ border: "1px solid #711FFF" }} onClick={() => handleSave(true)} className='payBtn rounded px-4 py-2 tw-text-white tw-border tw-border-[#711FFF] tw-bg-[#711FFF] tw-rounded'>Save</button>
              }
            </div>  
        </div>
        }
      </>
    );
  }
  

  export default Payment