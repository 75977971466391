import { BrowserStorageManager } from './BrowserStorageManager';

const localStorageManager = new BrowserStorageManager(localStorage);

// eslint-disable-next-line
export const saveColumnsToLocalStorage = (storageKeyColumnOrder, columns) => {
  const staticColumns = columns.map(
    ({ field, headerName, width, align, ...rest }) => ({
      field,
      headerName,
      width,
      align,
    }),
  );
  localStorageManager.set(storageKeyColumnOrder, staticColumns);
};

// eslint-disable-next-line
export const loadColumnsFromLocalStorage = (columnStorageKey, propsColumns) => {
  const storedColumns = localStorageManager.get(columnStorageKey);
  if (storedColumns) {
    const reconstructedColumns = storedColumns.map((column) => {
      const originalColumn = propsColumns.find(
        (col) => col.field === column.field,
      );

      return {
        ...column,
        ...originalColumn,
      };
    });

    return reconstructedColumns;
  }
  return propsColumns;
};
