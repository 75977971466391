
import Box from '@mui/material/Box';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import editTask from './assets/editTask.png';
import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import CustomizedIcon from './assets/CustomizedIcon.png';
import DeleteIcon from './assets/DeleteIcon.png';
import { getCustomers } from '../CustomersRedux';
import { NetnetField } from '../../../../../Componenets/NetField';
import { DataGridPro } from '@mui/x-data-grid-pro';
import NetNetTable from '../../../../../Componenets/atomic-organisms/Tables/NetNetTable';

const Filter = ({
  setFilterBase,
  filterBase,
  filterOption,
  setFilterOption,
}) => (

  <Box sx={{width:"100%"}} >
    <Offcanvas
      show={filterOption}
      onHide={() => setFilterOption(false)}
      scroll={false}
      backdrop
      placement="bottom "

    >
      <Offcanvas.Header style={{ padding: '2.4rem' }}>
        <h4
          style={{
            fontSize: '2.2rem',
            fontWeight: '600',
            color: '#000',
            fontFamily: 'Open Sans, sans-serif',
          }}
        >
          Invite User
        </h4>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ padding: '2.4rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Box className="df-r-j-c-gp-5">
            <span className="modalSpan sm-width">Email</span>

            <NetnetField
              id="standard-basic"
              label="Enter Title"
              variant="standard"
              className="MUI-P6-M5"
              color="secondary"
              style={{ width: 300 }}
            />
          </Box>
          <Box className="df-r-j-c-gp-5">
            <span className="modalSpan sm-width">First name</span>

            <NetnetField
              id="standard-basic"
              label="Enter Title"
              variant="standard"
              className="MUI-P6-M5"
              color="secondary"
              style={{ width: 300 }}
            />
          </Box>
          <Box className="df-r-j-c-gp-5">
            <span className="modalSpan sm-width">Last name</span>

            <NetnetField
              id="standard-basic"
              label="Enter Title"
              variant="standard"
              className="MUI-P6-M5"
              color="secondary"
              style={{ width: 300 }}
            />
          </Box>
        </Box>
        <section className="dFlex">
          <button
            type="button"
            style={{ marginTop: '1rem' }}
            className="close-modal"
            onClick={() => setFilterOption(false)}
          />
          <section className="AlRight">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => {
                setFilterOption(false);
              }}
            >
              Cancel
            </button>
            <button type="button" className="create-btn">
              Confirm
            </button>
          </section>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  </Box>

);


const Customers = ({screenHeight}) => {
  const [sureDelete, setSureDelete] = useState(false);
  const [customParams, setCustomParams] = useState({
    row: {},
  });
  const [menuIconEl, setMenuIconEl] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const customers = useSelector((state) => state.superAdmin.customersData);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [filterOption, setFilterOption] = useState(false);
  const [filterBase, setFilterBase] = useState([
    'client',
    'partners',
    'Subcontractors',
    'Prospects',
  ]);

  const [showProject, setShowProject] = useState(false);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getCustomers(meta));
  }, [showModal]);


  const columns = [
    {
      field: 'team',
      headerName: 'Company Name',
      width: 150,
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Company Name</div>,
      renderCell: (params) => (
        <div
          className="td"
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: 'work_category',
      headerName: 'Company Type',
      width: 390,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Company Type</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Email</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'number_of_users',
      headerName: '# of Users',
      width: 120,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader"># of Users</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'last_login',
      headerName: 'Last Sign in',
      width: 180,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Last Sign in</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'start_date',
      headerName: 'Sign Up Date',
      width: 180,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Sign Up Date</div>,
      renderCell: (params) => (
        <div className="td">{params.value}</div>
      ),
    },
    {
      field: 'quicktasks',
      headerName: 'Quick Tasks',
      width: 100,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Quick Tasks</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'projects',
      headerName: 'Projects',
      width: 100,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Projects</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'deliverables',
      headerName: 'Deliverabels',
      width: 100,
      flex: 0,
      renderHeader: () => <div style={{ fontWeight: 700 }} className="tableHeader">Deliverables</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
  ];

  const [delet, setDelet] = useState(false);

  const rows = customers?.map((item, index) => ({
    id: index,
    member: item.team_owner?.email ? item.team_owner?.email : '--------',
    email: item?.team_owner?.email
      ? item?.team_owner?.email
      : "N/A",
  
    team: item?.team_owner?.user_company
      ? item?.team_owner?.user_company?.name
      : "N/A",
      
    team_owner: item.team_owner ? item.team_owner : '--------',
    number_of_users: item.number_of_users ? item.number_of_users : 0,
    last_login: item?.team_owner?.last_login
      ? new Date(item?.team_owner?.last_login).toLocaleDateString()
      : 'N/A',
    start_date: item?.team_owner?.start_date
      ? new Date(item?.team_owner?.start_date).toLocaleDateString()
      : "N/A",
    work_category: item?.team_owner?.user_company
      ? item?.team_owner?.user_company?.Type
      : "N/A",
    quicktasks: item?.number_of_quicktasks ? item.number_of_quicktasks : 0,
    projects: item?.number_of_projects ? item?.number_of_projects : 0,
    deliverables: item?.number_of_deliverables ? item?.number_of_deliverables : 0,
    tasks: item?.number_of_quicktasks ? item?.number_of_quicktasks : 0,

  }));

  useEffect(() => {
    dispatch(
      getCustomers({
        access_token,
      })
    );
  }, [showProject]);

  useEffect(() => {
    dispatch(
      getCustomers({
        access_token,
      })
    );
  }, [showModal]);

  useEffect(() => {
    dispatch(getCustomers({ access_token }));
  }, []);

  const removePagination = () => null;

  return customers?.length < 1 ? (
    <section className="oppor-section">
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">Customers</h3>
        </div>
      </div>
      <sections className="myCenter">
        <article className="box-txt">
          <h3 className="txtCenter">No Customers just yet!</h3>

          <article className="buttonGroup">
            <button
              type="submit"
              className="btn"
              onClick={() => setFilterOption(true)}
            >
              Invite
            </button>
          </article>
        </article>
      </sections>
    </section>
  ) : (
    <>
      <section className="oppor-section">
        <div
          className="oppor-row fixed"

        >
          <div className="oppor-col">
            <h3 className="fstxtPro">Customers</h3>
            <img
              style={{ cursor: 'pointer' }}
              className="icon-sm"
              src={Dots}
              alt="dots"
              id="company-icon"
              aria-haspopup="true"
              aria-controls={menuIconEl ? 'company-menu' : undefined}
              aria-expanded={menuIconEl ? 'true' : undefined}
              onClick={(e) => setMenuIconEl(e.currentTarget)}
            />
            {sureDelete && (
              <SureDeleteContact
                setSureDelete={setSureDelete}
                selected={selectedPeople}
              />
            )}
            {delet && (
              <ContactDelete
                customParams={customParams}
                setSureDelete={setDelet}
              />
            )}
            <Menu
              id="company-icon-menu"
              sx={{
                '& .MuiMenu-paper': {
                  border: '1px solid #03071E33',
                  borderRadius: '10px',
                },
                '& .MuiMenu-list': {
                  margin: '5px 15px 5px 5px',
                },
              }}
              anchorEl={menuIconEl}
              open={!!menuIconEl}
              onClose={() => setMenuIconEl(null)}
              autoFocus={false}
            >
              <MenuItem className="mb-2">
                <img src={CustomizedIcon} className="icon-sm" />
                Customize View
              </MenuItem>
              <MenuItem className="text-danger mb-2">
                <img
                  src={DeleteIcon}
                  className="icon-sm"
                  onClick={() => {
                    setSureDelete(true);
                    setMenuIconEl(null);
                  }}
                />
                Delete Selected
              </MenuItem>
            </Menu>
          </div>

          <button
            type="button"
            className="create-btn"
            onClick={() => setFilterOption(true)}
            id="c-btn"
          >
            + Invite
          </button>
        </div>
        <Box
          sx={{
            width: '100%',
            height: screenHeight - 120,
          }}
          className=""
        >
          <Box
            sx={{
              borderRadius: '4px 0px 0 0',
              overflowY: "auto",
              width: '100%',
              borderBottom: 'none',
            }}
          />
          <NetNetTable
            rows={Array.isArray(rows) && rows?.length ? rows : []}
            columns={columns}
            rowHeight={75}
            headerHeight={78}
            sx={{
              background: "#f2f2f2",
              '.MuiDataGrid-columnHeader': {
                fontWeight: 'bold',
              },
              '.MuiDataGrid-row': {
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
                lineHeight: "19px",
                letterSpacing: "0.5px",
                backgroundColor: "#FDFDFE"
              },
              '.MuiDataGrid-columnHeader': {
                color: '#03071E99',
                backgroundColor: "#F2F2F2",
                fontFamily: 'Open Sans, sans-serif',

              },

              '.tableHeader': {
                fontWeight: 700
              },

              '.css-1q27lj3-MuiDataGrid-root': {
                borderRadius: "12px",

                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              },
              '.MuiDataGrid-columnHeaders ': {
                zIndex: 1,
                backgroundColor: '#F2F2F2',
              },
              '.tableHeader': {
                color: ' #03071E99',
              },
              height: "100%",
              border: '1px solid rgba(224, 224, 224, 1)',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}

            checkboxSelection={true}
            selectRow={setSelectedPeople}
            disableColumnSelector
            components={{
              pagination: removePagination,
            }}
          />
        </Box>

        <Filter
          filterOption={filterOption}
          setFilterOption={setFilterOption}
          filterBase={filterBase}
          setFilterBase={setFilterBase}
        />

      </section>

    </>
  );
};
export default Customers;
