import React from 'react';

import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Bar,
  LabelList,
  Cell,
  Tooltip,
} from 'recharts';

import {
  colorPalette,
  getProgressColor,
} from '../../../../utils/colors-helper';
import KPIChartLegend from '../../../../Componenets/atomic-molecules/KPIChartLegend';
import { formatNumber } from '../../../../utils/numberUtils';

import './KPI.css';

const CustomTooltip = React.memo(function CustomTooltip({ active, payload }) {
  if (!active || !payload || !payload?.length === 0) {
    return null;
  }

  const data = payload[0]?.payload ?? payload;
  let actualToEstimatedPercentage = 0;
  let actualToAssignedPercentage = 0;

  if (
    (data.estimitedTime === 0 && data.totalAssignedTime === 0) ||
    (data.totalAssignedTime === 0 && data.estimitedTime <= 0)
  ) {
    return null;
  }

  if (data && data.elapsedTime) {
    if (data.estimitedTime) {
      actualToEstimatedPercentage = (
        (data.elapsedTime / data.estimitedTime) *
        100
      ).toFixed(2);
    }

    if (data.totalAssignedTime) {
      actualToAssignedPercentage = (
        (data.elapsedTime / data.totalAssignedTime) *
        100
      ).toFixed(2);
    }
  }

  const percentageToUse =
    actualToEstimatedPercentage || actualToAssignedPercentage;

  const backgroundColor =
    percentageToUse <= 80
      ? colorPalette.backgroundOpaqueGreen
      : percentageToUse < 100
        ? colorPalette.backgroundOpaqueOrange
        : colorPalette.backgroundOpaqueRed;

  return (
    <div
      style={{
        backgroundColor,
        padding: '4px',
        borderRadius: '8px',
        fontSize: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor,
          padding: '5px 10px',
          borderRadius: '6px',
        }}
      >
        <ul
          style={{
            fontWeight: 'bold',
            listStyle: 'none',
            paddingLeft: 0,
            paddingTop: '10px',
          }}
        >
          <li>Actual Hours: {formatNumber(data.elapsedTime)}</li>
          {data.estimitedTime && (
            <li>Estimated Hours: {formatNumber(data.estimitedTime)}</li>
          )}
          <li>Assigned Hours: {formatNumber(data.totalAssignedTime)}</li>

          <hr
            style={{
              border: `1px solid #2a2a2a`,
              borderRadius: '5px',
              margin: '5px 0',
              padding: '0',
            }}
          />

          {data.totalAssignedTime ? (
            <li>
              Actual 2 Assigned:{' '}
              {actualToAssignedPercentage !== null
                ? `${actualToAssignedPercentage}% (A2A)`
                : 'N/A'}
            </li>
          ) : (
            ''
          )}

          {data.estimitedTime ? (
            <li>
              Actual 2 Estimated:{' '}
              {actualToEstimatedPercentage !== null
                ? `${actualToEstimatedPercentage}% (A2E)`
                : 'N/A'}
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </div>
  );
});

function KPIBarChart({ data, isLegend = false, showForExportPurposes }) {
  const valueToSeparateBars = 1;
  const height = (data.length + valueToSeparateBars) * 100;

  return (
    <>
      <ResponsiveContainer width="100%" height={height} className="bar-chart">
        <BarChart
          barGap={-53}
          data={data}
          layout="vertical"
          barCategoryGap={1}
          margin={{ top: 25, bottom: 0, left: 25, right: 150 }}
        >
          {isLegend ? <Legend align="left" content={KPIChartLegend} /> : null}
          <XAxis
            type="number"
            unit=" hrs"
            interval={0}
            domain={[0, 'dataMax']}
          />

          <YAxis
            type="category"
            dataKey="title"
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              width: '100%',
            }}
          />

          {data[0].estimitedTime >= 0 && data[0].totalAssignedTime >= 0 ? (
            <>
              <Bar
                dataKey="estimitedTime"
                barSize={45}
                fill={colorPalette.estimatedColor}
              />

              <Bar
                dataKey="totalAssignedTime"
                barSize={60}
                fill={colorPalette.assignedColor}
              />

              <Bar
                dataKey="elapsedTime"
                barSize={46}
                fill={colorPalette.actualGreenColor}
                maxBarSize={60}
              >
                {data.map((each) => {
                  return (
                    <Cell
                      key={each.title}
                      fill={getProgressColor(
                        each.elapsedTime,
                        each.estimitedTime,
                      )}
                    />
                  );
                })}

                <LabelList
                  dataKey={(value) => {
                    if (value.estimitedTime === 0) {
                      return '';
                    }

                    const percentage = (
                      (value.elapsedTime / value.estimitedTime) *
                      100
                    ).toFixed(2);

                    return `${value.elapsedTime} ● ${value.estimitedTime} ● ${value.totalAssignedTime} hrs ● ${percentage}% (A2E)`;
                  }}
                  position="right"
                  width={80000} // * This is a hack to make the label all the way to the right, and not wrap.
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    fill: '#8b8b8b',
                  }}
                />
              </Bar>
            </>
          ) : (
            <>
              <Bar
                dataKey="totalAssignedTime"
                barSize={60}
                fill={colorPalette.assignedColor}
              />

              <Bar
                dataKey="elapsedTime"
                barSize={46}
                fill={colorPalette.actualGreenColor}
                maxBarSize={60}
              >
                {data.map((each) => {
                  return (
                    <Cell
                      key={each.title}
                      fill={getProgressColor(
                        each.elapsedTime,
                        each.totalAssignedTime,
                      )}
                    />
                  );
                })}

                <LabelList
                  dataKey={(value) => {
                    if (value.totalAssignedTime === 0) {
                      return '';
                    }

                    const percentage = (
                      (value.elapsedTime / value.totalAssignedTime) *
                      100
                    ).toFixed(2);

                    return `${value.elapsedTime} ● ${value.totalAssignedTime} hrs ● ${percentage}% (A2A)`;
                  }}
                  position="right"
                  width={80000} // * This is a hack to make the label all the way to the right, and not wrap.
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    fill: '#8b8b8b',
                  }}
                />
              </Bar>
            </>
          )}

          <Tooltip content={<CustomTooltip />} cursor={false} />
        </BarChart>
      </ResponsiveContainer>

      {showForExportPurposes && (
        <div className="export-tooltips-container">
          {data.map((each) => {
            return (
              <div key={each.title}>
                <span>{each.title}</span>
                <CustomTooltip
                  id={`breakdown-outer-chart-loe-${each.title}`}
                  active={true}
                  payload={each}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default KPIBarChart;
