import { useEffect, useState } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { PreLoading } from '../../Contacts/People/EditContact';

const SureDelete = ({ setSureDelete }) => (
  <section
    className="loading"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      backgroundColor: 'rgba(255,255,255,0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
    }}
  >
    <Box
      sx={{
        width: '500px',
        height: '150px',
        backgroundColor: 'rgba(255,255,255)',
        border: '1px solid #d9d9d9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '0.68rem 1rem',
      }}
    >
      <p className="fstxt">
        Are You Sure You want to Remove this Collaborator? Removing this
        Collaborator will remove all associated data.
      </p>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <button className="cancel-btn" onClick={() => setSureDelete(false)}>
          Cancel
        </button>
        <button className="netDanger-btn">Remove</button>
      </Box>
    </Box>
  </section>
);

const SecondStep = ({ formData, setFormData, uniqueID }) => {
  const [sureDelete, setSureDelete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const colors = ['#cef6ff', '#ece7fb', '#8feeb2'];

  return !loading ? (
    <Grid container direction="column" sx={{ gap: '1.5rem' }}>
      {sureDelete && <SureDelete setSureDelete={setSureDelete} />}
      <Grid
        item
        sx={{
          backgroundColor: '#f8f9fa',
          width: '100%',
          color: '#711fff',
          fontWeight: 600,
          fontSize: 21,
          padding: '1rem 1.2rem',
        }}
      >
        COLLABORATION
      </Grid>
      <Grid
        item
        container
        direction="column "
        sx={{
          backgroundColor: '#f8f9fa',
          padding: '1rem',
          borderRadius: '8px',
          marginTop: '1rem',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            fontSize: '1.5rem',
            color: '#000',
          }}
        >
          Waiting for Collaborator(s) to define their Worktypes and
          Delieverables.
        </Typography>
        <Typography>You can come back here any time.</Typography>
      </Grid>
      <Grid
        item
        container
        direction={'column'}
        alignItems="flex-start"
        sx={{ gap: '1rem' }}
      >
        <Grid
          item
          container
          sx={{ width: '100%' }}
          justifyContent="space-around"
          alignItems="center"
        >
          <Skeleton variant="circular" sx={{ height: '50px', width: '50px' }} />
          <Skeleton
            variant="rectangular"
            sx={{ height: '50px', width: '90%' }}
          />
        </Grid>
        <Skeleton
          variant="rounded"
          sx={{
            height: '190px',
            width: '98%',
          }}
        />
      </Grid>

    </Grid>
  ) : (
    <PreLoading />
  );
};

export default SecondStep;
