import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Avatar,
  Grid,
  Box,
  ListItem,
  List,
  Typography,
  Divider,
  Alert,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIRichTextEditor from 'mui-rte';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { stateToHTML } from 'draft-js-export-html';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT } from './Opportunity/Redux/oppor';
import { NetnetField } from '../Componenets/NetField';
import PreLoader, { NetNetCheckBox } from '../Componenets/Misc';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NetProgress } from '../Componenets/ProgressBar';
import { Step2Content } from '../Componenets/TopNav/TopNav';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { getProjects, getQuickTask } from './Projects/proRedux';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import RichTextEditor from '../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import { getMessages } from './Stream/streamRedux';
import { useRouteTo } from '../hooks/useRouteTo';
import { appRoutes } from '../appRoutes';

const flex = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f1f3f5',
  borderRadius: '8px',
  fontFamily: 'Open Sans',
  fontSize: '1rem',
  fontWeight: '600',
};

const specialTypography = {
  color: '#711fff',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1rem',
};

const darKTypography = {
  color: '#310085',
  textTransform: 'uppercase',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1.2rem',
};

const status = [
  'Backlog',
  'In Progress',
  'Submitted For Review',
  'Needs More Work',
  'Stuck',
  'Completed',
  'Archived',
];
const Edit = ({ target, setShow, deliverable }) => {
  const routeTo = useRouteTo();
  const Team = useSelector((state) => state.userTeam.team);

  const projects = useSelector((state) => state.projects.tasks);
  const realTarget =
    Array.isArray(projects) && projects.length
      ? projects.find((item) => item.id === target.id)
      : target;
  const TeamOptions =
    Team && Team.member
      ? Team.member.map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          avatar: item.avatar,
        }))
      : false;
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);

  const realProjects = useSelector((item) => item.projects.projects);
  const [retainerCheck, setRetainerCheck] = useState();

  const checkIfProject = realProjects?.find(
    (item) => item?.id === target?.task_project,
  );

  useEffect(() => {
    if (checkIfProject) {
      setRetainerCheck(checkIfProject);
    }
  }, [checkIfProject]);

  const [formData, setFormData] = useState({
    id: '',
    assignee_task: [],
    task_name: '',
    description: '',
    work_type: '',
    company: '',
    contact: '',
    assignee: '',
    estimated_hours: 1,
    budget: '',
    due_date: '',
    billable: false,
    status: 'inprogress',
  });
  const [defaultDesc, setDefaultDesc] = useState();

  useEffect(() => {
    setFormData({
      ...formData,
      id: realTarget.id,
      assignee_task: realTarget.assignee_task,
      task_name: realTarget.task_name,
      description: realTarget.description,
      work_type: realTarget.work_type,
      company: realTarget.company,
      contact: realTarget.contact,
      assignee: realTarget.assignee,
      estimated_hours: realTarget.estimated_hours,
      budget: realTarget.budget,
      due_date: realTarget.due_date,
      billable: realTarget.billable,
      status: realTarget.status,
    });
    setHtml(realTarget.description ?? '<p></p>');
    const htmlContent = realTarget.description ?? '<p></p>';

    // Convert HTML to ContentState
    const blocksFromHTML = convertFromHTML(htmlContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );

    // Convert ContentState to string
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    setDefaultDesc(contentStateString);
  }, [realTarget]);
  const [task_assignee, setTaskAssingee] = useState([]);
  const [html, setHtml] = useState('');

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.user.access);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  const myTheme = createTheme({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.task_name === '') {
      setIsError(true);
      setError('Name Is Required');
    } else {
      const response = await fetch(
        `${ENDPOINT}/api/project/task/update/${formData.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            assignee_task: formData.assignee_task.map((item) => ({
              ...item,
              assignee_user_update: item.assignee_user,
            })),

            description: html,
          }),
        },
      );
      const data = await response.json();
      if (!response.ok) {
        setIsError(true);
        for (const key in data) {
          setError(data[key]);
        }
        setLoading(false);
      } else {
        setShow(false);
        routeTo(appRoutes.tasks);
        setLoading(false);
      }
    }
  };

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);

  const types =
    Array.isArray(userWorkTypes) &&
    userWorkTypes.length &&
    formData?.task_worktype &&
    formData.task_worktype.length
      ? userWorkTypes
          .filter((item) => item.id === formData.task_worktype[0]?.id)
          .map((item) => ({
            name: item.name,
          }))
      : [];

  const [allDone, setAllDone] = useState(false);
  const allProjects = useSelector((state) => state.projects.projects)?.filter(
    (item) => item.id === formData?.task_project,
  )[0];

  const dileverableOptions =
    Array.isArray(deliverable) && deliverable.length
      ? deliverable.map((item) => ({
          title: item.title,
          id: item.id,
        }))
      : Array.isArray(allProjects?.deliverable_project) &&
          allProjects?.deliverable_project.length
        ? allProjects?.deliverable_project.map((item) => ({
            title: item.deliverable_name,
            id: item.id,
          }))
        : [];

  const newDil = dileverableOptions.filter(
    (item) => item.id === realTarget?.task_deliverable,
  );

  const [openLink, setOpenLink] = useState(false);
  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="column"
        sx={{ overflow: 'auto' }}
      >
        <Grid
          container
          item
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            direction: 'column',
            padding: '1rem',
            position: 'relative',
            width: '90%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              padding: '18px 23px',
              textAlign: 'left',
              fontFamily: 'Open Sans',
              backgroundColor: '#f8f9fa',
              color: '#711fff',
              fontWeight: '700',
              height: '68px',
              fontSize: '1.50rem',
              borderRadius: '4px',
              margin: '1rem 0',
              textTransform: 'uppercase',
              borderRadius: '8px',
            }}
          >
            Edit Project Task
          </Box>
          <Grid item container sx={6} md={6.3} lg={6.3} xl={6.3}>
            <Box
              sx={{
                padding: '0rem 0rem ',
                borderRadius: '8px',
                display: 'flex',
                gap: '1.4rem',
                background: '#fff',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                mr: '1rem',
              }}
            >
              <Grid
                container
                sx={{
                  border: '1.5px solid #d9d9d9',
                  borderRadius: '8px',
                  backgroundColor: '#fff',

                  padding: '1rem 0.5rem',
                }}
              >
                <Grid item container direction="column" xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      backgroundColor: '#f8f9fa',
                      padding: '18px 23px',
                      borderRadius: '8px',
                      height: '100%',
                    }}
                  >
                    <Box sx={{ mt: '1.5rem' }}>
                      <span className="modalSpan">Name</span>
                    </Box>

                    <Box className="myJam" sx={{ mt: '1.5rem' }}>
                      <NetnetField
                        variant="outlined"
                        className="MUI-P6-M5"
                        value={formData?.task_name}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            task_name: e.target.value,
                          });
                        }}
                        color="secondary"
                        style={{ width: 300, height: 30 }}
                      />
                    </Box>
                    <Box sx={{ mt: '1.5rem' }}>
                      <span className="modalSpan">Description</span>
                    </Box>
                    <Box
                      className="myJam Editor-para1"
                      sx={{
                        mt: '1.5rem',
                      }}
                    >
                      <RichTextEditor
                        open={openLink}
                        setOpen={setOpenLink}
                        setHtml={setHtml}
                        html={realTarget?.description}
                        my_logic={true}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={5.7}
            lg={5.7}
            xl={5.7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e9ecef',
                padding: '18px 23px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: '0.44rem 0.88rem',
                  borderRadius: '8px',
                  background: '#fff',
                  width: 'fit-content',
                }}
              >
                <Autocomplete
                  options={
                    Array.isArray(types) && types.length ? [...types] : []
                  }
                  id="ssce2"
                  value={
                    Array.isArray(formData?.task_worktype) &&
                    formData?.task_worktype.length
                      ? formData?.task_worktype[0]
                      : ''
                  }
                  onChange={(_, value) => {
                    setFormData({
                      ...formData,
                      work_type: value.id,
                    });
                  }}
                  noOptionsText={<Link to={appRoutes.myWorkTypes}> + Add New</Link>}
                  style={{ width: 300 }}
                  getOptionLabel={(opt) => opt.name}
                  renderOption={(props, option) => (
                    <>
                      <li {...props}>{option.name}</li>
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Your Work Type"
                      variant="standard"
                      value={realTarget?.task_worktype[0].name}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: 300 },
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: '0.44rem 0.88rem',
                  borderRadius: '8px',
                  background: '#fff',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {!formData?.assignee_task ? (
                  <>
                    <Autocomplete
                      options={
                        TeamOptions || [{ name: 'You dont have any team yet' }]
                      }
                      multiple
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setTaskAssingee(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select your Assignee's and there hours "
                          variant="standard"
                          color="secondary"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#f2ebff',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { width: '100%' },
                          }}
                        />
                      )}
                    />
                  </>
                ) : (
                  <List sx={{ width: '100%' }}>
                    {formData?.assignee_task.map((per, ind) => {
                      const teamMember = TeamOptions.find(
                        (teamMember) => teamMember.id === per.assignee_user,
                      );

                      return (
                        <>
                          <ListItem
                            sx={{
                              width: '100%',
                              gap: '1rem',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                              }}
                            >
                              <Avatar
                                src={per.avatar ? per.avatar : '/Sharp/img.png'}
                                alt={teamMember.name}
                              />

                              <Typography>
                                {teamMember.name ? teamMember.name : ''}
                              </Typography>
                            </div>
                            <Box sx={{ display: 'flex' }}>
                              <TextField
                                placeholder="Hours"
                                defaultValue={per.hours}
                                onChange={(e) => {
                                  setFormData((prevFormData) => {
                                    const shallowCopy = {
                                      ...prevFormData,
                                      assignee_task:
                                        prevFormData.assignee_task.map(
                                          (item) => ({ ...item }),
                                        ),
                                    };
                                    const updatedAssigneeTasks = [
                                      ...shallowCopy.assignee_task,
                                    ];
                                    updatedAssigneeTasks[ind].hours = Number(
                                      e.target.value,
                                    );

                                    return {
                                      ...prevFormData,
                                      assignee_task: updatedAssigneeTasks,
                                    };
                                  });
                                }}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#711fff',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#5a0bdf',
                                    },

                                    '&.Mui-error fieldset': {
                                      borderColor: 'red',
                                    },

                                    '&.Mui-disabled fieldset': {
                                      borderColor: 'grey',
                                    },
                                  },
                                  '& .MuiFormLabel-root': {
                                    color: '#03071e66',
                                  },
                                  '& .MuiFormLabel-root.Mui-focused': {
                                    color: '#5a0bdf',
                                  },
                                  '& .MuiFormLabel-root.Mui-error': {
                                    color: 'red',
                                  },
                                }}
                                color="secondary"
                                type="number"
                                InputProps={{
                                  style: { width: 100 },
                                }}
                              />
                              <Box
                                sx={{
                                  width: '80px',
                                  backgroundColor: '#e6e6e6',
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '0 10px',
                                  borderStartEndRadius: '10px',
                                  borderEndEndRadius: '10px',
                                }}
                              >
                                {'Hours'}
                              </Box>
                            </Box>
                          </ListItem>
                          <Divider sx={{ borderColor: '#000' }} />
                        </>
                      );
                    })}
                  </List>
                )}

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {Array.isArray(task_assignee) &&
                  task_assignee.length &&
                  !allDone ? (
                    <div style={{ width: '25%' }}>
                      <button
                        className="btn"
                        onClick={(_) => {
                          if (isSelectionComplete) {
                            setFormData({
                              ...formData,
                              task_assignee: task_assignee.map((assg) => ({
                                assignee_user: assg.id,
                                hours: assg.hours,
                              })),
                            });
                            setAllDone(true);
                            return;
                          } else {
                            setIsSelectionComplete(true);
                          }
                        }}
                      >
                        Confirm {!isSelectionComplete && 'Selection'}
                      </button>
                    </div>
                  ) : null}
                </Box>
              </Box>

              <Box className="myJam" sx={{ marginBottom: '1.2rem' }}>
                <span className="modalSpan">Deliverable</span>

                <Autocomplete
                  id="company"
                  options={
                    newDil ? newDil : allProjects[0]?.deliverable_project
                  }
                  onChange={(_, value) => {
                    setFormData({
                      ...formData,
                      deliverable: value,
                    });
                  }}
                  value={
                    formData?.deliverable
                      ? formData?.deliverable
                      : Array.isArray(newDil) && newDil.length && newDil[0]
                  }
                  getOptionLabel={(option) => option.title}
                  style={{ width: '100%', marginTop: '0.7rem' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Deliverable Name"
                      variant="outlined"
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>

              <Box className="myJam">
                <span className="modalSpan">Status</span>
                <Autocomplete
                  id="country"
                  includeInputInList
                  onChange={(e, value) => {
                    setFormData({ ...formData, task_status: value });
                  }}
                  value={
                    formData?.task_status
                      ? formData?.task_status
                      : realTarget?.status
                  }
                  options={status}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%' }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#fff',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: '#fff',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Status"
                      variant="outlined"
                      value={'APP'}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },

                          '&.Mui-disabled fieldset': {
                            borderColor: 'grey',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>

              <Box
                className="dFlex"
                sx={{
                  gap: '0.5rem',
                  fontSize: '0.8rem',
                  fontWeight: 600,
                }}
              >
                <NetNetCheckBox
                  onChange={(e) => {
                    const checked = e.target.checked;

                    setFormData({
                      ...formData,
                      carry_forward_setting: checked
                        ? 'repeat_task'
                        : 'carry_task',
                    });
                  }}
                />{' '}
                <span>Repeat Task</span>
              </Box>
            </Box>

            <Box
              sx={{
                padding: '18px 23px',
                borderRadius: '8px',
                border: '1px solid gray',
                mt: '1.5rem',
              }}
            >
              <Box className="myJam">
                <span>Due Date</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM-dd-yyyy"
                    label="MM/DD/YYYY"
                    value={dayjs(formData?.due_date)}
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        due_date: newValue,
                      });
                    }}
                    sx={{
                      width: { xl: '70%', lg: '66%', md: '60%' },
                      height: '60px',
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                        {
                          color: '#711FFF',
                        },
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                        {
                          marginRight: '0px',
                          position: 'absolute',
                          borderRadius: '0px !important',
                          width: '100% !important',
                          height: '60px',
                        },
                      '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                      '& .MuiInputAdornment-root': {
                        position: 'absolute',
                        top: '28px',
                        left: '-6px',
                        borderRadius: '0px',
                        width: '100%',
                      },
                      '& .MuiSvgIcon-root': {
                        position: 'absolute',
                        right: '10px',
                      },
                      '& .MuiInputBase-root': {
                        width: '100%',
                        position: 'relative',
                      },
                      '& .MuiOutlinedInput-input': {
                        width: '100%',
                        height: '60px',
                        padding: '0px',
                        color: 'black',
                        marginLeft: '10px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        width: '100%',
                        height: '60px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                            '&.Mui-error fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#3071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: 'red',
                          },
                        }}
                        className="MUI-P6-M5"
                        style={{ width: '70%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box className="myJam">
                <span>Time Remaining</span>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={formData?.estimated_hours}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      estimated_hours: e.target.value,
                    })
                  }
                  color="secondary"
                  sx={{
                    width: '70%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                      '&.Mui-error fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                  type="number"
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              marginLeft: 'auto',
            }}
          >
            {isError && <Alert severity="error">{error}</Alert>}
            <div className="button-groups">
              <button
                className="cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
              >
                Cancel
              </button>
              <button className="create-btn" onClick={handleSubmit}>
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.1rem',
                    }}
                  >
                    <PreLoader />
                  </Box>
                ) : (
                  'Save'
                )}{' '}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const BillCheck = ({ check }) => (
  <Box sx={flex}>
    <Box
      sx={{
        backgroundColor: check ? '#d2b8ff' : '#f1f3f5',
        width: '50%',
        padding: '0.44rem 0.88rem',
        borderRadius: '8px',
      }}
    >
      Yes
    </Box>
    <Box
      sx={{
        backgroundColor: !check ? '#d2b8ff' : '#f1f3f5',
        width: '50%',
        padding: '0.44rem 0.88rem',
        borderRadius: '8px',
      }}
    >
      No
    </Box>
  </Box>
);

const AdditionalInfo = ({ currentTarget, deliverable }) => {
  const dileverableOptions =
    Array.isArray(deliverable) && deliverable.length
      ? deliverable.map((item) => item.title)
      : [];

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        item
        sx={{
          borderRadius: '8px',
          padding: '1rem',
          gap: '0.5rem',
        }}
        lg={3.9}
        md={3.9}
        sm={2.9}
      >
        <Box className="df-r-j-c-gp-5 MobileDFR autoWidth">
          <span>Billable</span>
          <BillCheck check={currentTarget.billable} />
        </Box>
        <Box className="df-r-j-c-gp-5 MobileDFR autoWidth">
          <span className="modalSpan">Deliverable</span>

          <Autocomplete
            id="company"
            options={dileverableOptions}
            includeInputInList
            defaultValue={
              deliverable.find(
                (ele) => ele?.id === currentTarget?.task_deliverable,
              )?.title
            }
            onChange={(_, value) => {
              setFormData({
                ...formData,
                deliverable: value,
              });
            }}
            style={{ width: 300, marginTop: '0.7rem' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter Deliverable Name"
                variant="outlined"
                color="secondary"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: '#711fff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#5a0bdf',
                    },

                    '&.Mui-error fieldset': {
                      borderColor: 'red',
                    },
                  },
                  '& .MuiFormLabel-root': {
                    color: '#03071e66',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#5a0bdf',
                  },
                  '& .MuiFormLabel-root.Mui-error': {
                    color: 'red',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { width: 300 },
                }}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid
        item
        lg={3.5}
        md={3}
        sm={2.5}
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      ></Grid>
    </>
  );
};

const IntroContent = ({ alphaValue }) => (
  <Grid
    container
    direction="column"
    alignItems="flex-start"
    sx={{
      padding: '1rem 2rem 1rem 0.8rem',
      gap: '0.88rem',
    }}
  >
    <h3 style={darKTypography}>{alphaValue.title}</h3>
    <p
      dangerouslySetInnerHTML={{
        __html: alphaValue.description
          ? alphaValue.description
          : 'No Description was provided.',
      }}
    ></p>
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: '#f2f3f5',
        width: 'fit-content',
        display: 'flex',
        gap: '1rem',
        fontSize: '1rem',
        padding: '0.44rem 0.88rem',
        alignItems: 'center',
      }}
    >
      Work Type
      <span style={specialTypography}>
        {alphaValue.work_type.map((it) => it + ', ')}
      </span>
    </Box>
  </Grid>
);

const BetaContent = ({ currentTarget, token }) => {
  const dispatch = useDispatch();
  const [myTarget, setMyTarget] = useState({});
  useEffect(() => {
    setMyTarget(currentTarget);
  }, []);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <Box
      sx={{
        padding: '1rem 2rem 1rem 0.8rem',
        gap: '0.55rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box className="df-r-j-c-gp-5 MobileDFR autoWidth ">
        <span>DUE DATE</span>
        <Box sx={{ width: '300px' }}>
          <p className="greenTxT">
            {`${
              myTarget.due_date
                ? new Date(myTarget.due_date.split('T')[0])
                    .toLocaleDateString('en-US', options)
                    .toUpperCase()
                : 'NO DUE DATE'
            }`}
          </p>
        </Box>
      </Box>

      <Box className="df-r-j-c-gp-5 MobileDFR autoWidth ">
        <span>STATUS</span>
        <Box sx={{ width: '300px' }}>
          <select
            className={myTarget.status ? myTarget.status : 'backlog'}
            style={{
              width: 'fit-content',
            }}
            onChange={async (e) => {
              const res = await fetch(
                `${ENDPOINT}/api/project/task/update/${currentTarget.id}/`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                  },
                  body: JSON.stringify({
                    task_name: currentTarget.task_name,
                    status: e.target.value,
                  }),
                },
              );

              const data = await res.json();
              setMyTarget({
                ...myTarget,
                status: e.target.value,
              });
              dispatch(
                getQuickTask({
                  access_token: token,
                }),
              );
            }}
          >
            <option value="backlog" selected={myTarget.status === 'backlog'}>
              Backlog
            </option>
            <option
              value="inprogress"
              selected={myTarget.status === 'inprogress'}
            >
              In Progress
            </option>
            <option value="review" selected={myTarget.status === 'review'}>
              Submitted For Review
            </option>
            <option
              value="needs_more_work"
              selected={myTarget.status === 'needs_more_work'}
            >
              Need more work
            </option>
            <option value="stuck" selected={myTarget.status === 'stuck'}>
              Stuck
            </option>
            <option
              value="completed"
              selected={myTarget.status === 'completed'}
            >
              Completed
            </option>
            <option value="archive" selected={myTarget.status === 'archive'}>
              Archived
            </option>
          </select>
        </Box>
      </Box>

      <Box className="df-r-j-c-gp-5 MobileDFR autoWidth ">
        <span>TIME</span>
        <Box sx={{ width: '300px' }}>
          <NetProgress
            status={myTarget.status}
            obt={
              Array.isArray(myTarget.time_tracking) &&
              myTarget.time_tracking.length
                ? myTarget.time_tracking.reduce((total, entry) => {
                    const [hours, minutes] = entry.tracked_time_hours
                      ? entry.tracked_time_hours.split(':')
                      : [0, 0];
                    const hoursInMinutes =
                      parseInt(hours, 10) + parseInt(minutes, 10) / 60;
                    return total + hoursInMinutes;
                  }, 0)
                : 0
            }
            eta={
              Array.isArray(myTarget.task_worktype) &&
              myTarget.task_worktype.length
                ? myTarget.task_worktype.reduce(
                    (total, acc) => total + acc.hours,
                    0,
                  )
                : 0
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

const ProTaskDetail = ({
  formData,
  show,
  setShow,
  currentTarget,
  setFormData,
  token,
  deliverable,
  setIsDetOpen,
}) => {
  const [value, setValue] = useState('chat');
  const [addTimer, setAddTimer] = useState(false);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsDetOpen(!show);
  }, []);

  const [html, setHtml] = useState('');
  const [switcher, setSwitcher] = useState(false);

  const AllChannels = useSelector((state) => state.Stream.data);

  const access_token = useSelector((state) => state.auth.user.access);
  const userData = useSelector((item) => item.userData.userData);
  const dispatch = useDispatch();
  const new_channals = useSelector((state) => state.Stream.new_channels);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    dispatch(getMessages({ access_token }));
    if (new_channals) {
      const crtMessages = new_channals?.find(
        (item) => item?.task === currentTarget?.id,
      );
      const response = fetch(
        `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setChats(data.message_in_channel ?? []);
          // Process the response data here
        })
        .catch((error) => {});
    }
  }, [currentTarget]);

  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });

  async function send_Message(e) {
    if (!notRun) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (e.key !== 'Enter' || e.shiftKey) {
          return;
        }
        const crtMessages = new_channals?.find(
          (item) => item?.task === currentTarget.id,
        );
        btn?.editor?.commands.clearContent();
        setHtml('');
        const tempMessage = {
          created: new Date().toISOString(),
          channel: crtMessages && crtMessages?.id,
          is_response: false,
          message: html,
          received: null,
          receiver_read: false,
          seen: null,
          sender_read: true, // Assuming the sender has already read their own message
          sender: userData,
          tempId: Math.random().toString(36).substring(7), // Unique temporary ID
        };
        setChats([...chats, tempMessage]);
        const response = await fetch(
          `${ENDPOINT}/api/chat/channel/message/create/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify({
              channel: crtMessages && crtMessages?.id,
              message: html,
            }),
          },
        );

        if (!response.ok) {
          const data = await response.json();
          return;
        }

        const data = await response.json();
        setChats([
          ...chats,
          {
            ...data,
            sender: userData,
          },
        ]);
        dispatch(getMessages({ access_token }));
      }
    }
  }

  const [btn, setBtn] = useState();
  const check = (e) => {
    setBtn(e);
  };
  const [notRun, setNotRun] = useState(false);
  const [openLink, setOpenLink] = useState(false);

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      placement="bottom"
      backdrop
      sx={{
        height: '82vh',
        overflow: 'auto',
      }}
    >
      <Offcanvas.Header
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <button className="close-modal" onClick={() => setShow(false)} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '93%',
          }}
        >
          <Box>
            <h5>Chats: Task</h5>
            <h6
              style={{
                backgroundColor: '#f5f5f5',
                color: '#9d5cf2',
                padding: '10px 5px',
                borderRadius: '10px',
              }}
            >
              {currentTarget ? currentTarget?.task_name : ''}
            </h6>
          </Box>
        </Box>
      </Offcanvas.Header>
      {!switcher && (
        <Offcanvas.Body style={{ height: '500px' }}>
          {!addTimer ? (
            <>
              {value === 'info' && (
                <Grid
                  container
                  style={{
                    height: '600px',
                    flexWrap: 'no-wrap',
                    flexDirection: 'column',
                  }}
                >
                  <Grid
                    item
                    container
                    sx={{
                      height: 'max-content',
                      gap: '1.5rem',
                    }}
                  >
                    <Grid
                      item
                      sm={4.5}
                      md={5.5}
                      lg={6}
                      sx={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '8px',
                      }}
                    >
                      <IntroContent
                        alphaValue={{
                          title: currentTarget.task_name,
                          work_type: [
                            ...new Set(
                              currentTarget.task_worktype.map(
                                (item) => item.name,
                              ),
                            ),
                          ],
                          description: currentTarget.description,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={3.5}
                      md={4.2}
                      lg={5}
                      sx={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '8px',
                      }}
                    >
                      <BetaContent
                        currentTarget={currentTarget}
                        token={token}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{
                      gap: { lg: '1.5rem', md: '1rem', sm: '0.5rem' },
                      height: '100px',
                    }}
                  >
                    <AdditionalInfo
                      currentTarget={currentTarget}
                      deliverable={deliverable}
                    />
                  </Grid>
                </Grid>
              )}

              {value === 'team' && (
                <Grid container direction="column" style={{ height: '600px' }}>
                  <Box
                    sx={{
                      width: '100%',
                      padding: '0.44rem 0.88rem',
                      textAlign: 'left',
                      fontFamily: 'Open Sans',
                      backgroundColor: '#f8f9fa',
                      color: '#711fff',
                      fontWeight: '600',
                      height: '48px',
                      fontSize: '1.1rem',
                      borderRadius: '4px',
                      margin: '1rem 0',
                      textTransform: 'uppercase',
                      borderRadius: '8px',
                      width: '20%',
                    }}
                  >
                    {currentTarget.task_name
                      ? currentTarget.task_name
                      : 'Task Details'}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '20%',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#f8f9fa',
                        borderRadius: '8px',
                        p: '0.44rem 0.88rem',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      Assignor
                      <Typography>No Assingor Yet</Typography>
                    </Box>

                    <Box
                      sx={{
                        borderRadius: '8px',
                        p: '0.44rem 0.88rem',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      Assignee
                      <Typography>No Assingee&apos;s Yet</Typography>
                    </Box>
                  </Box>
                </Grid>
              )}

              {value === 'time' && (
                <List sx={{ width: '100%' }}>
                  {Array.isArray(currentTarget.time_tracking) &&
                  currentTarget.time_tracking.length ? (
                    currentTarget.time_tracking
                      .filter(
                        (pep) =>
                          pep.time_tracking_start && pep.time_tracking_stop,
                      )
                      .map((item) => (
                        <ListItem
                          alignItems="flex-start"
                          key={item.id}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemAvatar>
                              <Avatar
                                alt={item.created_by.first_name}
                                src={item.created_by.avatar}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    color: '#03071E',
                                    letterSpacing: '-0.3px',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    fontFamily: 'Open Sans',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  {item.created_by.first_name}
                                </Typography>
                              }
                              secondary={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.note,
                                  }}
                                />
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            {item.tracked_time_hours !== null && (
                              <>
                                <p className="fstxt">
                                  {item.tracked_time_hours.split(':')[0]}h{' '}
                                  {item.tracked_time_hours.split(':')[1]}m{' '}
                                  {item.tracked_time_hours.split(':')[2]
                                    ? item.tracked_time_hours.split(':')[2]
                                    : 0}
                                  s
                                </p>

                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    color: '#9a9ca5',
                                    fontFamily: 'Open Sans',
                                  }}
                                >
                                  Start {item.time_tracking_start.split('T')[0]}{' '}
                                  |{' '}
                                  {new Date(item.time_tracking_start)
                                    .toISOString()
                                    .slice(11, 19)}
                                </p>
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    color: '#9a9ca5',
                                    fontFamily: 'Open Sans',
                                  }}
                                >
                                  End {item.time_tracking_stop.split('T')[0]} |{' '}
                                  {new Date(item.time_tracking_stop)
                                    .toISOString()
                                    .slice(11, 19)}
                                </p>
                              </>
                            )}
                          </Box>
                        </ListItem>
                      ))
                  ) : (
                    <Alert severity="info">
                      No recent Trackings on this Task
                    </Alert>
                  )}
                </List>
              )}

              {value === 'chat' && (
                <Box
                  sx={{
                    width: '93%',
                    height: '469px',
                    border: '1px solid gray',
                    borderRadius: '8px',
                    padding: '0px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    margin: '0 auto',
                  }}
                >
                  <Box
                    sx={{ height: '220px', width: '98%', overflowY: 'auto' }}
                  >
                    <List sx={{ display: 'flex', flexDirection: 'column' }}>
                      {chats.map((item, index) => {
                        return (
                          <>
                            <List sx={{ width: '100%' }}>
                              <Box key={item.id}>
                                <ListItem
                                  alignItems="flex-start"
                                  key={item.id}
                                  id={`message-${item.id}`}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.sender.first_name}
                                      src={
                                        item.sender.avatar
                                          ? item.sender.avatar
                                          : '/static/images/avatar/1.jpg'
                                      }
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: '#03071E',
                                          letterSpacing: '-0.3px',
                                          fontWeight: 'bold',
                                          fontSize: '1rem',
                                          fontFamily: 'Open Sans',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '0.5rem',
                                          opacity: item?.tempId ? 0.5 : 1,
                                        }}
                                      >
                                        {item?.sender?.first_name}

                                        <p
                                          style={{
                                            color: 'rgba(3, 7, 30, 0.4)',
                                            letterSpacing: '-0.3px',
                                            fontSize: '11px',
                                            fontWeight: '400',
                                            textAlign: 'left',
                                            fontFamily: 'Open Sans',
                                            marginBottom: '0',
                                          }}
                                        >
                                          {!item?.tempId
                                            ? `${new Date(
                                                item.created,
                                              ).toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                              })}, ${new Date(item.created).toDateString()}`
                                            : 'Sending'}
                                        </p>
                                      </Typography>
                                    }
                                    secondary={
                                      <div
                                        style={{
                                          fontWeight: '400',
                                          fontSize: '14px',
                                          lineHeight: '22px',
                                          fontStyle: 'normal',
                                          color: '#03071E',
                                          opacity: item?.tempId ? 0.5 : 1,
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.message,
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                              </Box>
                            </List>
                          </>
                        );
                      })}
                    </List>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#f5f5f5',
                      width: '100%',
                      borderRadius: '10px',
                      pb: 2,
                    }}
                    onKeyDown={async (event) => {
                      send_Message(event);
                    }}
                  >
                    {' '}
                    <RichTextEditor
                      open={openLink}
                      setOpen={setOpenLink}
                      setNotRun={setNotRun}
                      html={html}
                      setHtml={setHtml}
                      check={check}
                      my_logic={true}
                    />
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Step2Content
              currentTarget={{
                ...currentTarget,
                name: currentTarget.task_name,
              }}
              setShow={setShow}
              setAddTimer={setAddTimer}
            />
          )}
        </Offcanvas.Body>
      )}
      {switcher && (
        <Edit
          deliverable={deliverable}
          target={currentTarget}
          setShow={setShow}
        />
      )}
    </Offcanvas>
  );
};

function CreateProTask({
  currentTarget,
  state,
  currentCompany,
  setState,
  setCounter,
  deliverable,
  setDeliverables,
  il,
  pro,
  setAddTask,
  proId,
  setIsDetOpen,
  addTask,
  currentDil,
  id,
  setIsTyping,
  setDeliverable,
  setProjectFormData,
  unique_id,
}) {
  const Team = useSelector((state) => state.userTeam.team);
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const TeamOptions =
    Team && Team.member
      ? Team.member.map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          avatar: item.avatar,
        }))
      : false;

  const dileverableOptions =
    Array.isArray(deliverable) && deliverable.length
      ? deliverable.map((item) => ({
          title: item.title,
          id: item.id,
        }))
      : [];

  const [worktypeName, setWorktypeName] = useState('');
  const [formData, setFormData] = useState({
    task_name: '',
    description: '',
    work_type: '',
    company: currentCompany ? currentCompany.id : '',
    contact: currentTarget ? currentTarget.id : '',
    estimated_hours: 1,
    budget: '',
    task_name: '',
    due_date: '',
    billable: false,
    status: 'inprogress',
    task_type: 'project_task',
    deliverable: {
      title: currentDil?.title ?? '',
      id: currentDil?.id ?? '',
    },
  });

  const [task_assignee, setTaskAssingee] = useState([]);
  const [html, setHtml] = useState('');

  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);

  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };

  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.user.access);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  const companyOptions = currentCompanies.map((company) => ({
    name: company.name,
    id: company.id,
  }));

  const myTheme = createTheme({});
  const dispatch = useDispatch();

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const types =
    Array.isArray(userWorkTypes) && userWorkTypes.length
      ? userWorkTypes.map((item) => ({
          name: item.name,
          id: item.id,
        }))
      : [];

  const realTypes = types.filter((item) =>
    currentDil.worktype.some((ele) => ele.name === item.name),
  );

  const [allDone, setAllDone] = useState(false);

  const newArr =
    Array.isArray(currentDil.task_deliverable) &&
    currentDil.task_deliverable.length
      ? currentDil.task_deliverable.filter((item) =>
          item.task_worktype.some((ele) => ele.name === worktypeName),
        )
      : [];
  const filteredWork =
    Array.isArray(currentDil.worktype) && currentDil.worktype.length
      ? currentDil.worktype.filter((item) => item.name === worktypeName)
      : [];
  const assigned_hours =
    Array.isArray(newArr) && newArr.length
      ? newArr.reduce((total, acc) => {
          return total + Number(acc.estimated_hours ? acc.estimated_hours : 0);
        }, 0)
      : 0;
  const remainingTime = Number(filteredWork[0]?.hours) - assigned_hours;

  const [isHoursGreater, setIsHoursGreater] = useState(false);
  const totalOfNewAssignedHours =
    Array.isArray(formData.task_assignee) && formData.task_assignee.length
      ? formData.task_assignee.reduce(
          (acc, newVal) => acc + Number(newVal.hours),
          0,
        )
      : 0;
  const handleSubmit = async (e) => {
    if (totalOfNewAssignedHours > remainingTime) {
      setIsHoursGreater(true);
    } else {
      handleProceed(e);
    }
  };
  const handleProceed = async (e) => {
    e.preventDefault();
    if (formData.task_name === '') {
      setIsError(true);
      setError('Name Is Required');
    } else if (
      !Array.isArray(formData.task_assignee) ||
      !task_assignee.length
    ) {
      setIsError(true);
      setError('Please select assignee(s) with their hours');
    } else if (formData.work_type === '') {
      setIsError(true);
      setError('Work Type Is Required');
    } else if (formData.status === '') {
      setIsError(true);
      setError('Status Is Required');
    } else {
      for (const key in formData) {
        if (!formData[key]) {
          delete formData[key];
        }
      }

      setLoading(true);
      const response = await fetch(`${ENDPOINT}/api/project/task/create/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          formData.due_date
            ? {
                ...formData,
                due_date: new Date(
                  new Date(formData?.due_date).setDate(
                    new Date(formData.due_date).getDate() + 1,
                  ),
                ).toISOString(),
                description: html,
                task_deliverable: formData.deliverable.id,
                task_project: Number(id),
                estimated_hours: totalOfNewAssignedHours,
                task_worktypes: formData.work_type.map((item) => ({ ...item })),
              }
            : {
                ...formData,
                task_deliverable: formData.deliverable.id,
                task_project: id,
                description: html,
                estimated_hours: totalOfNewAssignedHours,
              },
        ),
      });
      const data = await response.json();
      if (!response.ok) {
        setIsError(true);
        for (const key in data) {
          setError(data[key]);
        }
        setLoading(false);
      } else {
        dispatch(getProjects({ access_token: token }));
        const res = await fetch(
          `${ENDPOINT}/api/project/detail/${unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${token}`,
            },
          },
        );
        const dataGet = await res.json();
        if (!res.ok) {
        }
        setProjectFormData(dataGet);

        if (
          data &&
          Array.isArray(dataGet.deliverable_project) &&
          dataGet.deliverable_project.length
        ) {
          setDeliverable(dataGet.deliverable_project);
        }
        dispatch(getQuickTask({ access_token: token }));
        setIsTyping(false);
        setLoading(false);
        setAddTask(false);
      }
    }
  };

  return (
    <>
      <Grid
        sx={{ zIndex: 99999, position: 'relative' }}
        container
        alignItems="center"
        direction="column"
      >
        <button
          className="close-modal"
          onClick={(e) => {
            e.preventDefault();
            setAddTask(false);
          }}
        />
        <Grid
          container
          item
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            direction: 'column',
            padding: '1rem',
            position: 'relative',
            width: '90%',
            zIndex: 99999,
          }}
        >
          <Box
            sx={{
              width: '100%',
              padding: '18px 23px',
              textAlign: 'left',
              fontFamily: 'Open Sans',
              backgroundColor: '#f8f9fa',
              color: '#711fff',
              fontWeight: '700',
              height: '68px',
              fontSize: '1.50rem',
              borderRadius: '4px',
              margin: '1rem 0',
              textTransform: 'uppercase',
              borderRadius: '8px',
            }}
          >
            Add A New Task
          </Box>
          <Grid item container sx={6} md={6.3} lg={6.3} xl={6.3}>
            <Box
              sx={{
                padding: '0rem 0rem ',
                borderRadius: '8px',
                display: 'flex',
                gap: '1.4rem',
                background: '#fff',
                flexDirection: 'column',
                width: '90%',
              }}
            >
              <Grid
                container
                sx={{
                  border: '1.5px solid #d9d9d9',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  padding: '1rem 0.5rem',
                }}
              >
                <Grid item container direction="column" xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      backgroundColor: '#f8f9fa',
                      padding: '18px 23px',
                      borderRadius: '8px',
                    }}
                  >
                    <Box className="myJam">
                      <span className="modalSpan">Name</span>

                      <NetnetField
                        label="Enter Task Name"
                        variant="outlined"
                        className="MUI-P6-M5"
                        value={formData.task_name}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            task_name: e.target.value,
                          });
                        }}
                        color="secondary"
                        style={{ width: 300, height: 30 }}
                      />
                    </Box>
                    <Box className="myJam Editor-para1">
                      <span className="modalSpan">Description</span>
                      <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                          label="Enter Description"
                          inlineToolbar={false}
                          onChange={fieldText}
                          value={fieldEmpty}
                          controls={[
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                          ]}
                          customControls={[
                            {
                              name: 'stateLine',
                              icon: '|',
                              type: 'inline',
                            },
                            {
                              name: 'stateLine2',
                              icon: '|',
                              type: 'inline',
                            },
                          ]}
                        />
                      </ThemeProvider>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '18px 23px',
                    }}
                  >
                    <Box className="myJam" sx={{ marginBottom: '1.2rem' }}>
                      <span className="modalSpan">Deliverable</span>

                      <Autocomplete
                        id="company"
                        options={dileverableOptions}
                        defaultValue={formData.deliverable}
                        onChange={(_, value) => {
                          setFormData({
                            ...formData,
                            deliverable: value,
                          });
                        }}
                        getOptionLabel={(option) => option.title}
                        style={{ width: '100%', marginTop: '0.7rem' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Enter Deliverable Name"
                            variant="outlined"
                            color="secondary"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#711fff',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#5a0bdf',
                                },

                                '&.Mui-error fieldset': {
                                  borderColor: 'red',
                                },
                              },
                              '& .MuiFormLabel-root': {
                                color: '#03071e66',
                              },
                              '& .MuiFormLabel-root.Mui-focused': {
                                color: '#5a0bdf',
                              },
                              '& .MuiFormLabel-root.Mui-error': {
                                color: 'red',
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { width: '100%' },
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box className="myJam">
                      <span className="modalSpan">Status</span>
                      <Autocomplete
                        id="country"
                        includeInputInList
                        value={formData.task_status}
                        onChange={(e, value) => {
                          setFormData({ ...formData, task_status: value });
                        }}
                        options={status}
                        getOptionLabel={(option) => option}
                        style={{ width: '100%' }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#fff',
                            },
                            '&.Mui-disabled fieldset': {
                              borderColor: '#fff',
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Enter Status"
                            variant="outlined"
                            value={formData.task_status}
                            color="secondary"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#711fff',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#5a0bdf',
                                },

                                '&.Mui-error fieldset': {
                                  borderColor: 'red',
                                },

                                '&.Mui-disabled fieldset': {
                                  borderColor: 'grey',
                                },
                              },
                              '& .MuiFormLabel-root': {
                                color: '#03071e66',
                              },
                              '& .MuiFormLabel-root.Mui-focused': {
                                color: '#5a0bdf',
                              },
                              '& .MuiFormLabel-root.Mui-error': {
                                color: 'red',
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { width: '100%' },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={5.7}
            lg={5.7}
            xl={5.7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e9ecef',
                padding: '18px 23px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: '0.44rem 0.88rem',
                  borderRadius: '8px',
                  background: '#fff',
                  width: 'fit-content',
                  width: '100%',
                }}
              >
                <Autocomplete
                  options={
                    Array.isArray(realTypes) && realTypes.length
                      ? [...realTypes]
                      : Array.isArray(types) && types.length
                        ? types
                        : []
                  }
                  multiple
                  id="ssce2"
                  onChange={(_, value) => {
                    setFormData({
                      ...formData,
                      work_type: value.map((item) => ({
                        id: item.id,
                        hours: 0,
                      })),
                    });
                    setWorktypeName(value.name);
                  }}
                  noOptionsText={<Link to={appRoutes.myWorkTypes}> + Add New</Link>}
                  style={{ width: '100%' }}
                  getOptionLabel={(opt) => opt.name}
                  renderOption={(props, option) => (
                    <>
                      <li {...props}>{option.name}</li>
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Your Work Type"
                      variant="standard"
                      color="secondary"
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: '0.44rem 0.88rem',
                  borderRadius: '8px',
                  background: '#fff',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {!isSelectionComplete ? (
                  <>
                    <Autocomplete
                      options={
                        TeamOptions || [{ name: 'You dont have any team yet' }]
                      }
                      multiple
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setTaskAssingee(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select your Assignee's and there hours "
                          variant="standard"
                          color="secondary"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#f2ebff',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { width: '100%' },
                          }}
                        />
                      )}
                    />
                  </>
                ) : (
                  <List sx={{ width: '100%' }}>
                    {task_assignee.map((per) => {
                      return (
                        <>
                          <ListItem
                            sx={{
                              width: '100%',
                              gap: '1rem',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                              }}
                            >
                              <Avatar
                                src={per.avatar ? per.avatar : '/sharp.png'}
                                alt={per.name}
                              />

                              <Typography>
                                {per.name ? per.name : ''}
                              </Typography>
                            </div>
                            <Box sx={{ display: 'flex' }}>
                              <TextField
                                placeholder="Hours"
                                onChange={(e) => {
                                  setTaskAssingee((prev) => {
                                    if (Array.isArray(prev) && prev.length) {
                                      const shallow_copy = prev.map((item) => ({
                                        ...item,
                                      }));
                                      const index = shallow_copy.findIndex(
                                        (ent) =>
                                          Number(ent.id) === Number(per.id),
                                      );
                                      shallow_copy[index].hours =
                                        e.target.value;
                                      return shallow_copy;
                                    } else {
                                      return [
                                        {
                                          assignee_user: per.id,
                                          hours: e.target.value,
                                        },
                                      ];
                                    }
                                  });
                                }}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#711fff',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#5a0bdf',
                                    },

                                    '&.Mui-error fieldset': {
                                      borderColor: 'red',
                                    },

                                    '&.Mui-disabled fieldset': {
                                      borderColor: 'grey',
                                    },
                                  },
                                  '& .MuiFormLabel-root': {
                                    color: '#03071e66',
                                  },
                                  '& .MuiFormLabel-root.Mui-focused': {
                                    color: '#5a0bdf',
                                  },
                                  '& .MuiFormLabel-root.Mui-error': {
                                    color: 'red',
                                  },
                                }}
                                color="secondary"
                                type="number"
                                InputProps={{
                                  style: { width: 100 },
                                }}
                              />
                              <Box
                                sx={{
                                  width: '80px',
                                  backgroundColor: '#e6e6e6',
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '0 10px',
                                  borderStartEndRadius: '10px',
                                  borderEndEndRadius: '10px',
                                }}
                              >
                                {'Hours'}
                              </Box>
                            </Box>
                          </ListItem>
                          <Divider sx={{ borderColor: '#000' }} />
                        </>
                      );
                    })}
                  </List>
                )}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {Array.isArray(task_assignee) &&
                  task_assignee.length &&
                  !allDone ? (
                    <div style={{ width: '25%' }}>
                      <button
                        className="btn"
                        onClick={(_) => {
                          if (isSelectionComplete) {
                            setFormData({
                              ...formData,
                              task_assignee: task_assignee.map((assg) => ({
                                assignee_user: assg.id,
                                hours: assg.hours,
                              })),
                            });
                            setAllDone(true);
                            return;
                          } else {
                            setIsSelectionComplete(true);
                          }
                        }}
                      >
                        Confirm {!isSelectionComplete && 'Selection'}
                      </button>
                    </div>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: '18px 23px',
                borderRadius: '8px',
                border: '1px solid gray',
              }}
            >
              <Box className="myJam">
                <span style={{ color: '#000', fontWeight: '600' }}>
                  Due Date
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM-dd-yyyy"
                    label="MM/DD/YYYY"
                    value={formData.due_date}
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        due_date: newValue,
                      });
                    }}
                    sx={{
                      width: { xl: '75%', lg: '65%' },
                      height: '60px',
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                        {
                          color: '#711FFF',
                        },
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                        {
                          marginRight: '0px',
                          position: 'absolute',
                          borderRadius: '0px !important',
                          width: '100% !important',
                          height: '60px',
                        },
                      '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                      '& .MuiInputAdornment-root': {
                        position: 'absolute',
                        top: '28px',
                        left: '-6px',
                        borderRadius: '0px',
                        width: '100%',
                      },
                      '& .MuiSvgIcon-root': {
                        position: 'absolute',
                        right: '10px',
                      },
                      '& .MuiInputBase-root': {
                        width: '100%',
                        position: 'relative',
                      },
                      '& .MuiOutlinedInput-input': {
                        width: '100%',
                        height: '60px',
                        padding: '0px',
                        color: 'black',
                        marginLeft: '10px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        width: '100%',
                        height: '60px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                            '&.Mui-error fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#3071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: '#000',
                          },
                        }}
                        className="MUI-P6-M5"
                        style={{ width: '70%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box sx={{ display: 'flex', gap: '4rem', alignItems: 'center' }}>
                <span style={{ fontSize: '15px' }}>
                  Time Remaining{' '}
                  <InfoOutlinedIcon
                    sx={{ color: '#00E6B8', fontSize: '17px' }}
                  />
                </span>
                <Box
                  sx={{
                    background: 'orange',
                    padding: '.8rem 1.2rem',
                    borderRadius: '8px',
                  }}
                >
                  {' '}
                  <Typography>
                    {remainingTime && remainingTime <= 0
                      ? '0 Hours Remaining'
                      : remainingTime
                        ? remainingTime + ' Hours Remaining'
                        : 'select worktype first'}
                  </Typography>{' '}
                </Box>
              </Box>
            </Box>
            {isHoursGreater && (
              <Box
                sx={{
                  width: '850px',
                  height: '300px',
                  background: 'rgba(241, 243, 245, 0.50)',
                  padding: '2rem',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: 1,
                  left: { xl: '23%', lg: '5%', md: '5%' },
                  zIndex: 999,
                }}
              >
                <Box
                  sx={{
                    width: '98%',
                    height: '95%',
                    backgroundColor: 'rgba(255,255,255)',
                    border: '1px solid #d9d9d9',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '8px',
                    padding: '0.68rem 1rem',
                    justifyContent: 'space-around',
                    boxShadow: '2px 2px 6px 0px #D9D9D9',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: '500',
                      fontFamily: 'Open Sans, sans-serif',
                      color: '#db8000',
                    }}
                  >
                    <InfoOutlinedIcon
                      SX={{
                        color: '#db8000',
                        fontSize: '22px',
                        marginRight: '10px',
                      }}
                    />{' '}
                    FEW HOURS REMAINING
                  </p>
                  <p
                    style={{
                      color: '#232125',
                      letterSpacing: '-0.3px',
                      textAlign: 'center',
                      fontSize: '1rem',
                      fontFamily: "'Open sans', sans-serif",
                      fontWeight: '500',
                    }}
                  >
                    You have{' '}
                    <strong>
                      {remainingTime && remainingTime < 0 ? '0' : remainingTime}
                    </strong>{' '}
                    Hours remaining. By Allocating{' '}
                    <strong>{totalOfNewAssignedHours + 'h'}</strong> Hours, it
                    will bring your total allocated hours over your estimated
                    hours, Please consider a change order to add more hours to
                    the pool. should we Proceed anyway?
                  </p>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '50%',
                      margin: '0 auto',
                    }}
                  >
                    <button
                      className="cancel-btn"
                      onClick={() => setIsHoursGreater(false)}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleProceed}
                      style={{
                        fontFamily:
                          "'Neue Haas Display Pro', 'Open Sans', sans-serif",
                        fontWeight: '500',
                        padding: '1rem 0.5rem',
                        backgroundColor: 'orange',
                        borderRadius: '5px',
                        color: 'var(--white-100)',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '0.5rem 2rem',
                      }}
                    >
                      {loading ? (
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            margin: '0rem 0.6rem',
                          }}
                        >
                          <PreLoader />
                        </Box>
                      ) : (
                        'Proceed'
                      )}{' '}
                    </button>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            item
            sx={{
              marginLeft: 'auto',
            }}
          >
            {isError && <p className="error">{error}</p>}
            <div className="button-groups">
              <button
                className="cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ ...state, bottom: false });
                }}
              >
                Cancel
              </button>
              <button
                className="create-btn"
                onClick={handleSubmit}
                disabled={!allDone}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.6rem',
                    }}
                  >
                    <PreLoader />
                  </Box>
                ) : (
                  'Create'
                )}{' '}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function CreateProDil({
  currentTarget,
  state,
  currentCompany,
  setState,
  setCounter,
  deliverable,
  setDeliverables,
  il,
  pro,
  setAddDil,
  proId,
  setIsDetOpen,
  addDil,
  project,
  setIsTyping,
  setProjectFormData,
  unique_id,
}) {
  useEffect(() => {
    setIsDetOpen(!addDil);
  }, [addDil]);

  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const dileverableOptions =
    Array.isArray(deliverable) && deliverable.length
      ? deliverable.map((item) => ({
          title: item.title,
          id: item.id,
        }))
      : [];

  const [formData, setFormData] = useState({
    deliverable_name: '',
    description: '',
    deliverable_worktype: [],
    deliverable_startdate: null,
    deliverable_enddate: null,
  });

  const [task_assignee, setTaskAssingee] = useState([]);
  const [html, setHtml] = useState('');
  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);

  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };

  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.auth.user.access);

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  const myTheme = createTheme({});
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.deliverable_name === '') {
      setIsError(true);
      setError('Name Is Required');
    } else if (
      !Array.isArray(formData.deliverable_worktype) ||
      !formData.deliverable_worktype.length ||
      !formData.deliverable_worktype.every((w) => w.hours)
    ) {
      setIsError(true);
      setError('Please Add Your work type');
    } else if (
      !formData.deliverable_startdate ||
      !formData.deliverable_enddate
    ) {
      setIsError(true);
      setError('Please select Date Range');
      return;
    } else {
      const response = await fetch(
        `${ENDPOINT}/api/project/deliverable/create/`,
        {
          method: 'POST',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([
            {
              ...formData,
              deliverable_project: Number(project),
              deliverable_startdate: moment(
                formData.deliverable_startdate,
              ).toISOString(),
              deliverable_enddate: moment(
                formData.deliverable_enddate,
              ).toISOString(),
              description: html,
              deliverable_worktype: formData.deliverable_worktype.map((wt) => {
                return {
                  id: wt.work_type,
                  hours: wt.hours,
                };
              }),
            },
          ]),
        },
      );
      const data = await response.json();
      if (!response.ok) {
        setIsError(true);
        setError('Something went wrong creating delieverable');
        setLoading(false);
        return;
      } else {
        dispatch(getProjects({ access_token: token }));
        const res = await fetch(
          `${ENDPOINT}/api/project/detail/${unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${token}`,
            },
          },
        );
        const dataGet = await res.json();
        if (!res.ok) {
        }
        setProjectFormData(dataGet);

        if (
          data &&
          Array.isArray(dataGet.deliverable_project) &&
          dataGet.deliverable_project.length
        ) {
          setDeliverables(dataGet.deliverable_project);
        }
        dispatch(getQuickTask({ access_token: token }));
        setLoading(false);
        setAddDil(false);
        setIsTyping(false);
      }
    }
  };

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);

  const types =
    Array.isArray(userWorkTypes) && userWorkTypes.length
      ? userWorkTypes.map((item) => ({
          name: item.name,
          id: item.id,
          hours: 0,
        }))
      : [];

  const [allDone, setAllDone] = useState(false);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
        setError('');
      }, 3600);
    }
  }, [isError]);

  return (
    <>
      <Grid
        sx={{ zIndex: 99999 }}
        container
        alignItems="center"
        direction="column"
      >
        <button
          className="close-modal"
          onClick={(e) => {
            e.preventDefault();
            setAddDil(false);
          }}
        />
        <Grid
          container
          item
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            direction: 'column',
            padding: '1rem',
            position: 'relative',
            width: '90%',
            zIndex: 99999,
          }}
        >
          <Box
            sx={{
              width: '100%',
              padding: '18px 23px',
              textAlign: 'left',
              fontFamily: 'Open Sans',
              backgroundColor: '#f8f9fa',
              color: '#711fff',
              fontWeight: '700',
              height: '68px',
              fontSize: '1.50rem',
              borderRadius: '4px',
              margin: '1rem 0',
              textTransform: 'uppercase',
              borderRadius: '8px',
            }}
          >
            Add A New Deliverable
          </Box>
          <Grid item container sx={6} md={6.3} lg={6.3} xl={6.3}>
            <Box
              sx={{
                padding: '0rem 0rem ',
                borderRadius: '8px',
                display: 'flex',
                gap: '1.4rem',
                background: '#fff',
                flexDirection: 'column',
                width: '90%',
              }}
            >
              <Grid
                container
                sx={{
                  border: '1.5px solid #d9d9d9',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  padding: '1rem 0.5rem',
                  height: '100%',
                }}
              >
                <Grid item container direction="column" xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      backgroundColor: '#f8f9fa',
                      padding: '18px 23px',
                      borderRadius: '8px',
                    }}
                  >
                    <Box className="myJam">
                      <span className="modalSpan">Name</span>

                      <NetnetField
                        label="Enter Deliverable Name"
                        variant="outlined"
                        className="MUI-P6-M5"
                        value={formData.deliverable_name}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            deliverable_name: e.target.value,
                          });
                        }}
                        color="secondary"
                        style={{ width: 300, height: 30 }}
                      />
                    </Box>
                    <Box className="myJam Editor-para1">
                      <span className="modalSpan">Description</span>

                      <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                          label="Enter Description"
                          inlineToolbar={false}
                          onChange={fieldText}
                          value={fieldEmpty}
                          controls={[
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                          ]}
                          customControls={[
                            {
                              name: 'stateLine',
                              icon: '|',
                              type: 'inline',
                            },
                            {
                              name: 'stateLine2',
                              icon: '|',
                              type: 'inline',
                            },
                          ]}
                        />
                      </ThemeProvider>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '18px 23px',
                    }}
                  >
                    <Box className="myJam" sx={{ marginBottom: '1.2rem' }}>
                      <span className="modalSpan">Dependency</span>

                      <Autocomplete
                        id="company"
                        options={dileverableOptions}
                        onChange={(_, value) => {
                          setFormData({
                            ...formData,
                            deliverable_dependancy: value,
                          });
                        }}
                        getOptionLabel={(option) => option.deliverable_name}
                        style={{ width: '100%', marginTop: '0.7rem' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add Dependency"
                            variant="outlined"
                            color="secondary"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#711fff',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#5a0bdf',
                                },

                                '&.Mui-error fieldset': {
                                  borderColor: 'red',
                                },
                              },
                              '& .MuiFormLabel-root': {
                                color: '#03071e66',
                              },
                              '& .MuiFormLabel-root.Mui-focused': {
                                color: '#5a0bdf',
                              },
                              '& .MuiFormLabel-root.Mui-error': {
                                color: 'red',
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { width: '100%' },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={5.7}
            lg={5.7}
            xl={5.7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e9ecef',
                padding: '18px 23px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
                maxHeight: '278px',
                overflowY: 'auto',
                my: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: '0.44rem 0.88rem',
                  borderRadius: '8px',
                  background: '#fff',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {!isSelectionComplete ? (
                  <>
                    <Autocomplete
                      options={
                        Array.isArray(types) && types.length
                          ? [...types].filter(
                              (tp) =>
                                !task_assignee.some(
                                  (entites) => Number(entites.id) === tp.id,
                                ),
                            )
                          : []
                      }
                      multiple
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setTaskAssingee(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select your Work Types and Hours"
                          variant="standard"
                          color="secondary"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#f2ebff',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { width: '100%' },
                          }}
                        />
                      )}
                    />
                  </>
                ) : (
                  <List sx={{ width: '100%' }}>
                    {task_assignee.map((per) => {
                      return (
                        <>
                          <ListItem
                            sx={{
                              width: '100%',
                              gap: '1rem',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography>NetNetWt {per.id}</Typography>

                            <Typography>{per.name ? per.name : ''}</Typography>

                            <Box sx={{ display: 'flex' }}>
                              <TextField
                                placeholder="Hours"
                                onChange={(e) => {
                                  setTaskAssingee((prev) => {
                                    if (Array.isArray(prev) && prev.length) {
                                      const shallow_copy = prev.map((item) => ({
                                        ...item,
                                      }));
                                      const index = shallow_copy.findIndex(
                                        (ent) =>
                                          Number(ent.id) === Number(per.id),
                                      );
                                      shallow_copy[index].hours =
                                        e.target.value;
                                      return shallow_copy;
                                    } else {
                                      return [
                                        {
                                          assignee_user: per.id,
                                          hours: e.target.value,
                                        },
                                      ];
                                    }
                                  });
                                }}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#711fff',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#5a0bdf',
                                    },

                                    '&.Mui-error fieldset': {
                                      borderColor: 'red',
                                    },

                                    '&.Mui-disabled fieldset': {
                                      borderColor: 'grey',
                                    },
                                  },
                                  '& .MuiFormLabel-root': {
                                    color: '#03071e66',
                                  },
                                  '& .MuiFormLabel-root.Mui-focused': {
                                    color: '#5a0bdf',
                                  },
                                  '& .MuiFormLabel-root.Mui-error': {
                                    color: 'red',
                                  },
                                }}
                                color="secondary"
                                type="number"
                                InputProps={{
                                  style: { width: 100 },
                                }}
                              />
                              <Box
                                sx={{
                                  width: '80px',
                                  backgroundColor: '#e6e6e6',
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '0 10px',
                                  borderStartEndRadius: '10px',
                                  borderEndEndRadius: '10px',
                                }}
                              >
                                {'Hours'}
                              </Box>
                            </Box>
                          </ListItem>
                          <Divider sx={{ borderColor: '#000' }} />
                        </>
                      );
                    })}
                  </List>
                )}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {Array.isArray(task_assignee) &&
                  task_assignee.length &&
                  !allDone ? (
                    <div style={{ width: '25%' }}>
                      <button
                        className="btn"
                        onClick={(_) => {
                          if (isSelectionComplete) {
                            setFormData({
                              ...formData,
                              deliverable_worktype: task_assignee.map(
                                (assg) => ({
                                  work_type: assg.id,
                                  hours: assg.hours,
                                }),
                              ),
                            });
                            setAllDone(true);
                            return;
                          } else {
                            setIsSelectionComplete(true);
                          }
                        }}
                        disabled={
                          !isSelectionComplete
                            ? false
                            : !task_assignee.every((ele) => ele.hours)
                        }
                      >
                        Confirm {!isSelectionComplete && 'Selection'}
                      </button>
                    </div>
                  ) : null}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                padding: '18px 23px',
                borderRadius: '8px',
                border: '1px solid gray',
              }}
            >
              <Box className="myJam">
                <span>Start Date</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM-dd-yyyy"
                    label="MM/DD/YYYY"
                    value={formData.deliverable_startdate}
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        deliverable_startdate: newValue,
                      });
                    }}
                    sx={{
                      width: '80%',
                      height: '60px',
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                        {
                          color: '#711FFF',
                        },
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                        {
                          marginRight: '0px',
                          position: 'absolute',
                          borderRadius: '0px !important',
                          width: '100% !important',
                          height: '60px',
                        },
                      '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                      '& .MuiInputAdornment-root': {
                        position: 'absolute',
                        top: '28px',
                        left: '-6px',
                        borderRadius: '0px',
                        width: '100%',
                      },
                      '& .MuiSvgIcon-root': {
                        position: 'absolute',
                        right: '10px',
                      },
                      '& .MuiInputBase-root': {
                        width: '100%',
                        position: 'relative',
                      },
                      '& .MuiOutlinedInput-input': {
                        width: '100%',
                        height: '60px',
                        padding: '0px',
                        color: 'black',
                        marginLeft: '10px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        width: '100%',
                        height: '60px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                            '&.Mui-error fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#3071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: '#000',
                          },
                        }}
                        className="MUI-P6-M5"
                        style={{ width: '70%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box className="myJam">
                <span>End Date</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM-dd-yyyy"
                    label="MM/DD/YYYY"
                    value={formData.deliverable_enddate}
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        deliverable_enddate: newValue,
                      });
                    }}
                    sx={{
                      width: '80%',
                      height: '60px',
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                        {
                          color: '#711FFF',
                        },
                      '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: '#711FFF',
                        },
                      '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                        {
                          marginRight: '0px',
                          position: 'absolute',
                          borderRadius: '0px !important',
                          width: '100% !important',
                          height: '60px',
                        },
                      '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '100% !important',
                        height: '60px',
                      },
                      '& .MuiInputAdornment-root': {
                        position: 'absolute',
                        top: '28px',
                        left: '-6px',
                        borderRadius: '0px',
                        width: '100%',
                      },
                      '& .MuiSvgIcon-root': {
                        position: 'absolute',
                        right: '10px',
                      },
                      '& .MuiInputBase-root': {
                        width: '100%',
                        position: 'relative',
                      },
                      '& .MuiOutlinedInput-input': {
                        width: '100%',
                        height: '60px',
                        padding: '0px',
                        color: 'black',
                        marginLeft: '10px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        width: '100%',
                        height: '60px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: '80%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                            '&.Mui-error fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#3071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: '#000',
                          },
                        }}
                        className="MUI-P6-M5"
                        style={{ width: '70%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Grid>
          {isError && (
            <Alert severity="error" sx={{ width: '100%', my: '0.5rem' }}>
              {error}
            </Alert>
          )}
          <Grid
            item
            sx={{
              marginLeft: 'auto',
            }}
          >
            <div className="button-groups">
              <button
                className="cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ ...state, bottom: false });
                }}
              >
                Cancel
              </button>
              <button className="create-btn" onClick={handleSubmit}>
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.6rem',
                    }}
                  >
                    <PreLoader />
                  </Box>
                ) : (
                  'Create'
                )}{' '}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { ProTaskDetail, CreateProDil, Edit };
export default CreateProTask;
