import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import { DashedField } from '../Misc';
import './atomic-atoms.css';

function SearchFieldWithIcon({ searchQuery, setSearchQuery }) {
  return (
    <AnimatePresence>
      <motion.div
        key="field"
        style={{
          background: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 8px',
          borderRadius: '6px',
          color: '#676B6B',
          cursor: 'pointer',
        }}
        initial={{ x: 10, opacity: 0, transition: { duration: 0.5 } }}
        animate={{ x: 0, opacity: 1, transition: { duration: 0.5 } }}
        exit={{ x: -10, opacity: 0, transition: { duration: 0.5 } }}
      >
        <SearchIcon style={{ curser: 'pointer' }} />
        <DashedField
          id="standard-basic"
          variant="standard"
          sx={{ padding: '0px', width: '100%' }}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </motion.div>
    </AnimatePresence>
  );
}
// eslint-disable-next-line
export { SearchFieldWithIcon };
