import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import Bounce from 'react-reveal/Bounce';
import { TextField, Typography, Button, Box, Avatar } from '@mui/material';
import PreLoader from '../../Componenets/Misc';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { userData as userData2 } from '../../Screens/InnerApp/userData';
import Shake from 'react-reveal/Shake';
import { PreLoader1 } from '../../Componenets/Misc';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const MyProfile = ({ setRunAllRequest, runAllRequest, screenHeight }) => {
  // this is alert modal

  const [showAlert, setShowAlert] = useState();
  const [msg, setMsg] = useState('');
  const funcAlert = (v) => {
    setMsg(v);
    setShowAlert(true);
  };

  //////////////////////////////////////////////////////////////////

  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const userData = useSelector((state) => state.userData.userData);
  // this is uplodeImage state
  const [selectedImage, setSelectedImage] = useState(userData.avatar);
  const [showImage, setShowImage] = useState(null);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setShowImage(e.target.result);
        };

        reader.readAsDataURL(file);
        setSelectedImage(file);
      } else {
        funcAlert('Invalid file type. Please select a valid image file.');
      }
    }
  };

  const [inputFiled, setInputFiled] = useState({
    firstName: userData.first_name,
    lastName: userData.last_name,
  });

  const handleInputFiledChange = (e) => {
    setInputFiled({
      ...inputFiled,
      [e.target.name]: e.target.value,
    });
  };

  const userUpdateData = new FormData();

  // Add fields to the formData object
  userUpdateData.append('profile_updated', 1);
  userUpdateData.append('personalization_updated', 1);
  userUpdateData.append('email', userData?.email);
  userUpdateData.append('first_name', inputFiled?.firstName);
  userUpdateData.append('last_name', inputFiled?.lastName);
  userUpdateData.append('password', 'P@ssword123');
  userUpdateData.append('username', userData?.username);
  {
    showImage && userUpdateData.append('avatar', selectedImage);
  }
  userUpdateData.append('skiped_welcome', 0);

  useEffect(() => {
    const func = async () => {
      try {
        const res = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        });

        if (!res.ok) {
        } else {
          const data = await res.json();
          setInputFiled({
            firstName: data.first_name,
            lastName: data.last_name,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    func();
  }, []);

  const [apiAlert, setApiAlert] = useState(false);

  const handleSaveProfile = async () => {
    setLoader(true);
    try {
      const res = await fetch(`${ENDPOINT}/api/user/update/${userData.id}/`, {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
        },
        body: userUpdateData,
      });

      if (!res.ok) {
        setLoader(false);
        funcAlert('error');

        setApiAlert('Unsuccessfully Saved Changes');
        setTimeout(() => {
          setApiAlert(false);
        }, 3000);
      } else {
        const data = await res.json();
        setApiAlert('Successfully Saved Changes');
        setTimeout(() => {
          setApiAlert(false);
        }, 3000);
        setLoader(false);

        const resGet = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
          },
        });

        if (!resGet.ok) {
          setLoader(false);
        } else {
          const dataGET = await resGet.json();
          dispatch(userData2(dataGET));
          setLoader(false);
          setRunAllRequest(!runAllRequest);
        }
      }
    } catch (error) {
      funcAlert('error');
      setLoader(false);

      setApiAlert('Unsuccessfully Saved Changes');
      setTimeout(() => {
        setApiAlert(false);
      }, 3000);
    }
  };

  const handleCancleProfile = () => {
    setInputFiled({
      firstName: userData.first_name,
      lastName: userData.last_name,
    });
    setShowImage(userData.avatar);
  };

  const [loadingSaveChangesIcon, setLoadingSaveChangesIcon] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPass, setNewPass] = useState('');
  const [oldPass, setOldPass] = useState('');
  const currentUser = useSelector((state) => state.userData.userData);
  const [error, setError] = useState({
    oldPass: false,
    misMatch: false,
    oldPassisCorrect: false,
  });

  ////////////////////////////////////////////////////////////////////////////////////////

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword1, setShowPassword1] = useState(false);
  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const [show, setShow] = useState(false);

  return (
    <>
      <Bounce left>
        <section className="oppor-section">
          <HelpVideo show={show} setShow={setShow} />
          <TitleBar
            helpIconFunction={() => setShow(true)}
            sectionTitle="My Profile"
            buttonRequired={false}
          />
          <div
            className=""
            style={{
              borderRadius: '10px',
              minHeight: screenHeight - 160,
              maxHeight: screenHeight - 160,
              backgroundColor: '#FFF',
              padding: '30px',
              overflow: 'auto',
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: 500, fontSize: '16px', color: '#03071E' }}
              >
                Edit Profile Changes
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                backgroundColor: '#F8F9FA',
                borderRadius: '10px',
                my: 2,
                p: 2,
              }}
            >
              {userData.avatar || showImage ? (
                <Avatar
                  alt="Image"
                  src={showImage ? showImage : `${userData.avatar}`}
                  sx={{ width: 34, height: 34 }}
                />
              ) : (
                <Avatar sx={{ width: 34, height: 34 }}>
                  {userData?.first_name.slice(0, 1)}
                </Avatar>
              )}
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#711FFF',
                  mx: 2,
                  textTransform: 'uppercase',
                }}
              >
                Add/edit profile Picture
              </Typography>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="image-selector"
                type="file"
                onChange={handleImageSelect}
              />
              <label htmlFor="image-selector">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#9747FF',
                    py: '4px',
                    px: '42px',
                    borderRadius: '4px',
                    backgroundColor: 'inherit',
                    border: '1px dotted #9747FF',
                    '&:hover': { cursor: 'pointer' },
                  }}
                >
                  UPLOAD
                </Typography>
              </label>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <TextField
                sx={{
                  width: '277px',
                  mr: '10%',
                  my: 2,
                  '& .MuiInputLabel-root': {
                    color: '#711FFF', // Change this to the desired label color
                    fontSize: '14px',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottomColor: '#711FFF', // Change the hover color
                  },
                  '& .MuiInput-underline.Mui-focused:after': {
                    borderBottomColor: '#711FFF', // Change the focused color
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#711FFF', // Change this to the desired focused label color
                  },
                }}
                placeholder="Enter Your First Name"
                id="standard-required"
                label="First Name"
                name="firstName"
                defaultValue={userData?.first_name}
                value={inputFiled?.firstName}
                onChange={(e) => {
                  handleInputFiledChange(e);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
              <TextField
                sx={{
                  width: '277px',
                  mr: '10%',
                  my: 2,
                  '& .MuiInputLabel-root': {
                    color: '#711FFF', // Change this to the desired label color
                    fontSize: '14px',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottomColor: '#711FFF', // Change the hover color
                  },
                  '& .MuiInput-underline.Mui-focused:after': {
                    borderBottomColor: '#711FFF', // Change the focused color
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#711FFF', // Change this to the desired focused label color
                  },
                }}
                placeholder="Enter Your Last Name"
                id="standard-required"
                label="Last Name"
                name="lastName"
                defaultValue={userData?.last_name}
                value={inputFiled?.lastName}
                onChange={(e) => handleInputFiledChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </Box>
            <Box></Box>
            <Box
              sx={{
                borderRadius: '10px',
                border: `1px solid var(--Functions-Tab-Stroke, #D9D9D9)`,
                p: 3,
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: '14px', color: '#03071E' }}
              >
                Change Your Password
              </Typography>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <TextField
                  sx={{
                    width: '277px',
                    my: 2,
                    border: 'none',
                    '& .MuiFilledInput-root': {
                      backgroundColor: '#F1F3F5',
                      borderRadius: '8px', // Set the desired !border radius value
                    },
                    '& .MuiInputLabel-root': {
                      color: '#711FFF', // Change this to the desired label color
                      fontSize: '14px',
                      fontWeight: 400,
                    },
                    '& .MuiFilledInput-underline:before': {
                      borderBottom: 'none', // Set the borderBottom property to "none"
                    },
                    '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before':
                      {
                        borderBottom: 'none', // Remove the hover line
                      },
                    '& .MuiFilledInput-underline:after': {
                      borderBottom: 'none', // Set the borderBottom property to "none"
                    },
                    '& .MuiInput-underline.Mui-focused:after': {
                      borderBottomColor: '#711FFF', // Change the focused color
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#711FFF', // Change this to the desired focused label color
                    },

                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => theme.palette.secondary.main,
                    },
                  }}
                  id="standard-required"
                  label="Type Your Old password"
                  placeholder="Type Your Old password"
                  onBlur={async (e) => {
                    setCurrentPassword(e.target.value);
                    if (e.target.value.length > 7) {
                      const response = await fetch(
                        `${ENDPOINT}/auth/jwt/create/`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            email: currentUser.email,
                            password: e.target.value,
                          }),
                        },
                      );
                      const data = await response.json();
                      if (!response.ok) {
                        setError({
                          oldPass:
                            'Your Password Does not match with current password',
                          misMatch: false,
                        });
                        setTimeout(() => {
                          setError({
                            oldPass: false,
                            misMatch: false,
                          });
                        }, 3000);
                      } else {
                        setError({
                          oldPass: false,
                          misMatch: false,
                          oldPassisCorrect: true,
                        });
                      }
                    } else {
                      if (e.target.value.length > 0) {
                        setError({
                          oldPass: 'Password must be atleast 8 Characters',
                          misMatch: false,
                          oldPassisCorrect: false,
                        });
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                  InputProps={{
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => handleTogglePasswordVisibility(e)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error?.oldPassisCorrect && (
                  <CheckCircleOutline sx={{ color: 'green' }} />
                )}
              </Box>
              {error?.oldPass ? (
                <Shake>
                  <p style={{ color: 'red' }}>{error.oldPass}</p>
                </Shake>
              ) : null}
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ mr: '10%' }}>
                  <TextField
                    sx={{
                      width: '277px',
                      mt: 2,
                      '& .MuiInputLabel-root': {
                        color: '#711FFF', // Change this to the desired label color
                        fontSize: '14px',
                      },
                      '& .MuiInput-underline:before': {
                        borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#711FFF', // Change the hover color
                      },
                      '& .MuiInput-underline.Mui-focused:after': {
                        borderBottomColor: '#711FFF', // Change the focused color
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#711FFF', // Change this to the desired focused label color
                      },
                    }}
                    placeholder="Enter Your New Password Here"
                    id="standard-required"
                    label="Add Your New Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setNewPass(e.target.value);
                    }}
                    InputProps={{
                      type: showPassword1 ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility1}
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '12px',
                      color: '#00000099',
                      width: '277px',
                    }}
                  >
                    * Your password must contain A-Z, 1-9, and one special
                    character
                  </Typography>
                </Box>
                <Box sx={{ mr: '10%' }}>
                  <TextField
                    sx={{
                      width: '277px',
                      mt: 2,
                      '& .MuiInputLabel-root': {
                        color: '#711FFF', // Change this to the desired label color
                        fontSize: '14px',
                      },
                      '& .MuiInput-underline:before': {
                        borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#711FFF', // Change this to the desired bottom line color
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#711FFF', // Change the hover color
                      },
                      '& .MuiInput-underline.Mui-focused:after': {
                        borderBottomColor: '#711FFF', // Change the focused color
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#711FFF', // Change this to the desired focused label color
                      },
                    }}
                    placeholder="Re-Enter Your New Password Here"
                    id="standard-required"
                    label="Re-Type New Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setOldPass(e.target.value);
                    }}
                    InputProps={{
                      type: showPassword2 ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility2}
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '12px',
                      color: '#00000099',
                      width: '277px',
                    }}
                  >
                    * Your password must contain A-Z, 1-9, and one special
                    character
                  </Typography>
                </Box>
              </Box>
              {error.misMatch ? (
                <Shake>
                  <p style={{ color: 'red' }}>{error.misMatch}</p>
                </Shake>
              ) : null}

              {loadingSaveChangesIcon ? (
                <Button
                  sx={{
                    backgroundColor: '#5FCEA8',
                    boxShadow: 'none',
                    color: 'white',
                    mt: 2,
                    fontWeight: 400,
                    py: 0.3,
                    px: 4.5,
                    '&:hover': { backgroundColor: '#5FCEA8' },
                  }}
                >
                  <Box width={'27px'} height={'27px'} sx={{ px: 0.2 }}>
                    <PreLoader1 />
                  </Box>
                </Button>
              ) : (
                <Button
                  onClick={async () => {
                    if (!error.oldPassisCorrect) {
                      setError({
                        oldPass:
                          'Your Password Does not match with current password',
                        misMatch: false,
                      });
                      setTimeout(() => {
                        setError({
                          oldPass: false,
                          misMatch: false,
                          oldPassisCorrect: false,
                        });
                      }, 3000);
                    } else {
                      if (newPass === oldPass) {
                        setLoadingSaveChangesIcon(true);
                        const passData = new FormData();
                        passData.append('current_password', currentPassword);
                        passData.append('new_password', newPass);
                        passData.append('re_new_password', oldPass);
                        try {
                          const res = await fetch(
                            `${ENDPOINT}/auth/users/set_password/`,
                            {
                              method: 'POST',
                              headers: {
                                Authorization: `JWT ${access_token}`,
                              },
                              body: passData,
                            },
                          );
                          const data = await res.json(); // Parse the response data

                          if (!res.ok) {
                            setLoadingSaveChangesIcon(false);
                            for (let i in data) {
                              let msg = data[i];
                              setError({
                                oldPass: false,
                                misMatch: msg,
                                oldPassisCorrect: true,
                              });
                            }
                          } else {
                            setLoadingSaveChangesIcon(false);
                            setCurrentPassword('');
                            setOldPass('');
                            setNewPass('');
                            setError({
                              oldPass: false,
                              misMatch: false,
                              oldPassisCorrect: false,
                            });
                            setApiAlert('Successfully Saved Changes');
                            setTimeout(() => {
                              setApiAlert(false);
                            }, 3000);
                          }
                        } catch (error) {
                          setLoadingSaveChangesIcon(false);
                          setApiAlert('Unsuccessfully Saved Changes');
                          setTimeout(() => {
                            setApiAlert(false);
                          }, 3000);
                        }
                      } else {
                        setError({
                          oldPass: false,
                          misMatch: 'Passwords are not matching',
                          oldPassisCorrect: true,
                        });
                        setTimeout(() => {
                          setError({
                            oldPass: false,
                            misMatch: false,
                            oldPassisCorrect: true,
                          });
                        }, 3000);
                      }
                    }
                  }}
                  sx={{
                    backgroundColor: '#5FCEA8',
                    boxShadow: 'none',
                    color: 'white',
                    mt: 2,
                    fontWeight: 400,
                    '&:hover': { backgroundColor: '#5FCEA8' },
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                my: 3,
                alignItems: 'center',
              }}
            >
              {apiAlert && (
                <Box className="fade-in-text" sx={{ mr: 3 }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      mt: 2,
                      color:
                        apiAlert === 'Successfully Saved Changes'
                          ? '#00B28F'
                          : '#DC241C',
                    }}
                  >
                    {apiAlert}
                  </Typography>
                </Box>
              )}
              <button
                className="payBtns px-4 py-2 rounded tw-text-[#711FFF] hover:tw-bg-[#711FFF] hover:tw-text-white tw-border tw-border-[#711FFF] tw-rounded "
                onClick={handleCancleProfile}
              >
                Cancel
              </button>
              {!loader ? (
                <button
                  style={{
                    border: '1px solid #711FFF',
                    marginLeft: '30px',
                    width: '90px',
                  }}
                  className="payBtn rounded px-4 py-2 tw-text-white tw-border tw-border-[#711FFF] tw-bg-[#711FFF] tw-rounded"
                  onClick={handleSaveProfile}
                >
                  {' '}
                  Save{' '}
                </button>
              ) : (
                <button
                  style={{
                    border: '1px solid #711FFF',
                    marginLeft: '30px',
                    width: '90px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="payBtn rounded px-4 py-2 tw-text-white tw-border tw-border-[#711FFF] tw-bg-[#711FFF] tw-rounded"
                >
                  {' '}
                  <Box sx={{ width: '20px', height: '20px' }}>
                    {' '}
                    <PreLoader />{' '}
                  </Box>{' '}
                </button>
              )}
            </Box>
            {showAlert && (
              <AlertShowComponent
                setShowAlert={setShowAlert}
                msg={msg}
                setMsg={setMsg}
              />
            )}
          </div>
        </section>
      </Bounce>
    </>
  );
};

export default MyProfile;

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CheckCircleOutline } from '@mui/icons-material';
import TitleBar from '../../Componenets/atomic-molecules/Headers/TitleBar';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';

const AlertShowComponent = ({ showAlert, setShowAlert, msg, setMsg }) => {
  setTimeout(() => {
    setShowAlert(false);
    setMsg('');
  }, 3000);

  return (
    <Box
      sx={{
        width: '850px',
        height: '300px',
        padding: '2rem',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '40%',
        left: { xl: '23%', lg: '5%', md: '5%' },
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '98%',
          height: '95%',
          backgroundColor: 'rgba(255,255,255)',
          border: '1px solid #d9d9d9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '0.68rem 1rem',
          justifyContent: 'space-around',
          boxShadow: '2px 2px 6px 0px #D9D9D9',
        }}
      >
        <p
          style={{
            fontSize: '1.1rem',
            fontWeight: '500',
            fontFamily: 'Open Sans, sans-serif',
            color: '#db8000',
          }}
        >
          <InfoOutlinedIcon
            SX={{ color: '#db8000', fontSize: '22px', marginRight: '10px' }}
          />{' '}
          Update Your Information
        </p>
        <p
          style={{
            color: '#232125',
            letterSpacing: '-0.3px',
            textAlign: 'center',
            fontSize: '1rem',
            fontFamily: "'Open sans', sans-serif",
            fontWeight: '500',
          }}
        >
          {msg}
        </p>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            margin: '0 auto',
          }}
        ></Box>
      </Box>
    </Box>
  );
};
