import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';

import VideoDemo from '../../Componenets/VideoDemo';
import { appRoutes } from '../../appRoutes';
import './netnetU.css';

function AllLesson() {
  const chapters = useSelector((state) => state.NetNetU.chapters);
  return (
    <section className="oppor-section">
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">All Lesson</h3>
        </div>
      </div>
      <div className="btn-group">
        <Tooltip title="Coming Soon 👀" arrow>
          <Link to={appRoutes.netnetU}>NetNetU</Link>
        </Tooltip>
        <ChevronRightIcon />
        <Link to={appRoutes.allLessons}>All Lessons</Link>
      </div>
      <Grid
        container
        direction="column"
        sx={{
          marginTop: '30px',
          gap: '2rem',
        }}
      >
        {chapters.map((codex) => (
          <>
            <h3 className="chapter-title">{codex.title}</h3>
            <Grid container spacing={1}>
              {codex.lessons.map((lesson) => (
                <Grid
                  item
                  xs={3}
                  key={lesson.id}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <VideoDemo
                    thumbnail={lesson.thumbnail}
                    duration={lesson.duration}
                    video={lesson.video}
                    lesson={lesson}
                    renderDet={true}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ))}
      </Grid>
    </section>
  );
}

export default AllLesson;
