import SectionHeader from '../../../../Componenets/atomic-atoms/SectionHeader';
import ComingSoon from '../../../../Componenets/atomic-atoms/ComingSoon';

function Templates() {
  return (
    <>
      <SectionHeader title="Templates" />
      <ComingSoon title="Templates" />
    </>
  );
}

export default Templates;
