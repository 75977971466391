import { Alert } from '@mui/material';
import SampleCSV from './SampleCSV';
import { useRouteTo } from '../../../hooks/useRouteTo';

function FirstStep({ setStep, screenHeight }) {
  const routeTo = useRouteTo();

  return (
    <div
      style={{
        height: screenHeight - 170,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '2rem',
        paddingTop: '1rem',
      }}
    >
      <section className="row">
        <h4 className="txtCenter">
          Here you can import existing contacts from other CRM systems, or
          files.
        </h4>
      </section>
      <section className="row">
        <article className="uploadBox">
          <div className="white-box" />
          <h3 className="txtCenter txtSM">File upload</h3>
          <p className="txtCenter initialParagraph txtESM">
            Please be sure to have a <strong>.csv</strong> file with headers
            ready to import.
          </p>
          <button
            type="button"
            className="btn btnSm"
            onClick={() => setStep(2)}
          >
            Start
          </button>
        </article>
      </section>

      <Alert
        severity="info"
        action={<SampleCSV />}
        sx={{
          borderRadius: '8px',
          padding: '1rem',
          fontSize: '0.9rem',
          textAlign: 'center',
          '& .MuiAlert-action': {
            padding: '0 0.5rem',
          },
        }}
      >
        You can download a sample CSV file:
      </Alert>

      <div className="AlRight dd-import-group">
        <button
          type="button"
          className="btninCc alRight"
          onClick={() => routeTo(-1)}
        >
          Go back
        </button>
      </div>
    </div>
  );
}
export default FirstStep;
