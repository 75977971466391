import SectionHeader from '../../../../Componenets/atomic-atoms/SectionHeader';
import ComingSoon from '../../../../Componenets/atomic-atoms/ComingSoon';

function AccountReports() {
  return (
    <>
      <SectionHeader title="Account Reports" />
      <ComingSoon title="Account Reports" />
    </>
  );
}

export default AccountReports;
