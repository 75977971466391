import { useState } from 'react';

import { Box, Alert } from '@mui/material';

import Papa from 'papaparse';
import { useRouteTo } from '../../../hooks/useRouteTo';

const importLimit = 500;
const errorMessageForImportLimit =
  'The file contains over 500 contacts. Please upload a file with 500 or fewer contacts to proceed.';

function SecondStep({
  step,
  setStep,
  setError,
  setImportData,
  setErrorMessage,
  error,
  errorMessage,
  setNewParser,
}) {
  const routeTo = useRouteTo();

  const types = ['text/csv'];
  const [file, setFile] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleFileUpload = (selectedFile) => {
    if (selectedFile && types.includes(selectedFile.type)) {
      setFile(selectedFile);

      sessionStorage.setItem('importContactsFileName', selectedFile.name);

      setError('');

      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (result.data.length > importLimit) {
            setError(true);
            setErrorMessage(errorMessageForImportLimit);
            setFile(null);
            return;
          }

          const dataWithContent =
            result.data.filter((row) =>
              Object.values(row).some((value) => value.trim() !== ''),
            ) ?? [];

          setNewParser(dataWithContent);
        },
      });
    } else {
      setFile(null);
      setError(true);
      setErrorMessage('Please select a csv file (text/csv)');
    }
  };

  const handleFileOnDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      const selectedFile = e.dataTransfer.files[0];

      handleFileUpload(selectedFile);
    }
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];

    handleFileUpload(selectedFile);
  };

  const processCSVFileAndSetImportData = () => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      if (reader.readyState === 2) {
        const first_names = [];
        const last_names = [];
        const emails = [];
        const companies = [];
        const res = reader.result.split(',');

        // eslint-disable-next-line
        for (let i = 0; i < res.length; i++) {
          if (i % 4 === 0) {
            first_names.push(res[i]);
          } else if (i % 4 === 1) {
            last_names.push(res[i]);
          } else if (i % 4 === 2) {
            emails.push(res[i]);
          } else if (i % 4 === 3) {
            companies.push(res[i]);
          }
        }

        const finalResult = {
          first_name: first_names,
          last_name: last_names,
          emails,
          preCompanies: companies,
          company: [...new Set([...companies])],
        };

        setImportData(finalResult);
        setStep(3);
      }
    };
  };

  return (
    <>
      <div className="FileCheck">
        <div className="steps-container">
          <h3 className="txtCenter">
            Importing Contacts & Companies : Step 1 - Import File
          </h3>
          <p className="txtCenter initialParagraph txtESM">
            This first step is easy peasy. Simply click the select button and
            choose the <strong>.csv</strong> file of your contacts to import.
          </p>
        </div>

        <div className="row">
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleFileOnDrop}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <label
              htmlFor="file"
              className="file-input"
              style={{
                border: error ? '2px dashed red' : '2px dashed #711fff',
              }}
            >
              <div className="grey-box" />
              <p className="txtCenter initialParagraph txtESM">
                {file ? (
                  file.name
                ) : (
                  <span>
                    Drag and Drop or Choose a <strong>.csv</strong> file to
                    upload your Contacts.
                  </span>
                )}
              </p>
              <input
                type="file"
                id="file"
                onChange={handleFileSelect}
                title=""
                className="NoOpec"
              />
            </label>
            {error && (
              <Alert
                severity="error"
                style={{ width: '100%', marginTop: '0.5rem' }}
              >
                {errorMessage}
              </Alert>
            )}
          </div>
        </div>
      </div>

      <Box
        sx={{
          alignItems: 'end',
          width: { xl: '85%', lg: '80%', md: '98%' },
          paddingBottom: '1rem',
          marginBottom: '1rem',
          border: '1px solid #ccc',
          boxShadow: 'unset',
        }}
        className="AlBottom footer"
      >
        <ul className="footer-list">
          <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
            Import File
          </li>
          <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
            Map Fields
          </li>
          <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
            {/* ! '@Type/Tags'. (See 'FourthStep.jsx' for more.) */}
            {/* Type */}
            Ready to Import!
          </li>
          <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
            Exceptions
          </li>
        </ul>

        <div className="AlRight dd-import-group">
          <button
            type="button"
            className="cancel-btn alRight fsESM border"
            onClick={() => routeTo(-1)}
            style={{
              borderRadius: '4px',
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="smBtn alRight"
            onClick={processCSVFileAndSetImportData}
            disabled={!file}
          >
            Next
          </button>
        </div>
      </Box>
    </>
  );
}

export default SecondStep;
