import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import Bounce from 'react-reveal/Bounce';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Avatar, Menu, MenuItem } from '@mui/material';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { CSVLink } from 'react-csv';

import { useDispatch, useSelector } from 'react-redux';

import Dots from '../assets/Dots.png';
import ExportIcon from '../assets/ExportIcon.png';
import DeleteIcon from '../assets/DeleteIcon.png';
import { getPersons, getCompanies } from '../ContactRedux';
import { NetNetSwitch } from '../../../Componenets/Misc';
import HelpVideo from '../../../Componenets/TopNav/HelpVideo';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';
// eslint-disable-next-line
import TitleBar from '../../../Componenets/atomic-molecules/Headers/TitleBar';
import NetNetTable from '../../../Componenets/atomic-organisms/Tables/NetNetTable';
import { Dropdown } from '../Companies/Companies';
import {
  loadColumnsFromLocalStorage,
  saveColumnsToLocalStorage,
} from '../../../cache/columnsPersistence';
import { BrowserStorageManager } from '../../../cache/BrowserStorageManager';

const sessionStorageManager = new BrowserStorageManager(sessionStorage);
const localStorageManager = new BrowserStorageManager(localStorage);

function Filter({
  setFilterBase,
  filterBase,
  filterOption,
  setFilterOption,
  projects,
  setPreRows,
}) {
  const applyToFiltertheData = () => {
    const filteredProject = projects.filter((item) =>
      filterBase.includes(item.contact_type),
    );
    const newData = filteredProject.map((person) => ({
      avatar: person.avatar,
      id: person.id,
      first_name: ` ${person.last_name}  ${person.first_name}`,
      last_name: person.last_name,
      email: person.email,
      company: person.company ? person.company : '--------',
      type: person.contact_type ? person.contact_type : 'Customer',
      Options: Dots,
      unique_id: person.unique_id,
    }));
    setPreRows(newData);
    setFilterOption(false);
  };

  return (
    <Offcanvas
      show={filterOption}
      onHide={() => setFilterOption(false)}
      scroll={false}
      backdrop
      placement="bottom "
    >
      <Offcanvas.Header>
        <h4 className="sub-heading">People Filters</h4>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <section className="filterBody">
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '74%',
            }}
          >
            <div className="fstxt">Type :</div>
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Customer')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Customer'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Customer']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Customer</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Former Customer')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Former Customer'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Former Customer']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Former Customer</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Prospect')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Prospect'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Prospect']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Prospects</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Lead')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Lead'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Lead']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Lead</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Partner')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Partner'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Partner']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },

                    '&.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#711fff',
                    },

                    '&.Mui-checked:hover + .MuiSwitch-track': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Partners</div>}
            />
          </FormGroup>
        </section>
        <section className="dFlex">
          {/* eslint-disable-next-line */}
          <button
            type="button"
            style={{ marginTop: '1rem' }}
            className="close-modal"
            onClick={() => setFilterOption(false)}
          />
          <section className="AlRight">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => {
                setFilterOption(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={applyToFiltertheData}
            >
              Apply Filter
            </button>
          </section>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function OptBtn({
  params,
  setSureDelete,
  setCustomParams,
  updateInnerNetNetTabs,
}) {
  const routeTo = useRouteTo();

  useEffect(() => {
    setCustomParams(params);
  }, []);

  return (
    <Popup
      trigger={
        <button type="button" className="noBorder">
          <img src={params.value} alt="more" />
        </button>
      }
      position="right center"
    >
      <ul className="popup" style={{ width: '150px' }} id={params.id}>
        <li
          className="popupChildren"
          onClick={() => {
            routeTo(`${appRoutes.editContact}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Edit Contact',
              `${appRoutes.editContact}/${params.row.unique_id}`,
            );
          }}
        >
          Edit
        </li>

        <li
          className="popupChildren LastChild"
          onClick={() => {
            setCustomParams(params);
            setSureDelete(true);
          }}
          style={{ color: 'red' }}
        >
          Delete
        </li>
      </ul>
    </Popup>
  );
}

// eslint-disable-next-line
export function People({ screenHeight, updateInnerNetNetTabs }) {
  const [sureDelete, setSureDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [customParams, setCustomParams] = useState({
    row: {},
  });

  // eslint-disable-next-line
  const [deleteTheme, setDeleteTheme] = useState(null);
  const handleRowClassName = (params) => {
    if (deleteTheme === params?.id) {
      return { backgroundColor: 'red' };
    }
    return {};
  };

  const [sortModel, setSortModel] = useState(
    sessionStorageManager.get('sortModel')
      ? sessionStorageManager.get('sortModel')
      : [
          {
            field: 'first_name',
            sort: 'asc',
          },
        ],
  );

  useEffect(() => {
    sessionStorageManager.set('sortModel', sortModel);
  }, [sortModel]);

  const [menuIconEl, setMenuIconEl] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const persons = useSelector((state) => state.Contacts.persons);
  const routeTo = useRouteTo();
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);

  const [projects, setProjects] = useState([]);
  const [filterOption, setFilterOption] = useState(false);
  const [filterBase, setFilterBase] = useState([
    'Lead',
    'Former Customer',
    'Customer',
    'Prospect',
    'Partner',
  ]);
  const currentUser = useSelector((state) => state.userData.userData);
  const handleExportToExcel = () => {
    if (selectedPeople.length > 0) {
      const peopleRecord = selectedPeople
        .map((pid) => persons.find((person) => person.id === pid))
        .filter((record) => !!record)
        .map(
          ({
            id,
            unique_id,
            opportunity,
            avatar,
            title,
            office_tel,
            mobile_num,
            first_name,
            last_name,
            email,
            country,
            street,
            city,
            state,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            category,
            activated,
            created_by,
            project,
          }) => ({
            id,
            unique_id,
            opportunity,
            avatar,
            title,
            office_tel,
            mobile_num,
            first_name,
            last_name,
            email,
            country,
            street,
            city,
            state,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            category,
            activated,
            created_by,
            project,
            ' ': '',
          }),
        );
      return peopleRecord;
    }
    return [];
  };

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getPersons(meta));
    setProjects(persons);
  }, []);

  useEffect(() => {
    setProjects(persons);
  }, [persons]);
  const menuRef = useRef(null);

  const [columns, setColumns] = useState([
    {
      field: 'id',
      headerName: 'ID',
      hideable: false,
      width: 50,
      renderHeader: () => <div className="tableHeader" />,
      renderCell: (params) => (
        <div className="td">
          <Avatar
            sx={{
              width: 27,
              height: 27,
              paddingTop: { xl: '2px', lg: '0px', md: '0px', sm: '0px' },
              paddingRight: { xl: '1px', lg: '0px', md: '0px', sm: '0px' },
            }}
            src={
              params.row.avatar
                ? params.row.avatar
                : '/static/images/avatar/1.jpg'
            }
            alt={params.row.first_name[1]}
          />
        </div>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'left',
    },
    {
      field: 'last_name',
      headerName: 'First Name',
      width: 210,
      align: 'left',
      renderHeader: () => <div className="tableHeader">First Name</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            routeTo(`${appRoutes.personDetail}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Person Detail',
              `${appRoutes.personDetail}/${params.row.unique_id}`,
            );
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'first_name',
      headerName: 'Last Name',
      width: 210,
      align: 'left',
      renderHeader: () => <div className="tableHeader">Last Name</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            routeTo(`${appRoutes.personDetail}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Person Detail',
              `${appRoutes.personDetail}/${params.row.unique_id}`,
            );
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 210,
      renderHeader: () => <div className="tableHeader">Company</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      renderHeader: () => <div className="tableHeader">Type</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      renderHeader: () => <div className="tableHeader">Email</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            const mailtoURL = `mailto:${params.value}`;
            window.open(mailtoURL);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
      renderHeader: () => <div className="tableHeader">Title</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'mobile',
      headerName: 'Mobile Number',
      width: 150,
      renderHeader: () => <div className="tableHeader">Mobile</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'address',
      headerName: 'Address 1',
      width: 200,
      renderHeader: () => <div className="tableHeader">Address 1</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 200,
      renderHeader: () => <div className="tableHeader">Address 2</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
      renderHeader: () => <div className="tableHeader">City</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
      renderHeader: () => <div className="tableHeader">State</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      width: 200,
      renderHeader: () => <div className="tableHeader">Zip Code</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 200,

      renderHeader: () => <div className="tableHeader">Country</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'facebook',
      headerName: 'Facebook',
      width: 200,
      renderHeader: () => <div className="tableHeader">Facebook</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'instagram',
      headerName: 'Instagram',
      width: 200,
      renderHeader: () => <div className="tableHeader">Instagram</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'x_twitter',
      headerName: 'X',
      width: 200,
      renderHeader: () => <div className="tableHeader">X</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'linkedin',
      headerName: 'Linked in',
      width: 200,
      renderHeader: () => <div className="tableHeader">Linked in</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 200,
      renderHeader: () => <div className="tableHeader">Tags</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'Options',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      lockVisibility: true,
      hideable: false,
      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <OptBtn
            updateInnerNetNetTabs={updateInnerNetNetTabs}
            params={params}
            setSureDelete={setSureDelete}
            setCustomParams={setCustomParams}
            menuRef={menuRef}
          />
        </div>
      ),
    },
  ]);

  const peopleTableCOlumnOrderStorageKey = `peopleTableCOlumnOrderForUserID${currentUser.id}`;

  useEffect(() => {
    setColumns(
      loadColumnsFromLocalStorage(peopleTableCOlumnOrderStorageKey, columns),
    );
  }, []);

  const rows = projects.map((person) => ({
    avatar: person.avatar,
    id: person.id,
    first_name: ` ${person.last_name} `,
    last_name: person.first_name,
    email: person.email,
    company: person.company ? person.company : '--------',
    type: person.contact_type ? person.contact_type : 'Customer',
    unique_id: person.unique_id,
    title: person.title ?? '--------',
    mobile: person?.mobile_num ?? '--------',
    address: person.street ?? '--------',
    address2: person.street2 ?? '--------',
    city: person.city ?? '--------',
    state: person.state ?? '--------',
    zip: person.address_zip ?? '--------',
    country: person.country ?? '--------',
    facebook: person.facebook_url ?? '--------',
    x_twitter: person.twitter_url ?? '--------',
    linkedin: person.linkedin_url ?? '--------',
    instagram: person.instagram_url ?? '--------',
    tags: person.tags ?? '--------',
    Options: Dots,
  }));

  const searchedRows = searchQuery
    ? rows.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.email?.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : rows;

  const [preRows, setPreRows] = useState([]);

  useEffect(() => {
    setPreRows(searchedRows);
  }, [projects, searchQuery]);

  const [myDropDown, setShowDropDown] = useState(false);
  document.addEventListener('click', (e) => {
    if (e.target.id !== 'c-btn') {
      setShowDropDown(false);
    }
  });

  useEffect(() => {
    dispatch(
      getPersons({
        access_token,
      }),
    );
    dispatch(
      getCompanies({
        access_token,
      }),
    );
  }, [sureDelete]);

  const handleColumnOrderChange = (newColumnOrder) => {
    const updatedColumns = [...columns.map((item) => ({ ...item }))];
    const columnToMove = updatedColumns[newColumnOrder.targetIndex - 1];
    const find = columns.find(
      (item) => item.field === newColumnOrder.column.field,
    );

    updatedColumns[newColumnOrder.targetIndex - 1] = find;
    updatedColumns[newColumnOrder.oldIndex - 1] = columnToMove;

    saveColumnsToLocalStorage(peopleTableCOlumnOrderStorageKey, updatedColumns);
    setColumns(updatedColumns);
  };

  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'Peoples');
  });
  const [show, setShow] = useState(false);

  const baseKey = 'peopleTableColumnsVisibilityForUserID';
  const storageKey = `${baseKey}${currentUser?.id}`;

  const defaultVisibilityModel = {
    address: false,
    address2: false,
    city: false,
    type: false,
    facebook: false,
    instagram: false,
    linkedin: false,
    x_twitter: false,
    country: false,
    zip: false,
    tags: false,
  };

  const getStoredVisibility = () => {
    const storedSettings = localStorageManager.get(storageKey);
    return storedSettings ?? defaultVisibilityModel;
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    getStoredVisibility(),
  );

  useEffect(() => {
    localStorageManager.set(storageKey, columnVisibilityModel);
  }, [columnVisibilityModel]);

  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };

  const itemName = selectedPeople.length > 1 ? 'Companies' : 'Company';
  const deleteEndPoint =
    selectedPeople.length > 0
      ? `api/contact/delete/`
      : `api/contact/delete/${customParams?.row?.id}/`;

  return persons.length < 1 ? (
    <section className="oppor-section">
      <TitleBar
        helpIconFunction={() => setShow(true)}
        sectionTitle="People"
        buttonFunction={() => setShowDropDown(!myDropDown)}
        buttonRequired={true}
        buttonTitle="New"
      />
      {myDropDown && (
        <Dropdown
          routeTo={routeTo}
          updateInnerNetNetTabs={updateInnerNetNetTabs}
        />
      )}
      <section className="myCenter" style={{ height: screenHeight - 155 }}>
        <article className="box-txt">
          <h3 className="txtCenter">
            Whoopsy.... You don&apos;t have any People just yet!
          </h3>
          <p className="txtCenter initialParagraph">
            That&apos;s okay, you can easily add one at a time or many through
            the import process. Let&apos;s get started, eh?
          </p>
          <article className="buttonGroup">
            <button
              type="submit"
              className="btn"
              onClick={() => {
                updateInnerNetNetTabs(
                  'Import People & Companies',
                  appRoutes.importContacts,
                );
                routeTo(appRoutes.importContacts);
              }}
            >
              Import
            </button>
            <button
              type="submit"
              className="btn"
              onClick={() => {
                routeTo(appRoutes.newPerson);
                updateInnerNetNetTabs('New Person', appRoutes.newPerson);
              }}
            >
              New Person
            </button>
          </article>
        </article>
      </section>
      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
    </section>
  ) : (
    <Bounce left>
      <section className="oppor-section">
        <TitleBar
          helpIconFunction={() => setShow(true)}
          sectionTitle="People"
          Dotopen={menuIconEl}
          handleDotDropDown={(e) => setMenuIconEl(e.currentTarget)}
          menuDotRequired={true}
          buttonFunction={() => setShowDropDown(!myDropDown)}
          buttonRequired={true}
          buttonTitle="New"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchBarRequired={true}
        />
        <div className="fixed">
          {myDropDown && (
            <Dropdown
              routeTo={routeTo}
              updateInnerNetNetTabs={updateInnerNetNetTabs}
            />
          )}

          <Menu
            id="company-icon-menu"
            sx={{
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
              },
              '& .MuiMenu-list': {
                margin: '5px 15px 5px 5px',
              },
            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <MenuItem className="mb-2">
              <CSVLink
                style={{ color: 'black' }}
                enclosingCharacter=""
                data={handleExportToExcel()}
                onClick={() => handleExportToExcel().length > 0}
                filename="People.csv"
              >
                <img alt="icon" src={ExportIcon} className="icon-sm" />
                Export Selected
              </CSVLink>
            </MenuItem>
            {currentUser.is_owner || currentUser.is_admin ? (
              <MenuItem
                className="text-danger mb-2"
                onClick={() => {
                  setSureDelete(true);
                  setMenuIconEl(null);
                }}
              >
                <img alt="icon" src={DeleteIcon} className="icon-sm" />
                Delete Selected
              </MenuItem>
            ) : null}
          </Menu>
        </div>

        <NetNetTable
          rows={preRows}
          columns={columns}
          screenHeight={screenHeight}
          sortModel={sortModel}
          setSortModel={setSortModel}
          selectRow={selectedPeople}
          setSelectRow={setSelectedPeople}
          handleColumnOrderChange={handleColumnOrderChange}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          handleRowClassName={handleRowClassName}
          componentsProps={{
            panel: {
              sx: {
                [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                  display: 'none',
                },
                [`& .MuiDataGrid-columnsPanel > div:last-child`]: {
                  display: 'none',
                },
              },
            },
          }}
          sx={{
            '&.MuiDataGrid-columnHeaders': {
              backgroundColor: '#f2f2f2',
            },
            minHeight: screenHeight - 155,
            maxHeight: screenHeight - 155,
            borderRadius: '8px',
            width: '100%',
            minWidth: 1200,
            '&::-webkit-scrollbar': {
              height: '2px',
              width: '2px',
              backgroundColor: '#f0f0f0',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#b8b8b8',
              borderRadius: '2px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#999999',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#b8b8b8 #f0f0f0',
          }}
          checkboxSelection={true}
          initialState={{
            columns: {
              columnVisibilityModel,
            },
          }}
          setSureDelete={setSureDelete}
          sureDelete={sureDelete}
          itemName={itemName}
          paragraph={`Are You Sure You want to Delete
                      ${selectedPeople.length > 1 ? 'These Contacts' : 'This Contact'}? 
                      Removing ${selectedPeople.length > 1 ? 'These Contacts' : 'This Contact'}
                      will remove all associated data.`}
          deleteEndPoint={deleteEndPoint}
          // eslint-disable-next-line
          multipleDelete={selectedPeople.length > 0 ? true : false}
          multipleDeleteData={selectedPeople}
        />
      </section>

      <Filter
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        filterBase={filterBase}
        setFilterBase={setFilterBase}
        projects={projects}
        setProjects={setProjects}
        preRows={preRows}
        setPreRows={setPreRows}
      />

      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
    </Bounce>
  );
}
