import { CacheManagerBase } from './CacheManagerBase';

/**
 * * Usage:
 * *   const localStorageManager = new BrowserStorageManager(localStorage);
 * *   const sessionStorageManager = new BrowserStorageManager(sessionStorage);
 * *
 * *   // Set, Get, Delete, and Clear data in the respective storage
 * *   localStorageManager.set('key', '[1, 2, 3]');
 * *   localStorageManager.get('key'); // Returns '[1, 2, 3]'
 * *   localStorageManager.delete('key');
 * *   localStorageManager.clear();
 *
 * ! Instantiate the class outside of the component to avoid re-instantiating it on every render.
 * *  This will prevent unnecessary performance overhead.
 */

// eslint-disable-next-line
export class BrowserStorageManager extends CacheManagerBase {
  constructor(storage = localStorage) {
    super();
    this.storage = storage;
  }

  get(key) {
    const value = this.storage.getItem(key);
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }

  set(key, value) {
    const data = typeof value === 'object' ? JSON.stringify(value) : value;
    this.storage.setItem(key, data);
  }

  delete(key) {
    this.storage.removeItem(key);
  }

  // ! Use this method with caution.
  clear() {
    this.storage.clear();
  }
}
