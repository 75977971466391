import { useCallback, useEffect, useState } from 'react';

import { getJobDetail } from '../../api/jobs/getJobDetail';

// eslint-disable-next-line
export const useGetJobDetail = (accessToken, jobUniqueID, shouldFetch) => {
  const [jobDetail, setJobDetail] = useState(null);
  const [isJobDetailRequestLoading, setIsJobDetailRequestLoading] =
    useState(false);
  const [errorWhenFetchingJobDetail, setErrorWhenFetchingJobDetail] =
    useState(null);

  const fetchJobDetail = useCallback(async () => {
    if (!shouldFetch || !accessToken || !jobUniqueID) {
      return;
    }

    try {
      setIsJobDetailRequestLoading(true);
      const data = await getJobDetail(accessToken, jobUniqueID);

      setJobDetail(data);
      setErrorWhenFetchingJobDetail(null);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error fetching Job Detail:', err);
      setErrorWhenFetchingJobDetail(
        'Unable to fetch Job Detail. Please try again later.',
      );
    } finally {
      setIsJobDetailRequestLoading(false);

      setTimeout(() => {
        setErrorWhenFetchingJobDetail(null);
      }, 3000);
    }
  }, [accessToken, jobUniqueID, shouldFetch]);

  useEffect(() => {
    if (shouldFetch) {
      fetchJobDetail();
    }
  }, [fetchJobDetail, shouldFetch]);

  return {
    jobDetail,
    isJobDetailRequestLoading,
    errorWhenFetchingJobDetail,
  };
};
