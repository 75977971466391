import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Shake from 'react-reveal/Shake';
import { PreLoader1 } from '../../Misc';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';

function ConfirmDeletionModal({
  setSureDelete,
  deleteEndPoint,
  itemName,
  paragraph,
  currentTargetData,
  multipleDelete,
  multipleDeleteData,
}) {
  const [val, setVal] = useState();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
    setLoading(false);
    const timeoutId = setTimeout(() => {
      setErrorMessage(false);
    }, 3000);

    // Clear timeout on unmount
    return () => clearTimeout(timeoutId);
  };

  const handleDeleteAction = async () => {
    if (!val || val !== 'DELETE') {
      handleErrorMessage('Please Enter DELETE to Confirm');
      return;
    }

    setLoading(true);

    try {
      if (multipleDelete) {
        const deletionResults = await Promise.all(
          multipleDeleteData.map(async (id) => {
            try {
              const response = await fetch(
                `${ENDPOINT}/${deleteEndPoint}${id}/`,
                {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                },
              );
              return response;
            } catch (error) {
              // eslint-disable-next-line
              console.error(`Failed to delete item with ID ${id}:`, error);
              return { ok: false };
            }
          }),
        );

        const failedDeletions = multipleDeleteData.filter(
          (_, index) => !deletionResults[index].ok,
        );

        if (failedDeletions.length > 0) {
          handleErrorMessage(`Failed to delete ${itemName}`);
          return;
        }
      } else {
        // * Single delete operation.
        const res = await fetch(`${ENDPOINT}/${deleteEndPoint}`, {
          method: 'DELETE',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!res.ok) {
          if (itemName === 'Company') {
            const data = await res.json();
            handleErrorMessage(data.detail);
          } else {
            handleErrorMessage(`Failed to delete ${itemName}`);
          }
          return;
        }
      }

      // Success handling
      setSureDelete(false);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Unexpected error during deletion:', error);
      handleErrorMessage('An unexpected error occurred.');
    } finally {
      setLoading(false);
      setVal('');
    }
  };

  const hasTimeTracking =
    itemName === 'Task' &&
    Array.isArray(currentTargetData?.time_tracking) &&
    currentTargetData.time_tracking.length > 0
      ? currentTargetData.time_tracking.filter(
          (ele) => ele?.tracked_time_hours ?? 0, // Fallback for undefined or null `tracked_time_hours`
        )
      : false;

  return (
    <div className="loading main-background-div-delete-confirmation">
      <div className="container">
        {!hasTimeTracking ? (
          <div className="inner-box">
            <p className="delete-text">
              DELETE {itemName ? itemName.toLocaleUpperCase() : ''}
            </p>
            <p className="fstxt description-text">{paragraph ?? ''}</p>
            <div className="confirm-section">
              <p className="instruction-text fstxt">
                Please type the word &quot;DELETE&quot; and click the Delete
                button
              </p>
              <div className="input-wrapper">
                <input
                  value={val}
                  onChange={(e) => setVal(e.target.value.toLocaleUpperCase())}
                  placeholder="DELETE"
                  className="confirm-delete-input"
                />
              </div>
              {errorMessage && (
                <Shake>
                  {' '}
                  <p style={{ color: 'red' }}>{errorMessage}</p>
                </Shake>
              )}
            </div>
            <div className="action-buttons">
              <button
                type="button"
                className="cancel-btn"
                onClick={() => setSureDelete(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="delete-button"
                onClick={handleDeleteAction}
                disabled={!val}
              >
                {loading ? (
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.1rem',
                    }}
                  >
                    <PreLoader1 />
                  </div>
                ) : (
                  'Delete'
                )}{' '}
              </button>
            </div>
          </div>
        ) : (
          <div className="inner-box">
            <p className="fstxt instruction-text">
              Tasks with logged time cannot be deleted.
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
                margin: '0 auto',
              }}
            >
              <button
                type="button"
                className="delete-button"
                style={{
                  backgroundColor: '#710FFF',
                }}
                onClick={() => setSureDelete(false)}
              >
                Okay
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ConfirmDeletionModal;
