import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getUsers } from './usersReducer';
import NetNetTable from '../../../../Componenets/atomic-organisms/Tables/NetNetTable';
import TitleBar from '../../../../Componenets/atomic-molecules/Headers/TitleBar';
import { useRouteTo } from '../../../../hooks/useRouteTo';

const dummyUsers = [
  {
    personName: 'Ali',
    level: 'AliTech',
    url: '//netnet.com',
    email: 'thenetnet08@gmail.com',
    id: 1,
  },
  {
    personName: 'Petar',
    level: 'SpaceX',
    url: '//spaceX.com',
    email: 'thespacex08@gmail.com',
    id: 2,
  },
  {
    personName: 'Ronaldo',
    level: 'Space2X',
    url: '//spaceX2.com',
    email: 'thespacex0822@gmail.com',
    id: 4,
  },
  {
    personName: 'Ramos',
    level: 'Hard',
    url: '//spaceX2.com',
    email: 'thespacex0822@gmail.com',
    id: 5,
  },
  {
    personName: 'Elon Musk',
    level: 'Space2X',
    url: '//spaceX2.com',
    email: 'thespacex0822@gmail.com',
    id: 6,
  },
];

function SystemAdminUsers() {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.auth?.user?.access);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getUsers(meta))
      .then(() => {})
      .catch((error) => {
        // eslint-disable-next-line
        console.error('Error fetching users:', error);
      });
  }, [dispatch, access_token]);

  const columns = [
    {
      field: 'personName',
      headerName: 'Person Name',
      width: 200,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Person Name
          <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">
          <img
            alt="user"
            src={params.row.picture}
            style={{
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              marginRight: '3px',
            }}
          />
          {params.row.personName}
        </div>
      ),
    },
    {
      field: 'level',
      headerName: 'level',
      width: 180,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Level <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">{params.value}</div>
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 200,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          URL <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">{params.value}</div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Email <ArrowDropDownIcon style={{ marginLeft: '-12px' }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">{params.value}</div>
      ),
    },
  ];

  const routeTo = useRouteTo();

  return (
    <div
      style={{
        padding: '4px 18px',
      }}
    >
      <TitleBar
        sectionTitle="Users"
        buttonRequired={true}
        buttonFunction={() => routeTo(`#`)}
        buttonTitle="New"
      />

      <div className="authorsWrapper">
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <NetNetTable
            rows={
              Array.isArray(dummyUsers) && dummyUsers?.length ? dummyUsers : []
            }
            columns={columns}
            rowHeight={76}
            headerHeight={78}
            sx={{
              '.MuiDataGrid-row': {
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
                letterSpacing: '0.5px',
                backgroundColor: '#FDFDFE',
                maxHeight: '76px',
              },
              '.MuiDataGrid-columnHeader': {
                color: '#03071E99',
                backgroundColor: '#F2F2F2',
                fontFamily: 'Open Sans, sans-serif',
              },
              '.css-1q27lj3-MuiDataGrid-root': {
                borderRadius: '10px',
              },
              '.MuiDataGrid-columnHeaders ': {
                zIndex: 1,
                backgroundColor: '#F2F2F2',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              },
              '.tableHeader': {
                color: ' #03071E99',
              },
              height: '100%',
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: '10px',
              margin: '1.38%',
            }}
            checkboxSelection={true}
          />
        </Box>
      </div>
    </div>
  );
}

export default SystemAdminUsers;
